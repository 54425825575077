import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonReaderProvider } from '../../../infrastructure/providers';
import { ResourcesConsts } from '../../consts/resources.consts';

@Injectable()
export class AutocompleteListProvider {
    predefinedLists: any;
    autoCompleteUrl: string =
        ResourcesConsts.SURVEY_EDITOR_GET_AUTOCOMPLETE_LISTS;

    constructor(private jsonReaderProvider: JsonReaderProvider) {}

    getLists(): Observable<any[]> {
        return (this.predefinedLists = this.jsonReaderProvider.getJsonData(
            this.autoCompleteUrl
        ));
    }

    getListById(id: number): Observable<any> {
        const url = `${this.autoCompleteUrl}/${id}`;
        return this.jsonReaderProvider.getJsonData(url);
    }
}
