import { Routes } from '@angular/router';
import { SecuritySettingsResolver } from '../../shared/services/security-settings.resolver';
import { SharedPaths } from '../../shared/consts/urls';
import { ChangePasswordPageComponent } from '../../shared/containers/change-password-page/change-password-page.component';
import { SurveyResponsePreviewComponent } from '../../shared/survey-components/survey-responses/survey-response-preview/survey-response-preview.component';
import { AccountNameResolverGuard } from '../guards/account-name-resolver.guard';
import { TakeSurveyRedirectGuard } from '../guards/take-survey-redirect.guard';
import { LoginPageComponent } from '../identity/login/login-page.component';
import { RegistrationNewAccountComponent } from '../identity/registration-new-account/registration-new-account.component';
import { ResetPasswordPageComponent } from '../identity/reset-password-page/reset-password-page.component';
import { ApplicationNotActiveComponent } from '../pages/application-not-active/application-not-active.component';
import { BlankPageComponent } from '../pages/blank/blank-page.component';
import { GDPRComponent } from '../pages/gdpr/gdpr.component';
import { NotFoundPageComponent } from '../pages/not-found/not-found-page.component';
import { OldSurveyRedirectComponent } from '../surveys/oldSurveyRedirect/old-survey-redirect.component';
import { SamlProcessorGuard } from '../guards/saml-processor.guard';

export const routes: Routes = [
    {
        path: 'application-not-active',
        component: ApplicationNotActiveComponent
    },
    {
        path: '',
        canActivate: [AccountNameResolverGuard],
        children: [
            {
                path: '',
                component: BlankPageComponent,
                canActivate: [TakeSurveyRedirectGuard],
                pathMatch: 'full'
            },
            {
                path: 'print-response',
                component: SurveyResponsePreviewComponent,
                data: { printMode: true }
            },
            {
                path: 'response',
                component: SurveyResponsePreviewComponent
            },
            {
                path: 'survey.aspx',
                component: OldSurveyRedirectComponent,
                pathMatch: 'full'
            },
            {
                path: 'login',
                canActivate: [SamlProcessorGuard],
                component: LoginPageComponent
            },
            {
                path: 'new-account',
                component: RegistrationNewAccountComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordPageComponent
            },
            {
                path: SharedPaths.changePassword,
                component: ChangePasswordPageComponent,
                resolve: { securitySettings: SecuritySettingsResolver}
            },
            // {
            //     path: 'survey-password/:surveyId',
            //     component: PasswordProtectedFormComponent
            // },
            {
                path: 'reports',
                loadChildren: () => import('../reports/take-survey-reports.module').then(m => m.TakeSurveyReportsModule)
            },
            {
                path: 'surveys',
                loadChildren: () => import('../surveys/surveys-list/take-survey-surveys-list.module').then(m => m.TakeSurveySurveysListModule)
            },
            {
                path: 'gdpr',
                component: GDPRComponent,
                canActivate: []
            },
            {
                path: '404',
                component: NotFoundPageComponent

            },
            {
                path: ':surveyId',
                loadChildren: () => import('../surveys/take-survey-surveys.module').then(m => m.TakeSurveySurveysModule)
            },
            {
                path: '**',
                redirectTo: '/404'
            }
        ]
    },
];
