import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'cb-simple-confirmation-dialog',
    templateUrl: './simple-confirmation-dialog.component.html',
    styleUrls: ['./simple-confirmation-dialog.component.scss']
})
export class SimpleConfirmationDialogComponent implements OnInit {
    public message: string;

    constructor(
        public dialogRef: MatDialogRef<SimpleConfirmationDialogComponent>
    ) {}

    ngOnInit() {}
}
