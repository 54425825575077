import { AfterViewChecked, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { SurveyPagesProvider, SurveyResponseProvider } from '../../../../app-admin/providers';
import { zip } from 'rxjs';
import { first, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SurveyResponseModel, ResponseContactInfo, ResponseWebhookCall } from '../../../../infrastructure/models/surveys/survey-responses.model';

@Component({
    selector: 'cb-survey-response-preview',
    templateUrl: './survey-response-preview.component.html',
    styleUrls: ['./survey-response-preview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SurveyResponsePreviewComponent
    implements OnInit, AfterViewChecked {
    isLoading: boolean;
    pages: any[];

    response: any;
    user: ResponseContactInfo;
    responseWebhookCalls: ResponseWebhookCall[] = [];
    loadWebhooks: boolean;
    expandedWebhookCallId: string;
    readyToPrint: boolean;
    deletedContactId = 'DeletedContact';
    respondentName: string;

    printUrl = ['/surveys', 'print-response'];
    printParams: any = { };
    printMode: boolean;
    standalone: boolean;

    @Input() responseId: string;
    @Input() surveyId: string;

    constructor(
        private surveyResponseProvider: SurveyResponseProvider,
        private surveyPagesProvider: SurveyPagesProvider,
        private activatedRoute: ActivatedRoute,
        @Optional() @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit(): void {
        this.printMode = this.activatedRoute.snapshot.data['printMode'];
        this.initFromQueryParams();
        this.standalone = !this.data;
        if (this.standalone) {
            this.initFromQueryParams();
        } else {
            this.initFromDialogData();
        }
        _.assign(this.printParams, {
            responseId: this.responseId,
            surveyId: this.surveyId
        });
        this.loadWebhooks = !this.standalone && !this.printMode;
        this.fetchData(this.responseId, this.surveyId);
    }

    // we are using afterViewChecked to ensure that content of page is rendered before we will print it
    // also, used setTimeout here because loader have some fadeout time, and if we will exclude setTimeout here,
    // contents of page will be printed, but part of loader will appear. You can replace this solution by setTimeout,
    // but that one looks more reliable

    ngAfterViewChecked(): void {
        if (this.readyToPrint) {
            this.readyToPrint = false;
            setTimeout(() => {
                this.printResponse();
            }, 2000);
        }
    }

    initFromQueryParams() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        this.surveyId = queryParams['surveyId'];
        this.responseId = queryParams['responseId'];
        const tf = { 'true': true, 'false': false };
        _.forEach(queryParams, (value, key) => {
            this.printParams[key] = tf[value];
        });
    }

    initFromDialogData() {
        this.surveyId = this.data && this.data.surveyId;
        this.responseId = this.data && this.data.responseId;
        this.printParams.show_hidden_items = true;
        this.printParams.show_points = true;
    }

    fetchData(responseId: string, surveyId: string) {
        this.isLoading = true;
        const responseDetailsObservable = this.surveyResponseProvider.getResponseById(
            surveyId,
            responseId
        );
        const pagesObservable = this.surveyPagesProvider.loadPages(+surveyId);
        zip(responseDetailsObservable, pagesObservable)
            .pipe(
                first(),
            )
            .subscribe(([_responseData, pages]) => {
                const responseData = new SurveyResponseModel(_responseData);
                this.pages = pages;
                this.response = responseData;
                this.respondentName = !this.response || !this.response.respondent
                    ? 'Anonymous respondent'
                    : (this.response.respondent === this.deletedContactId ? 'Deleted contact' : this.response.respondent);
                this.loadContactInfo(responseId, surveyId, this.response.respondent);
                this.loadWebhookCalls(responseId, surveyId);
                this.isLoading = false;
            }, err => this.errorHandler(err));
    }

    loadContactInfo(responseId: string, surveyId: string, contactId) {
        if (contactId && contactId !== this.deletedContactId) {
            this.surveyResponseProvider.getResponseContactInfo(surveyId, responseId).pipe(
                take(1)
            ).subscribe(contactInfo => {
                if (contactInfo) {
                    this.user = contactInfo;
                }
            });
        }
    }

    loadWebhookCalls(responseId: string, surveyId: string) {
        if (this.loadWebhooks) {
            this.surveyResponseProvider.getResponseWebhookCalls(surveyId, responseId).pipe(
                take(1)
            ).subscribe(data => {
                this.responseWebhookCalls = data;
            });
        }
    }

    isSuccessWebhookCall(call: ResponseWebhookCall): boolean {
        return call.response_status_code >= 200 && call.response_status_code < 300;
    }

    toggleWebhookCallDetails(call: ResponseWebhookCall) {
        if (call.id == this.expandedWebhookCallId) {
            this.expandedWebhookCallId = null;
        } else {
            this.expandedWebhookCallId = call.id;
        }
    }

    isWebhookCallDetailsExpanded(call: ResponseWebhookCall): boolean {
        return call.id == this.expandedWebhookCallId;
    }

    onResponseDetailsLoaded() {
        if (this.printMode) {
            this.readyToPrint = true;
        }
    }

    errorHandler(error) {
        this.isLoading = false;
    }

    prepareTables() {
        const tables = document.querySelectorAll('table');
        const viewportWidth = 768;
        _.map(tables, table => this.transformTable(table, viewportWidth));
    }

    transformTable(table, viewportWidth) {
        const totalWidth = table.offsetLeft + table.offsetWidth;
        if (totalWidth > viewportWidth) {
            const scaleRatio = viewportWidth / totalWidth;
            table.style.transform = `scale(${scaleRatio})`;
        }

    }


    printResponse() {
        // Putting window.close directly after print causes the window to disappear before the print dialog.
        // However, using setTimeout causes the close to be queued on the event queue
        this.prepareTables();
        window.print();
        setTimeout(() => {
            window.close();
        });
    }
}
