import * as _ from 'lodash';
import { getNonUtcDateFromString } from '../../infrastructure/helpers/date.helper';

export enum ExportType {
    REPORT_PDF_EXPORT = 'ReportPdfExport',
    SURVEY_PDF_EXPORT = 'SurveyPdfExport',
    SURVEY_RESPONSE_CSV = 'SurveyResponseCsv',
    SURVEY_RESPONSE_MAXDIFF_CSV = 'SurveyResponseMaxDiffCsv',
    SURVEY_RESPONSE_SPSS_COMPATIBLE_CSV = 'SurveyResponseSpssCompatibleCsv',
    SURVEY_RESPONSE_SPSS = 'SurveyResponseSpss'
}

export enum PdfGenerationStatus {
    REQUESTED = 'Requested',
    LOCKED =  'Locked',
    PROCESSING =  'Processing',
    COMPLETED =  'Completed',
    ERROR =  'Error',
    REPORT_NOT_FOUND =  'ReportNotFound'
}

export class DownloadItem {
    id: string;
    type: string;
    source_id: number;
    source_name: string;
    status: PdfGenerationStatus;
    created: Date;
    file_name: string;
    exception_id: string;
    mime_content_type: string;

    constructor(props) {
        _.forEach(props, (value, key) => {
            if (key === 'created') {
                this[key] = getNonUtcDateFromString(value);
            } else {
                this[key] = value;
            }
        });
    }

}
