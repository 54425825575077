import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomMatSnackBarComponent } from '../../shared/components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class ToasterService {
    private readonly errorConfig: MatSnackBarConfig;
    private readonly infoConfig: MatSnackBarConfig;
    private readonly warningConfig: MatSnackBarConfig;
    private snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>;
    private isInstanceVisible: boolean;
    private messageQueue: Array<any> = Array<any>();
    private contentEventEmitter: EventEmitter<any> = new EventEmitter<any>()
    private validStyles = ['none', 'outline', 'fill'];
    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {
        const config = new MatSnackBarConfig();
        config.horizontalPosition = 'center';
        config.verticalPosition = 'top';
        config.duration = 0;
        this.errorConfig = Object.assign({}, config, {
            panelClass: ['error']
        });
        this.warningConfig = Object.assign({}, config, {
            panelClass: ['warning'],
            duration: 3000
        });
        this.infoConfig = Object.assign({}, config, {
            panelClass: ['info'],
            duration: 3000
        });
    }

    showError(error, hideCopyBtn = false, style?: string, action?: string, onAction?: Subject<void>) : EventEmitter<any> {
        const type = 'error';
        const config = Object.assign({}, this.errorConfig,{
            horizontalPosition: style? 'end' : 'center',
            verticalPosition: style? 'bottom' : 'top',
            panelClass: [type, this.validStyleOrDefault(style)]
        });
        const message = (typeof error) === 'string' ? error : (typeof error.message === 'object') ? error.message.message : error.message;
        this.show(message, action, config, false, error.fullError, hideCopyBtn, type, style, onAction);
        return this.contentEventEmitter;
    }

    showWarning(msg: string, localizedString = false, duration?: number, style?: string, action?: string, onAction?: Subject<void>) {
        this.showTypeMessage(msg, localizedString, duration, 'warning', style, action, onAction);
    }

    showInfo(msg: string, localizedString = false, duration?: number, style?: string, action?: string, onAction?: Subject<void>) {
        this.showTypeMessage(msg, localizedString, duration, 'info', style, action, onAction);
    }
    showSuccess(msg: string, localizedString = false, duration?: number, style?: string, action?: string, onAction?: Subject<void>) {
        this.showTypeMessage(msg, localizedString, duration, 'success', style, action, onAction);
    }
    //lets abstract the show's method logic to a private method
    private showTypeMessage(msg: string, localizedString = false, duration?: number, type?: string, style?: string, action?: string, onAction?: Subject<void>) {
        const config = Object.assign({}, this.infoConfig, {
            horizontalPosition: style? 'end' : 'center',
            verticalPosition: style? 'bottom' : 'top',
            panelClass: [type, this.validStyleOrDefault(style)]
        });
        config.duration = duration ?? config.duration;

        if (localizedString) {
            this.translateSnackbar(msg, config, type, style, action, onAction);
        } else {
            this.show(msg, action, config, true, '', true, type, style, onAction);
        }
    }
    

    private translateSnackbar(text, config, type?: string, style?: string, action?: string, onAction?: Subject<void>) {
        if (text) {
            this.translateService.get(text).subscribe((data: string) => {
                setTimeout(() => {
                    this.show(data, action, config, true, '', true, type, style, onAction);
                });
            });
        }
    }
    private validStyleOrDefault(type: string) {
        return this.validStyles.includes(type) ? type : 'none';
    }
   show(
       message: string,
       action?: string,
       config?: MatSnackBarConfig,
       hideCloseBtn?: boolean,
       fullErrorMessage?: string,
       hideCopyBtn?: boolean,
       type?: string,
       style?: string,
       onAction?: Subject<void>
   ): void {
     if (!config) {
         config = new MatSnackBarConfig();
         config.duration = 0;
         config.verticalPosition = 'top';
       config.horizontalPosition = 'center';
     }

       const sbMessage = {
           message,
           config,
           hideCloseBtn,
           fullErrorMessage,
           hideCopyBtn,
           type,
           style,
           action,
           onAction
       };
       if (this.messageQueue.length) {
           this.messageQueue.forEach(
               item => {
                   if (!_.isEqual(item, sbMessage)) {
                       this.messageQueue.push(sbMessage);
                   }
               }
           );
       } else {
           this.messageQueue.push(sbMessage);
       }

       if (!this.isInstanceVisible) {
           this.showNext();
       }
   }

    private showNext() {
        if (this.messageQueue.length === 0) {
            return;
        }

        const { 
            message, 
            config,
            action,
            hideCloseBtn,
            fullErrorMessage,
            hideCopyBtn,
            type,
            style,
            onAction
        } = this.messageQueue.shift();
        setTimeout(() => {
            this.isInstanceVisible = true;
            this.snackBarRef = this.snackBar.openFromComponent(CustomMatSnackBarComponent, {
                ...config,
                ...action,
                data: {
                    text: message,
                    hideCloseBtn,
                    fullErrorMessage,
                    hideCopyBtn,
                    type,
                    style,
                    action
                }
            });
            this.snackBarRef.afterDismissed().subscribe(() => {
                this.isInstanceVisible = false;
                this.showNext();
            });
            this.snackBarRef.instance.contentEventEmitter.subscribe((data) => {
                this.contentEventEmitter.emit(data);
            });
            if(action && onAction) {
                this.snackBarRef.onAction().subscribe(() => {
                    (<Subject<void>>onAction).next();
                });
            }
        }, 0);
    }
}
