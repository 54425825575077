import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IconsRegistry } from '../../consts/icons.const';

@Component({
    selector: 'cb-icon-with-caret',
    templateUrl: './icon-with-caret.component.html'
})
export class IconWithCaretComponent implements OnInit {
    @Input() name: String;
    @Input() menuTrigger: any;
    @Input() tooltip: String;
    @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
    public isMenuOpened = 'menu-closed';
    get iconRegistered() {
        return Object.keys(IconsRegistry).includes(this.name as string ?? '');
    }

    constructor() {}

    public ngOnInit() {}

    public onClick($event) {
        $event.stopPropagation();
    }

    public appMenuOpen() {
        this.isMenuOpened = 'menu-opened';
    }
    public appMenuClose() {
        this.isMenuOpened = 'menu-closed';
    }

    public openMenu() {
        this.matMenuTrigger.openMenu();
    }

    public closeMenu() {
        this.matMenuTrigger.closeMenu();
    }
}
