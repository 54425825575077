<mat-card>
    <mat-card-title>{{ 'PAGE404.TITLE' | translate }}</mat-card-title>
    <mat-card-content>
        <p>{{ 'PAGE404.INFO' | translate }}</p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/">
            {{ 'PAGE404.BACK' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
