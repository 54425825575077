import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdentityProvider } from '../providers/identity.provider';
import { ISecuritySettings } from '../../app-take-survey/models';
import { AbstractControl } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validators';
import { format } from '../helpers/string.helpers';
import { map, first } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

@Injectable()
export class PasswordService {
    private errorMessage: string;
    private ERROR_MIN_COUNT: string;
    private ERROR_CATEGORIES: string;
    private ERROR_UPPERCASE: string;
    private ERROR_LOWERCASE: string;
    private ERROR_DIGIT: string;
    private ERROR_SPECIAL_CHAR: string;

    constructor(
        private translateService: TranslateService,
        private ldentityProvider: IdentityProvider
    ) {

        translateService
            .get('FORMS.PASSWORD-VALIDATION-ERROR_CATEGORIES')
            .subscribe(text => {
                this.ERROR_CATEGORIES = text;
            });
        translateService
            .get('FORMS.PASSWORD-VALIDATION-ERROR_UPPERCASE')
            .subscribe(text => {
                this.ERROR_UPPERCASE = text;
            });
        translateService
            .get('FORMS.PASSWORD-VALIDATION-ERROR_LOWERCASE')
            .subscribe(text => {
                this.ERROR_LOWERCASE = text;
            });
        translateService
            .get('FORMS.PASSWORD-VALIDATION-ERROR_DIGIT')
            .subscribe(text => {
                this.ERROR_DIGIT = text;
            });
        translateService
            .get('FORMS.PASSWORD-VALIDATION-ERROR_SPECIAL_CHAR')
            .subscribe(text => {
                this.ERROR_SPECIAL_CHAR = text;
            });
    }

    private createErrorMesage(securitySettings: ISecuritySettings): void {
        if (securitySettings) {
            if (securitySettings.min_password_length >= 0) {
                this.translateService
                .get('FORMS.PASSWORD-VALIDATION-ERROR_MIN_COUNT')
                .subscribe(text => {
                    this.ERROR_MIN_COUNT = text;
                    this.errorMessage = format(
                        this.ERROR_MIN_COUNT,
                        securitySettings.min_password_length
                    );
                    const settings: string[] = [];

                    if (securitySettings.has_digit) {
                        settings.push(this.ERROR_DIGIT);
                    }
                    if (securitySettings.has_lowercase_letter) {
                        settings.push(this.ERROR_LOWERCASE);
                    }
                    if (securitySettings.has_special_character) {
                        settings.push(this.ERROR_SPECIAL_CHAR);
                    }
                    if (securitySettings.has_uppercase_letter) {
                        settings.push(this.ERROR_UPPERCASE);
                    }

                    if (settings.length > 0) {
                        this.errorMessage = `${this.errorMessage} ${format(
                            this.ERROR_CATEGORIES,
                            settings.length
                        )} ${settings.join(', ')}`;
                    }
                });

            }
        }
    }

    private setValidatorsToControl(
        passwordControl: AbstractControl,
        securitySettings: ISecuritySettings,
        passwordRequired: boolean
    ): void {
        if (securitySettings) {
            passwordControl.clearValidators();
            passwordControl.setValidators(
                PasswordValidator.getValidators(securitySettings, passwordRequired)
            );
            this.createErrorMesage(securitySettings);
        }
    }

    setValidators(passwordControl: AbstractControl, passwordRequired: boolean = true): void {
        this.setValidatorsObservable(passwordControl, passwordRequired).subscribe();
    }

    setValidatorsObservable(
        passwordControl: AbstractControl,
        passwordRequired: boolean = true
    ): Observable<ISecuritySettings> {
        return this.ldentityProvider
            .getSecuritySettings()
            .pipe(first())
            .pipe(
                map(securitySettings => {
                    this.setValidatorsToControl(
                        passwordControl,
                        securitySettings,
                        passwordRequired
                    );
                    return securitySettings;
                })
            );
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    getErrorMessage$(securitySettings: ISecuritySettings): Observable<string> {
        return forkJoin([
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_MIN_COUNT'),
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_CATEGORIES'),
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_UPPERCASE'),
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_LOWERCASE'),
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_DIGIT'),
            this.translateService.get('FORMS.PASSWORD-VALIDATION-ERROR_SPECIAL_CHAR')
        ]).pipe(
            map(([minCount, categories, upperCase, lowerCase, digit, specialChar]) => {
                if (securitySettings && securitySettings.min_password_length >= 0) {
                    let errorMessage = format(
                        minCount,
                        securitySettings.min_password_length
                    );
                    const settings: string[] = [];

                    if (securitySettings.has_digit) {
                        settings.push(digit);
                    }
                    if (securitySettings.has_lowercase_letter) {
                        settings.push(lowerCase);
                    }
                    if (securitySettings.has_special_character) {
                        settings.push(specialChar);
                    }
                    if (securitySettings.has_uppercase_letter) {
                        settings.push(upperCase);
                    }

                    if (settings.length > 0) {
                        errorMessage = `${errorMessage} ${format(
                            categories,
                            settings.length
                        )} ${settings.join(', ')}`;
                    }
                    return errorMessage;
                } else {
                    return null;
                }
            })
        );
    }
}
