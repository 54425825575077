import { Identity } from '../../models';

export interface State {
    isLoading: boolean;
    identity: Identity;
}

export const initialState: State = {
    isLoading: false,
    identity: null
};
