
import { Injectable } from '@angular/core';
import {  Subject } from 'rxjs';

@Injectable()
export class EventLogService {

    logStream = new Subject<any>();

    isLoggingEnabled = window['__env'] && window['__env'].eventLogEnabled;

    logEvent(message) {
        if (this.isLoggingEnabled) {
            this.logStream.next(message);
        }
    }
}
