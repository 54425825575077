import _ from 'lodash';
import { of, throwError } from 'rxjs';

export const decodeJWTToken$ = (jwt: string, jwtKeys: string[]) => {
    const decodeResult = decodeJWTToken(jwt, jwtKeys);
    return !!decodeResult
        ? of(decodeResult)
        : throwError({ message: 'Invalid token' });
}

export const decodeJWTToken = (jwt: string, jwtKeys: string[]) => {
    try {
        const payload = jwt.split('.')[1];
        const parsedPayload = JSON.parse(atob(payload));
        return _.every(jwtKeys, key => parsedPayload[key])
            ? { email: parsedPayload.email, account: parsedPayload.account }
            : null;
    } catch (error) {
        return null;
    }
}
