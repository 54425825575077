export const SurveyDefaultText = {
    activationPeriodExpired: 'The activation period for this survey has ended.',
    activationPeriodInFuture: 'The activation period for this survey has not started.',
    backButton: 'Back',
    continueButton: 'Continue >>',
    emailAddress: 'Email Address:',
    emailResumeLinkInstruction: 'You could also email yourself. Please enter your address and click the "Send Reminder" button.',
    enterSurveyPassword: 'Please enter the password to take the survey:',
    enterUserName: 'Username',
    enterUserPassword: 'Password',
    finishButton: 'Finish',
    formResetButton: 'Form Reset',
    incorrectPasswordEntered: 'You have entered an incorrect password.',
    invitationRequired: 'This survey is available only by invitation.',
    loginButton: 'Log In',
    loginFailed: 'Login failed, please try again',
    loginText: 'Please enter your credentials',
    mobileResumeInstructions: 'Use the following button to return to your response: ',
    nextButton: 'Next',
    progressSavedText: 'Survey Progress Saved',
    resetPasswordLinkText: 'Forgot password',
    resumeInstructions: 'Save the following link and use to it when you wish to continue where you left off: ',
    saveAndExitButton: 'Save And Exit',
    saveProgressClickHere: 'Click here',
    saveProgressToReturn: ' to return to your response.',
    selectLanguage: 'This survey is available in more than one language. Please select a language from the list below then click "Continue."',
    selfRegistrationLinkText: 'Create new account',
    sendResumeEmailButton: 'Send Reminder',
    surveyDeleted: 'This survey is no longer available.',
    surveyIsNotActive: 'This survey has not been activated.',
    surveyTitle: '',
    answerRecommended: 'An answer is recommended, but not required.',
    totalResponseLimitReached: 'No more responses are permitted for this survey.',
    CompletedColumnHeader: 'Completed',
    EditColumnHeader: 'Edit',
    EmailAddressRequired: 'An email address is required.',
    ResumeEmailBody: 'Below is the link to resume your response to "[SURVEY_NAME]" survey where you last left off. This link is unique to you and your response, so please do not share it with anyone. If you have any questions, please contact your survey administrator.',
    ResumeEmailSubject: 'Link to resume "[SURVEY_NAME]"',
    EmailValidation: 'Resume link has not been sent to the following email addresses:',
    EmptyEmail: 'Email address cannot be empty.',
    ReminderError: 'An unexpected error has occurred while attempting to send your email reminder.',
    StartedColumnHeader: 'Started',
    ReminderSuccess: 'Your email reminder has successfully been sent.',
    ClickHere: 'Click here',
    Completed: 'Completed',
    CreateNewSurvey: ' to take the survey again.',
    EditSurveyPrompt: 'You have already completed this survey.  Click the Edit icon next to the response you wish to edit.',
    InvalidInvitation: 'The specified invitation is not valid for this survey.',
    NoSurveyID: 'No survey ID was specified.  Please verify the URL is correct and try again.',
    Of: 'of',
    Page: 'Page',
    PageNumber: 'Page {0} of {1}.',
    InvalidIncompleteAnswers: 'Invalid or incomplete answers were provided.  Please review your answers and make any necessary corrections.',
    RespondentNotAuthorized: 'This survey is currently not available for you to take.',
    UnableLoadSurvey: 'A survey was specified, but could not be loaded.',
    Started: 'Started',
    SurveyErrorOccurred: 'An error occurred while processing the survey.  Please contact your survey administrator.',
    SurveyMoved: 'Survey moved to folder {0}.',
    AnswerTextPrompt: 'Answer text',
    YourOptionPrompt: 'Your option',
    SelectAnOption: 'Select an option',
    AllowedFileTypes: 'Allowed file type(s):',
    NpsNotAtAllLikely: 'Not at all Likely',
    NpsExtremelyLikely: 'Extremely Likely',
    SignatureClear: 'CLEAR',
    NumberOfCharactersRemainingToMinLength: 'Please enter at least {0} more characters.',
    NumberOfCharactersRemaining: '{0} characters remaining.',
    PrivacyInformationLinkText: 'Privacy Information'
};
