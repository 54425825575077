import { OnInit, Component } from '@angular/core';
import { EventLogService } from '../../../infrastructure/services';

@Component({
    selector: 'cb-event-log',
    templateUrl: './event-log.component.html',
    styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit {
    isEventLogEnabled: boolean;
    eventLog: string[] = [];

    constructor(
        private eventLogService: EventLogService
    ) {}

    ngOnInit() {
        if (this.eventLogService.isLoggingEnabled) {
            this.isEventLogEnabled = true;
            this.eventLogService.logStream.subscribe(msg => {
                this.eventLog.push(msg);
            });
        }
    }

}
