import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';

// Example of using https://www.notion.so/Feature-flag-7cb3b7f9ed2f4a029f6173ae53f0adc5
@Directive({
    selector: '[removeIfFeatureOff]'
})
export class RemoveIfFeatureOff implements OnInit {
    @Input('removeIfFeatureOff') featureName: string;

    constructor(
        private el: ElementRef,
        private featureFlagService: FeatureFlagService) {
        this.featureName = '';
    }

    ngOnInit() {
        if (!this.featureFlagService.isFeatureOn(this.featureName)) {
            this.el.nativeElement.parentNode?.removeChild(this.el.nativeElement);
        }
    }
}
