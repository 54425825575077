import {
    TOGGLE_VALUES,
    ToggleItem
} from '../../infrastructure/consts/toggle.const';

export const activeToggleData: ToggleItem = {
    default: TOGGLE_VALUES.on.name,
    on: 'Active',
    off: 'Inactive'
};

export const surveyActiveData: ToggleItem = {
    default: TOGGLE_VALUES.off.name,
    on: 'Published',
    off: 'Draft'
};
