import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { concatMap, first } from 'rxjs/operators';
import { SurveyResponseProvider } from '../../../infrastructure/providers/responses/survey-response.provider';
import { SurveysSettingProvider } from '../../../infrastructure/providers/surveys-setting.provider';

@Component({
    selector: 'cb-old-redirect',
    template: ''
})
export class OldSurveyRedirectComponent implements OnInit {
    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private surveyResponseProvider: SurveyResponseProvider,
        private surveysSettingProvider: SurveysSettingProvider
    ) {}

    ngOnInit() {
        if (this._route.snapshot.queryParams['s']) {
            const queryParams = { ...this._route.snapshot.queryParams };
            delete queryParams['s'];
            const url = this._route.snapshot.queryParams['s'];
            this._router.navigate([url], { queryParams: queryParams });
        } else if (this._route.snapshot.queryParams['iid']) {
            const resumeKey = this._route.snapshot.queryParams['iid'];
            this.surveyResponseProvider
                .getResponseDataByResumeKey(resumeKey)
                .pipe(first())
                .subscribe(
                    res => {
                        this._router.navigate([res.survey_external_id], {
                            queryParams: { rk: resumeKey }
                        });
                    }
                );
        } else {
            this._router.navigate(['/']);
        }
    }
}
