import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.const';
import { Contact, ContactModel, EditCurrentContactModel } from '../models/contact.model';
import { map } from 'rxjs/operators';

@Injectable()
export class EditMyInfoProvider {
    constructor(private $http: HttpClient) {}

    getCurrentUserInfo(): Observable<Contact> {
        return this.$http.get(ResourcesConsts.CURRENT_USER_INFO).pipe(
            map((data: any) => new ContactModel(data))
        );
    }

    editCurrentUserInfo(contactData: EditCurrentContactModel): Observable<Contact> {
        return this.$http.put(ResourcesConsts.CURRENT_USER_INFO, contactData).pipe(
            map((data: any) => new ContactModel(data))
        );
    }
}
