import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { version } from '../../environments/version';
import { transformToCloudfrontUrl } from '../../infrastructure/helpers/cloudfront.helper';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        transformToCloudfrontUrl('assets/i18n/'),
        '.json?v=' + version
    );
}

@NgModule({
    imports: [
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    exports: [
        TranslateModule
    ]
})
export class LazyTranslateLoaderModule {
}
