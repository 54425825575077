<cb-spinner [show]="isLoading"></cb-spinner>
<div class="dialog-content" *ngIf="!isLoading">
    <h2 mat-dialog-title>
        {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.MODAL-TITLE' | translate }}
    </h2>
    <div class="cb-response-preview-content">
        <div class="cb-section-divider"></div>
        <div class="form-section">
            <mat-expansion-panel
                class="cb-expansion-panel cb-simple-panel cb-response-details-panel"
                [expanded]="standalone"
            >
                <mat-expansion-panel-header
                    [collapsedHeight]="'48px'"
                    [expandedHeight]="'48px'"
                >
                    <mat-panel-title
                        class="d-flex align-items-center ghost-class"
                    >
                        <span class="icon-collapse-wrap">
                            <cb-svg-icon
                                class="icon icon-less"
                                [name]="'collapse-less'"
                            ></cb-svg-icon>
                            <cb-svg-icon
                                class="icon icon-more"
                                [name]="'collapse-more'"
                            ></cb-svg-icon>
                        </span>
                        {{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.RESPONSE-INFORMATION'
                                | translate
                        }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="response-preview-section">
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.STARTED'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.started | date: 'medium'
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.COMPLETED'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.ended | date: 'medium'
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.LAST-EDITED'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.lastEdit | date: 'medium'
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.TOTAL-TIME'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.totalTimeInSeconds
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.IS-TEST'
                                | translate
                        }}</span>
                        <span class="field-value">{{ response.is_test }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.IP-ADDRESS'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.ipAddress
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.TOTAL-SCORE'
                                | translate
                        }}</span>
                        <span class="field-value">{{ response.score }}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="cb-section-divider"></div>
        <div class="form-section">
            <mat-expansion-panel
                class="cb-expansion-panel cb-simple-panel cb-response-details-panel"
                [expanded]="standalone"
            >
                <mat-expansion-panel-header
                    [collapsedHeight]="'48px'"
                    [expandedHeight]="'48px'"
                >
                    <mat-panel-title
                        class="d-flex align-items-center ghost-class"
                    >
                        <span class="icon-collapse-wrap">
                            <cb-svg-icon
                                class="icon icon-less"
                                [name]="'collapse-less'"
                            ></cb-svg-icon>
                            <cb-svg-icon
                                class="icon icon-more"
                                [name]="'collapse-more'"
                            ></cb-svg-icon>
                        </span>
                        {{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.RESPONDENT-INFO'
                                | translate
                        }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="response-preview-section">
                    <h4>
                        {{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.LOGIN-INFO'
                                | translate
                        }}
                    </h4>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.USER-NAME'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            respondentName
                        }}</span>
                    </div>
                    <div class="response-preview-section-field">
                        <span class="field-name">{{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.INVITEE'
                                | translate
                        }}</span>
                        <span class="field-value">{{
                            response.invitee || ''
                        }}</span>
                    </div>
                    <h4>
                        {{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.USER-PROFILE'
                                | translate
                        }}
                    </h4>
                    <ng-container *ngIf="user">
                        <div class="response-preview-section-field">
                            <span class="field-name">{{
                                'SURVEY-RESPONSES.RESPONSE-PREVIEW.EMAIL'
                                    | translate
                            }}</span>
                            <span class="field-value">{{
                                user.email || ''
                            }}</span>
                        </div>
                        <div
                            class="response-preview-section-field"
                            *ngFor="
                                let profileProperty of user.profile_properties
                            "
                        >
                            <span class="field-name">{{
                                profileProperty.property_name
                            }}</span>
                            <span class="field-value">{{
                                profileProperty.value || ''
                            }}</span>
                        </div>
                    </ng-container>
                    <span *ngIf="!user">{{
                        'SURVEY-RESPONSES.RESPONSE-PREVIEW.CONTACT-INFO-NA'
                            | translate
                    }}</span>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="cb-section-divider"></div>
        <div class="form-section">
            <mat-expansion-panel
                class="cb-expansion-panel cb-simple-panel cb-response-details-panel"
                expanded
            >
                <mat-expansion-panel-header
                    [collapsedHeight]="'48px'"
                    [expandedHeight]="'48px'"
                >
                    <mat-panel-title
                        class="d-flex align-items-center ghost-class"
                    >
                        <span class="icon-collapse-wrap">
                            <cb-svg-icon
                                class="icon icon-less"
                                [name]="'collapse-less'"
                            ></cb-svg-icon>
                            <cb-svg-icon
                                class="icon icon-more"
                                [name]="'collapse-more'"
                            ></cb-svg-icon>
                        </span>
                        {{
                            'SURVEY-RESPONSES.RESPONSE-PREVIEW.RESPONSE-DETAILS'
                                | translate
                        }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="response-preview-section">
                    <cb-survey-response-details
                        id="section-to-print"
                        [pages]="pages"
                        [response]="response"
                        [surveyId]="surveyId"
                        [showDisplayMessages]="printParams.include_message_items"
                        [showItemNumeration]="printParams.show_question_numbers"
                        [showPagesNumeration]="printParams.show_page_numbers"
                        [showHiddenItems]="printParams.show_hidden_items"
                        [showPoints]="printParams.show_points"
                        (onLoad)="onResponseDetailsLoaded()"
                    ></cb-survey-response-details>
                    <div
                        class="response-webhook-calls-section"
                        *ngIf="responseWebhookCalls.length"
                    >
                        <h4>{{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-CALLS' | translate }}</h4>
                        <table class="response-webhook-call-log">
                            <thead>
                                <th class="timestamp-col">
                                    {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-DATE-COL-HEADER' | translate }}
                                </th>
                                <th class="url-col">
                                    {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-URL-COL-HEADER' | translate }}
                                </th>
                                <th class="status-col">
                                    {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-STATUS-COL-HEADER' | translate }}
                                </th>
                                <th class="actions-col">&nbsp;</th>
                            </thead>
                            <ng-container *ngFor="let call of responseWebhookCalls">
                                <tr>
                                    <td class="timestamp-col">{{ call.timestamp | date: 'medium' }}</td>
                                    <td class="url-col">{{ call.url }}</td>
                                    <td class="status-col">
                                        <div
                                            [class]="isSuccessWebhookCall(call) ? 'status-block sb-success' : 'status-block sb-error'"
                                        >
                                            <span *ngIf="call.response_status_code">
                                                {{ call.response_status_code }}
                                            </span>
                                            <span *ngIf="!call.response_status_code">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-ERROR-STATUS' | translate }}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="actions-col">
                                        <button
                                            mat-button
                                            class="btn-show-details"
                                            type="button"
                                            (click)="toggleWebhookCallDetails(call)"
                                            *ngIf="!isWebhookCallDetailsExpanded(call)"
                                        >
                                            {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-SHOW-DETAILS' | translate }}
                                        </button>
                                        <button
                                            mat-button
                                            class="btn-hide-details"
                                            type="button"
                                            (click)="toggleWebhookCallDetails(call)"
                                            *ngIf="isWebhookCallDetailsExpanded(call)"
                                        >
                                            {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-HIDE-DETAILS' | translate }}
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="isWebhookCallDetailsExpanded(call)">
                                    <td class="webhook-call-details" colspan="4">
                                        <div>
                                            <span class="webhook-call-details-label">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-ID-LABEL' | translate }}
                                            </span>
                                            <span class="webhook-call-details-text">{{ call.id }}</span>
                                        </div>
                                        <div>
                                            <span class="webhook-call-details-label">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-METHOD-LABEL' | translate }}
                                            </span>
                                            <span class="webhook-call-details-text">POST</span>
                                        </div>
                                        <div *ngIf="call.error_message">
                                            <span class="webhook-call-details-label">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-ERROR-MESSAGE-LABEL' | translate }}
                                            </span>
                                            <span class="webhook-call-details-text">{{ call.error_message }}</span>
                                        </div>
                                        <div *ngIf="call.response_headers">
                                            <div class="webhook-call-details-label">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-RESPONSE-HEADERS-LABEL' | translate }}
                                            </div>
                                            <div class="webhook-call-details-text">{{ call.response_headers }}</div>
                                        </div>
                                        <div *ngIf="call.response_body">
                                            <div class="webhook-call-details-label">
                                                {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.WEBHOOK-RESPONSE-BODY-LABEL' | translate }}
                                            </div>
                                            <div class="webhook-call-details-text">{{ call.response_body }}</div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>

<div
    mat-dialog-actions
    class="buttons-container large-button-container justify-end"
    *ngIf="!isLoading && !standalone"
>
    <a
        mat-button
        class="btn-submit cb-button-default"
        type="button"
        [routerLink]="printUrl"
        [queryParams]="printParams"
        [target]="'_blank'"
    >
        {{ 'SURVEY-RESPONSES.RESPONSE-PREVIEW.PRINT' | translate }}
    </a>
    <button
        mat-button
        mat-dialog-close
        class="btn-cancel cb-button-default"
        type="button"
    >
        {{ 'SHARED.CLOSE' | translate }}
    </button>
</div>
