<div *ngIf="currentStyle===none" [innerHTML]="data.text | keepHtml : tagWhiteList"></div>

<button
    *ngIf="!data.hideCopyBtn&&currentStyle===none"
    mat-raised-button
    (click)="copyToClipboard()">
    {{ 'SHARED.COPY-CLIPBOARD' | translate }}
</button>

<button
    *ngIf="!data.hideCloseBtn&&currentStyle===none"
    class="mat-icon-button cb-icon-button close-btn"
    (click)="dismiss()">
    <cb-svg-icon
        [name]="'close'"
    ></cb-svg-icon>
</button>
<div>

</div>

<div class="styled" *ngIf="currentStyle!==none" [ngClass]="data.style">
    <mat-icon class="main-icon" [svgIcon]="snackBarShowData[data.style][data.type].icon"  [ngClass]="snackBarShowData[data.style][data.type].color"></mat-icon>
    <div>
        <div>
            <span>{{snackBarShowData[data.style][data.type].captionKey|translate}}!</span>
            <div [innerHTML]="data.text | keepHtml : tagWhiteList"></div>
        </div>
        <cb-button
            *ngIf="data.action"
            [color]="snackBarShowData[data.style][data.type].color"
            type="flat"
            [text]="data.action"
            (onClick)="dismissWithAction()"></cb-button>
        <cb-button
            *ngIf="!data.hideCloseBtn&&currentStyle!==none&&!data.action"
            [color]="snackBarShowData[data.style][data.type].color"
            type="flat"
            [text]="'SHARED.COPY-CLIPBOARD' | translate"
            (onClick)="copyToClipboard()"></cb-button>
    </div>
    <mat-icon (click)="dismiss()" class="close-icon" svgIcon="fi_x"></mat-icon>
</div>
