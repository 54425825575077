import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { IdentityStore } from '../store';
import { ToasterService } from '../services/toaster.service';
import { AuthConfigConst } from '../../infrastructure/consts/auth.consts';
import { SsoService } from '../services/sso.service';
import { EnvironmentProvider } from '../providers/environment.provider';
import { SkipInterceptor, InterceptorOptions } from '../../infrastructure/consts/interceptors.const';
import { version } from '../../environments/version';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ErrorProvider } from '../providers';
import { ServerError } from '../models';
import * as _ from 'lodash';
import { retryOnError, RetryOptions } from '../rxjs-custom-operators/retry-on-error.operator';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    appType = environment.isMultiTenant ? 'Checkbox online' : 'Checkbox server';
    isAdminSite = environment.isAdminSite;
    userAgent = window.navigator.userAgent;
    concurrentUserMessage = this.isAdminSite ? 'Another user has signed in with your username. As a result, you have been signed out of Checkbox. \nIf you need to purchase additional user licenses, please contact sales@checkbox.com.'
        : 'Another user has signed in with your username. As a result, you have been signed out of Checkbox.';

    constructor(
        private store: IdentityStore,
        private toaster: ToasterService,
        private router: Router,
        private ssoService: SsoService,
        private environmentProvider: EnvironmentProvider,
        private errorProvider: ErrorProvider
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const ignore404Handling = req.headers.has(SkipInterceptor.NOT_FOUND);
        let headers = req.headers.delete(SkipInterceptor.NOT_FOUND);

        const ignoreErrorHandling = req.headers.has(SkipInterceptor.ERROR_SHOW);
        headers = headers.delete(SkipInterceptor.ERROR_SHOW);

        const ignoreForbiddenHandling = req.headers.has(SkipInterceptor.FORBIDDEN);
        headers = headers.delete(SkipInterceptor.FORBIDDEN);

        const ignore401Handling = req.headers.has(SkipInterceptor.UNAUTHORIZED);
        headers = headers.delete(SkipInterceptor.UNAUTHORIZED);

        const retryAttempts = req.headers.has(InterceptorOptions.RETRY_ATTEMPTS)
            ? +req.headers.get(InterceptorOptions.RETRY_ATTEMPTS)
            : 1;
        headers = headers.delete(InterceptorOptions.RETRY_ATTEMPTS);

        const updatedRequest = req.clone({ headers });
        return next.handle(updatedRequest).pipe(
            retryOnError(retryAttempts, <RetryOptions> {
                shouldRetry: (err) => this.canRetryFailedHttpRequest(err)
            }),
            mergeMap(response => {
                if (this.errorProvider.hasOfflineErrors && !(_.get(response, 'type') === 0)) {
                    return this.errorProvider.sendOfflineErrors().pipe(map(() => {
                        return response;
                    }));
                }
                return of(response);
            }),
            catchError((err: HttpErrorResponse) => {
                let defaultMessage = '';
                switch (err.status) {
                    case 0:
                        defaultMessage = 'Connection was lost. Please, try again later.';
                        this.showErrorMessage(updatedRequest, err.url, defaultMessage, null, ignoreErrorHandling, true);
                        const error = new ServerError(0, err.message, []);
                        this.errorProvider.offlineErrorsList.push(error);
                        break;
                    case 400:
                        defaultMessage = err.error.error_description ? err.error.error_description :
                        err.error.message ? err.error.message : err.message;
                        this.showErrorMessage(updatedRequest, err.url, defaultMessage, null, ignoreErrorHandling, true);
                        break;
                    case 403:
                        if (!ignoreForbiddenHandling) {
                            defaultMessage = err.error.error_description ? err.error.error_description :
                            err.error.message ? err.error.message : err.message;
                            this.showErrorMessage(updatedRequest, err.url, defaultMessage, null, ignoreErrorHandling, true);
                        } else {
                            return of(true);
                        }
                        break;
                    case 401:
                        return this.error401action(next, updatedRequest, err, ignore401Handling);
                    case 404:
                        this.error404action(updatedRequest, ignore404Handling);
                        break;
                    case 415:
                        this.showErrorMessage(updatedRequest, err.url, err.error.Message, err.error.StackTrace, ignoreErrorHandling);
                        break;
                    default:
                        if (err.error instanceof Blob) {
                            this.parseErrorBlob(err).subscribe(
                                data => data,
                                e => {
                                    err = this.defaultErrorAction(e, updatedRequest, ignoreErrorHandling);
                                }
                            );
                            break;
                        }
                        err = this.defaultErrorAction(err, updatedRequest, ignoreErrorHandling);
                        break;
                }
                console.error(err);
                return throwError(err);
            })
        );
    }

    private generateErrorInfo(req, url, message, exceprion = null) {
        const error = {
            message: message,
            fullError: `${this.appType} v${version}
                    user agent : ${this.userAgent}
                    current page: ${window.location.href}
                    request url : ${url}
                    request method : ${req.method}
                    message: ${message}`
        };
        if (exceprion) {
            error.fullError += `
                    exception: ${exceprion}`;
        }
        return error;
    }

    private canRetryFailedHttpRequest(error) {
        const isHttpError = error instanceof HttpErrorResponse;
        const skipRetryStatuses = [400, 401, 403, 404];
        return isHttpError && !skipRetryStatuses.includes(error.status);
    }

    private error401action(next, req, err, ignore) {
        if (ignore) {
            return of(err);
        }
            
        if (this.isAdminSite || this.ssoService.alreadyhad401 || this.environmentProvider.disableSSO) {
            this.relogin(err);
                console.error(err);
                return throwError(err);
        }

        this.ssoService.alreadyhad401 = true;
        return from(this.ssoService.takeSurveyAutologin()).pipe(
            switchMap(() => next.handle(req)),
            catchError(secondReqError => {
                this.relogin(secondReqError);
                return throwError(secondReqError);
            })
        ) as Observable<HttpEvent<any>>;
    }

    private relogin(err) {
        if (
            err.error
            && err.error.error === 'concurrent_session'
            && localStorage.getItem(AuthConfigConst.IDENTITY_KEY)
            && this.isAdminSite
        ) {
            this.toaster.showError({ message: this.concurrentUserMessage }, true);
        }
        this.store.reloginOnInvalidToken();
    }

    private error404action(req, ignore) {
        if (
            req.url.indexOf('./assets') === -1
            && !ignore
        ) {
            return this.router.navigate(['404']);
        }
    }

    private showErrorMessage(req, url, message, exceprion = null, ignore, hideCopyBtn = false) {
        if (!ignore) {
            const error = this.generateErrorInfo(req, url, message, exceprion);
            return this.toaster.showError(error, hideCopyBtn);
        }
    }

    private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = Observable.create((observer: any) => {
          reader.onloadend = (e) => {
            const blobError = {...{}, ...err};
            blobError.error = JSON.parse(<string>reader.result);
            observer.error(blobError);
            observer.complete();
          };
        });
        reader.readAsText(err.error);
        return obs;
    }

    private defaultErrorAction(err, updatedRequest, ignoreErrorHandling) {
        const defaultMessage = err.error.message ? err.error.message : err.message;
        err.error.message = this.generateErrorInfo(updatedRequest, err.url, defaultMessage, err.error.exception);
        if (!ignoreErrorHandling) {
            if (err.error.category === 'Business') {
                this.toaster.showError(err.error.message, true);
            } else {
                this.toaster.showError(err.error.message);
            }
        }
        return err;
    }
}
