import { Action } from '@ngrx/store';

import { ApplicationError } from '../../models';

export const HANDLE_ERROR = '[Error] Handle Error';
export const LOG_ERROR = '[Error] Log Error to Server';
export const LOG_ERROR_SUCCESS = '[Error] Log Error to Server Success';
export const LOG_ERROR_FAILED = '[Error] Log Error to Server Failed';

export class HandleErrorAction implements Action {
    readonly type = HANDLE_ERROR;

    constructor(public payload: ApplicationError) {}
}

export class LogErrorAction implements Action {
    readonly type = LOG_ERROR;

    constructor(public payload: ApplicationError) {}
}

export class LogErrorSuccessAction implements Action {
    readonly type = LOG_ERROR_SUCCESS;

    constructor(public payload: ApplicationError = null) {}
}

export class LogErrorFailedAction implements Action {
    readonly type = LOG_ERROR_FAILED;

    constructor(public payload: any) {}
}

export type Actions =
    | HandleErrorAction
    | LogErrorAction
    | LogErrorSuccessAction
    | LogErrorFailedAction;
