import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ChangePasswordPayload } from '../../../infrastructure/models/change-password.model';
import { environment } from '../../../environments/environment';
import { ISecuritySettings } from '../../../infrastructure/models/security-settings.model';
import { Signin } from '../../../infrastructure/models/signin.model';
import { PasswordService } from '../../../infrastructure/services/password.service';
import { ControlsValuesValidator } from '../../../infrastructure/validators/controls-value-matcher.validator';
import { PasswordValidator } from '../../../infrastructure/validators/password.validators';
import { getEnvironmentDomain } from '../../utils/host-names';

@Component({
    selector: 'cb-change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
    @Input() signInData: Signin;
    @Input() loginUrl: string = '/login';
    @Input() securitySettings: ISecuritySettings;
    @Output() passwordChanged: EventEmitter<ChangePasswordPayload> = new EventEmitter<ChangePasswordPayload>();
    form: FormGroup;
    isServerVersion = environment.name === 'server';

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private passwordService: PasswordService,
    ) { }

    ngOnInit(): void {
        if (!this.signInData) {
            this.router.navigateByUrl(this.loginUrl);
        }

        this.form = this.fb.group({
            account: new FormControl({ value: this.signInData?.account || '', disabled: true }),
            id: new FormControl({ value: this.signInData?.id || '', disabled: true }),
            newPassword: ['', [
                PasswordValidator.getValidators(this.securitySettings),
                PasswordValidator.isOldPassword(this.signInData?.password || '') ]
            ],
            confirmPassword: ['', [ Validators.required ]],
        }, {
            validators: [ ControlsValuesValidator.isNotMatched('newPassword', 'confirmPassword') ]
        });
    }

    getEnvironmentDomain() {
        const host = window.location.host;
        return getEnvironmentDomain(host);
    }

    passwordValidationError$(): Observable<string> {
        return this.passwordService.getErrorMessage$(this.securitySettings);
    }

    onSubmit() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.passwordChanged.emit({ signInModel: this.signInData, newPassword: this.form.controls['newPassword'].value });
        }
    }

    isAdmin() {
        return environment.isAdminSite;
    }
}
