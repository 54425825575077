import * as _ from 'lodash';
import { ModelImplementor } from '../implementor.model';
import { MapperType } from '../../../infrastructure/models/survey-settings.model';

export interface ExportCSVSettings {
    detailedResponseInfo: boolean;
    detailedUserInfo: boolean;
    mergeCheckboxResults: boolean;
    exportOpenEndedResults: boolean;
    exportWithAliases: boolean;
    exportHiddenItems: boolean;
    exportIncompleteResponses: boolean;
    stripHtmlTags: boolean;
    exportRankOrderPoints: boolean;
    detailedScoredInfo: boolean;
    endDate: any;
    startDate: any;
}

export interface ExportMaxDiffSettings {
    maxDiffDetailedResponseInfo: boolean;
    maxDiffDetailedUserInfo: boolean;
    maxDiffExportWithAliases: boolean;
    maxDiffExportIncompleteResponses: boolean;
    maxDiffStripHtmlTags: boolean;
    maxDiffIncludeTestResponses: boolean;
    startDate: any;
    endDate: any;
}

export interface ExportSPSSSettings {
    includeResponseId: boolean;
    includeOpenEndedResults: boolean;
    includePartialResponses: boolean;
    startDate: any;
    endDate: any;
    isLongText: boolean;
}

export class FileDownloadSummary {
    hasFiles: boolean;
    fileCount: number;
    infectedFileCount: number;
}

export class FileDownloadSummaryModel extends ModelImplementor
    implements FileDownloadSummary {
    hasFiles: boolean;
    fileCount: number;
    infectedFileCount: number;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'hasFiles', out: 'has_files', type: 'boolean' },
            { in: 'fileCount', out: 'file_count', type: 'boolean' },
            { in: 'infectedFileCount', out: 'infected_file_count', type: 'number' }
        ];
        super(options, mapper);
    }
}

export class ExportMaxDiffSettingsModel extends ModelImplementor
    implements ExportMaxDiffSettings {
    maxDiffDetailedResponseInfo: boolean;
    maxDiffDetailedUserInfo: boolean;
    maxDiffExportIncompleteResponses: boolean;
    maxDiffExportWithAliases: boolean;
    maxDiffIncludeTestResponses: boolean;
    maxDiffStripHtmlTags: boolean;
    startDate: Date;
    endDate: Date;

    constructor(options: any) {
        const mapper: MapperType[] = [
            {
                in: 'maxDiffDetailedResponseInfo',
                out: 'detailed_response_info',
                type: 'boolean'
            },
            {
                in: 'maxDiffDetailedUserInfo',
                out: 'detailed_response_info',
                type: 'boolean'
            },
            {
                in: 'maxDiffExportWithAliases',
                out: 'export_with_aliases',
                type: 'boolean'
            },
            {
                in: 'maxDiffExportIncompleteResponses',
                out: 'export_incomplete_responses',
                type: 'boolean'
            },
            {
                in: 'maxDiffStripHtmlTags',
                out: 'strip_html_tags_from_answers',
                type: 'boolean'
            },
            {
                in: 'maxDiffIncludeTestResponses',
                out: 'include_test_responses',
                type: 'boolean'
            },
            { in: 'startDate', out: 'start_date', type: 'date' },
            { in: 'endDate', out: 'end_date', type: 'date' }
        ];
        super(options, mapper);
    }
}

export class ExportSPSSSettingsModel extends ModelImplementor
    implements ExportSPSSSettings {
    includeOpenEndedResults: boolean;
    includePartialResponses: boolean;
    includeResponseId: boolean;
    startDate: Date;
    endDate: Date;
    isLongText: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            {
                in: 'includeOpenEndedResults',
                out: 'include_open_ended_results',
                type: 'boolean'
            },
            {
                in: 'includePartialResponses',
                out: 'include_partial_responses',
                type: 'boolean'
            },
            {
                in: 'includeResponseId',
                out: 'include_response_id',
                type: 'boolean'
            },
            { in: 'startDate', out: 'start_date', type: 'date' },
            { in: 'endDate', out: 'end_date', type: 'date' },
            {
                in: 'isLongText',
                out: 'is_long_text',
                type: 'boolean'
            }
        ];
        super(options, mapper);
    }
}

export class ExportCSVSettingsModel extends ModelImplementor
    implements ExportCSVSettings {
    detailedResponseInfo: boolean;
    detailedScoredInfo: boolean;
    detailedUserInfo: boolean;
    exportHiddenItems: boolean;
    exportIncompleteResponses: boolean;
    exportOpenEndedResults: boolean;
    exportRankOrderPoints: boolean;
    exportWithAliases: boolean;
    mergeCheckboxResults: boolean;
    stripHtmlTags: boolean;
    includeTestResponses: boolean;
    startDate: Date;
    endDate: Date;

    constructor(options: any) {
        const mapper: MapperType[] = [
            {
                in: 'exportIncompleteResponses',
                out: 'include_partial_responses',
                type: 'boolean'
            },
            {
                in: 'exportOpenEndedResults',
                out: 'include_open_ended_results',
                type: 'boolean'
            },
            {
                in: 'exportHiddenItems',
                out: 'include_hidden_items',
                type: 'boolean'
            },
            {
                in: 'includeTestResponses',
                out: 'include_test_responses',
                type: 'boolean'
            },
            {
                in: 'detailedResponseInfo',
                out: 'include_detailed_response_info',
                type: 'boolean'
            },
            {
                in: 'detailedUserInfo',
                out: 'include_detailed_contact_info',
                type: 'boolean'
            },
            {
                in: 'detailedScoredInfo',
                out: 'include_detailed_score_info',
                type: 'boolean'
            },
            {
                in: 'stripHtmlTags',
                out: 'strip_html_tags',
                type: 'boolean'
            },
            {
                in: 'mergeCheckboxResults',
                out: 'merge_select_multiple_results',
                type: 'boolean'
            },
            {
                in: 'exportRankOrderPoints',
                out: 'export_rank_order_points',
                type: 'boolean'
            },
            {
                in: 'exportWithAliases',
                out: 'export_with_aliases',
                type: 'boolean'
            },
            { in: 'startDate', out: 'start_date', type: 'date' },
            { in: 'endDate', out: 'end_date', type: 'date' }
        ];
        super(options, mapper);
    }
}

export enum ExportAction {
    Csv = 'Csv',
    SpssNative = 'SpssNative',
    SpssCsv = 'SpssCsv',
    MaxDiff = 'maxDiff',
    FileUploads = 'FileUploads'
}

export class FileExportOptions {
    startDate: Date;
    endDate: Date;
}
