import { SummaryChartOtherOptionType } from '../../../infrastructure/consts/dashboards.consts';

export interface DashboardItemChart {
    chart_type: string;
    dashboard_item_id: number;
    size: ChartSize;
    use_aliases: boolean;
    other_option: SummaryChartOtherOptionType;
    appearance?: DashboardItemChartAppearance;
}

export interface DashboardItemChartSaveData {
    chart_type: string;
    size: ChartSize;
}

export interface NetPromoterScoreTableDashboardItemChart
    extends DashboardItemChart {
    use_aliases: boolean;
}

export interface MaxDiffSummaryTableDashboardItemChart
    extends DashboardItemChart {
    use_aliases: boolean;
}

export interface ContactFormSummaryTableDashboardItemChart
    extends DashboardItemChart {
    use_aliases: boolean;
}

export interface WordCloudDashboardItemChart extends DashboardItemChart {
    use_aliases: boolean;
}

export interface CrossTabDashboardItemChart extends DashboardItemChart {
    use_aliases: boolean;
    horizontal_data_sources: number[];
    vertical_data_sources: number[];
}

// tslint:disable-next-line:no-empty-interface
export interface AverageScoreByPageDashboardItemChart
    extends DashboardItemChart {}

export interface DashboardItemChartWithAppearance extends DashboardItemChart {
    appearance: DashboardItemChartAppearance;
}

export interface DashboardItemChartAppearance {
    appearance_type: ChartAppearanceType;
    show_legend?: boolean;
}

export interface MessageDashboardItemChart extends DashboardItemChart {
    message: string;
}

export interface MatrixSummaryDashboardItemChart extends DashboardItemChart {
    use_aliases: boolean;
}

export interface AverageScoreDashboardItemChart extends DashboardItemChart {
    use_aliases: boolean;
}

export interface NetPromoterScoreStatisticsTableDashboardItemChart
    extends DashboardItemChart {
    use_aliases: boolean;
}

export interface RankOrderChartDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
}

export interface SummaryChartDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
    other_option: string;
    display_answers: boolean;
    display_statistics: boolean;
}

export interface RankOrderTableDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
}

export interface RatingScaleStatisticsTableDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
    calculation: string;
}

export interface ResponseDetailsDashboardItemChart
    extends DashboardItemChartWithAppearance {
    group_answers: boolean;
    link_to_response_details: boolean;
}

export interface SummaryTableDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
    other_option: string;
}

export interface TrendsDashboardItemChart
    extends DashboardItemChartWithAppearance {
    use_aliases: boolean;
    period: string;
}

export enum ChartAppearanceType {
    AverageScore = 'AverageScore',
    AverageScoreByPage = 'AverageScoreByPage',
    MaxDiffSummaryTable = 'MaxDiffSummaryTable',
    ContactFormSummaryTable = 'ContactFormSummaryTable',
    NetPromoterScoreStatisticsTable = 'NetPromoterScoreStatisticsTable',
    NetPromoterScoreTable = 'NetPromoterScoreTable',
    RankOrderSummaryChartBarGraph = 'RankOrderSummaryChartBarGraph',
    RankOrderSummaryChartColumnGraph = 'RankOrderSummaryChartColumnGraph',
    RankOrderSummaryChartDoughnutGraph = 'RankOrderSummaryChartDoughnutGraph',
    RankOrderSummaryChartLineGraph = 'RankOrderSummaryChartLineGraph',
    RankOrderSummaryChartPieGraph = 'RankOrderSummaryChartPieGraph',
    RankOrderSummaryTable = 'RankOrderSummaryTable',
    RatingScaleStatisticsTable = 'RatingScaleStatisticsTable',
    StatisticsTable = 'StatisticsTable',
    ResponseDetails = 'ResponseDetails',
    SummaryChartBarGraph = 'SummaryChartBarGraph',
    SummaryChartColumnGraph = 'SummaryChartColumnGraph',
    SummaryChartDoughnutGraph = 'SummaryChartDoughnutGraph',
    SummaryChartLineGraph = 'SummaryChartLineGraph',
    SummaryChartPieGraph = 'SummaryChartPieGraph',
    SummaryTable = 'SummaryTable',
    TrendsLineGraph = 'TrendsLineGraph',
    NetPromoterScoreTrendsLineGraph = 'NetPromoterScoreTrendsLineGraph'
}

export enum ChartSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large'
}

export enum TrendsPeriod {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly'
}
