import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

export enum DatepickerMode {
    DATE_TIME = 'both',
    DATE = 'calendar',
    TIME = 'timer'
}

@Component({
    selector: 'cb-date-time-picker',
    templateUrl: 'date-time-picker.component.html',
    styleUrls: ['date-time-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent
    implements ControlValueAccessor, OnChanges {
    @Input() placeholder: string;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() datepickerMode: DatepickerMode = DatepickerMode.DATE_TIME;
    @Output() dateChanged: EventEmitter<string> = new EventEmitter();
    @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
    dateTime: Date;
    startAt = moment()
        .hour(0)
        .minute(0)
        .second(0)
        .toDate();
    isTemporaryDateTime: boolean;
    dateOnlyMode = DatepickerMode.DATE;
    onChange = (dateTime: Date) => {};

    ngOnChanges(changes: SimpleChanges): void {
        const minDate = changes['minDate'] && changes['minDate'].currentValue;
        const maxDate = changes['maxDate'] && changes['maxDate'].currentValue;
        const currentDateTime = moment(this.dateTime);
        if (minDate && currentDateTime.isBefore(minDate)) {
            this.dateTime = minDate;
        }
        if (maxDate && currentDateTime.isAfter(maxDate)) {
            this.dateTime = minDate;
        }
    }

    writeValue(dateTime: Date): void {
        this.dateTime = dateTime;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    propagateChange(dateTime) {
        this.isTemporaryDateTime = false;
        this.onChange(dateTime?.toDate()?? dateTime);
        this.dateChanged.emit(dateTime?.toDate()?? dateTime);
    }
    openDatePicker() {
        this.picker.open();
    }
    beforePickerOpen() {
        if (!this.dateTime) {
            this.isTemporaryDateTime = true;
            this.dateTime = this.startAt;
        }
    }

    afterPickerClosed() {
        if (this.isTemporaryDateTime) {
            this.dateTime = null;
        }
    }
}
