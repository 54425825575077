<div class="cb-reset-password">
    <div class="cb-survey-header"></div>
    <div class="take-survey-content">
        <div class="login-container">
            <h2 class="login-title">{{ 'FORMS.RESET-PASS' | translate }}</h2>
            <cb-spinner [show]="isLoading" [blockLayout]="true"></cb-spinner>
            <cb-reset-password
                (sendMail)="sendMail($event)"
                [emailSent]="emailSent"
                [isBrokenToken]="brokenToken"
                [account]="account"
                *ngIf="sendEmail"
            ></cb-reset-password>
            <cb-reset-password-new-password
                (resetMail)="resetMail($event)"
                [user]="user"
                *ngIf="resetPassword"
            ></cb-reset-password-new-password>
            <cb-reset-password-notification
                [message]="message"
                *ngIf="showNotification"
            ></cb-reset-password-notification>
        </div>
    </div>
</div>
