import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.consts';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ContentFileProvider {
    constructor(private $http: HttpClient) {}

    uploadContentFile(formData): Observable<any> {
        const url = ResourcesConsts.FILE_UPLOAD;
        return this.$http
            .post(url, formData);
    }

    getUploadedImages(): Observable<any> {
        const url = ResourcesConsts.GET_FILE + '?content_category=images';
        return this.$http
            .get(url);
    }

    saveImageUrl(imageUrl: string): Observable<any> {
        const url = ResourcesConsts.GET_FILE;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .post(url, { file_url: imageUrl }, { headers: headers });
    }
}
