import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IconsRegistry } from '../../consts/icons.const';

@Component({
  selector: 'cb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() text = '';
  @Input() icon = '';
  @Input() disabled = false;
  @Input() type: string = '';
  @Input() color = '';
  @Input() size = '';
  @Input() sufixIcon = '';
  @Input() rotateSufix = false;
  @Input() hasDivider = false;
  @Output() onClick: EventEmitter<PointerEvent> = new EventEmitter<PointerEvent>();
  sizeOptions = ['x-small', 'small', 'medium', 'large'];
  basicOption = 'basic';
  flatOption = 'flat';
  raisedOption = 'raised';
  strokedOption = 'stroked';
  fabOption = 'fab';
  miniFabOption = 'minifab';
  iconOption = 'icon';
  options = [ this.basicOption, this.flatOption, this.raisedOption, this.strokedOption, this.fabOption, this.miniFabOption, this.iconOption ];
  matIcon = '';
  registryIcon = '';
  get registrySufixIcon() {
    return Object.keys(IconsRegistry).includes(this.sufixIcon) ? this.sufixIcon : '';
  }
  get matSufixIcon() {
    return !Object.keys(IconsRegistry).includes(this.sufixIcon) ? this.sufixIcon : '';
  }
  public sizeOrDefault(){
    return this.sizeOptions.includes(this.size) ? this.size : 'small';
  }
  constructor() { }
  ngOnChanges(_: SimpleChanges): void {
    this.matIcon = this.notInIconRegistry() ? this.icon : '';
    this.registryIcon = this.notInIconRegistry() ? '' : this.icon;
  }

  ngOnInit() {
    this.matIcon = this.notInIconRegistry() ? this.icon : '';
    this.registryIcon = this.notInIconRegistry() ? '' : this.icon;
  }
  notAnOption() {
    return !this.options.includes(this.type);
  }
  notInIconRegistry() {
    return !Object.keys(IconsRegistry).includes(this.icon);
  }
  click(event: PointerEvent) {
    this.onClick.emit(event);
  }

}
