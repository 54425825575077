export const environment = {
  production: true,
  isAdminSite: false,
  isMultiTenant: true,
  disableSSO: false,
  name: "feature",
  storageHubUrl: 'http://here_should_be_api_url_for_dev_env/hub.html',
  apiUrl: 'http://here_should_be_api_url_for_dev_env',
  googleAuthClientId: '',
  featureFlagEnvironmentID: '7PYdpDAePFVN6zEuHtKcZp',
  featureFlagEnableCache: false,
  feedbackKey: '',
  analyticsApiKey: '',
  analyticsHost: ''
};
