import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../../app-admin/consts/resources.consts';
import { LeftOperandSource, RightOperandSource } from '../../../app-admin/models';

export class DashboardExpressionsSourceType {
    public static DEFAULT = 'Default';
    public static QUESTION = 'SurveyItem';
    public static USER_ATTRIBUTE = 'ProfileProperty';
    public static RESPONSE_PROPERTY = 'ResponseProperty';
}

@Injectable()
export class DashboardExpressionsProvider {
    constructor(private $http: HttpClient) {
    }

    getLeftOperands(
        dashboardId,
        surveyId?,
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_EXPRESSIONS_GET_LEFT_OPERANDS,
            dashboardId
        );
        let params = new HttpParams();
        if (surveyId) {
            params = params.set('survey_id', surveyId);
        }

        return this.$http.get(url, { params: params });
    }

    getOperators(
        dashboardId,
        leftOperandType,
        leftOperandValue?, // later it may be not only survey item id
        surveyId?
    ): Observable<Object> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_EXPRESSIONS_GET_OPERATORS,
            dashboardId
        );

        let params = new HttpParams()
            .set('left_operand_type', leftOperandType)
            .set('left_operand_value', leftOperandValue);
        if (leftOperandType === 'surveyItem') {
            params = params
                .set('survey_id', surveyId);
        }

        return this.$http.get(url, { params: params });
    }

    getRightOperands(
        dashboardId,
        operator,
        leftOperandType,
        leftOperandValue, // survey item id for now
        surveyId,
    ): Observable<RightOperandSource> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_EXPRESSIONS_GET_RIGHT_OPERANDS,
            dashboardId
        );

        let params = new HttpParams()
            .set('left_operand_type', leftOperandType)
            .set('operator', operator)
            .set('left_operand_value', leftOperandValue);

        if (leftOperandType === 'surveyItem') {
            params = params
                .set('survey_id', surveyId);
        }

        return this.$http.get<RightOperandSource>(url, { params: params });
    }
}
