import { environment } from '../../environments/environment';

function getAdminCloudfrontUrl(): string {
    return window['__env'] && window['__env']['cloudFrontAdmin'];
}

function getTakeSurveyCloudfrontUrl(): string {
    return window['__env'] && window['__env']['cloudFrontTakeSurvey'];
}

export function transformToCloudfrontUrl(url: string) {
    const currentEnvUrl = environment.isAdminSite ? getAdminCloudfrontUrl() : getTakeSurveyCloudfrontUrl();
    if (environment.name !== 'server' && currentEnvUrl) {
        return `${currentEnvUrl}/${url}`;
    } else {
        return url;
    }
}
