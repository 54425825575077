<div *ngIf="title" class="title">
  <div>
    <div><h4>{{title}}</h4></div>
    <mat-icon mat-ripple svgIcon="fi_x" (click)="onCancel.emit()"></mat-icon>
  </div>
  <mat-divider></mat-divider>
</div>
<div class="content">
  <div *ngIf="text||texts">
    <span *ngIf="text">{{text}}</span>
    <span *ngFor="let item of texts">{{item}}</span>
  </div>
  <ng-content></ng-content>
</div>
<div *ngIf="mainButton || cancelButton" class="actions">
  <mat-divider></mat-divider>
  <div>
    <cb-button *ngIf="cancelButton" [text]="cancelButton" type="stroked" (onClick)="onCancel.emit()"></cb-button>
    <cb-button *ngIf="mainButton" [text]="mainButton" type="flat" color="primary" [disabled]="disabled" (onClick)="onMain.emit()"></cb-button>
  </div>
</div>