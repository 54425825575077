import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { EnvironmentService } from '../services/environment.service';

export interface Environment {
    production?: boolean;
    isAdminSite?: boolean;
    isMultiTenant?: boolean;
    name?: string;
    storageHubUrl?: string;
    apiUrl?: string;
    disableSSO?: boolean;
    googleAuthClientId?: string;
}

@Injectable()
export class EnvironmentProvider {
    constructor(
        private injector: Injector,
        private environmentService: EnvironmentService
    ) {}

    get apiUrl() {
        return this.environmentService.apiUrl || environment.apiUrl;
    }

    get storageHubUrl() {
        return (
            this.environmentService.storageHubUrl || environment.storageHubUrl
        );
    }

    get disableSSO() {
        return (
            this.environmentService.disableSSO ||
            environment.disableSSO ||
            false
        );
    }

    get googleAuthClientId() {
        return this.environmentService.googleAuthClientId || environment.googleAuthClientId;
    }

    get featureFlagEnvironmentID(): string {
        return environment.featureFlagEnvironmentID;
    }

    get featureFlagEnableCache(): boolean {
        return environment.featureFlagEnableCache;
    }
}
