import { ErrorProvider } from './error.provider';
import { IdentityProvider } from './identity.provider';
import { StorageProvider } from './storage.provider';
import { JsonReaderProvider } from './json-reader.provider';
import { AccountNameResolverProvider } from './account-name-resolver.provider';
import { JWTProvider } from './JWT.provider';
import { CustomSourceDropdownlistProvider } from './custom-source-dropdownlist.provider';
import { EditMyInfoProvider } from './edit-my-info.provider';
import { ApplicationLanguageService } from '../services/application-language.service';

export const dataProviders = [
    ErrorProvider,
    IdentityProvider,
    JWTProvider,
    StorageProvider,
    JsonReaderProvider,
    AccountNameResolverProvider,
    CustomSourceDropdownlistProvider,
    EditMyInfoProvider,
    ApplicationLanguageService
];

export {
    ErrorProvider,
    IdentityProvider,
    JWTProvider,
    StorageProvider,
    JsonReaderProvider,
    AccountNameResolverProvider,
    CustomSourceDropdownlistProvider,
    EditMyInfoProvider,
    ApplicationLanguageService
};
