import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../../consts/resources.consts';
import { AccountInfo } from '../../models/support/account-info-model';

@Injectable()
export class MyAccountInfoProvider {
    constructor(private $http: HttpClient) {
    }

    getAccountInfo(): Observable<AccountInfo> {
        const url: string = ResourcesConsts.MY_ACCOUNT_INFO;
        return this.$http
            .get<AccountInfo>(url);
    }
}
