import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { ISecuritySettings } from '../models/security-settings.model';
import { CustomValidators } from './custom.validators';
import { MinLengthAsyncValidator } from './min-length-async.validator';
import { RegexAsyncValidator } from './regex-async.validator';

export class PasswordValidatorBuilder {
    private regexMapper: Map<keyof ISecuritySettings, RegExp> = new Map([
        [ 'has_uppercase_letter', /[A-Z]/],
        [ 'has_lowercase_letter', /[a-z]/],
        [ 'has_digit', /\d/],
        [ 'has_special_character', /[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/],
    ]);
    private errorMapper: Map<keyof ISecuritySettings, Object> = new Map([
        [ 'has_uppercase_letter', { hasCapitalCase: true }],
        [ 'has_lowercase_letter', { hasSmallCase: true }],
        [ 'has_digit', { hasNumber: true }],
        [ 'has_special_character', { hasSpecialCharacters: true }],
    ]);

    constructor(
        private securitySettings: ISecuritySettings,
    ) {}

    build(passwordRequired: boolean = true): ValidatorFn {
        let validators: ValidatorFn[] = passwordRequired ? [Validators.required] : [];

        if (this.securitySettings?.min_password_length) {
            validators.push(
                Validators.minLength(this.securitySettings.min_password_length)
            );
        }

        if (this.securitySettings?.has_uppercase_letter) {
            validators.push(
                CustomValidators.patternValidator(
                    this.regexMapper.get('has_uppercase_letter'),
                    this.errorMapper.get('has_uppercase_letter')
                )
            );
        }

        if (this.securitySettings?.has_lowercase_letter) {
            validators.push(
                CustomValidators.patternValidator(
                    this.regexMapper.get('has_lowercase_letter'),
                    this.errorMapper.get('has_lowercase_letter')
                )
            );
        }

        if (this.securitySettings?.has_digit) {
            validators.push(
                CustomValidators.patternValidator(
                    this.regexMapper.get('has_digit'),
                    this.errorMapper.get('has_digit')
                )
            );
        }

        if (this.securitySettings?.has_special_character) {
            validators.push(
                CustomValidators.patternValidator(
                    this.regexMapper.get('has_special_character'),
                    this.errorMapper.get('has_special_character')
                )
            );
        }

        return Validators.compose(validators);
    }

    buildAsync(): AsyncValidatorFn {
        let validators: AsyncValidatorFn[] = [];

        if (this.securitySettings.min_password_length) {
            validators.push(
                MinLengthAsyncValidator.validate(this.securitySettings.min_password_length)
            );
        }

        if (this.securitySettings.has_uppercase_letter) {
            validators.push(
                RegexAsyncValidator.validate(
                    this.regexMapper.get('has_uppercase_letter'),
                    this.errorMapper.get('has_uppercase_letter')
                )
            );
        }

        if (this.securitySettings.has_lowercase_letter) {
            validators.push(
                RegexAsyncValidator.validate(
                    this.regexMapper.get('has_lowercase_letter'),
                    this.errorMapper.get('has_lowercase_letter')
                )
            );
        }

        if (this.securitySettings.has_digit) {
            validators.push(
                RegexAsyncValidator.validate(
                    this.regexMapper.get('has_digit'),
                    this.errorMapper.get('has_digit')
                )
            );
        }

        if (this.securitySettings.has_special_character) {
            validators.push(
                RegexAsyncValidator.validate(
                    this.regexMapper.get('has_special_character'),
                    this.errorMapper.get('has_special_character')
                )
            );
        }

        return Validators.composeAsync(validators);
    }
}
