import { ModelImplementor, ModelPageListImplementor } from '../../app-admin/models/implementor.model';
import { PagedList } from './index';
import { MapperType } from './survey-settings.model';

export interface ActiveSurvey{
    response_template_id:number;
    template_name: string;
    created_by: string;
    responses_last30_days: number;
    responses_last7_days: number;
    responses_last_day: number;
    }

export interface ActiveSurveys extends PagedList<ActiveSurvey> {}

export class ActiveSurveysModel extends ModelPageListImplementor<ActiveSurvey>
    implements ActiveSurveys {
    constructor(options: any) {
        super(options, ActiveSurveyModel);
    }
}

// ActiveSurvey Model is the class, which is adapted to inheritance model of other component (Contact list, infinite table, etc
export class ActiveSurveyModel extends ModelImplementor implements ActiveSurvey {
    response_template_id:number;
    template_name: string;
    created_by: string;
    responses_last30_days: number;
    responses_last7_days: number;
    responses_last_day: number;
    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'template_name', out: 'template_name', type: 'string' },
            { in: 'response_template_id', out: 'response_template_id', type: 'string' },
            { in: 'created_by', out: 'created_by', type: 'string' },
            { in: 'responses_last30_days', out: 'responses_last30_days', type: 'number' },
            { in: 'responses_last7_days', out: 'responses_last7_days', type: 'number' },
            { in: 'responses_last_day', out: 'responses_last_day', type: 'number' }
        ];

        super(options, mapper);
    }
}