export interface JWTSignInEffectData {
    token: string;
    jwtType: JWTSignInType;
    destinationURL: string;
}

export enum JWTSignInType {
    MasterSite,
    SingleSignOn,
    AdminAppRedirect
}
