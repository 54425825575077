import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISecuritySettings } from '../../app-take-survey/models';
import { IdentityProvider } from '../../infrastructure/providers/identity.provider';
import { CustomValidators } from './custom.validators';
import { PasswordValidatorBuilder } from './password-validator-builder';

export class PasswordValidator {
    static getValidators(securitySettings: ISecuritySettings, passwordRequired: boolean = true): ValidatorFn {
        const builder = new PasswordValidatorBuilder(securitySettings);
        const validator = builder.build(passwordRequired);
        return validator;
    }

    static isOldPassword(oldPassword: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const isOldPassword = value === oldPassword;
            return isOldPassword ? { isOldPassword: true } : null;
        }
    }

    static securitySettingsAsync(identityProvider: IdentityProvider): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return identityProvider.getSecuritySettings().pipe(
                map(securitySettings => {
                    const builder = new PasswordValidatorBuilder(securitySettings);
                    const validator = builder.buildAsync();
                    return validator(control);
                })
            );
        }
    }
}
