import { ApplicationError } from '../../models';

export interface State {
    lastError: ApplicationError;
    errors: ApplicationError[];
    unhandledError: any;
}

export const initialState: State = {
    lastError: null,
    errors: [],
    unhandledError: null
};
