import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../consts/resources.consts';
import {
    ExportCSVSettingsModel,
    ExportSPSSSettingsModel
} from '../../models/application-settings/response-export-application-settings.model';

@Injectable()
export class ResponseExportSettingsProvider {
    constructor(private $http: HttpClient) {}

    getDefaultCSVExportSettings(): Observable<ExportCSVSettingsModel> {
        const url: string =
            String.Format(
                ResourcesConsts.APPLICATION_SETTINGS_RESPONSE_EXPORT
            ) + '/csv';

        return this.$http.get(url).pipe(
            map((data: any) => new ExportCSVSettingsModel(data))
        );
    }

    getDefaultSPSSExportSettings(): Observable<ExportSPSSSettingsModel> {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_RESPONSE_EXPORT + '/spss'
        );

        return this.$http.get(url).pipe(
            map((data: any) => new ExportSPSSSettingsModel(data))
        );
    }

    getDefaultExportSettings() {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_RESPONSE_EXPORT
        );

        return this.$http.get(url).pipe(
            map((data: any) => {
                data.csv = new ExportCSVSettingsModel(data.csv);
                data.spss = new ExportSPSSSettingsModel(data.spss);
                return data;
            })
        );
    }
}
