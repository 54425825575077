
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageItemModel, LanguageSSSModel} from '../models/survey-settings.model';
@Injectable()
export class LanguageService {

    appLanguageList = new BehaviorSubject <LanguageItemModel[]> (null);
    surveyLanguageSettings = new BehaviorSubject <LanguageSSSModel>(null);
    surveyLanguages = new BehaviorSubject <LanguageItemModel[]> (null);
    surveySelectedLanguage = new BehaviorSubject <LanguageItemModel>(null);

    setSurveyLanguageSettings(data) {
        this.surveyLanguageSettings.next(data);
    }

    setSurveySelectedLanguage(data) {
        this.surveySelectedLanguage.next(data);
    }

    setSurveyLanguages(data) {
        this.surveyLanguages.next(data);
    }

    setAppLanguages(data) {
        this.appLanguageList.next(data);
    }

    clearSurveyLanguageData() {
        this.surveyLanguageSettings.next(null);
        this.surveyLanguages.next([]);
        this.surveySelectedLanguage.next(null);
    }
}
