import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FileExtension } from '../models';
import { ResourcesConsts } from '../consts/resources.consts';
import * as _ from 'lodash';

@Injectable()
export class FileExtensionsProvider {
    constructor(private $http: HttpClient) {}

    getFileExtensions(): Observable<FileExtension[]> {
        const url: string = ResourcesConsts.SURVEY_EDITOR_GET_FILE_EXTENSIONS;
        return this.$http.get(url).pipe(
            map(this.toFileExtension)
        );
    }

    toFileExtension(r: any): FileExtension[] {
        return _.map(r.allowed_file_types, function(item) {
            return <FileExtension>{
                extension: item.extension,
                description: item.description
            };
        });
    }
}
