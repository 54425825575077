import * as _ from 'lodash';
import { ModelImplementor } from '../../../models/implementor.model';
import { MapperType } from '../../../../infrastructure/models/survey-settings.model';
import { WhatsappTemplateType } from '../../../models/messaging/sms-message.model';

export class MessagingSettingsModelBase {
    constructor(props) {
        _.forEach(props, (value, key) => {
            this[_.camelCase(key)] = value;
        });
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            json[_.snakeCase(key)] = value;
        });
        return json;
    }
}


export class CompanyProfile extends MessagingSettingsModelBase {
    id: number;
    is_default: boolean;
    name: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    post_code: number;
    state: string;

    constructor(props) {
        super(props);
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            if (key === 'address1' || key === 'address2') {
                json[key] = value;
            } else {
                json[_.snakeCase(key)] = value;
            }
        });
        return json;
    }
}

export class EmailInvitationSettingsModel {
    default_from_email_address: string;
    default_sender_name: string;
    company_profiles: Array<CompanyProfile>;
    email_campaign_settings: EmailCampaignSettingsModel;
}

export class EmailCampaignSettingsModel {
    enable_footer: boolean;
    footer_text: string;
}

export class SmsInvitationSettingsModel extends MessagingSettingsModelBase {
    allowSmsMessaging: boolean;
    allowProvisionPhoneNumber: boolean;
    provisionPhoneNumberCountryCodes: Array<string>;
    provisionedPhoneNumber: string;
    useCustomPhoneNumber: boolean;
    customPhoneNumber: string;
    customAccountSid: string;
    customAuthToken: string;
    hideCreateSmsCampaignButton: boolean;

    constructor(props) {
        super(props);
    }
}

export class PhoneNumberModel extends MessagingSettingsModelBase {
    friendlyName: string;
    phoneNumber: string;
    countryCode: string;
    locality: string;
    region: string;

    constructor(props) {
        super(props);
    }
}

export class SmsMessagingStatusModel extends ModelImplementor {
    isAllowed: boolean;
    isConfigured: boolean;
    requireNanpPhoneNumbers: boolean;
    hideCreateSmsCampaignButton: boolean;

    constructor(options: any = {}) {
        const mapper: MapperType[] = [
            {
                in: 'isAllowed',
                out: 'is_allowed',
                type: 'boolean'
            },
            {
                in: 'isConfigured',
                out: 'is_configured',
                type: 'boolean'
            },
            {
                in: 'requireNanpPhoneNumbers',
                out: 'require_nanp_phone_numbers',
                type: 'boolean'
            },
            {
                in: 'hideCreateSmsCampaignButton',
                out: 'hide_create_sms_campaign_button',
                type: 'boolean'
            }
        ];
        super(options, mapper);
    }
}

export class InvitationLinkSettingsModel extends MessagingSettingsModelBase {
    expirationInHours: number;

    constructor(props) {
        super(props);
    }
}