<div [style.text-align]="displayItem.item_position">
    <div *ngIf="!isTakeSurvey">
        <div *ngFor="let item of displayItem.messages" class="cb-message-container">
            <span>{{ item.low_score }} - {{ item.high_score }} --&nbsp;</span>
            <span [innerHTML]="item.text | keepHtml"></span>
        </div>
    </div>
    <div *ngIf="isTakeSurvey && message">
        <span [innerHTML]="message | keepHtml"></span>
    </div>
    <div *ngIf="isTakeSurvey && !message">
        No appropriate score message
    </div>
</div>
