import { defer, Observable, throwError, timer } from 'rxjs';
import { concatMap, retryWhen } from 'rxjs/operators';

export interface RetryOptions {
    delay: number;
    nextDelay?: (attempt: number, initialDelay: number) => number;
    maxDelay: number;
    shouldRetry?: (error: any) => boolean;
}

export function exponentialDelay(attempt: number, initialDelay: number) {
    return Math.pow(2, attempt - 1) * initialDelay;
}

export function retryOnError(
    maxAttempts: number, options?: RetryOptions): <T>(source: Observable<T>) => Observable<T> {

    const {
        delay = 1000,
        maxDelay = 30000,
        nextDelay = exponentialDelay,
        shouldRetry = () => true,
    } = options;

    return <T>(source: Observable<T>) =>
        maxAttempts <= 1
            ? source
            : defer(() => {
                let attempt = 0;

                return source.pipe(
                    retryWhen<T>(errors =>
                        errors.pipe(
                            concatMap(error => {
                                attempt++;
                                return attempt < maxAttempts && shouldRetry(error)
                                    ? timer(Math.min(nextDelay(attempt, delay), maxDelay))
                                    : throwError(error);
                            })
                        )
                    )
                );
            });
}