import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsProvider } from '../../../app-admin/settings/application-settings/application-settings.provider';
import {
    PreferenceApplicationSettings,
    RootRedirectOption
} from '../../../app-admin/settings/application-settings/preferences-application-settings/perferences-application-settings.model';
import { first } from 'rxjs/operators';

@Component({
    selector: 'cb-blank-page',
    templateUrl: './blank-page.component.html',
    styleUrls: ['./blank-page.component.scss']
})
export class BlankPageComponent implements OnInit {
    settings: PreferenceApplicationSettings;
    RootRedirectOption = RootRedirectOption;

    constructor(
        private applicationSettingsProvider: ApplicationSettingsProvider
    ) {}

    ngOnInit(): void {
        this.applicationSettingsProvider
            .getSystemPreferences()
            .pipe(first())
            .subscribe(settings => {
                this.settings = settings;
            });
    }
}
