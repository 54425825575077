import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../consts/resources.consts';
import { LeftOperandSource, RightOperandSource } from '../../models';

@Injectable()
export class SurveyExpressionsProvider {
    constructor(private $http: HttpClient) {
    }

    getLeftOperands(
        surveyId,
        pageId,
        itemId = '',
        ruleType,
        languageCode,
        isTemplate = false
    ): Observable<LeftOperandSource> {
        const url: string = String.Format(
            isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_EXPRESSIONS_GET_LEFT_OPERANDS :
                         ResourcesConsts.SURVEY_EXPRESSIONS_GET_LEFT_OPERANDS,
            surveyId
        );
        const params = new HttpParams()
            .set('item_id', itemId)
            .set('rule_type', ruleType)
            .set('page_id', pageId.toString())
            .set('language_code', languageCode);

        return this.$http
            .get<LeftOperandSource>(url, { params: params });
    }

    getOperators(
        surveyId,
        left_operand_type,
        left_operand_value,
        isTemplate = false
    ): Observable<string[]> {
        const url: string = String.Format(
            isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_EXPRESSIONS_GET_OPERATORS :
                         ResourcesConsts.SURVEY_EXPRESSIONS_GET_OPERATORS,
            surveyId
        );

        const params = new HttpParams()
            .set('left_operand_type', left_operand_type)
            .set('left_operand_value', left_operand_value);

        return this.$http
            .get<string[]>(url, { params: params });
    }

    getExpressionValueData(
        surveyId,
        left_operand_type,
        left_operand_value,
        operator,
        language_code = 'en-US',
        isTemplate = false
    ): Observable<RightOperandSource> {
        const url: string = String.Format(
            isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_EXPRESSIONS_GET_EXPRESSION_VALUE :
                         ResourcesConsts.SURVEY_EXPRESSIONS_GET_EXPRESSION_VALUE,
            surveyId
        );

        const params = new HttpParams()
            .set('left_operand_type', left_operand_type)
            .set('left_operand_value', left_operand_value)
            .set('operator', operator)
            .set('language_code', language_code);

        return this.$http
            .get<RightOperandSource>(url, { params: params });
    }
}
