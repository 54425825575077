import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './entry-point/app.module';
import { environment } from '../environments/environment';

if (environment.production) {
    enableProdMode();
} else {
    console.log(`Environment Name: ${environment.name}`);
}

platformBrowserDynamic().bootstrapModule(AppModule);
