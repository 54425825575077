import * as _ from 'lodash';

export function generateUniversalLink(relativeURL) {
    const virtualDirectoryPrefix = (window['__env'] && window['__env'].virtualDirectory) || '';
    const hostArr = location.href.split('/');
    const host = `${hostArr[0]}//${hostArr[2]}`;
    if (_.last(virtualDirectoryPrefix) !== '/') {
        return `${host}${virtualDirectoryPrefix}/${relativeURL}`;
    } else {
        return `${host}${virtualDirectoryPrefix}${relativeURL}`;
    }
}
