import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { ISecuritySettings } from '../../infrastructure/models/security-settings.model';
import { IdentityProvider } from '../../infrastructure/providers/identity.provider';
import { Injectable } from "@angular/core";

@Injectable()
export class SecuritySettingsResolver implements Resolve<ISecuritySettings> {
    constructor(
        private identityProvider: IdentityProvider,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ISecuritySettings> {
        return this.identityProvider.getSecuritySettings();
    }
}
