import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ApplicationSettingsProvider } from '../../../app-admin/settings/application-settings/application-settings.provider';
import { Title } from '@angular/platform-browser';
import { ApplicationLanguageService } from '../../../infrastructure/services/application-language.service';
import { TranslateService, LangChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'cb-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss']
})
export class GDPRComponent implements OnInit {
    gdprText: string;
    currentLanguage;

    constructor(
        protected appSettingsProvider: ApplicationSettingsProvider,
        protected titleService: Title,
        private translateService: TranslateService,
        private langService: ApplicationLanguageService
    ) {}

    ngOnInit() {
        this.currentLanguage = this.langService.supportedLanguages[this.translateService.currentLang];
        this.translateService.get('SHARED.PRIVACY-INFORMATION').pipe(
            first()
        ).subscribe(
            text => this.titleService.setTitle(text)
        );
        this.getPrivacyInfoText(this.currentLanguage);
    }

    getPrivacyInfoText(lang) {
        this.appSettingsProvider
        .getPrivacyInformationText(lang)
        .pipe(first())
        .subscribe(data => {
            if ((!data || data && !data['text']) && lang !== 'en-US') {
                return this.getPrivacyInfoText('en-US');
            }
            this.gdprText = data && data['text'] ? data['text'].toString() : '';
        });
    }
}
