import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SurveyPage } from '../models/survey-page.model';
import { StringPatterns } from '../consts/string-patterns.const';
import * as moment from 'moment';

@Injectable()
export class TakeSurveyPipingService {
    handleCurrentDatePiping(pipeValues) {
        if (pipeValues['@@CurrentDateROTW']) {
            pipeValues['@@CurrentDateROTW'] = moment().format('DD/MM/YYYY HH:mm:ss');
        }
        if (pipeValues['@@CurrentDateUS']) {
            pipeValues['@@CurrentDateUS'] = moment().format('MM/DD/YYYY h:mm:ss A');
        }
        return pipeValues;
    }

    replacePipingCodes(currentPage: SurveyPage) {
        // ok
        const mergePattern = StringPatterns.mergePattern;
        let pipeValues = _.reduce(
            currentPage.pipe_values,
            (obj, item) => ((obj[item.merge_code] = item.value || ''), obj),
            {}
        );
        pipeValues = this.handleCurrentDatePiping(pipeValues);
        currentPage.items.forEach(item => {
            this.checkTopLevelMergeCodes(item, pipeValues, mergePattern); // ok
            if (item.columns && item.columns.length) {
                // for matrix
                item.columns.forEach(column => {
                    if (column.prototype_item) {
                        this.checkTopLevelMergeCodes(
                            // ok
                            column.prototype_item,
                            pipeValues,
                            mergePattern
                        );
                    }
                });
            }
            if (item.rows && item.rows.length) {
                // for matrix
                item.rows.forEach(row => {
                    this.checkAndReplaceCodes(row, pipeValues, mergePattern, [
                        // ok
                        'text'
                    ]);
                });
            }
            if (item.fields && item.fields.length) {
                item.fields.forEach(field => {
                    if (field.question) {
                        this.checkTopLevelMergeCodes(
                            field.question,
                            pipeValues,
                            mergePattern
                        );
                    }
                });
            }
            if (item.messages && item.messages.length) {
                item.messages.forEach((message) => {
                    this.checkAndReplaceCodes(
                        message,
                        pipeValues,
                        mergePattern,
                        ['text']
                    );
                });
            }
        });
    }

    checkAndReplaceCodes(parent, codeValues, pattern, fields) {
        // ok
        fields.forEach(field => {
            let needCLear = true;
            if (parent[field]) {
                const result = parent[`__${field}`]
                    ? parent[`__${field}`].match(pattern)
                    : parent[field].match(pattern);
                if (result) {
                    _.chain(result as string[]).sort((a, b) => b.length - a.length).forEach(code => {
                        if (codeValues[code] != null) {
                            const re = new RegExp(code, 'g');
                            if (needCLear) {
                                parent[`__${field}`] = parent[`__${field}`]
                                    ? parent[`__${field}`]
                                    : parent[field];
                                parent[field] = parent[`__${field}`]
                                    ? parent[`__${field}`]
                                    : parent[field];
                                needCLear = false;
                            }
                            parent[field] = parent[field].replace(
                                re,
                                codeValues[code]
                            );
                        }
                    }).value();
                }
            }
        });
    }

    checkTopLevelMergeCodes(parent, codeValues, pattern) {
        // ok
        this.checkAndReplaceCodes(parent, codeValues, pattern, [
            // ok
            'subtext',
            'question_text',
            'default_text',
            'text',
            'url',
            'choice_list_url'
        ]);
        if (parent.choices) {
            parent.choices.forEach(choice => {
                this.checkAndReplaceCodes(choice, codeValues, pattern, [
                    // ok
                    'text'
                ]);
            });
        }
    }
}
