export interface PagingInfo {
    page_size: number;
    page_num: number;
}

export interface PagedList<T> {
    total_count: number;
    paging_info: PagingInfo;
    items: T[];
    has_next?: boolean;
    has_previous?: boolean;
}

export interface PageRequestOptions {
    queryString: string;
    pageSize: string;
    pagesNumber: string;
    sourceId?: string;
    sortBy?: string;
    sortOrder?: string;
    sortAscending?: string;
}

export function EmptyPagedList<T>() {
    return <PagedList<T>>{
        items: [],
        total_count: 0,
        paging_info: {
            page_num: 0,
            page_size: 10
        }
    };
}

export function EmptyPagingInfo(): PagingInfo {
    return <PagingInfo>{
        page_num: 0,
        page_size: 10
    };
}
