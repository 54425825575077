import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesConsts } from '../consts/resources.consts';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';

@Injectable()
export class RespondentFileUploadProvider {
    constructor(private $http: HttpClient) {}

    uploadRespondentFile(surveyId: number, responseId: string, itemId: number, formData): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.UPLOAD_RESPONDENT_FILE,
            surveyId,
            responseId,
            itemId
        );
        return this.$http
            .post(url, formData);
    }

}
