import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
    AbstractControl,
    ValidatorFn
} from '@angular/forms';
import { Router } from '@angular/router';
import { ResourcesConsts } from '../../../infrastructure/consts/resources.const';
import { String } from 'typescript-string-operations-ng4';
import { LoginProvider, UsersProvider } from '../../providers';
import { ProfileProperty, RegistrationSettings } from '../../models';
import { PasswordService, ToasterService } from '../../../infrastructure/services';
import { CreateContactModel, CreateProfilePropertyModel } from '../../../infrastructure/models/contact.model';
import { forkJoin, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-registration-new-account',
    templateUrl: './registration-new-account.component.html',
    styleUrls: ['./registration-new-account.component.scss']
})
@AutoUnsubscribe()
export class RegistrationNewAccountComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public account: string;
    public validationMessage: string;
    public profileUrl: string = String.Format(ResourcesConsts.PROFILE_SETTINGS);
    public properties: ProfileProperty[];
    public items: string[] = [];
    public showSmsPhone: boolean = false;
    private componentDestroyed = new Subject();

    public get passwordValidationError(): string {
        return this.passwordService.getErrorMessage();
    }

    @Output() register: EventEmitter<any> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private usersProvider: UsersProvider,
        private router: Router,
        private toasterService: ToasterService,
        private loginProvider: LoginProvider,
        private passwordService: PasswordService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            id: ['', Validators.required],
            password: ['', Validators.required],
            confirm_password: [
                '',
                Validators.compose([
                    Validators.required,
                    this.confirmValidator()
                ])
            ],
            email: ['', Validators.required],
            phoneSms: ['']
        });

        forkJoin([
            this.loginProvider.getUserProfileProperties(),
            this.loginProvider.getRegistrationSettings()
        ])
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(([properties, regSettings]: [ProfileProperty[], RegistrationSettings]) => {
                if (!regSettings.allow_public_registration) {
                    return this.router.navigateByUrl('/login');
                }

                this.showSmsPhone = regSettings == null || !regSettings.hide_sms_phone;
                this.properties = properties;
                this.addProfilePropertiesControls(properties);
            });

        this.passwordService.setValidators(this.form.controls['password']);
    }

    addProfilePropertiesControls(properties: ProfileProperty[]) {
        properties.forEach(property => {
            if (!property.is_hidden) {
                this.form.addControl(property.name, new FormControl());
                this.items.push(property.name);
            }
        });
    }

    onSubmit() {
        const form = this.form.value;
        if (form.password !== form.confirm_password) {
            this.validationMessage = "Passwords don't match";
            return;
        }
        if (this.form.valid) {
            const newUser = new CreateContactModel();
            newUser.id = form.id;
            newUser.password = form.password;
            newUser.email = form.email;
            newUser.phone_sms = form.phoneSms;
            newUser.profile_properties = [];
            newUser.status = 'Active';
            for (const key in form) {
                if (form.hasOwnProperty(key)) {
                    if (this.items.includes(key)) {
                        newUser.profile_properties.push(
                            new CreateProfilePropertyModel({
                                property_name: key,
                                value: form[key]
                            })
                        );
                    }
                }
            }
            this.usersProvider.addContact(newUser).subscribe(user => {
                this.router.navigate(['/login']);
                setTimeout(() => {
                    this.toasterService.showInfo(`User - '${user.id}' has been created`);
                }, 500);
            });
        }
    }

    // taken from contact-password component.
    private confirmValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!this.form) {
                return null;
            }

            const password = this.form.get('password').value;
            const password2 = control.value;

            const passwordNotMatch =
                password && password2 && password !== password2;
            return passwordNotMatch ? { confirmValidator: true } : null;
        };
    }

    ngOnDestroy() {}
}
