import * as _ from 'lodash';
import { MapperType } from '../../../../infrastructure/models/survey-settings.model';
import { ModelImplementor } from '../../../models/implementor.model';

export enum ExportDefaultTypes {
    Csv = 'Csv',
    SpssCsv = 'SpssCsv',
    SpssNative = 'SpssNative'
}

export class ExportResponsesSettingsModel {
    default_export_type: string;
    csv: IExportResponsesCSVSettings;
    spss: IExportResponsesSPSSSettings;

    constructor(props) {
        _.forEach(props, (value, key) => {
            if (typeof value === 'object' && value !== null) {
                this[_.camelCase(key)] = {};
                _.forEach(value, (v, k) => {
                    this[_.camelCase(key)][_.camelCase(k)] = v;
                });
            } else {
                this[_.camelCase(key)] = value;
            }
        });
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            if (typeof value === 'object' && value !== null) {
                json[_.snakeCase(key)] = {};
                _.forEach((<Object>value), (v, k) => {
                    json[_.snakeCase(key)][_.snakeCase(k)] = v;
                });
            } else {
                json[_.snakeCase(key)] = value;
            }
        });
        return json;
    }
}

export interface ExportResponsesSettings {
    defaultExportType: string;
    csv: ExportResponsesCSVSettings;
    spss: ExportResponsesSPSSSettings;
}

export interface IExportResponsesCSVSettings {
    include_partial_responses: boolean;
    include_open_ended_results: boolean;
    include_hidden_items: boolean;
    include_detailed_response_info: boolean;
    include_detailed_contact_info: boolean;
    include_detailed_score_info: boolean;
    include_possible_score: boolean;
    strip_html_tags: boolean;
    merge_select_multiple_results: boolean;
    export_rank_order_points: boolean;
    export_with_aliases: boolean;
}

export interface IExportResponsesSPSSSettings {
    include_response_id: boolean;
    include_partial_responses: boolean;
    include_open_ended_results: boolean;
    is_long_text: boolean;
}

export interface ExportResponsesCSVSettings {
    includePartialResponses: boolean;
    includeOpenEndedResults: boolean;
    includeHiddenItems: boolean;
    includeDetailedResponseInfo: boolean;
    includeDetailedContactInfo: boolean;
    includeDetailedScoreInfo: boolean;
    includePossibleScore: boolean;
    stripHtmlTags: boolean;
    mergeSelectMultipleSesults: boolean;
    exportRankOrderPoints: boolean;
    exportWithAliases: boolean;
}

export interface ExportResponsesSPSSSettings {
    includeResponseId: boolean;
    includePartialResponses: boolean;
    includeOpenEndedResults: boolean;
    isLongText: boolean;
}

export interface ReportApplicationSettings {
    reportOptions: IReportOptions;
    reportItemOptions: any;
}

export class ReportApplicationSettingsModel extends ModelImplementor
    implements ReportApplicationSettings {
    reportOptions: any;
    reportItemOptions: any;

    constructor(options: any = {}) {
        const mapper: MapperType[] = [
            {
                in: 'reportOptions',
                out: 'report_options',
                type: 'any'
            },
            {
                in: 'reportItemOptions',
                out: 'report_item_options',
                type: 'any'
            }
        ];
        super(options, mapper);
    }
}

export interface IReportOptions {
    style_templateid: number;
    item_position: any;
    use_aliases: boolean;
    include_incomplete_responses: boolean;
    include_test_responses: boolean;
    place_all_report_items_on_single_page: boolean;
    display_survey_title: boolean;
    display_pdf_export_button: boolean;
}
