import * as _ from 'lodash';
import { ModelImplementor } from '../../app-admin/models/implementor.model';

export enum LanguageSourceType {
    NOT_SPECIFIED = '',
    PROMPT = 'Prompt',
    QUERY_STRING = 'QueryString',
    CONTACT_ATTRIBUTE = 'User',
    BROWSER = 'Browser'
}

export interface MapperType {
    in: string;
    out: string;
    type: string;
    default?: any;
}

export interface ResponseLimitsSSS {
    startDate: string;
    endDate: string;
    responseLimit: number;
    perRespondentLimit: number;
}

export interface BasicSSS {
    id: string;
    surveyFolderId: string;
    name: string;
    description: string;
    status: string; //  'Draft', 'Published', 'Archived'
    allowEditActive: boolean;
    customUrl: string;
    fireBaseUrl: string;
    googleAnalyticsId: string;
}

export interface RespondentSSS {
    allowBack: boolean;
    allowReset: boolean;
    allowEdit: boolean;
    allowResume: boolean;
    allowSave: boolean;
    anonymized: boolean;
    securityType: string; // 'public'
    surveyPassword: string;
    makeQuestionsRequired: boolean;
}

export interface AppearanceSSS {
    showTitle: boolean;
    showPageNumber: boolean;
    showItemsNumber: boolean;
    enableDynamicPageNumber: boolean;
    enableDynamicItemNumber: boolean;
    isItemOrderRandomized: boolean;
    showValidationErrors: boolean;
    showAsterisks: boolean;
    showProgressBar: boolean;
    progressBarOrientation: string;
    allowComplianceLink: boolean;
}

export interface LanguageSSS {
    defaultLanguage: string;
    supportedLanguages: string[];
    languageSource: string;
    languageSourceToken: string;
    allowResponseLanguageChange: string;
}

export interface TextSSS {
    customizeFor: string;
    surveyTitle: string;
    btnNext: string;
    btnBack: string;
    btnFinish: string;
    btnSaveExit: string;
    btnReset: string;
    btnContinue: string;
}

export interface AdvancedSSS {
    thirdItem: string;
    jsItem: string;
    cssItem: string;
}

export interface LanguageItem {
    value: string;
    viewValue: string;
}

export interface SurveyText {
    languageCode: string;
    surveyTitle: string;

    nextButton: string;
    backButton: string;
    finishButton: string;
    saveAndExitButton: string;
    formResetButton: string;
    continueButton: string;
    answerRecommended: string;
    surveyIsNotActive: string;
    surveyDeleted: string;
    activationPeriodInFuture: string;
    activationPeriodExpired: string;
    totalResponseLimitReached: string;
    invitationRequired: string;

    enterSurveyPassword: string;
    incorrectPasswordEntered: string;

    selectLanguage: string;

    loginFailed: string;
    enterUserName: string;
    enterUserPassword: string;
    resetPasswordLinkText: string;
    selfRegistrationLinkText: string;
    loginButton: string;
    loginText: string;

    progressSavedText: string;
    resumeInstructions: string;
    mobileResumeInstructions: string;
    saveProgressClickHere: string;
    saveProgressToReturn: string;
    emailResumeLinkInstruction: string;
    emailAddress: string;
    sendResumeEmailButton: string;
}

export class SurveyTextModel extends ModelImplementor implements SurveyText {
    activationPeriodExpired: string;
    activationPeriodInFuture: string;
    backButton: string;
    continueButton: string;
    emailAddress: string;
    emailResumeLinkInstruction: string;
    enterSurveyPassword: string;
    enterUserName: string;
    enterUserPassword: string;
    finishButton: string;
    formResetButton: string;
    incorrectPasswordEntered: string;
    invitationRequired: string;
    languageCode: string;
    loginButton: string;
    loginFailed: string;
    loginText: string;
    mobileResumeInstructions: string;
    nextButton: string;
    progressSavedText: string;
    resetPasswordLinkText: string;
    resumeInstructions: string;
    saveAndExitButton: string;
    saveProgressClickHere: string;
    saveProgressToReturn: string;
    selectLanguage: string;
    selfRegistrationLinkText: string;
    sendResumeEmailButton: string;
    surveyDeleted: string;
    surveyIsNotActive: string;
    surveyTitle: string;
    totalResponseLimitReached: string;
    answerRecommended: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'languageCode', out: 'language_code', type: 'string' },
            { in: 'surveyTitle', out: 'survey_title', type: 'string' },
            { in: 'nextButton', out: 'next_button', type: 'string' },
            { in: 'backButton', out: 'back_button', type: 'string' },
            { in: 'finishButton', out: 'finish_button', type: 'string' },
            {
                in: 'saveAndExitButton',
                out: 'save_and_exit_button',
                type: 'string'
            },
            { in: 'formResetButton', out: 'form_reset_button', type: 'string' },
            { in: 'continueButton', out: 'continue_button', type: 'string' },
            {
                in: 'surveyIsNotActive',
                out: 'survey_is_not_active',
                type: 'string'
            },
            { in: 'surveyDeleted', out: 'survey_deleted', type: 'string' },
            {
                in: 'activationPeriodInFuture',
                out: 'activation_period_in_future',
                type: 'string'
            },
            {
                in: 'activationPeriodExpired',
                out: 'activation_period_expired',
                type: 'string'
            },
            {
                in: 'totalResponseLimitReached',
                out: 'total_response_limit_reached',
                type: 'string'
            },
            {
                in: 'answerRecommended',
                out: 'answer_recommended',
                type: 'string'
            },
            {
                in: 'invitationRequired',
                out: 'invitation_required',
                type: 'string'
            },
            {
                in: 'enterSurveyPassword',
                out: 'enter_survey_password',
                type: 'string'
            },
            {
                in: 'incorrectPasswordEntered',
                out: 'incorrect_password_entered',
                type: 'string'
            },
            { in: 'selectLanguage', out: 'select_language', type: 'string' },
            { in: 'loginFailed', out: 'login_failed', type: 'string' },
            { in: 'enterUserName', out: 'enter_user_name', type: 'string' },
            {
                in: 'enterUserPassword',
                out: 'enter_user_password',
                type: 'string'
            },
            {
                in: 'resetPasswordLinkText',
                out: 'reset_password_link_text',
                type: 'string'
            },
            {
                in: 'selfRegistrationLinkText',
                out: 'self_registration_link_text',
                type: 'string'
            },
            { in: 'loginButton', out: 'login_button', type: 'string' },
            { in: 'loginText', out: 'login_text', type: 'string' },
            {
                in: 'progressSavedText',
                out: 'progress_saved_text',
                type: 'string'
            },
            {
                in: 'resumeInstructions',
                out: 'resume_instructions',
                type: 'string'
            },
            {
                in: 'mobileResumeInstructions',
                out: 'mobile_resume_instructions',
                type: 'string'
            },
            {
                in: 'saveProgressClickHere',
                out: 'save_progress_click_here',
                type: 'string'
            },
            {
                in: 'saveProgressToReturn',
                out: 'save_progress_to_return',
                type: 'string'
            },
            {
                in: 'emailResumeLinkInstruction',
                out: 'email_resume_link_instruction',
                type: 'string'
            },
            { in: 'emailAddress', out: 'email_address', type: 'string' },
            {
                in: 'sendResumeEmailButton',
                out: 'send_resume_email_button',
                type: 'string'
            }
        ];

        super(options, mapper);
    }
}

export class BasicSSSModel extends ModelImplementor implements BasicSSS {
    id: string;
    surveyFolderId: string;
    name: string;
    description: string;
    status: string; //  'Draft', 'Published', 'Archived'
    allowEditActive: boolean;
    customUrl: string;
    fireBaseUrl: string;
    googleAnalyticsId: string;
    securityType: string; // 'public'
    surveyPassword: string;
    styleTemplateId: string;
    createdBy: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'any' },
            { in: 'surveyFolderId', out: 'survey_folder_id', type: 'number' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'description', out: 'description', type: 'string' },
            { in: 'status', out: 'status', type: 'string' },
            {
                in: 'allowEditActive',
                out: 'allow_edit_while_active',
                type: 'boolean'
            },
            { in: 'customUrl', out: 'custom_url', type: 'string' },
            { in: 'fireBaseUrl', out: 'firebase_url', type: 'string' },
            {
                in: 'googleAnalyticsId',
                out: 'google_analytics_tracking_id',
                type: 'string'
            },
            {
                in: 'googleTagManagerId',
                out: 'google_tag_manager_tracking_id',
                type: 'string'
            },
            { in: 'securityType', out: 'security_type', type: 'string' },
            { in: 'surveyPassword', out: 'survey_password', type: 'string' },
            { in: 'styleTemplateId', out: 'style_template_id', type: 'any' },
            { in: 'createdBy', out: 'created_by', type: 'string' },
        ];

        super(options, mapper);
    }
}

export class SurveySettingsModel {
    respondentSettings: RespondentSSSModel;
    responseLimits: ResponseLimitsSSSModel;
    appearanceSettings: AppearanceSSSModel;
    languageSettings: LanguageSSSModel;

    constructor(surveySettings: any) {
        this.respondentSettings = new RespondentSSSModel(surveySettings.respondent_settings);
        this.responseLimits = new ResponseLimitsSSSModel(surveySettings.response_limits);
        this.appearanceSettings = new AppearanceSSSModel(surveySettings.appearance);
        this.languageSettings = new LanguageSSSModel(surveySettings.language_settings);
    }
}

export class ResponseLimitsSSSModel extends ModelImplementor
    implements ResponseLimitsSSS {
    startDate: string;
    endDate: string;
    responseLimit: number;
    perRespondentLimit: number;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'startDate', out: 'activation_start_date', type: 'date' },
            { in: 'endDate', out: 'activation_end_date', type: 'date' },
            {
                in: 'responseLimit',
                out: 'total_response_limit',
                type: 'number',
                default: 0
            },
            {
                in: 'perRespondentLimit',
                out: 'per_respondent_response_limit',
                type: 'number',
                default: 0
            }
        ];

        super(options, mapper);
    }
}

export class RespondentSSSModel extends ModelImplementor
    implements RespondentSSS {
    allowBack: boolean;
    allowReset: boolean;
    allowEdit: boolean;
    allowResume: boolean;
    allowSave: boolean;
    anonymized: boolean;
    securityType: string; // 'public'
    surveyPassword: string;
    makeQuestionsRequired: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'allowBack', out: 'allow_back_button', type: 'boolean' },
            { in: 'allowReset', out: 'allow_response_reset', type: 'boolean' },
            {
                in: 'allowEdit',
                out: 'allow_complete_response_edit',
                type: 'boolean'
            },
            {
                in: 'allowResume',
                out: 'allow_response_resume',
                type: 'boolean'
            },
            { in: 'allowSave', out: 'allow_response_save', type: 'boolean' },
            { in: 'anonymized', out: 'anonymized', type: 'boolean' },
            { in: 'securityType', out: 'security_type', type: 'string' },
            { in: 'surveyPassword', out: 'survey_password', type: 'string' },
            { in: 'makeQuestionsRequired', out: 'make_questions_required', type: 'boolean' }
        ];

        super(options, mapper);
    }
}

export class AppearanceSSSModel extends ModelImplementor
    implements AppearanceSSS {
    showTitle: boolean;
    showPageNumber: boolean;
    showItemsNumber: boolean;
    enableDynamicPageNumber: boolean;
    enableDynamicItemNumber: boolean;
    isItemOrderRandomized: boolean;
    showValidationErrors: boolean;
    showAsterisks: boolean;
    showProgressBar: boolean;
    progressBarOrientation: string;
    allowComplianceLink: boolean;
    allowAccessibilityOptions: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'showTitle', out: 'show_survey_title', type: 'boolean' },
            { in: 'showPageNumber', out: 'show_page_numbers', type: 'boolean' },
            {
                in: 'showItemsNumber',
                out: 'show_item_numbers',
                type: 'boolean'
            },
            {
                in: 'enableDynamicPageNumber',
                out: 'enable_dynamic_page_numbers',
                type: 'boolean'
            },
            {
                in: 'enableDynamicItemNumber',
                out: 'enable_dynamic_item_numbers',
                type: 'boolean'
            },
            {
                in: 'isItemOrderRandomized',
                out: 'is_item_order_randomized',
                type: 'boolean'
            },
            {
                in: 'showValidationErrors',
                out: 'show_validation_error_alert',
                type: 'boolean'
            },
            { in: 'showAsterisks', out: 'show_asterisks', type: 'boolean' },
            {
                in: 'showProgressBar',
                out: 'show_progress_bar',
                type: 'boolean'
            },
            {
                in: 'progressBarOrientation',
                out: 'progress_bar_orientation',
                type: 'string'
            },
            {
                in: 'allowComplianceLink',
                out: 'show_compliance_link',
                type: 'boolean'
            },
            {
                in: 'allowAccessibilityOptions',
                out: 'allow_accessibility_options',
                type: 'boolean'
            }
        ];

        super(options, mapper);
    }
}

export class LanguageSSSModel extends ModelImplementor implements LanguageSSS {
    defaultLanguage: string;
    supportedLanguages: string[];
    languageSource: LanguageSourceType;
    languageSourceToken: string;
    allowResponseLanguageChange: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'defaultLanguage', out: 'default_language', type: 'string' },
            {
                in: 'supportedLanguages',
                out: 'supported_languages',
                type: 'any'
            },
            { in: 'languageSource', out: 'language_source', type: 'string' },
            {
                in: 'languageSourceToken',
                out: 'language_source_token',
                type: 'string'
            },
            {
                in: 'allowResponseLanguageChange',
                out: 'allow_response_language_change',
                type: 'boolean'
            }
        ];

        super(options, mapper);
    }
}

export class TextSSSModel extends ModelImplementor implements TextSSS {
    customizeFor: string;
    surveyTitle: string;
    btnNext: string;
    btnBack: string;
    btnFinish: string;
    btnSaveExit: string;
    btnReset: string;
    btnContinue: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'customizeFor', out: 'customizeFor', type: 'string' },
            { in: 'surveyTitle', out: 'surveyTitle', type: 'string' },
            { in: 'btnNext', out: 'btnNext', type: 'string' },
            { in: 'btnBack', out: 'btnBack', type: 'string' },
            { in: 'btnFinish', out: 'btnFinish', type: 'string' },
            { in: 'btnSaveExit', out: 'btnSaveExit', type: 'string' },
            { in: 'btnReset', out: 'btnReset', type: 'string' },
            { in: 'btnContinue', out: 'btnContinue', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class AdvancedSSSModel extends ModelImplementor implements AdvancedSSS {
    thirdItem: string;
    jsItem: string;
    cssItem: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'thirdItem', out: 'thirdItem', type: 'string' },
            { in: 'jsItem', out: 'jsItem', type: 'string' },
            { in: 'cssItem', out: 'cssItem', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class LanguageItemModel extends ModelImplementor
    implements LanguageItem {
    value: string;
    viewValue: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'value', out: 'language_code', type: 'string' },
            { in: 'viewValue', out: 'name', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class SurveySelectedLanguage extends LanguageItemModel {
    isDefault?: boolean;
}

export function createLanguageList(languages: any): LanguageItem[] {
    return languages && languages.length
        ? _.map(languages, (language: any) => new LanguageItemModel(language))
        : [];
}
