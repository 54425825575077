import { first, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    SurveyBulkDeleteModel,
    SurveyResponseListModel,
    SurveyResponseModel,
    ResponseContactInfo,
    SurveyResponsesTimeLine, ResponseWebhookCall
} from '../../models/surveys/survey-responses.model';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../../app-admin/consts/resources.consts';
import { ExportCSVSettingsModel, FileDownloadSummaryModel } from '../../../app-admin/models';
import {
    ExportMaxDiffSettingsModel,
    ExportSPSSSettingsModel,
    FileExportOptions
} from '../../../app-admin/models/application-settings/response-export-application-settings.model';
import { convertDateToNonTimezoneString } from '../../helpers/date.helper';
import {
    ResponseImportData,
    ResponseImportPreviewResult
} from '../../../app-admin/surveys/survey-responses-import/survey-response-import-data';
import { FileExportProvider } from '../file-export.provider';
import { ResponseResumeKey } from '../../models/response/response.model';
import { ExportType } from '../../../../src/app-admin/downloads/download-item.model';

@Injectable()
export class SurveyResponseProvider {
    constructor(private $http: HttpClient, private fileExportProvider: FileExportProvider) {}

    getResponsesBySurveyId(
        surveyId: string,
        params
    ): Observable<SurveyResponseListModel> {
        const url: string =
            String.Format(ResourcesConsts.SURVEY_RESPONSE_LIST, surveyId);

        return this.$http.get(url, { params }).pipe(
            map((data: any) => new SurveyResponseListModel(data))
        );
    }

    getMyResponses(surveyId: string, params) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_MY_RESPONSES,
            surveyId
        );
        return this.$http.get(url, { params }).pipe(
            map((data: any) => new SurveyResponseListModel(data))
        );
    }

    getResponseSummaryBySurveyId(surveyId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_SUMMARY,
            surveyId
        );
        return this.$http
            .get(url);
    }


    getResponseTimeLine(surveyId: string, params = {}): Observable<SurveyResponsesTimeLine> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_TIMELINE,
            surveyId
        );
        return this.$http
            .get<SurveyResponsesTimeLine>(url, { params });
    }

    getResponseById(
        surveyId: string | number,
        responseId: string
    ): Observable<SurveyResponseModel> {
        const url: string =
            String.Format(ResourcesConsts.SURVEY_RESPONSE_LIST, surveyId) +
            '/' +
            responseId;
        return this.$http
            .get<SurveyResponseModel>(url);
    }

    getResponseContactInfo(
        surveyId: string | number,
        responseId: string
    ): Observable<ResponseContactInfo> {
        const url: string =
            String.Format(ResourcesConsts.SURVEY_RESPONSE_LIST, surveyId) +
            '/' +
            responseId + '/contact';
        return this.$http
            .get<ResponseContactInfo>(url);
    }

    getResponseWebhookCalls(
        surveyId: string | number,
        responseId: string
    ): Observable<ResponseWebhookCall[]> {
        const url: string =
            String.Format(ResourcesConsts.SURVEY_RESPONSE_LIST, surveyId) +
            '/' +
            responseId + '/webhook-calls';
        return this.$http.get<ResponseWebhookCall[]>(url);
    }

    exportCsvResponses(surveyId: string, getData: ExportCSVSettingsModel) {
        const url: string = String.Format(ResourcesConsts.SURVEY_RESPONSE_EXPORT_QUEUE, surveyId, 'csv');
        return this.$http.post(url, getData.getBaseMappedObject()).pipe(
            mergeMap(id => {
                return this.fileExportProvider.getExportResult(id as string, ExportType.SURVEY_RESPONSE_CSV);
            }),
            first()
        );
    }

    exportSpssKey(surveyId: string, getData: ExportSPSSSettingsModel) {
        const params = new HttpParams()
            .set('include_response_id', getData.includeResponseId.toString())
            .set(
                'include_open_ended_results',
                getData.includeOpenEndedResults.toString()
            )
            .set(
                'include_partial_responses',
                getData.includePartialResponses.toString()
            )
            .set('start_date', this.parseStartDateForParams(getData.startDate))
            .set('end_date', this.parseEndDateForParams(getData.endDate));
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_EXPORT,
            surveyId,
            'spss-key'
        );
        return this.$http
            .get(url, { params: params });
    }

    exportMaxDiff(surveyId: string, getData: ExportMaxDiffSettingsModel) {
        const url: string = String.Format(ResourcesConsts.SURVEY_RESPONSE_EXPORT_QUEUE, surveyId, 'max-diff-csv');
        return this.$http.post(url, getData.getBaseMappedObject()).pipe(
            mergeMap(id => {
                return this.fileExportProvider.getExportResult(id as string, ExportType.SURVEY_RESPONSE_MAXDIFF_CSV);
            }),
            first()
        );
    }

    exportNativeSpss(
        surveyId: string,
        getData: ExportSPSSSettingsModel
    ) {
        return this.exportSpss(surveyId, getData, ExportType.SURVEY_RESPONSE_SPSS);
    }

    exportCompatibleSpss(
        surveyId: string,
        getData: ExportSPSSSettingsModel
    ) {
        return this.exportSpss(surveyId, getData, ExportType.SURVEY_RESPONSE_SPSS_COMPATIBLE_CSV);
    }

    private exportSpss(
        surveyId: string,
        getData: ExportSPSSSettingsModel,
        exportType: ExportType
    ) {
        const fileType = exportType == ExportType.SURVEY_RESPONSE_SPSS ? 'spss' : 'spss-compatible-csv';
        const url: string = String.Format(ResourcesConsts.SURVEY_RESPONSE_EXPORT_QUEUE, surveyId, fileType);
        return this.$http.post(url, getData.getBaseMappedObject()).pipe(
            mergeMap(id => {
                return this.fileExportProvider.getExportResult(id as string, exportType);
            }),
            first()
        );
    }

    parseEndDateForParams(endDate) {
        return convertDateToNonTimezoneString(endDate) || '';
    }

    parseStartDateForParams(startDate) {
        return convertDateToNonTimezoneString(startDate) || '';
    }

    getFileUploadsBySurveyId(surveyId: string, options: FileExportOptions) {
        const params = new HttpParams()
            .set('start_date', this.parseStartDateForParams(options.startDate))
            .set('end_date', this.parseEndDateForParams(options.endDate));
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_EXPORT,
            surveyId,
            'respondent-files'
        );
        return this.fileExportProvider.getFileFromBackend(url, { params });
    }

    deleteResponseById(surveyId: string, responseId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_DELETE,
            surveyId,
            responseId
        );
        return this.$http
            .delete(url);
    }

    deleteResponsesById(surveyId: string, model: SurveyBulkDeleteModel) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_BULK_DELETE,
            surveyId
        );
        return this.$http
            .post(url, model);
    }

    deleteAllResponses(surveyId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_DELETE_ALL,
            surveyId
        );
        return this.$http
            .delete(url);
    }

    deleteTestResponses(surveyId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEST_RESPONSE_DELETE,
            surveyId
        );
        return this.$http
            .delete(url);
    }

    fileDownloadSummary(surveyId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_EXPORT,
            surveyId,
            'respondent-files-summary'
        );
        return this.$http.get(url).pipe(
            map((data: any) => new FileDownloadSummaryModel(data))
        );
    }

    resumeResponse(surveyId: number, responseId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_RESUME,
            surveyId,
            responseId
        );
        return this.$http
            .post(url, {});
    }

    editResponse(surveyId: number, responseId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_EDIT,
            surveyId,
            responseId
        );
        return this.$http
            .post(url, {});
    }

    getResumeKey(
        surveyId: string,
        responseId: string,
    ): Observable<ResponseResumeKey> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_GET_RESUME_KEY,
            surveyId,
            responseId
        );
        return this.$http.get<ResponseResumeKey>(url);
    }

    sendResponseResumeEmail(
        surveyId: string,
        responseId: string,
        email: string
    ) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_SEND_RESUME_EMAIL,
            surveyId,
            responseId
        );
        return this.$http
            .post(url, { email });
    }

    getResponseDataByResumeKey(resumeKey: string, contactId = null) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_GET_BY_RESUME_KEY,
            resumeKey
        );
        let params = new HttpParams();
        if (contactId) {
             params = params.set('contact_id', contactId);
        }

        return  this.$http.get<any>(url, {params});
    }

    importResponsesPreview(
        params: ResponseImportData
    ): Observable<ResponseImportPreviewResult> {
        const url: string = String.Format(
            ResourcesConsts.IMPORT_RESPONSES_PREVIEW,
            params.survey_id
        );
        return this.$http
            .post<ResponseImportPreviewResult>(url, params);
    }

    importResponses(
        params: ResponseImportData
    ): Observable<ResponseImportPreviewResult> {
        const url: string = String.Format(
            ResourcesConsts.IMPORT_RESPONSES_IMPORT,
            params.survey_id
        );
        return this.$http
            .post<ResponseImportPreviewResult>(url, params);
    }

    restoreResponses(surveyId: number, startDate, endDate) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_RESTORE,
            surveyId
        );

        const params = new HttpParams()
            .set('start_date', this.parseStartDateForParams(startDate))
            .set('end_date', this.parseEndDateForParams(endDate));

        return this.$http
            .post(url, {
                start_date: this.parseStartDateForParams(startDate),
                end_date: this.parseEndDateForParams(endDate)
            });
    }
}
