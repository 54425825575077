import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as actions from '../actions';
import { ApplicationError } from '../../models';
import { ErrorProvider } from '../../providers';

@Injectable()
export class ErrorEffect {
    constructor(
        private actions$: Actions,
        private errorProvider: ErrorProvider
    ) {}

    @Effect()
    logError$: Observable<Action> = this.actions$.pipe(
        ofType<actions.error.LogErrorAction>(actions.error.LOG_ERROR),
        map(action => action.payload),
        switchMap((err: ApplicationError) =>
            this.errorProvider.logError(err).pipe(
                map(
                    (errItem: ApplicationError) =>
                        new actions.error.LogErrorSuccessAction(errItem)
                ),
                catchError(errItem =>
                    of(new actions.error.LogErrorFailedAction(errItem))
                )
            )
        )
    );
}
