import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const newUrl = url.indexOf('?') + 1 ? url.split('?')[0].toLowerCase() + '?' + url.split('?')[1] : url.toLowerCase();
        return super.parse(newUrl);
    }
}
