export interface State {
    isLoading: boolean;
    sidenavOpened: boolean;
    printMode?: number;
}

export const initialState: State = {
    isLoading: false,
    sidenavOpened: false,
    printMode: null
};
