import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.const';
import { ChangePassword, SendEmailData } from '../models/reset-password.model';

@Injectable()
export class ResetPasswordProvider {
    constructor(private $http: HttpClient) {}

    sendEmailForResetPassword(data: SendEmailData): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url: string = String.Format(
            ResourcesConsts.RESET_PASSWORD_SEND_EMAIL,
            data.contact_id
        );
        return this.$http
            .post(url, JSON.stringify(data), { headers: headers });
    }

    getUserByToken(tokenvalue: object): Observable<object> {
        const url: string = String.Format(
            ResourcesConsts.RESET_PASSWORD_GET_USER,
            tokenvalue
        );
        return this.$http
            .get(url);
    }

    sendNewPassword(data: ChangePassword): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url: string = String.Format(
            ResourcesConsts.RESET_PASSWORD_CREATE_NEW
        );
        return this.$http
            .post(url, JSON.stringify(data), { headers: headers });
    }
}
