import { SurveyItemAnswerType, SurveyQuestionType } from './../consts/surveys.consts';
import { ResponsePagePostData } from './../../app-take-survey/models/response-page-post-data.model';
import * as _ from 'lodash';
import { ValidationObject } from '../models/validation-object.model';
import { SingleChoiceAnswer, TextAnswer, UploadedFileAnswer } from '../../app-take-survey/models';
import { SignatureAnswer } from '../../app-take-survey/models/base-item-answer.model';
import { ContactFormItem } from '../../shared/models/survey-items/question-items/contactFormItem';
import {TakeSurveySanitizerService} from '../../shared/services/take-survey-sanitizer.service';
const sumTotalError = 'SumTotalError';
const requiredError = 'RequiredError';
const softRequiredError = 'SoftRequiredError';
const minMaxError = 'MinMaxError';
const checkboxesError = 'CheckboxesError';
const textLengthError = 'TextLengthError';
const customRegExError = 'customRegExError';

export class TakeSurveyValidator {
    static errors: ValidationObject[] = [];
    static questionType = SurveyQuestionType;
    static surveyItemAnswerType = SurveyItemAnswerType;

    static _validateMatrix(postData) {
        const matrixItems = postData.items.filter(x => _.has(x, 'subitems'));
        matrixItems.forEach(item => {
            const sumTotalItems = item.subitems.filter(x =>
                _.has(x.answer, 'sum_total_props')
            );
            if (
                sumTotalItems &&
                sumTotalItems[0] &&
                (sumTotalItems[0].is_required ||
                    this.checkMinMaxValue(sumTotalItems))
            ) {
                this.validateMinMaxValue(sumTotalItems);
                const groups = _.groupBy(
                    sumTotalItems.map(x => x.answer),
                    x => x.sum_total_props.column
                );
                for (const key in groups) {
                    if (groups.hasOwnProperty(key)) {
                        const result = groups[key]
                            .map(x => +x.text)
                            .reduce((total, amount) => total + amount);
                        const { operator, total_value, columnId } = groups[
                            key
                        ][0].sum_total_props;
                        this.validateSumTotal(
                            operator,
                            total_value,
                            result,
                            columnId
                        );
                    }
                }
            }
        });
        _.chain(postData.items)
            .filter(x => _.has(x, 'subitems'))
            .map(x => x.subitems)
            .flatten()
            .filter(x => _.has(x.answer, 'sum_total_props'))
            .forEach(c => {
                _.unset(c.answer, 'sum_total_props');
            })
            .value();
    }

    static checkMinMaxValue(arr) {
        const filledValue = arr.findIndex(
            a => a.answer.text && a.answer.text.length
        );
        return filledValue + 1;
    }

    static validateMinMaxValue(sumTotalItems: any[]) {
        sumTotalItems.forEach(item => {
            const { min_value, max_value } = item.answer.sum_total_props;
            if (!_.inRange(+item.answer.text, min_value, max_value + 1)) {
                this.addError(`MIN-MAX`, item.item_id, minMaxError, {
                    max_value,
                    min_value
                });
            }
        });
    }

    static validateSumTotal(
        operator: string,
        total_value: number,
        result: number,
        columnId: number
    ) {
        switch (operator) {
            case 'Equal':
                if (result !== total_value) {
                    this.addError(`SumTotalOperatorEqual`, columnId, sumTotalError, {
                        total_value
                    });
                }
                break;
            case 'GreaterThan':
                if (!(result > total_value)) {
                    this.addError(`SumTotalOperatorGreaterThan`, columnId, sumTotalError, {
                        total_value
                    });
                }
                break;
            case 'GreaterThanEqual':
                if (!(result >= total_value)) {
                    this.addError(
                        `SumTotalOperatorGreaterThanEqual`,
                        columnId,
                        sumTotalError,
                        { total_value }
                    );
                }
                break;
            case 'LessThan':
                if (!(result < total_value)) {
                    this.addError(`SumTotalOperatorLessThan`, columnId, sumTotalError, {
                        total_value
                    });
                }
                break;
            case 'LessThanEqual':
                if (!(result <= total_value)) {
                    this.addError(
                        `SumTotalOperatorLessThanEqual`,
                        columnId,
                        sumTotalError,
                        { total_value }
                    );
                }
                break;
            default:
                break;
        }
    }

    static addError(message, itemId = null, type: string, params = null) {
        // message must be a key from system validation texts
        TakeSurveyValidator.errors.push({
            item_id: itemId ? itemId : null,
            type: type,
            validationMessage: message,
            params: params
        });
    }

    static validate(postData: ResponsePagePostData, page, surveySanitizerService: TakeSurveySanitizerService): ValidationObject[] {
        TakeSurveyValidator.errors = [];
        const originalItems = this.generateOriginalItemsDict(page.items);

        postData.items.forEach(item => {
            this.validateItem(item, originalItems, surveySanitizerService);
        });

        return TakeSurveyValidator.errors;
    }

    static generateOriginalItemsDict(items) {
        return _.keyBy(items, 'item_id');
    }

    static addRequiredError(item, answer) {
        this.addError('AnswerRequired', item.item_id, requiredError);
        _.unset(answer, 'is_required');
    }

    static addSoftRequiredError(item, answer, originalItem) {
        this.addError('AnswerRecommended', item.item_id, softRequiredError);
        originalItem.is_soft_required = false;
        _.unset(answer, 'is_soft_required');
    }

    static validateItem(item,
                        originalItems,
                        surveySanitizerService: TakeSurveySanitizerService,
                        isMatrix = false) {
        const answer = item.answer;
        switch (answer && answer.answer_type) {
            case this.surveyItemAnswerType.TEXT:
                this.validateTextAnswer(item,
                    answer,
                    originalItems[item.item_id],
                    isMatrix,
                    surveySanitizerService);
                break;
            case this.surveyItemAnswerType.SINGLE_CHOICE:
                this.validateSingleChoice(item, answer, originalItems[item.item_id]);
                break;
            case this.surveyItemAnswerType.MULTIPLE_CHOICE:
                this.validateMultipleChoice(item, answer, originalItems[item.item_id]);
                // possibly we will need to use original items to handle all types of validations
                // like it made here.
                // we can get rid of setting and unsetting in postData is_required field in that case.
                break;
            case this.surveyItemAnswerType.MAX_DIFF:
                this.validateMaxDiff(item, answer, originalItems[item.item_id]);
                break;
            case this.surveyItemAnswerType.UPLOADED_FILE:
                this.validateFile(item, answer, originalItems[item.item_id]);
                break;
            case this.surveyItemAnswerType.SIGNATURE:
                this.validateSignature(item, answer, originalItems[item.item_id]);
                break;
            case this.surveyItemAnswerType.CUSTOM_SOURCE_DROPDOWNLIST:
                this.validateCustomSourceDropdown(item, answer, originalItems[item.item_id]);
            break;
            case this.surveyItemAnswerType.RANK_ORDER:
                this.validateRankOrder(item, answer, originalItems[item.item_id]);
            break;
            default:
                break;
        }
        if (item.subitems && item.subitems.length) {
            const parentItem = originalItems[item.item_id];

            if (parentItem.item_type === SurveyQuestionType.MATRIX) {
                this.validateMatrix(item.subitems, parentItem, surveySanitizerService);
            } else {
                this.validateContactForm(item.subitems, parentItem, surveySanitizerService);
            }
        }
    }

    static isRequired(item) {
        return item.is_required || item.answer.is_required;
    }

    static isSoftRequired(item) {
        return item.is_soft_required || item.answer.is_soft_required;
    }

    static validateCustomSourceDropdown(item, answer, originalItem) {
        if (this.isRequired(item) && !answer.value) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !answer.value) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
    }

    static validateRankOrder(item, answer, originalItem) {
        if (this.isRequired(item) && !answer.choices.length) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !answer.choices.length) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
    }

    static validateTextAnswer(item,
                              answer: TextAnswer,
                              originalItem,
                              isMatrix,
                              surveySanitizerService: TakeSurveySanitizerService) {
        const answerText = answer.text || '';
        if (this.isRequired(item) && !answerText) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !answerText) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
        if (originalItem) {
            const rawText = surveySanitizerService.getTextContent(answerText);
            if (originalItem.max_length && rawText.length > originalItem.max_length) {
                this.addError('TextMaxLength', item.item_id, textLengthError, { max: originalItem.max_length });
            }
            if (originalItem.min_length && rawText.length < originalItem.min_length) {
                this.addError('TextMinLength', item.item_id, textLengthError, { min: originalItem.min_length });
            }
            if (originalItem.regex_pattern && answerText && !isMatrix) {
                const regExStringLength = originalItem.regex_pattern.length;
                const preparedRegExString = originalItem.regex_pattern[0] === '/' &&
                                            originalItem.regex_pattern[regExStringLength - 1] === '/' ?
                                            originalItem.regex_pattern.substring(1, regExStringLength - 1) : originalItem.regex_pattern;
                const regEx = new RegExp(preparedRegExString);
                if (!regEx.test(answerText)) {
                    originalItem.server_error_messages = [originalItem.regex_validation_message];
                    this.addError(originalItem.regex_validation_message, item.item_id, customRegExError);
                }
            }
        }
    }

    static validateSingleChoice(item, answer, originalItem) {
        if (this.isRequired(item) && !(answer as SingleChoiceAnswer).choice_id) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !(answer as SingleChoiceAnswer).choice_id) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
    }

    static validateMultipleChoice(item, answer, originalItem) {
        if (this.isRequired(item) && !answer.choices.length) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !answer.choices.length) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
        if (originalItem) {
            if (originalItem.select_max_limit && answer.choices.length > originalItem.select_max_limit) {
                this.addError('SelectManyMaxError', item.item_id, checkboxesError, { max: originalItem.select_max_limit });
            }
            if (originalItem.select_min_limit && answer.choices.length < originalItem.select_min_limit) {
                if (originalItem.select_min_limit === 1) {
                    this.addError('SelectManyMinErrorSingular', item.item_id, checkboxesError);
                } else {
                    this.addError('SelectManyMinError', item.item_id, checkboxesError, { min: originalItem.select_min_limit });
                }
            }
        }
    }

    static validateMaxDiff(item, answer, originalItem) {
        const sets = answer.sets;
        const hasEmptySet = _.some(sets, s => !s.best_choice_id && !s.worst_choice_id);
        if (this.isRequired(item) && (_.isEmpty(sets) || hasEmptySet)) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && (_.isEmpty(sets) || hasEmptySet)) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
    }

    static validateFile(item, answer, originalItem) {
        if (this.isRequired(item) && !(answer as UploadedFileAnswer).file_id) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !(answer as UploadedFileAnswer).file_id) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
    }

    static validateSignature(item, answer, originalItem) {
        if (this.isRequired(item) && !(answer as SignatureAnswer).has_signature) {
            this.addRequiredError(item, answer);
        }
        if (this.isSoftRequired(item) && !(answer as SignatureAnswer).has_signature) {
            this.addSoftRequiredError(item, answer, originalItem);
        }
        _.unset(answer, 'has_signature');
    }

    static validateMatrix(subItems, originalMatrix, surveySanitizerService: TakeSurveySanitizerService) {
        const protoItems = _.chain(originalMatrix)
            .get('columns')
            .map('prototype_item')
            .keyBy('id')
            .value();
        const originalItems = _.chain(originalMatrix)
            .get('elements')
            .map('item')
            .keyBy('id')
            .mapValues(val => protoItems[val.prototype_item_id])
            .value();

        _.forEach(subItems, item => this.validateItem(item, originalItems,  surveySanitizerService, true));
    }

    static validateContactForm(subItems,
                               contactForm: ContactFormItem,
                               surveySanitizerService: TakeSurveySanitizerService) {
        const subQuestions = _.chain(contactForm)
            .get('fields')
            .map('question')
            .keyBy('id')
            .value();
        _.forEach(subItems, item => this.validateItem(item, subQuestions, surveySanitizerService, true ));
    }
}
