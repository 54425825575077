import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import { StyleTemplate } from '../models/style-template.model';
import { String } from 'typescript-string-operations-ng4';
import * as _ from 'lodash';
import { PagedList } from '../models';
import { SurveyListGetData } from '../../app-admin/models';
import { CustomURLEncoder } from '../../infrastructure/helpers/urlEncode.helper';

@Injectable()
export class StyleTemplatesProvider {
    styleTemplates: StyleTemplate[];

    constructor(private $http: HttpClient) {}

    getStyleTemplatesPaged(
        getData: SurveyListGetData,
        inUse?: boolean | null
    ): Observable<PagedList<StyleTemplate>> {
        const url: string = String.Format(ResourcesConsts.STYLE_LIST);
        const params = new HttpParams({encoder: new CustomURLEncoder() })
            .set('search_text', getData.search_text)
            .set('page_num', getData.paging_info.page_num.toString())
            .set('page_size', getData.paging_info.page_size.toString())
            .set(
                'in_use',
                inUse !== null && inUse !== undefined ? inUse.toString() : '');
        return this.$http.get(url, { params: params }).pipe(
            map((data: PagedList<StyleTemplate>) => {
                _.forEach(data.items, (value, key) => {
                    data.items[key] = new StyleTemplate(value);
                });
                return data;
            })
        );
    }

    getStyleTemplateById(id: number): Observable<StyleTemplate> {
        const url: string = String.Format(ResourcesConsts.STYLE_BY_ID, id);
        return this.$http.get(url).pipe(
            map(data => new StyleTemplate(data))
        );

    }

    getStyleTemplateSimpleList(): Observable<any> {
        const url: string = String.Format(ResourcesConsts.STYLE_LIST_SIMPLE);
        return this.$http.get(url);

    }

    updateStyleTemplate(styleTemplate: StyleTemplate): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.STYLE_BY_ID,
            styleTemplate.id
        );
        return this.$http.put(url, styleTemplate.toJSON()).pipe(
            map(data => new StyleTemplate(data))
        );
    }

    createStyleTemplate(styleTemplate: StyleTemplate): Observable<any> {
        const url: string = String.Format(ResourcesConsts.STYLE_LIST);
        return this.$http.post(url, styleTemplate.toJSON()).pipe(
            map(data => new StyleTemplate(data))
        );
    }

    deleteStyleTemplate(id: number): Observable<any> {
        const url: string = String.Format(ResourcesConsts.STYLE_BY_ID, id);
        return this.$http.delete(url);
    }

    checkInUse(id: number) {
        const url = `${String.Format(ResourcesConsts.STYLE_BY_ID, id)}/in-use`;
        return this.$http.get<boolean>(url).pipe(
            map(data => data)
        );
    }

    generateCss(style: StyleTemplate) {
        const url: string = String.Format(ResourcesConsts.STYLE_GENERATE_CSS);
        return this.$http
            .post(url, style.toJSON(), { responseType: 'text' });
    }

    getCssByStyleTemplateId(styleTemplateId?: number) {
        if (styleTemplateId) {
            const url: string =
                String.Format(ResourcesConsts.STYLE_BY_ID, styleTemplateId) +
                '/css';
            return this.$http
                .get(url, { responseType: 'text' });
        } else {
            return observableOf('');
        }
    }

    appendCssToPage(css: string, styleId: string) {
        const style = document.getElementById(styleId);
        if (style) {
            style.innerHTML = css;
        } else {
            const node = document.createElement('style');
            node.type = 'text/css';
            node.innerHTML = css;
            node.id = styleId;
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    removeCssFromPage(styleId: string) {
        const element = document.getElementById(styleId);
        if (element) {
            element.parentNode.removeChild(element);
        }
    }

    applyStyleTemplate(styleTemplate: StyleTemplate) {
        return this.generateCss(styleTemplate).pipe(
            map(css => {
                this.applyCss(css, styleTemplate.customCss);
            })
        );
    }

    applyCss(themeCss, customCss?) {
        this.clearCss();
        this.appendCssToPage(themeCss, 'cb-custom-theme');
        if (customCss) {
            this.appendCssToPage(customCss, 'cb-custom-theme-user-css');
        }
    }

    clearCss() {
        this.removeCssFromPage('cb-custom-theme');
        this.removeCssFromPage('cb-custom-theme-user-css');
    }

    copyTemplate(templateId, name, is_editable, is_public): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.COPY_STYLE_TEMPLATE,
            templateId
        );
        return this.$http
            .post(url, { name, is_editable, is_public });
    }
}
