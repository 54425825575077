export class AuthConfigConst {
    public static IDENTITY_KEY = 'identity_key';
    public static ACCOUNT_KEY = 'account_key';
    public static EXTERNAL_ID = 'external_id';
    public static RESPONDENT_KEY = 'respondent_key';
    public static TAKE_SURVEY_KEY = 'take_survey_key';
}

export class UserRoles {
    public static SYSTEM_ADMINISTRATOR = 'System Administrator';
    public static CONTACT_ADMINISTRATOR = 'Contact Administrator';
    public static SURVEY_ADMINISTRATOR = 'Survey Administrator';
    public static RESPONDENT = 'Respondent';
    public static REPORT_VIEWER = 'Report Viewer';
    public static REPORT_ADMINISTRATOR = 'Report Administrator';
    public static SURVEY_EDITOR = 'Survey Editor';
}
