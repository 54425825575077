<button *ngIf="type===basicOption || notAnOption()" mat-button [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="sizeOrDefault()" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===flatOption" mat-flat-button [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="sizeOrDefault()" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===strokedOption" mat-stroked-button [disabled]="disabled" [color]="color" [ngClass]="{'button-icon': !text, 'small': true}" (click)="click($event)" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===iconOption" mat-icon-button [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="['button-icon', sizeOrDefault()]" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===fabOption" mat-fab [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="sizeOrDefault()" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===miniFabOption" mat-mini-fab [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="sizeOrDefault()" type="button">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<button *ngIf="type===raisedOption" mat-raised-button [disabled]="disabled" [color]="color" (click)="click($event)" [ngClass]="sizeOrDefault()">
  <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</button>

<ng-template #buttonTemplate>
  <div>
    <mat-icon class="button-icon" *ngIf="registryIcon" [svgIcon]="registryIcon"></mat-icon>
    <mat-icon class="button-icon" *ngIf="matIcon">{{matIcon}}</mat-icon>
    <span *ngIf="text">{{text}}</span>
    <div [ngClass]="{'sufix-divider': hasDivider, 'sufix-icon':true}" *ngIf="sufixIcon">
      <mat-icon [ngClass]="{'rotate': rotateSufix}" [svgIcon]="registrySufixIcon">{{matSufixIcon}}</mat-icon>
    </div>
  </div>
</ng-template>