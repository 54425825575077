import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    Validators,
    ValidatorFn,
    AbstractControl,
    FormGroup
} from '@angular/forms';
import { PasswordService } from '../../../infrastructure/services';

@Component({
    selector: 'cb-reset-password-new-password',
    templateUrl: './reset-password-new-password.component.html',
    styleUrls: ['./reset-password-new-password.component.scss']
})
export class ResetPasswordNewPasswordComponent implements OnInit {
    public form: FormGroup;
    @Output() resetMail: EventEmitter<void> = new EventEmitter();
    @Input() user: string;

    public get passwordValidationError(): string {
        return this.passwordService.getErrorMessage();
    }

    constructor(
        private fb: FormBuilder,
        private passwordService: PasswordService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            password: new FormControl('', Validators.required),
            passwordConfirmation: new FormControl('', [
                Validators.required,
                this.confirmValidator()
            ])
        });

        this.passwordService.setValidators(this.form.controls['password']);
    }

    private confirmValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!this.form) {
                return null;
            }
            const password = this.form.get('password').value;
            const password2 = control.value;
            const passwordNotMatch =
                password && password2 && password !== password2;
            return passwordNotMatch ? { confirmValidator: true } : null;
        };
    }

    get passwordControl() {
        return this.form.get('password');
    }

    onSubmit() {
        const data = this.form.value;
        this.resetMail.emit(data);
    }
}
