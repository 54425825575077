import { MapperType } from '../../infrastructure/models/survey-settings.model';
import { PagedList } from '../../infrastructure/models';
import { ModelImplementor, ModelPageListImplementor } from './implementor.model';

export interface ReportDelivery {
    delivery_id: number;
    report_id: number;
    report_delivery_type: string;
    deliveries_rest: number;
    next_send_on: Date;
    last_sent_on: Date;
    created_by: string;

    subject: string;
    body: string;
    from_address: string;
    from_name: string;

    recipients: string;
}

export interface ReportDeliveriesList extends PagedList<ReportDelivery> {}

export class ReportDeliveriesListModel
    extends ModelPageListImplementor<ReportDelivery>
    implements ReportDeliveriesList {
    constructor(options: any) {
        super(options, ReportDeliveryModel);
    }
}

export class ReportDeliveryModel extends ModelImplementor
implements ReportDelivery {
    delivery_id: number;
    report_id: number;
    report_delivery_type: string;
    deliveries_rest: number;
    next_send_on: Date;
    last_sent_on: Date;
    created_by: string;
    subject: string;
    body: string;
    from_address: string;
    from_name: string;
    recipients: string;
    
    get status() { 
        if (this.last_sent_on && !this.next_send_on && !this.deliveries_rest)
            return 'Completed';
        if (this.next_send_on)
            return 'Active';
        else
            return 'Draft';
    }

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'delivery_id', out: 'delivery_id', type: 'number' },
            { in: 'report_id', out: 'report_id', type: 'number' },
            { in: 'report_delivery_type', out: 'report_delivery_type', type: 'string' },
            { in: 'deliveries_rest', out: 'deliveries_rest', type: 'number' },
            { in: 'next_send_on', out: 'next_send_on', type: 'date' },
            { in: 'last_sent_on', out: 'last_sent_on', type: 'date' },
            { in: 'created_by', out: 'created_by', type: 'string' },
            { in: 'subject', out: 'subject', type: 'string' },
            { in: 'body', out: 'body', type: 'string' },
            { in: 'from_address', out: 'from_address', type: 'string' },
            { in: 'from_name', out: 'from_name', type: 'string' },
            { in: 'recipients', out: 'recipients', type: 'string' }
        ];

        super(options, mapper);
    }
}