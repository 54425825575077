import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-svg-icon',
    templateUrl: './svg.component.html'
})
export class SvgComponent {
    @Input() name: String;
    @Input() width: String;
    @Input() height: String;

    constructor() {}

    get absUrl() {
        return window.location.href;
    }
}
