import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { IdentityStore } from '../store';
import { Identity } from '../models';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: IdentityStore,
    ) {}

    isLoggedIn(identity: Identity) {
        return !!identity && !!identity.access_token;
    }

    permissionGranted(identity: Identity) {
        return (
            this.isLoggedIn(identity)
        );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.identity.pipe(
            map((identity: Identity) => {
                if (this.permissionGranted(identity)) {
                    return true;
                }
                this.store.setRedirectUri(state.url);
                this.store.goToLoginUri();
                return false;
            })
        );
    }
}
