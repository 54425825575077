export class ResourcesConsts {
    // public static ACCOUNT = '/{account}';
    public static CONTACTS = '/{account}/contacts';
    public static CONTACTS_LIST = '/{account}/contact-list';
    public static MEMBERSHIP = '/{account}/membership-list';
    public static CONTACTS_CSV_EXPORT = '/{account}/export-contacts/csv';
    public static CONTACT_INFO = '/{account}/contacts/{0}';
    public static CONTACT_PROFILE =
        '/{account}/contacts/{0}/profile-properties';
    public static CONTACT_GROUPS = '/{account}/contacts/{0}/group-memberships';
    public static CONTACT_GROUP_REMOVE =
        '/{account}/contacts/{0}/group-memberships/{1}';
    public static CONTACT_ROLES = '/{account}/contacts/{0}/roles';
    public static CONTACT_ROLES_REMOVE = '/{account}/contacts/{0}/roles/{1}';
    public static CONTACT_SOURCES = '/{account}/contact-sources';
    public static CONTACT_LANGUAGE = '/{account}/contacts/me/application-language';
    public static SURVEYS = '/{account}/surveys';
    public static SURVEYS_PDF = '/{account}/surveys/{0}/export-pdf';
    public static SURVEYS_GENERATE_PDF = '/{account}/surveys/{0}/export-pdf/queue';
    public static SURVEYS_EXPORT = '/{account}/surveys/{0}/export';
    public static SURVEYS_IMPORT = '/{account}/surveys/import';
    public static SURVEYS_EXTERNAL = '/{account}/surveys/external-id';
    public static ROLES = '/{account}/roles';
    public static CHECK_LICENSING_LIMITS = '/{account}/licensing/limits/{0}';
    public static CHECK_LICENSING = '/{account}/licensing/limits';
    public static AVAILABLE_ROLES = '/{account}/roles/available';
    public static GROUPS = '/{account}/contact-groups';
    public static GROUP_DETAIL = '/{account}/contact-groups/{0}';
    public static GROUP_MEMBER = '/{account}/contact-groups/{0}/members';
    public static PERMISSION_EVERYONE = '/{account}/authorization/authorize';
    public static PERMISSION_SURVEY = '/{account}/surveys/{0}/acl';
    public static PERMISSION_FOLDER = '/{account}/survey-folders/{0}/acl';
    public static PERMISSION_GROUP = '/{account}/contact-groups/{0}/acl';
    public static PERMISSION_CONTACTS = '/{account}/contacts/acl';
    public static PERMISSION_SURVEY_DEFAULT =
        '/{account}/surveys/{0}/acl/default-access';
    public static PERMISSION_FOLDER_DEFAULT =
        '/{account}/survey-folders/{0}/acl/default-access';
    public static PERMISSION_GROUP_DEFAULT =
        '/{account}/contact-groups/{0}/acl/default-access';
    public static PERMISSION_CONTACTS_DEFAULT =
        '/{account}/contacts/acl/default-access';
    public static PERMISSION_SURVEY_ENTRY =
        '/{account}/surveys/{0}/acl/entries/{1}';
    public static PERMISSION_FOLDER_ENTRY =
        '/{account}/survey-folders/{0}/acl/entries/{1}';
    public static PERMISSION_GROUP_ENTRY =
        '/{account}/contact-groups/{0}/acl/entries/{1}';
    public static PERMISSION_CONTACTS_ENTRY =
        '/{account}/contacts/acl/entries/{0}';
    public static PERMISSION_SURVEY_ENTRIES =
        '/{account}/surveys/{0}/acl/entries';
    public static PERMISSION_FOLDER_ENTRIES =
        '/{account}/survey-folders/{0}/acl/entries';
    public static PERMISSION_GROUP_ENTRIES =
        '/{account}/contact-groups/{0}/acl/entries';
    public static PERMISSION_CONTACTS_ENTRIES =
        '/{account}/contacts/acl/entries';
    public static ZENDESK_SSO_JWT = '/{account}/zendesk-sso/jwt';
    public static SURVEY_FOLDERS = '/{account}/survey-folders';
    public static SURVEY_LIST = '/{account}/survey-list';
    public static PAGES = '/{account}/surveys/{0}/pages';
    public static PAGE = '/{account}/surveys/{0}/pages/{1}';
    public static PAGE_CLONE = '/{account}/surveys/{0}/pages/{1}/copy';
    public static ARCHIVE = '/{account}/surveys/{0}/archive';
    public static UNARCHIVE = '/{account}/surveys/{0}/unarchive';
    public static SURVEY_EDITOR_ADD_QUESTION = '/{account}/surveys/{0}/items';
    public static SURVEY_EDITOR_ADD_QUESTION_FROM_TEMPLATE = '/{account}/surveys/{0}/items/copy-from-template';
    public static SURVEY_EDITOR_UPDATE_QUESTION =
        '/{account}/surveys/{0}/items/{1}';
    public static SURVEY_EDITOR_REORDER_QUESTION =
        '/{account}/surveys/{0}/items/{1}/page-position';
    public static SURVEY_EDITOR_REORDER_PAGE =
        '/{account}/surveys/{0}/pages/{1}/position';
    public static SURVEY_EDITOR_GET_QUESTIONS_PER_PAGE =
        '/{account}/surveys/{0}/pages/{1}/items';
    public static SURVEY_EDITOR_GET_QUESTION =
        '/{account}/surveys/{0}/items/{1}';
    public static SURVEY_EDITOR_DELETE_QUESTION =
        '/{account}/surveys/{0}/items/{1}';
    public static SURVEY_EDITOR_CLONE_QUESTION =
        '/{account}/surveys/{0}/items/{1}/copy';
    public static SURVEY_EDITOR_UPLOAD_IMAGE = '/{account}/files/upload';
    public static SURVEY_EDITOR_SAVE_IMAGE_URL = '/{account}/files';
    public static SURVEY_EDITOR_GET_IMAGE = '/{account}/files/{0}/content';
    public static SURVEY_EDITOR_GET_FILE_EXTENSIONS =
        '/{account}/application-settings/survey/file-upload-item';
    public static SURVEY_EDITOR_GET_AUTOCOMPLETE_LISTS =
        '/{account}/autocomplete-lists';
    public static SURVEY_ENABLE_ITEM = '/{account}/surveys/{0}/items/{1}/enabled';
    public static SURVEY_EXPRESSIONS_GET_LEFT_OPERANDS =
        '/{account}/surveys/{0}/expression-builder/left-operand-source';
    public static SURVEY_EXPRESSIONS_GET_OPERATORS =
        '/{account}/surveys/{0}/expression-builder/operators';
    public static SURVEY_EXPRESSIONS_GET_EXPRESSION_VALUE =
        '/{account}/surveys/{0}/expression-builder/right-operand-source';
    public static SURVEY_PAGE_MERGE_CODES =
        '/{account}/surveys/{0}/pages/{1}/merge-codes';
    public static SURVEY_INVITATION_MERGE_CODES =
        '/{account}/surveys/{0}/invitations/{1}/merge-codes';
    public static EMAIL_INVITATION_FOOTER_MERGE_CODES =
        '/{account}/application-settings/messaging/email/invitation-merge-codes';
    public static SURVEY_INVITATION_BASICS =
        '/{account}/surveys/{0}/invitations';
    public static SURVEY_INVITATION_SUMMARY =
        '/{account}/surveys/{0}/invitations/recipients/summary';
    public static SURVEY_INVITATION_OPT_OUT =
        '/{account}/surveys/{0}/invitations/opt-out';
    public static SURVEY_INVITATION = '/{account}/surveys/{0}/invitations/{1}';
    public static SURVEY_INVITATION_SEND_PENDING =
        '/{account}/surveys/{0}/invitations/{1}/send-to-pending';
    public static SURVEY_INVITATION_SEND_REMINDER =
        '/{account}/surveys/{0}/invitations/{1}/send-reminder';
    public static SURVEY_EXPORT_INVITATION_DETAILS =
        '/{account}/surveys/{0}/invitations/{1}/export-csv';
    public static SURVEY_INVITATION_COPY =
        '/{account}/surveys/{0}/invitations/{1}/copy';
    public static SURVEY_INVITATION_SEND_TEST =
        '/{account}/surveys/{0}/invitations/{1}/send-test';
    public static SURVEY_INVITATION_SCHEDULES =
        '/{account}/surveys/{0}/invitations/{1}/schedules';
    public static SURVEY_INVITATION_SCHEDULES_DELETE =
        '/{account}/surveys/{0}/invitations/{1}/schedules/bulk-delete';
    public static SURVEY_INVITATION_SCHEDULE =
        '/{account}/surveys/{0}/invitations/{1}/schedules/{2}';
    public static SURVEY_INVITATION_MESSAGE =
        '/{account}/surveys/{0}/invitations/{1}/message';
    public static SURVEY_INVITATION_REMINDER =
        '/{account}/surveys/{0}/invitations/{1}/reminder';
    public static SURVEY_SMS_INVITATION_MESSAGE_LIMITS =
        '/{account}/surveys/{0}/invitations/{1}/sms-message-limits';
    public static SURVEY_INVITATION_PANELS =
        '/{account}/surveys/{0}/invitations/{1}/panels';
    public static SURVEY_INVITATION_PANEL =
        '/{account}/surveys/{0}/invitations/{1}/panels/{2}';
    public static SURVEY_INVITATION_PANELS_CONTACTS =
        '/{account}/surveys/{0}/invitations/{1}/panels/contacts-to-add';
    public static SURVEY_INVITATION_PANELS_GROUPS =
        '/{account}/surveys/{0}/invitations/{1}/panels/contact-groups-to-add';
    public static SURVEY_INVITATION_BATCH =
        '/{account}/surveys/{0}/invitations/{1}/panels/batch';
    public static SURVEY_INVITATION_RECIPIENTS =
        '/{account}/surveys/{0}/invitations/{1}/recipients';
    public static SURVEY_INVITATION_RECIPIENTS_RESPONDED =
        '/{account}/surveys/{0}/invitations/{1}/recipients/mark-as-responded';
    public static SURVEY_INVITATION_RECIPIENTS_OPT_OUT =
        '/{account}/surveys/{0}/invitations/{1}/recipients/mark-as-opted-out';
    public static SURVEY_INVITATION_RECIPIENTS_SUMMARY =
        '/{account}/surveys/{0}/invitations/{1}/recipients/summary';
    public static SURVEY_INVITATION_RECIPIENT_LINK =
        '/{account}/surveys/{0}/invitations/{1}/recipients/{2}/link';
    public static SURVEY_INVITATION_RECIPIENT_RESEND_INVITATION =
        '/{account}/surveys/{0}/invitations/{1}/recipients/{2}/resend-invitation';
    public static SURVEY_INVITATION_RECIPIENT_RESEND_REMINDER =
        '/{account}/surveys/{0}/invitations/{1}/recipients/{2}/resend-reminder';
    public static SURVEY_RESPONSE_LIST = '/{account}/surveys/{0}/responses';
    public static SURVEY_RESPONSE_SUMMARY =
        '/{account}/surveys/{0}/responses/summary';
    public static SURVEY_RESPONSE_TIMELINE =
        '/{account}/surveys/{0}/responses/timeline';
    public static SURVEY_RESPONSE_RESTORE =
        '/{account}/surveys/{0}/responses/restore-deleted';
    public static SURVEY_MY_RESPONSES =
        '/{account}/surveys/{0}/responses/for-current-respondent';
    public static SURVEY_RESPONSE_DELETE =
        '/{account}/surveys/{0}/responses/{1}';
    public static SURVEY_RESPONSE_BULK_DELETE =
        '/{account}/surveys/{0}/responses/bulk-delete';
    public static SURVEY_RESPONSE_DELETE_ALL =
        '/{account}/surveys/{0}/responses/bulk-delete-all';
    public static SURVEY_TEST_RESPONSE_DELETE =
        '/{account}/surveys/{0}/responses/test-data';
    public static SURVEY_RESPONSE_EDIT =
        '/{account}/surveys/{0}/responses/{1}/edit';
    public static SURVEY_RESPONSE_RESUME =
        '/{account}/surveys/{0}/responses/{1}/resume';
    public static SURVEY_RESPONSE_SEND_RESUME_EMAIL =
        '/{account}/surveys/{0}/responses/{1}/send-resume-email';
    public static SURVEY_RESPONSE_GET_RESUME_KEY =
        '/{account}/surveys/{0}/responses/{1}/resume-key';
    public static SURVEY_RESPONSE_GET_BY_RESUME_KEY =
        '/{account}/response-by-resume-key/{0}';
    public static SURVEY_RESPONSE_EXPORT =
        '/{account}/surveys/{0}/export-responses/{1}';
    public static SURVEY_RESPONSE_EXPORT_QUEUE =
        '/{account}/surveys/{0}/export-responses/queue/{1}';
    public static SURVEY_SETTINGS_BASICS = '/{account}/surveys/{0}';
    public static SURVEY_COPY = '/{account}/surveys/{0}/copy';
    public static SURVEY_SETTINGS_SCRIPTS =
        '/{account}/surveys/{0}/survey-wide-javascript';
    public static SURVEY_SETTINGS =
        '/{account}/surveys/{0}/settings';
    public static SURVEY_SETTINGS_RESPONDENT =
        '/{account}/surveys/{0}/respondent-settings';
    public static SURVEY_SETTINGS_RESPONSE_LIMIT =
        '/{account}/surveys/{0}/response-settings';
    public static SURVEY_SETTINGS_APPEARANCE =
        '/{account}/surveys/{0}/appearance-settings';
    public static SURVEY_SETTINGS_LANGUAGE =
        '/{account}/surveys/{0}/language-settings';
    public static SURVEY_SETTINGS_LANGUAGE_BY_EXTERNAL_ID =
            '/{account}/surveys/external-id/{0}/language-settings';
    public static SURVEY_SETTINGS_TEXT = '/{account}/surveys/{0}/text-settings';
    public static SURVEY_SETTINGS_CSS =
        '/{account}/surveys/{0}/survey-wide-css';
    public static SURVEY_SETTINGS_REFERENCES =
        '/{account}/surveys/{0}/survey-wide-references';
    public static SURVEY_VALIDATE_PASSWORD =
        '/{account}/surveys/{0}/validate-survey-password';
    public static SURVEY_SETTINGS_DICT_LANGUAGES =
        '/{account}/application-settings/survey/languages';
    public static SURVEY_SYSTEM_SETTINGS = '/{account}/application-settings/survey';
    public static SURVEY_SETTINGS_PREFERENCES =
        '/{account}/application-settings/survey/preferences';
    public static SURVEY_SETTINGS_MAKE_ALL_REQUIRED = '/{account}/surveys/{0}/items/make-all-questions-required';
    public static CAPTCHA_SETTINGS = '/{account}/application-settings/captcha';
    public static CONTACT_IMPORT_FROM_FILE = '/{account}/contact-import';
    public static CONTACT_IMPORT = '/{account}/contacts/import';
    public static CONTACT_PARSE_CSV = '/{account}/contacts/import/parse-csv';
    public static COMPANY_PROFILES = '/{account}/company-profiles';
    public static COMPANY_PROFILE = '/{account}/company-profiles/{0}';
    public static SURVEY_QUESTIONS_TEXT =
        '/{account}/surveys/{0}/question-source';
    public static SURVEY_TEXT = '/{account}/surveys/{0}/texts/{1}';
    public static SURVEY_TEXT_BY_EXTERNAL_ID = '/{account}/surveys/external-id/{0}/texts/{1}';
    public static STYLE_GENERATE_CSS = '/{account}/style-templates/css';
    public static STYLE_LIST = '/{account}/style-templates';
    public static STYLE_LIST_SIMPLE = '/{account}/style-templates/simplified-list';
    public static STYLE_BY_ID = '/{account}/style-templates/{0}';
    public static COPY_STYLE_TEMPLATE = '/{account}/style-templates/{0}/copy';
    public static DASHBOARD = '/{account}/dashboards';
    public static CREATE_DASHBOARD = '/{account}/reports';
    public static CLONE_DASHBOARD_ITEM = '/{account}/reports/{0}/copy';
    public static CREATE_DASHBOARD_ITEM = '/{account}/reports/{0}/items';
    public static DELETE_DASHBOARD_ITEM = '/{account}/reports/{0}/items/{1}';
    public static UPDATE_DASHBOARD_ITEM_VISIBILITY = '/{account}/reports/{0}/items/{1}/enabled';
    public static DASHBOARD_ITEMS = '/{account}/reports/{0}/pages/{1}/items';
    public static DASHBOARD_PAGES = '/{account}/reports/{0}/pages';
    public static DASHBOARD_BY_ID = '/{account}/reports/{0}';
    public static DASHBOARD_GENERATE_PDF = '/{account}/reports/{0}/export-pdf/queue';
    public static DASHBOARD_DATE_FILTER = '/{account}/reports/{0}/filter-group';
    public static DASHBOARD_ITEM_SURVEY_QUESTIONS =
        '/{account}/surveys/{0}/report-data-sources';
    public static DASHBOARD_ITEM_FILTER_SOURCES =
        '/{account}/surveys/{0}/report-filter-sources';
    public static DASHBOARD_ITEM_CHART_DATA =
        '/{account}/reports/{0}/items/{1}/data';
    public static DASHBOARD_RELATED_SURVEY_ITEM =
        '/{account}/reports/{0}/items/{1}/data-sources/{2}/survey-item';
    public static DASHBOARD_COMPATIBLE_RESPONSE_PROPERTIES =
        '/{account}/application-settings/report/response-properties';
    public static DASHBOARD_PAGE_REORDER_DATA =
        '/{account}/reports/{0}/pages/{1}/position';
    public static DASHBOARD_PAGE = '/{account}/reports/{0}/pages/{1}';
    public static REPORT_DELIVERIES = '/{account}/reports/{0}/deliveries';
    public static REPORT_DELIVERY = '/{account}/reports/{0}/deliveries/{1}';
    public static REPORT_DELIVERY_CANCEL = '/{account}/reports/{0}/deliveries/{1}/cancel';
    public static REPORT_DELIVERY_DELETE = '/{account}/reports/{0}/deliveries/bulk-delete';
    public static APPLICATION_SETTINGS_CONTACT =
        '/{account}/application-settings/contact';
    public static APPLICATION_SETTINGS_CONTACT_NEW_JWT_TOKEN =
        '/{account}/application-settings/contact/new-single-signon';
    public static APPLICATION_SETTINGS_CONTACT_ADMIN_COUNT =
        '/{account}/application-settings/contact/system-administrators-count';
    public static APPLICATION_SETTINGS_CONTACT_PERFORMANCE =
    '/{account}/application-settings/contact/performance ';
    public static APPLICATION_SETTINGS_REPORT =
        '/{account}/application-settings/report';
    public static APPLICATION_SETTINGS_RESPONSE_EXPORT =
        '/{account}/application-settings/response-export';
    public static APPLICATION_SETTINGS_PREFERENCES =
        '/{account}/application-settings/system-preferences';
    public static APPLICATION_SETTINGS_TIME_ZONES =
        '/{account}/application-settings/system-preferences/time-zones';
    public static APPLICATION_SETTINGS_SAML =
        '/{account}/application-settings/system-preferences/saml';
    public static APPLICATION_SETTINGS_IS_SAML_ENABLED =
        '/{account}/application-settings/system-preferences/saml/is-enabled';
    public static APPLICATION_SETTINGS_LICENSING = '/{account}/licensing';
    public static APPLICATION_SETTINGS_PRIVACY_INFORMATION =
        '/{account}/application-settings/texts/privacy-information/{0}';
    public static APPLICATION_SETTINGS_VALIDATION_TEXT =
        '/{account}/application-settings/texts/validation-message/{0}';
    public static APPLICATION_SETTINGS_SURVEY_TEXT =
        '/{account}/application-settings/texts/survey-text/{0}';
    public static APPLICATION_SETTINGS_MESSAGING_EMAIL =
        '/{account}/application-settings/messaging/email';
    public static APPLICATION_SETTINGS_MESSAGING_SMS =
        '/{account}/application-settings/messaging/sms';
    public static APPLICATION_SETTINGS_MESSAGING_WHATSAPP =
        '/{account}/application-settings/messaging/whatsapp';
    public static APPLICATION_SETTINGS_MESSAGING_SMS_AVAILABLE_PHONE_NUMBERS =
        '/{account}/application-settings/messaging/sms/available-phone-numbers';
    public static APPLICATION_SETTINGS_MESSAGING_SMS_PROVISION_PHONE_NUMBER =
        '/{account}/application-settings/messaging/sms/provision-phone-number';
    public static APPLICATION_SETTINGS_MESSAGING_SMS_STATUS =
        '/{account}/application-settings/messaging/sms/status';
    public static APPLICATION_SETTINGS_INVITATION_LINK =
        '/{account}/application-settings/survey/invitation-link';
    public static PERMISSION_DASHBOARD_DEFAULT =
        '/{account}/reports/{0}/acl/default-access';
    public static PERMISSION_DASHBOARD = '/{account}/reports/{0}/acl';
    public static PERMISSION_DASHBOARD_ENTRIES =
        '/{account}/reports/{0}/acl/entries';
    public static PERMISSION_DASHBOARD_ENTRY =
        '/{account}/reports/{0}/acl/entries/{1}';
    public static DASHBOARD_BY_EXTERNAL_ID =
        '/{account}/reports/external-id/{0}';
    public static DASHBOARD_SETTINGS = '/{account}/reports/{0}/settings';
    public static IS_LIVE_FILTERS_GRANTED =
        '/{account}/reports/{0}/is-live-filters-granted';
    public static QUICK_DASHBOARD = '/{account}/surveys/{0}/quick-report';
    public static IMPORT_RESPONSES_UPLOAD =
        '/{account}/surveys/{0}/import-responses/upload-csv';
    public static IMPORT_RESPONSES_PREVIEW =
        '/{account}/surveys/{0}/import-responses/preview-csv';
    public static IMPORT_RESPONSES_IMPORT =
        '/{account}/surveys/{0}/import-responses/import-csv';
    public static SURVEY_GOOGLE_ANALYTICS_ID =
        '/{account}/surveys/{0}/survey-google-analytics-id';

    public static SURVEY_GOOGLE_TAG_MANAGER_ID =
        '/{account}/surveys/{0}/survey-google-tag-manager-id';

    public static SMS = '/{account}/sms';

    public static EXCEPTION_LOG = '/{account}/exception-log';
    public static FILE_UPLOAD = '/{account}/files/upload';
    public static GET_FILE = '/{account}/files';

    public static SURVEY_SAVE_AS_TEMPLATE = '/{account}/surveys/{0}/save-as-template';
    public static SURVEY_TEMPLATES = '/{account}/survey-templates';
    public static SURVEY_TEMPLATE = '/{account}/survey-templates/{0}';

    public static SURVEY_TEMPLATE_ACL  = '/{account}/survey-templates/{0}/acl';
    public static SURVEY_TEMPLATE_ACL_ENTRIES = '/{account}/survey-templates/{0}/acl/entries';
    public static SURVEY_TEMPLATE_ACL_ENTRY  = '/{account}/survey-templates/{0}/acl/entries/{1}';
    public static SURVEY_TEMPLATE_ACL_DEFAULT = '/{account}/survey-templates/{0}/acl/default-access';

    public static SURVEY_TEMPLATE_PAGES = '/{account}/survey-templates/{0}/pages';
    public static SURVEY_TEMPLATE_PAGE = '/{account}/survey-templates/{0}/pages/{1}';
    public static SURVEY_TEMPLATE_PAGE_ITEMS = '/{account}/survey-templates/{0}/pages/{1}/items';
    public static SURVEY_TEMPLATE_PAGE_POSITION = '/{account}/survey-templates/{0}/pages/{1}/position';
    public static SURVEY_TEMPLATE_PAGE_CLONE = '/{account}/survey-templates/{0}/pages/{1}/copy';
    public static SURVEY_TEMPLATE_PAGE_MERGE_CODES = '/{account}/survey-templates/{0}/pages/{1}/merge-codes';

    public static SURVEY_TEMPLATE_ITEMS = '/{account}/survey-templates/{0}/items';
    public static SURVEY_TEMPLATE_ITEM = '/{account}/survey-templates/{0}/items/{1}';
    public static SURVEY_TEMPLATE_POSITION = '/{account}/survey-templates/{0}/items/{1}/page-position';
    public static SURVEY_TEMPLATE_EDITOR_CLONE_QUESTION = '/{account}/survey-templates/{0}/items/{1}/copy';
    public static SURVEY_TEMPLATE_ENABLE_ITEM = '/{account}/survey-templates/{0}/items/{1}/enabled';

    public static SURVEY_TEMPLATE_EXPRESSIONS_GET_LEFT_OPERANDS =
        '/{account}/survey-templates/{0}/expression-builder/left-operand-source';
    public static SURVEY_TEMPLATE_EXPRESSIONS_GET_OPERATORS =
        '/{account}/survey-templates/{0}/expression-builder/operators';
    public static SURVEY_TEMPLATE_EXPRESSIONS_GET_EXPRESSION_VALUE =
        '/{account}/survey-templates/{0}/expression-builder/right-operand-source';

    public static SURVEY_TEMPLATE_SETTINGS_LANGUAGE = '/{account}/survey-templates/{0}/language-settings';
    public static SURVEY_TEMPLATE_SETTINGS_SCRIPTS = '/{account}/survey-templates/{0}/survey-wide-javascript';
    public static SURVEY_TEMPLATE_SETTINGS_CSS = '/{account}/survey-templates/{0}/survey-wide-css';
    public static SURVEY_TEMPLATE_SETTINGS_REFERENCES = '/{account}/survey-templates/{0}/survey-wide-references';

    public static MY_ACCOUNT_INFO = '/{account}/my-account';
    public static CHART_TYPES = '/{account}/report-charts';

    public static DASHBOARD_EXPRESSIONS_GET_LEFT_OPERANDS =
        '/{account}/reports/{0}/report-filter-builder/left-operand-source';
    public static DASHBOARD_EXPRESSIONS_GET_OPERATORS =
        '/{account}/reports/{0}/report-filter-builder/operators';
    public static DASHBOARD_EXPRESSIONS_GET_RIGHT_OPERANDS =
        '/{account}/reports/{0}/report-filter-builder/right-operand-source';
    public static DOWNLOADS = '/{account}/downloads';
    public static DOWNLOADS_FILE = '/{account}/downloads/{0}';
    public static DOWNLOADS_FILE_DATA = '/{account}/downloads/{0}/file-data';
    public static DOWNLOADS_SETTINGS = '/{account}/downloads/settings';

    public static EMAIL_VERIFICATION_SEND_CODE = '/{account}/contacts/{0}/email-verification/send-code';
    public static EMAIL_VERIFICATION_VERIFY = '/{account}/contacts/{0}/email-verification/verify';
    public static EMAIL_VERIFICATION_STATUS = '/{account}/contacts/{0}/email-verification/status';
    public static EMAIL_VERIFICATION_ISSUE_TOKEN = '/{account}/contacts/{0}/email-verification/issue-token';
    public static EMAIL_VERIFICATION_SETTINGS = '/{account}/contacts/{0}/email-verification/settings';

    public static USER_ACTIVITY = '/{account}/user-activity';
}
