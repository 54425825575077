import { createSelector } from 'reselect';

import { AppState } from '../reducers';
import * as error from './error.selectors';
import * as layout from './layout.selectors';
import * as identity from './identity.selectors';

/* -- root selectors -- */
const getLayoutState = (state: AppState) => state.layout;
const getIdentityState = (state: AppState) => state.identity;
const getErrorState = (state: AppState) => state.errors;

/*--- error selectors -----*/
export const getLastError = createSelector(
    getErrorState,
    error.getLastError
);
export const getLastFiveErrors = createSelector(
    getErrorState,
    error.getLastFiveErrors
);
export const getUnhandledError = createSelector(
    getErrorState,
    error.getUnhandledError
);

/*--- layout selectors -----*/
export const getLoadingStatus = createSelector(
    getLayoutState,
    layout.getProgressStatus
);
export const getSidenavStatus = createSelector(
    getLayoutState,
    layout.getSidenavStatus
);

/*--- identity selectors -----*/
export const getIdentityLoadingStatus = createSelector(
    getIdentityState,
    identity.getIsLoading
);
export const getIdentityUser = createSelector(
    getIdentityState,
    identity.getIdentity
);
