import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.consts';
import { Permission, PermissionEntry } from '../models';
import { PermissionEntryModel, PermissionsModel } from '../models/permission.model';

@Injectable()
export class AclProvider {
    constructor(private $http: HttpClient) {}

    getSurveyAclById(surveyId: string): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_SURVEY,
            surveyId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateSurveyAclById(surveyId: string, entries): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_SURVEY,
            surveyId
        );
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    getFolderAclById(folderId: number): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_FOLDER,
            folderId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateFolderAclById(folderId: number, entries): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_FOLDER,
            folderId
        );
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    getTemplateAclById(templateId: number) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE_ACL,
            templateId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateTemplateAclById(
        templateId: number,
        entries
    ): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE_ACL,
            templateId
        );
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    getGroupAclById(groupId: string): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_GROUP,
            groupId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateGroupAclById(groupId: string, entries): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_GROUP,
            groupId
        );
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    getContactAcl(): Observable<Permission> {
        const url: string = String.Format(ResourcesConsts.PERMISSION_CONTACTS);
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateContactAcl(entries): Observable<Permission> {
        const url: string = String.Format(ResourcesConsts.PERMISSION_CONTACTS);
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    getAdministerContactGroupPermission(groupId: string): Observable<boolean> {
        const params = {
            'permission_List': 'Administer',
            'resource_type': 'ContactGroup',
            'resource_id': groupId
        };
        return this.getPermissions(params);
    }

    getManageContactGroupPermission(groupId: string): Observable<boolean> {
        const params = {
            'permission_List': 'ManageMembership',
            'resource_type': 'ContactGroup',
            'resource_id': groupId
        };
        return this.getPermissions(params);
    }

    getAdministerContactListPermission(): Observable<boolean> {
        const params = {
            'permission_List': 'Administer',
            'resource_type': 'ContactList'
        };
        return this.getPermissions(params);
    }

    getViewContactListPermission(): Observable<boolean> {
        const params = {
            'permission_List': 'View',
            'resource_type': 'ContactList'
        };
        return this.getPermissions(params);
    }

    getManageContactListPermission(): Observable<boolean> {
        const params = {
            'permission_List': 'ManageMembership',
            'resource_type': 'ContactList'
        };
        return this.getPermissions(params);
    }

    getEditContactPermission(contactId: string): Observable<boolean> {
        const params = {
            'permission_List': 'Edit',
            'resource_type': 'Contact',
            'resource_id': contactId
        };
        return this.getPermissions(params);
    }

    getManageContactPermission(contactId: string): Observable<boolean> {
        const params = {
            'permission_List': 'ManageMembership',
            'resource_type': 'Contact',
            'resource_id': contactId
        };
        return this.getPermissions(params);
    }

    getAdministerReportsPermission(reportId: number): Observable<boolean> {
        const params = {
            'permission_List': 'Administer',
            'resource_type': 'Report',
            'resource_id': reportId
        };
        return this.getPermissions(params);
    }

    getEditReportsPermission(reportId: number): Observable<boolean> {
        const params = {
            'permission_List': 'Edit',
            'resource_type': 'Report',
            'resource_id': reportId
        };
        return this.getPermissions(params);
    }

    getCreateReportForSurveyPermission(surveyId: number): Observable<boolean> {
        const params = {
            'permission_List': 'AnalyzeData',
            'resource_type': 'Survey',
            'resource_id': surveyId
        };
        return this.getPermissions(params);
    }

    getEditTemplateAclPermission(templateId: number): Observable<boolean> {
        const params = {
            'permission_List': 'Administer',
            'resource_type': 'SurveyTemplate',
            'resource_id': templateId
        };
        return this.getPermissions(params);
    }

    getEditSurveyResponseAclPermission(surveyId: number): Observable<boolean> {
        const params = {
            'permission_List': 'Administer',
            'resource_type': 'Survey',
            'resource_id': surveyId
        };
        return this.getPermissions(params);
    }


    getPermissions(params) {
        const url: string = ResourcesConsts.PERMISSION_EVERYONE;
        return this.$http
            .get(url, { params }).pipe(
                map((data: any) => !!data));
    }

    deleteEntrySurveyAclById(
        surveyId: string,
        entryId: number
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_SURVEY_ENTRY,
            surveyId,
            entryId
        );
        return this.$http
            .delete<void>(url);
    }

    newEntrySurveyAclById(
        surveyId: string,
        entry: PermissionEntry
    ): Observable<PermissionEntry> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_SURVEY_ENTRIES,
            surveyId
        );
        return this.$http.post(url, entry).pipe(
            map((data: any) => new PermissionEntryModel(data))
        );
    }

    getDashboardAclById(dashboardId: number): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_DASHBOARD,
            dashboardId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }

    updateDashboardAclById(
        dashboardId: number,
        entries
    ): Observable<Permission> {
        const url: string = String.Format(
            ResourcesConsts.PERMISSION_DASHBOARD,
            dashboardId
        );
        return this.$http.put(url, entries).pipe(
            map((data: any) => new PermissionsModel(data))
        );
    }
}
