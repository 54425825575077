import { PageRequestOptions } from '../models';
import * as _ from 'lodash';
import { StringPatterns } from '../consts/string-patterns.const';

export function getShortenedString(data, length) {
    if (!data) {
        return '';
    }
    if (data.length > length) {
        data = data.substring(0, length) + '...';
    }
    return data;
}

export function setEmptyIfUndefined(input: string) {
    if (!input) {
        return '';
    }
    return input;
}

export const sanitizeString = (src: string): string => {
    return src;
};

export const addQueryString = (options: {
    initialString: string;
    paramName: string;
    paramValue: string;
}): string => {
    const { initialString, paramName, paramValue } = options;

    if (!paramName) {
        return initialString;
    }
    if (!paramValue) {
        return initialString;
    }

    return `${initialString ? initialString + '&' : '?'}${sanitizeString(
        paramName
    )}=${sanitizeString(paramValue)}`;
};

export const createPageQuery = (options: PageRequestOptions): string => {
    const {
        queryString,
        pageSize,
        pagesNumber,
        sourceId,
        sortBy,
        sortOrder,
        sortAscending
    } = options;
    let query = addQueryString({
        initialString: '',
        paramName: 'search_text',
        paramValue: queryString
    });
    query = addQueryString({
        initialString: query,
        paramName: 'page_size',
        paramValue: pageSize
    });
    query = addQueryString({
        initialString: query,
        paramName: 'page_num',
        paramValue: pagesNumber
    });
    query = addQueryString({
        initialString: query,
        paramName: 'source_id',
        paramValue: sourceId
    });
    query = addQueryString({
        initialString: query,
        paramName: 'sort_by',
        paramValue: sortBy
    });
    query = addQueryString({
        initialString: query,
        paramName: 'sort_order',
        paramValue: sortOrder
    });
    query = addQueryString({
        initialString: query,
        paramName: 'sort_ascending',
        paramValue: sortAscending
    });
    return query;
};

export const createEscapedRegexp = (filterString: string): RegExp => {
    if (!filterString) {
        return new RegExp('', 'gi');
    }

    // escape point in filter string
    const pointRE = new RegExp('[.]', 'gi');
    const escapedString = filterString.replace(pointRE, '[.]');

    return new RegExp(escapedString, 'gi');
};

export const isEmailValid = (email: string): boolean => {
    // The solution powered by https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const regexp = new RegExp(StringPatterns.emailPattern);
    const testString = email.toLowerCase();

    return regexp.test(testString);
};

export const isPhoneSmsValid = (phoneSms: string, requireNanpPhoneNumbers: boolean): boolean => {
    if (!phoneSms)
        return false;

    const phoneNumberPattern = requireNanpPhoneNumbers
        ? StringPatterns.nanpPhoneNumberPattern
        : StringPatterns.internationalPhoneNumberPattern;

    const regexp = new RegExp(phoneNumberPattern);
    return regexp.test(phoneSms);
};

export const getStringByKeyValueArray = (
    keyValueArray: { [key: string]: string }[],
    value: string,
    field: string
): string => {
    const index = _.findIndex(keyValueArray, { value });
    return index !== -1 ? keyValueArray[index][field] : '';
};

export const format = function(fmtstr, ...args) {
    return fmtstr.replace(/\{(\d+)\}/g, function(match, index) {
        return args[index];
    });
};

export const sanitizeFilename = function(s: string): string {
    let filename = s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    return filename ?? '_';
}
