import * as _ from 'lodash';
import { ContactGroup } from '../../infrastructure/models/contact.model';
import { SurveyFolder } from './surveys/survey-folder.model';

export interface Permission {
    entries: PermissionEntry[];
    default_access: PermissionEntry;
}

export interface PermissionEntry {
    id?: number | string;
    grantee?: Grantee;
    permissions: string[];
}

export interface Grantee {
    grantee_id: string;
    grantee_type: string;
    grantee_name: string;
}

export interface PermissionStructure {
    id: string;
    title: string;
}

export interface AccessSubject {
    id: string;
    name: string;
    subjectType: string;
    isCurrentlyLogged?: boolean;
    isUsed?: boolean;
}

export interface PermissionDialogData {
    setOfPermission: string;
    options: ContactGroup | SurveyFolder | number;
}

export function createPermissions(permissions: any): string[] {
    return permissions && permissions.length
        ? _.map(permissions, (permissiion: any) =>
              typeof permissiion === 'string' ? permissiion : ''
          )
        : [];
}

export function createPermissionEntries(entries: any): PermissionEntry[] {
    return entries && entries.length
        ? _.map(entries, (entry: any) => new PermissionEntryModel(entry))
        : [];
}

export class GranteeModel implements Grantee {
    grantee_id: string;
    grantee_type: string;
    grantee_name: string;

    constructor(options: any = {}) {
        this.grantee_id = options.grantee_id || '';
        this.grantee_type = options.grantee_type || '';
        this.grantee_name = options.grantee_name || '';
    }
}

export class PermissionEntryModel implements PermissionEntry {
    id: number;
    grantee: Grantee;
    permissions: string[];

    constructor(options: any = {}) {
        this.id = options.id;
        if (options.grantee) {
            this.grantee = new GranteeModel(options.grantee);
        }
        this.permissions = options.permissions
            ? createPermissions(options.permissions)
            : [];
    }
}

export class PermissionsModel implements Permission {
    entries: PermissionEntry[];
    default_access: PermissionEntry;

    constructor(options: any = {}) {
        this.entries = createPermissionEntries(options.entries);
        this.default_access = options.default_access;
    }
}
