import * as error from './error.state';
import { errorReducer } from './error.reducer';
import * as identity from './identity.state';
import { identityReducer } from './identity.reducer';
import * as layout from './layout.state';
import { layoutReducer } from './layout.reducer';

export interface AppState {
    errors: error.State;
    layout: layout.State;
    identity: identity.State;
}

export const reducers: any = {
    errors: errorReducer,
    layout: layoutReducer,
    identity: identityReducer
};
