import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.consts';
import { ReportDeliveriesListModel, ReportDelivery } from '../models/report-delivery.model';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportDeliveryProvider {
    constructor(private $http: HttpClient) {}

    getDelivery(reportId: number, deliveryId: number): Observable<ReportDelivery> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERY,
            reportId,
            deliveryId
        );
        return this.$http.get<ReportDelivery>(url);
    }

    getDeliveriesByReportId(
        reportId: number,
        params
    ): Observable<ReportDeliveriesListModel> {
        const url: string =
            String.Format(ResourcesConsts.REPORT_DELIVERIES, reportId);

        return this.$http.get(url, { params }).pipe(
            map((data: any) => new ReportDeliveriesListModel(data))
        );
    }

    addDelivery (
        delivery: ReportDelivery
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERIES,
            delivery.report_id
        );
        return this.$http.post(url, delivery);
    }

    putDelivery (
        delivery: ReportDelivery
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERY,
            delivery.report_id,
            delivery.delivery_id
        );
        return this.$http.put(url, delivery);
    }

    deleteDelivery (
        reportId: number, deliveryId: number
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERY,
            reportId,
            deliveryId
        );
        return this.$http.delete(url);
    }

    cancelDelivery (
        reportId: number, deliveryId: number
    ): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERY_CANCEL,
            reportId,
            deliveryId
        );
        return this.$http.put(url, {});
    }

    batchDeleteReportDeliveries(
        reportId: number,
        deliveriesIds: any
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.REPORT_DELIVERY_DELETE,
            reportId
        );
        return this.$http
            .post<void>(url, deliveriesIds);
    }
}
