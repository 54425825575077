import {
    createValuedList,
    ModelImplementor,
    ModelPageListImplementor
} from './implementor.model';
import { MapperType } from '../../infrastructure/models/survey-settings.model';
import { PagedList } from '../../infrastructure/models';
import {
    Contact,
    ContactGroup,
    ContactGroupModel,
    CreateContactModel
} from '../../infrastructure/models/contact.model';

export interface SurveyInvitation {
    id: string;
    invitationType: string;
    name: string;
    createdBy: string;
    lastSent: Date;
    scheduled: Date;
    status: string;
    allowOptOut: boolean;
    allowEmbedded: boolean;
    invitationLinkExpirationInHours: number;
    companyProfile: number;
    sourceMessage: number;
    sourceValue: string;
}

export interface CompanyProfile {
    id: number;
    isDefault: boolean;
    name: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    postCode: string;
    state: string;
}

export interface InvitationReminder {
    subject: string;
    body: string;
    invitationType: string;
}

export interface InvitationMessage extends InvitationReminder {
    fromEmail: string;
    fromName: string;
}

export interface SmsMessageLimits {
    surveyLinkReservedLength: number;
    mergeCodeReservedLength: number;
    maxGsm7MessageLength: number;
    maxUcs2MessageLength: number;
}

export interface InvitationPanel {
    id: number;
    resourceId: string;
    name: string;
    email: string;
    phoneSms: string;
    invitationPanelType: string;
    isTest: boolean;
}

export interface InvitationPanelCreateResult extends InvitationPanel {
    status: string;
    details: string;
    invalidMembers: string[];
}

export interface InvitationRecipient {
    id: number;
    contactId: string;
    email: string;
    lastSent: Date;
    messages: number;
    panelId: number;
    responseId: number;
    status: string;
}

export interface InvitationSchedule {
    id: number;
    type: string;
    scheduled: Date;
    sending: Date;
    sent: Date;
    statusMessage: string;
}

export interface InvitationSubject<T> {
    isAvailable: boolean;
    item: T;
}

export interface InvitationOptedOut {
    recipientId: number;
    status: string;
    comment: string;
}

export interface InvitationDetailsSummary {
    bounced: number;
    notResponded: number;
    notSent: number;
    optedOut: number;
    responded: number;
}

export interface InvitationCopy {
    name: string;
    copyRecipients: boolean;
    useDefaultText: boolean;
}

export interface InvitationPanelList extends PagedList<InvitationPanel> {}

export interface InvitationRecipientList
    extends PagedList<InvitationRecipient> {}

export interface SurveyInvitationsList extends PagedList<SurveyInvitation> {}

export interface InvitationSubjectList<T>
    extends PagedList<InvitationSubject<T>> {}

export interface InvitationScheduleList extends PagedList<InvitationSchedule> {}

export class SurveyInvitationModel extends ModelImplementor
    implements SurveyInvitation {
    id: string;
    invitationType: string;
    name: string;
    createdBy: string;
    lastSent: Date;
    scheduled: Date;
    status: string;
    allowOptOut: boolean;
    allowEmbedded: boolean;
    invitationLinkExpirationInHours: number;
    companyProfile: number;
    sourceMessage: number;
    sourceValue: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'any' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'invitationType', out: 'invitation_type', type: 'string' },
            { in: 'createdBy', out: 'created_by', type: 'string' },
            { in: 'lastSent', out: 'last_sent', type: 'date' },
            { in: 'scheduled', out: 'scheduled_date', type: 'date' },
            { in: 'status', out: 'status', type: 'string' },
            { in: 'allowOptOut', out: 'allow_opt_out', type: 'boolean' },
            { in: 'allowEmbedded', out: 'allow_auto_login', type: 'boolean' },
            {
                in: 'invitationLinkExpirationInHours',
                out: 'invitation_link_expiration_in_hours',
                type: 'number'
            },
            { in: 'companyProfile', out: 'company_profile_id', type: 'number' },
            {
                in: 'sourceMessage',
                out: 'message_source_invitation_id',
                type: 'number'
            }
        ];

        super(options, mapper);
    }
}

export class CompanyProfileModel extends ModelImplementor
    implements CompanyProfile {
    id: number;
    isDefault: boolean;
    name: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    postCode: string;
    state: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'any' },
            { in: 'isDefault', out: 'is_default', type: 'boolean' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'address1', out: 'address1', type: 'string' },
            { in: 'address2', out: 'address2', type: 'string' },
            { in: 'city', out: 'city', type: 'string' },
            { in: 'company', out: 'company', type: 'string' },
            { in: 'country', out: 'country', type: 'string' },
            { in: 'postCode', out: 'post_code', type: 'string' },
            { in: 'state', out: 'state', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class InvitationMessageModel extends ModelImplementor
    implements InvitationMessage {
    fromEmail: string;
    fromName: string;
    subject: string;
    body: string;
    invitationType: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'fromEmail', out: 'from_email', type: 'string' },
            { in: 'fromName', out: 'from_name', type: 'string' },
            { in: 'subject', out: 'subject', type: 'string' },
            { in: 'body', out: 'body', type: 'string' },
            { in: 'invitationType', out: 'invitation_type', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class InvitationReminderModel extends ModelImplementor
    implements InvitationReminder {
    subject: string;
    body: string;
    invitationType: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'subject', out: 'subject', type: 'string' },
            { in: 'body', out: 'body', type: 'string' },
            { in: 'invitationType', out: 'invitation_type', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class SmsMessageLimitsModel extends ModelImplementor
    implements SmsMessageLimits {
    surveyLinkReservedLength: number;
    mergeCodeReservedLength: number;
    maxGsm7MessageLength: number;
    maxUcs2MessageLength: number;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'surveyLinkReservedLength', out: 'survey_link_reserved_length', type: 'number' },
            { in: 'mergeCodeReservedLength', out: 'merge_code_reserved_length', type: 'number' },
            { in: 'maxGsm7MessageLength', out: 'max_gsm7_message_length', type: 'number' },
            { in: 'maxUcs2MessageLength', out: 'max_ucs2_message_length', type: 'number' }
        ];

        super(options, mapper);
    }
}

export class InvitationPanelCreateResultModel extends ModelImplementor
    implements InvitationPanelCreateResult {
    id: number;
    resourceId: string;
    name: string;
    email: string;
    phoneSms: string;
    invitationPanelType: string;
    status: string;
    details: string;
    invalidMembers: string[];
    isTest: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'number' },
            { in: 'resourceId', out: 'resource_id', type: 'string' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'email', out: 'email', type: 'string' },
            { in: 'phoneSms', out: 'phone_sms', type: 'string' },
            { in: 'invitationPanelType', out: 'type', type: 'string' },
            { in: 'status', out: 'status', type: 'string' },
            { in: 'details', out: 'details', type: 'string' },
            { in: 'invalidMembers', out: 'invalid_members', type: 'any' },
            { in: 'isTest', out: 'is_test', type: 'boolean' }
        ];

        super(options, mapper);
    }
}

export class InvitationPanelModel extends ModelImplementor
    implements InvitationPanel {
    id: number;
    resourceId: string;
    name: string;
    email: string;
    phoneSms: string;
    invitationPanelType: string;
    isTest: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'number' },
            { in: 'resourceId', out: 'resource_id', type: 'string' },
            { in: 'name', out: 'name', type: 'string' },
            { in: 'email', out: 'email', type: 'string' },
            { in: 'phoneSms', out: 'phone_sms', type: 'string' },
            { in: 'invitationPanelType', out: 'type', type: 'string' },
            { in: 'isTest', out: 'is_test', type: 'boolean' }
        ];

        super(options, mapper);
    }
}

export class InvitationRecipientModel extends ModelImplementor
    implements InvitationRecipient {
    id: number;
    contactId: string;
    email: string;
    phoneSms: string;
    lastSent: Date;
    messages: number;
    panelId: number;
    responseId: number;
    status: string;
    errorMessage: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'number' },
            { in: 'email', out: 'email', type: 'string' },
            { in: 'phoneSms', out: 'phone_sms', type: 'string' },
            { in: 'contactId', out: 'contact_id', type: 'string' },
            { in: 'lastSent', out: 'last_sent', type: 'date' },
            { in: 'messages', out: 'messages', type: 'number' },
            { in: 'panelId', out: 'panel_id', type: 'number' },
            { in: 'responseId', out: 'response_id', type: 'number' },
            { in: 'status', out: 'status', type: 'string' },
            { in: 'errorMessage', out: 'error_message', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class InvitationScheduleModel extends ModelImplementor
    implements InvitationSchedule {
    id: number;
    type: string;
    scheduled: Date;
    sending: Date;
    sent: Date;
    statusMessage: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'id', out: 'id', type: 'number' },
            { in: 'type', out: 'type', type: 'string' },
            { in: 'scheduled', out: 'scheduled_date', type: 'date' },
            { in: 'sending', out: 'processing_started', type: 'date' },
            { in: 'sent', out: 'processing_finished', type: 'date' },
            { in: 'statusMessage', out: 'status_message', type: 'string' }
        ];

        super(options, mapper);
    }
}

export class InvitationContactModel extends ModelImplementor
    implements InvitationSubject<Contact> {
    isAvailable: boolean;
    item: Contact;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'isAvailable', out: 'is_available', type: 'boolean' }
        ];

        super(options, mapper);
        this.item = new CreateContactModel(options.item);
    }
}

export class InvitationGroupModel extends ModelImplementor
    implements InvitationSubject<ContactGroup> {
    isAvailable: boolean;
    item: ContactGroup;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'isAvailable', out: 'is_available', type: 'boolean' }
        ];

        super(options, mapper);
        this.item = new ContactGroupModel(options.item);
    }
}

export class InvitationOptedOutModel extends ModelImplementor
    implements InvitationOptedOut {
    recipientId: number;
    status: string;
    comment: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'recipientId', out: 'recipient_id', type: 'number' },
            { in: 'status', out: 'status', type: 'string' },
            { in: 'comment', out: 'comment', type: 'string' }
        ];
        super(options, mapper);
    }
}

export class InvitationSummaryModel extends ModelImplementor
    implements InvitationDetailsSummary {
    bounced: number;
    notResponded: number;
    notSent: number;
    optedOut: number;
    responded: number;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'bounced', out: 'bounced', type: 'number' },
            { in: 'notResponded', out: 'not_responded', type: 'number' },
            { in: 'notSent', out: 'not_sent', type: 'number' },
            { in: 'optedOut', out: 'opted_out', type: 'number' },
            { in: 'responded', out: 'responded', type: 'number' }
        ];
        super(options, mapper);
    }
}

export class InvitationCopyModel extends ModelImplementor
    implements InvitationCopy {
    name: string;
    copyRecipients: boolean;
    useDefaultText: boolean;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'name', out: 'name', type: 'string' },
            { in: 'copyRecipients', out: 'copy_recipients', type: 'boolean' },
            { in: 'useDefaultText', out: 'use_defaultText', type: 'boolean' }
        ];
        super(options, mapper);
    }
}

export class InvitationContactListModel
    extends ModelPageListImplementor<InvitationSubject<Contact>>
    implements InvitationSubjectList<Contact> {
    constructor(options: any) {
        super(options, InvitationContactModel);
    }
}

export class InvitationGroupListModel
    extends ModelPageListImplementor<InvitationSubject<ContactGroup>>
    implements InvitationSubjectList<ContactGroup> {
    constructor(options: any) {
        super(options, InvitationGroupModel);
    }
}

export class SurveyInvitationListModel
    extends ModelPageListImplementor<SurveyInvitation>
    implements SurveyInvitationsList {
    constructor(options: any) {
        super(options, SurveyInvitationModel);
    }
}

export class InvitationPanelListModel
    extends ModelPageListImplementor<InvitationPanel>
    implements InvitationPanelList {
    constructor(options: any) {
        super(options, InvitationPanelModel);
    }
}

export class InvitationRecipientListModel
    extends ModelPageListImplementor<InvitationRecipient>
    implements InvitationRecipientList {
    constructor(options: any) {
        super(options, InvitationRecipientModel);
    }
}

export class InvitationScheduleListModel
    extends ModelPageListImplementor<InvitationSchedule>
    implements InvitationScheduleList {
    constructor(options: any) {
        super(options, InvitationScheduleModel);
    }
}

export const createCompanyProfiles = (companies: any): CompanyProfileModel[] =>
    createValuedList(companies, CompanyProfileModel);

export const createInvitationPanels = (panels: any): InvitationPanelModel[] =>
    createValuedList(panels, InvitationPanelModel);

export const createInvitationPanelResults = (results: any): InvitationPanelCreateResultModel[] =>
    createValuedList(results, InvitationPanelCreateResultModel);

export const createInvitationRecipients = (
    recipients: any
): InvitationRecipientModel[] =>
    createValuedList(recipients, InvitationRecipientModel);

export const createInvitationSchedules = (
    schedules: any
): InvitationScheduleModel[] =>
    createValuedList(schedules, InvitationScheduleModel);
