import {
    ModelImplementor,
    ModelPageListImplementor
} from '../../app-admin/models/implementor.model';
import { MapperType } from './survey-settings.model';

export interface AppLimit {
    limitType: string;
    status: string;
    currentValue: string;
    baseValue: string;
}

export enum LimitStatus {
    LimitNotReached,
    LimitExceeded
}

enum LimitType {
    MultiLanguageLimit = 1,
    EmailLimit,
    SpssLimit,
    SurveyEditorLimit
}

export class AppLimitModel extends ModelImplementor implements AppLimit {
    baseValue: string;
    currentValue: string;
    limitType: string;
    status: string;

    constructor(options: any) {
        const mapper: MapperType[] = [
            { in: 'baseValue', out: 'base_value', type: 'string' },
            { in: 'currentValue', out: 'current_value', type: 'string' },
            { in: 'limitType', out: 'limit_type', type: 'string' },
            { in: 'status', out: 'status', type: 'string' }
        ];
        super(options, mapper);
    }
}
