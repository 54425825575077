<div *ngIf="items && items.length" class="cb-register">
    <div class="cb-survey-header"></div>
    <div class="take-survey-content">
        <div class="login-container">
            <h2 class="login-title">{{ 'FORMS.REGISTRATION' | translate }}</h2>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-sub-section">
                    <h3 class="login-subtitle">
                        {{ 'FORMS.LOGIN-INFO' | translate }}
                    </h3>
                    <div class="form-group">
                        <mat-form-field class="cb-half-full-width-input">
                            <input
                                matInput
                                placeholder="{{
                                    'FORMS.DESIRED-USERNAME' | translate
                                }}"
                                formControlName="id"
                            />
                            <mat-error>
                                {{ 'FORMS.USERNAME-ERROR' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group password-validation">
                        <mat-form-field class="cb-half-full-width-input">
                            <input
                                matInput
                                type="password"
                                placeholder="{{ 'FORMS.PASSWORD' | translate }}"
                                formControlName="password"
                            />
                            <mat-error
                                *ngIf="
                                    form.controls['password'].hasError(
                                        'required'
                                    );
                                    else validation_error
                                "
                            >
                                {{ 'FORMS.PASSWORD-ERROR' | translate }}
                            </mat-error>
                            <ng-template #validation_error>
                                <mat-error>
                                    {{ passwordValidationError }}
                                </mat-error>
                            </ng-template>
                        </mat-form-field>
                        <mat-form-field
                            class="cb-half-full-width-input cb-left-indent"
                        >
                            <input
                                matInput
                                type="password"
                                placeholder="{{
                                    'FORMS.PASSWORD-CONFIRM' | translate
                                }}"
                                formControlName="confirm_password"
                            />
                            <mat-error>
                                {{
                                    'FORMS.PASSWORD-MISMATCH-CONFIRMATION'
                                        | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-sub-section">
                    <h3 class="login-subtitle">
                        {{ 'FORMS.USER-INFO' | translate }}
                    </h3>
                    <div class="form-group">
                        <mat-form-field class="cb-half-full-width-input">
                            <input
                                matInput
                                placeholder="{{ 'FORMS.EMAIL' | translate }}"
                                formControlName="email"
                            />
                            <mat-error>
                                {{ 'FORMS.EMAIL-ERROR' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            *ngIf="showSmsPhone"
                            class="cb-half-full-width-input"
                        >
                            <input
                                matInput
                                placeholder="{{ 'FORMS.PHONE-SMS' | translate }}"
                                formControlName="phoneSms"
                            />
                        </mat-form-field>
                        <ng-container *ngFor="let item of items">
                            <mat-form-field class="cb-half-full-width-input">
                                <input
                                    matInput
                                    [placeholder]="item"
                                    [formControlName]="item"
                                />
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
                <div class="cb-take-survey-buttons-container">
                    <button
                        mat-button
                        class="btn-cancel"
                        [routerLink]="['/login']"
                    >
                        {{ 'SHARED.CANCEL' | translate }}
                    </button>
                    <button mat-button class="btn-next" type="submit">
                        {{ 'FORMS.REGISTER' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
