import { ScoringMessageItem } from '../../../models/survey-items/display-items/scoringMessageItem';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { SurveyPage } from '../../../../app-admin/models';

@Component({
    selector: 'cb-scoring-message-item-preview',
    templateUrl: './scoring-message-item-preview.component.html',
    styleUrls: ['./scoring-message-item-preview.component.scss']
})
export class ScoringMessagePreviewItemComponent implements OnInit {
    @Input() displayItem: ScoringMessageItem;
    @Input() isTakeSurvey: boolean;
    @Input() pages: SurveyPage[];
    message: string;
    score: number;

    constructor() {}

    ngOnInit(): void {
        if (this.isTakeSurvey) {
            if (this.displayItem.score_scope_page_id) {
                this.score = this.pages.find(
                    p => p.id === this.displayItem.score_scope_page_id
                ).points;
            } else {
                const currentPageIndex = _.findIndex(
                    this.pages,
                    p => p.id === this.displayItem.page_id
                );
                this.score = _.chain(this.pages)
                    .take(currentPageIndex + 1)
                    .map(x => x.points)
                    .reduce((acc, val) => {
                        return acc + val || 0;
                    }, 0)
                    .value();
            }
            this.isInRange();
        }
    }

    isInRange() {
        if (!_.isEmpty(this.displayItem.messages)) {
            this.displayItem.messages.forEach(message => {
                if (
                    _.inRange(
                        this.score,
                        message.low_score,
                        message.high_score + 1
                    )
                ) {
                    this.message = message.text;
                    return;
                }
            });
        }
    }
}
