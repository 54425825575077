import { ToasterService } from './toaster.service';
import { SharedService } from './shared.service';
import { AppConfigService } from './appConfig.service';
import { RequestCacheService } from './requestCache.service';
import { ActiveDirectoryService } from './activeDirectory.service';
import { PasswordService } from './password.service';
import { EventLogService } from './event-log.service';
import { ComponentPrintStatus, PrintService, PrintMode } from './print.service';
import { RequestQueueService } from './requestQueue.service';
import { LanguageService } from './language.service';
import { SsoService } from './sso.service';

export const services = [
    ToasterService,
    SharedService,
    AppConfigService,
    RequestCacheService,
    PasswordService,
    EventLogService,
    PrintService,
    RequestQueueService,
    LanguageService,
    SsoService
];

export {
    ToasterService,
    SharedService,
    AppConfigService,
    RequestCacheService,
    ActiveDirectoryService,
    PasswordService,
    EventLogService,
    PrintService,
    ComponentPrintStatus,
    PrintMode,
    RequestQueueService,
    LanguageService,
    SsoService
};
