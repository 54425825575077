import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JsonReaderProvider {
    constructor(private $http: HttpClient) {}

    getJsonData(url: string): Observable<any> {
        return this.$http.get<any>(url);
    }
}
