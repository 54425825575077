import * as error from '../actions/error.actions';
import { State, initialState } from './error.state';

export function errorReducer(
    state = initialState,
    action: error.Actions
): State {
    switch (action.type) {
        case error.LOG_ERROR: {
            return {
                lastError: action.payload,
                errors: state.errors,
                unhandledError: null
            };
        }
        case error.HANDLE_ERROR:
        case error.LOG_ERROR_SUCCESS: {
            let errors = state.errors || [];
            if (errors.length > 5) {
                errors = errors.splice(1, errors.length);
            }
            return {
                lastError: action.payload,
                errors: [...errors, ...[action.payload]],
                unhandledError: null
            };
        }
        case error.LOG_ERROR_FAILED: {
            return {
                lastError: action.payload,
                errors: state.errors,
                unhandledError: action.payload
            };
        }
        default:
            return state;
    }
}
