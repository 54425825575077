interface ServerItemError {
    error_text: string;
    errorItem: any;
}

export enum ServerErrorItemType {
    SurveyItem,
    MatrixColumn,
    MatrixSubItem
}

const surveyItemRegex = /(SurveyItem\/)(\d+)$/i;
const surveyMatrixColumnRegex = /(SurveyItem\/)(\d+)(\/Column\/)(\d+)$/i;
const surveyMatrixSubItemRegex = /(SurveyItem\/)(\d+)(\/Subitem\/)(\d+)$/i;

export function getPostCurrentPageErrors(err: any) {
    return getErrorsTarget(err.errors).filter(x => x.errorItem);
}

function getErrorsTarget(errors: any[]) {
    const serverItemErrors: ServerItemError[] = [];
    if (errors) {
        errors.forEach(err => {
            serverItemErrors.push(<ServerItemError>{
                error_text: err.error_text,
                errorItem: getErrorItem(err.target)
            });
        });
    }
    return serverItemErrors;
}

function getErrorItem(target: string) {
    if (surveyItemRegex.test(target)) {
        return {
            type: ServerErrorItemType.SurveyItem,
            itemId: +target.match(surveyItemRegex)[2]
        };
    }
    if (surveyMatrixColumnRegex.test(target)) {
        return {
            type: ServerErrorItemType.MatrixColumn,
            itemId: +target.match(surveyMatrixColumnRegex)[2],
            columnId: +target.match(surveyMatrixColumnRegex)[4]
        };
    }
    if (surveyMatrixSubItemRegex.test(target)) {
        return {
            type: ServerErrorItemType.MatrixSubItem,
            itemId: +target.match(surveyMatrixSubItemRegex)[2],
            subItemId: +target.match(surveyMatrixSubItemRegex)[4]
        };
    }
}
