import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'extractInnerText', pure: true })
export class TrimHtmlPipe implements PipeTransform {

    constructor() {}

    transform(content) {
        if (!content) {
            return '';
        }

        try {
            const doc = (new DOMParser).parseFromString(content, 'text/html');
            return doc.body.textContent.trim();
        }
        catch(err) {
            return content.replace(/(<([^>]+)>)/ig, '');
        }
    }
}
