import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'notNull' })
export class NotNullPipe implements PipeTransform {
    transform(text: string): string {
        return text
            ? text
            : '';
    }
}
