import { Component, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '../../decorators/autoUnsubscribe.decorator';
import { StorageProvider } from '../../../infrastructure/providers';
import { Subject } from 'rxjs';

@Component({
  selector: 'cb-survey-owner-show',
  templateUrl: './survey-owner-show.component.html',
  styleUrls: ['./survey-owner-show.component.scss']
})
@AutoUnsubscribe()
export class SurveyOwnerShow implements OnInit {
  @Input() user = '';
  @Input() showLabel = false;
  get me(){
    return this.currentUser === this.user;
  }
  get defaultClass(){
    return this.me ? 'me' : 'default';
  }
  currentUser = '';
  constructor(private storageProvider: StorageProvider) { }

  ngOnInit() {
    this.storageProvider.getIdentity().pipe().subscribe(identity => {
      this.currentUser = <string>JSON.parse(identity).user_name;
    });
  }

}
