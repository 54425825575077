import { first, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { Survey } from '../models';
import { ResourcesConsts } from '../consts/resources.consts';
import * as _ from 'lodash';
import { FileExportProvider } from '../../infrastructure/providers/file-export.provider';
import { SkipInterceptor } from '../../infrastructure/consts/interceptors.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportType } from '../downloads/download-item.model';
import { SurveyStatus } from '../../infrastructure/consts/surveys.consts';
import { PageRequestOptions } from '../../infrastructure/models';
import {
    ActiveSurvey,
    ActiveSurveyModel,
    ActiveSurveys,
    ActiveSurveysModel
} from '../../infrastructure/models/active-survey.model';
import { CustomURLEncoder } from '../../infrastructure/helpers/urlEncode.helper';

@Injectable()
export class UserProvider {
    constructor(private $http: HttpClient, 
        private fileExportProvider: FileExportProvider,
        private snackBar: MatSnackBar) {}


        getUserActivity() {
            const url: string =
                String.Format(ResourcesConsts.USER_ACTIVITY); 
            //+ '/active-surveys';
            // const params = new HttpParams({encoder: new CustomURLEncoder() })
            //             .set('page_num', getData.page_num.toString())
            //             .set('page_size', getData.page_size.toString());
            return this.$http
                .get<any>(url);
        }

}