import { first, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { Survey } from '../models';
import { ResourcesConsts } from '../consts/resources.consts';
import * as _ from 'lodash';
import { FileExportProvider } from '../../infrastructure/providers/file-export.provider';
import { SkipInterceptor } from '../../infrastructure/consts/interceptors.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportType } from '../downloads/download-item.model';
import { SurveyStatus } from '../../infrastructure/consts/surveys.consts';
import { PageRequestOptions } from '../../infrastructure/models';
import {
    ActiveSurvey,
    ActiveSurveyModel,
    ActiveSurveys,
    ActiveSurveysModel
} from '../../infrastructure/models/active-survey.model';
import { CustomURLEncoder } from '../../infrastructure/helpers/urlEncode.helper';

@Injectable()
export class SurveysProvider {
    constructor(private $http: HttpClient, 
        private fileExportProvider: FileExportProvider,
        private snackBar: MatSnackBar) {}

    addSurvey(survey: Survey, isTemplate = false): Observable<Survey> {
        const url: string = isTemplate ? ResourcesConsts.SURVEY_TEMPLATES : ResourcesConsts.SURVEYS;
        return this.$http
            .post<Survey>(url, survey);
    }

    addSurveyFromFile(survey): Observable<any> {
        const headers = new HttpHeaders();
        const form = new FormData();

        form.append('file', survey.file, survey.file.name);
        form.append('name', survey.name);

        if (survey.custom_url) {
            form.append('custom_url', survey.custom_url);
        }

        if (survey.survey_folder_id) {
            form.append('survey_folder_id', survey.survey_folder_id);
        }

        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        const options = { headers };

        const url: string = String.Format(
            ResourcesConsts.SURVEYS_IMPORT
        );
        return this.$http
            .post(url, form, options);
    }

    saveAsTemplate(surveyId , data) {
            const url: string = String.Format( ResourcesConsts.SURVEY_SAVE_AS_TEMPLATE, surveyId);
        return this.$http
            .post(url, data);
    }

    getValidationTextApplicationSettings(lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_VALIDATION_TEXT,
            lang
        );
        return this.$http.get(url, {}).pipe(
            map((data: any) => {
                const validationText = {};
                for (let i = 0; i < data.length; i++) {
                    validationText[data[i].key] = data[i].text;
                }
                return validationText;
            })
        );
    }

    getSurveyTextApplicationSettings(lang: string) {
        const url: string = String.Format(
            ResourcesConsts.APPLICATION_SETTINGS_SURVEY_TEXT,
            lang
        );
        return this.$http
            .get(url, { headers: new HttpHeaders({ [SkipInterceptor.JWT]: 'true' }) })
            .pipe(
                map((data: any) => {
                    const applicationText = {};
                    for (let i = 0; i < data.length; i++) {
                        applicationText[_.upperFirst(_.camelCase(data[i].key))] = data[i].text;
                    }
                    return applicationText;
                }));
    }

    updateSurvey(surveyId: number, survey: Survey, isTemplate = false): Observable<Survey> {
        const url = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE, surveyId) : `${ResourcesConsts.SURVEYS}/${surveyId}`;
        return this.$http
            .put<Survey>(url, survey);
    }

    updateSurveyUrl(surveyId: number, survey: Survey): Observable<string> {
        const url = `${ResourcesConsts.SURVEYS}/${surveyId}/update-url/`;
        return this.$http
            .put<string>(url, survey);
    }

    deleteSurvey(surveyId: number): Observable<any> {
        const url = `${ResourcesConsts.SURVEYS}/${surveyId}`;
        return this.$http
            .delete(url);
    }

    bulkDeleteSurveys(surveyIds: number[]) {
        const url: string = ResourcesConsts.SURVEYS + '/bulk-delete';
        const params = {
            survey_ids: surveyIds
        };
        return this.$http.post(url, params);
    }

    getSurvey(surveyId: number, isTemplate = false): Observable<Survey> {
        const url = isTemplate ? String.Format(ResourcesConsts.SURVEY_TEMPLATE, surveyId) : `${ResourcesConsts.SURVEYS}/${surveyId}`;
        return this.$http
            .get<Survey>(url);
    }

    getSurveyByExternalId(surveyId: string): Observable<Survey> {
        const url = `${ResourcesConsts.SURVEYS_EXTERNAL}/${surveyId}`;
        return this.$http
            .get<Survey>(url);
    }

    getSurveyIdByExternalId(surveyId: string): Observable<number> {
        const url = `${ResourcesConsts.SURVEYS_EXTERNAL}/${surveyId}/id`;
        return this.$http.get<number>(url);
    }

    generatePDF(surveyId: number, lang: string) {
        const url: string = String.Format(ResourcesConsts.SURVEYS_GENERATE_PDF, surveyId);
        return this.$http.post(url, {language: lang, orientation: 'Portrait'}).pipe(
            mergeMap(id => {
                return this.fileExportProvider.getExportResult(id as string, ExportType.SURVEY_PDF_EXPORT);
            }),
            first()
        );
    }

    exportSurveyJSON(surveyId: number, lang): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url: string = String.Format(
            ResourcesConsts.SURVEYS_EXPORT,
            surveyId
        ) + `?language=${lang}`;
        return this.fileExportProvider.getFileFromBackend(url, {headers});
    }

    archiveSurvey(surveyId: number): Observable<Survey> {
        const url: string = String.Format(ResourcesConsts.ARCHIVE, surveyId);
        return this.$http
            .post<Survey>(url, surveyId);
    }

    unarchiveSurvey(surveyId: number): Observable<Survey> {
        const url: string = String.Format(ResourcesConsts.UNARCHIVE, surveyId);
        return this.$http
            .post<Survey>(url, surveyId);
    }

    bulkArchiveSurveys(surveyIds: number[]) {
        const url: string = ResourcesConsts.SURVEYS + '/bulk-archive';
        const params = {
            survey_ids: surveyIds
        };
        return this.$http.post(url, params);
    }

    isFavorite(surveyId: number): Observable<boolean> {
        const url =
            ResourcesConsts.CONTACTS + '/me/favorite-surveys/' + surveyId;
        return this.$http.get<boolean>(url);
    }

    setFavorite(surveyId: number): Observable<Object> {
        const url = ResourcesConsts.CONTACTS + '/me/favorite-surveys/';
        return this.$http.post(url, surveyId);
    }

    removeFavorite(surveyId: number): Observable<Object> {
        const url =
            ResourcesConsts.CONTACTS + '/me/favorite-surveys/' + surveyId;
        return this.$http.delete(url);
    }

    getSurveys(params, isTemplate = false): Observable<any> {
        const url: string = isTemplate ? ResourcesConsts.SURVEY_TEMPLATES : ResourcesConsts.SURVEYS;
        return this.$http
            .get(url, { params });
    }

    copySurvey(surveyId, name, folderId, custom_url): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_COPY,
            surveyId
        );
        return this.$http
            .post(url, { name, folder_id: folderId, custom_url });
    }

    getDeletedSurveys(): Observable<any> {
        const url: string =
            String.Format(ResourcesConsts.SURVEYS) + '/deleted-surveys';
        return this.$http
            .get(url);
    }

    getSurveysWithDeletedResponses(): Observable<any> {
        const url: string =
            String.Format(ResourcesConsts.SURVEYS) + '/with-deleted-responses';
        return this.$http
            .get(url);
    }

    restoreSurvey(
        surveyId: Number,
        restoreResponses: boolean
    ): Observable<any> {
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const url: string =
            String.Format(ResourcesConsts.SURVEYS) +
            `/deleted-surveys/${surveyId}`;
        return this.$http
            .post(url, JSON.stringify(restoreResponses), { headers });
    }

    checkSurveyIsAlive(surveyId: Number): Observable<boolean> {
        return this.getSurvey(+surveyId)
        .pipe(
            mergeMap((survey) => {
                if (survey.status === SurveyStatus.ACTIVE || survey.status === SurveyStatus.ARCHIVED) {
                    return this.getResponseSummaryBySurveyId(surveyId);
                } else {
                    return of(null);
                }
            }),
            mergeMap(summary => {
                if (summary) {
                    return of(summary.complete_response_count > 0
                        || summary.incomplete_response_count > 0
                        || summary.test_response_count > 0);
                }
                return of(false);
            })
        );
    }

    private getResponseSummaryBySurveyId(surveyId: Number) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_RESPONSE_SUMMARY,
            surveyId
        );
        return this.$http
            .get(url);
    }

    getActiveSurveys(options: PageRequestOptions, rolesFilter: string): Observable<any> {
        const url: string =
            String.Format(ResourcesConsts.SURVEYS) + '/active-surveys';
        const params = new HttpParams({encoder: new CustomURLEncoder() })
            .set('page_num', options.pagesNumber.toString())
            .set('page_size', options.pageSize.toString());

            return this.$http.get(url, { params: params }).pipe(
                map((data: any) => new ActiveSurveysModel(data))
            );
    }

    getActiveSurveysGetData( getData ) {
        const url: string =
            String.Format(ResourcesConsts.SURVEYS) + '/active-surveys';
        const params = new HttpParams({encoder: new CustomURLEncoder() })
                    .set('page_num', getData.page_num.toString())
                    .set('page_size', getData.page_size.toString());
        return this.$http
            .get<any>(url, { params: params });
    }
}
