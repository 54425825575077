import { ElementRef, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { EventLogService } from './event-log.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { LayoutStore } from '../store';

export enum PrintMode {
    REPORT,
    SURVEY
}

export enum ComponentPrintStatus {
    RENDERING = 1,
    RENDERED,
    ERROR
}

export interface ComponentPrintState {
    id: number;
    status: ComponentPrintStatus;
}

@Injectable()
export class PrintService {
    constructor (private eventLogService: EventLogService, private layoutStore: LayoutStore){}

    private componentPrintStatusStream: Subject<ComponentPrintState>;
    private eventLogEnabled = window['__env'] && window['__env'].eventLogEnabled;
    private reportPdfGenerationTimeout = window['__env'] && window['__env'].reportPdfGenerationTimeout;
    private imageLoadingTimeout = window['__env'] && window['__env'].imageLoadingTimeout ? window['__env'].imageLoadingTimeout : 30000;

    public printMode = new Subject<PrintMode>();

    public waitForImageLoadingAndMarkReady(surveyItemId: number, elem: ElementRef) {
        this.waitForImageLoading(elem)
            .pipe(first())
            .subscribe(() => {
                this.setStatus(surveyItemId, ComponentPrintStatus.RENDERED);
            });
    }

    public waitForImageLoading(elem: ElementRef): Observable<void> {
        if (!this.isPrinting())
            return of(null);

        const self = this;
        const imgs = elem.nativeElement.querySelectorAll('img');
        const total = _.filter(imgs, {complete: false}).length;
        if (total > 0) {
            return new Observable(function(observer){
                let timeout;
                let handled = 0;

                const func = function() {
                    handled++;
                    if (handled == total){
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        observer.next();
                    }
                };
                imgs.forEach(img => {
                    img.onload = func;
                    img.onerror = func;
                });

                //send complete anyway in some period
                timeout = setTimeout(() => {
                    observer.next();
                }, self.imageLoadingTimeout);
            });
        }

        return of(null);
    }

    public setStatus(id: number, status: ComponentPrintStatus) {
        if (this.componentPrintStatusStream) {
            this.componentPrintStatusStream.next({id, status});
        }
    }

    public print(expectingComponentCount: number, renderStartTime: Moment) {
        if (this.reportPdfGenerationTimeout) {
            setTimeout(() => {
                this.sendPrintSignal();
            }, this.reportPdfGenerationTimeout * 1000);
        } else {
            this.printInit(expectingComponentCount, renderStartTime);
        }
    }

    private isPrinting():boolean {
        return this.componentPrintStatusStream ? true : false;
    }

    private printInit(expectingComponentCount: number, renderStartTime: Moment) {
        this.printMode.next(PrintMode.REPORT);
        this.layoutStore.changePrintMode(PrintMode.REPORT);
        let dashboardItemsStatuses = {};
        this.componentPrintStatusStream = new Subject();
        let renderedItemsCount = 0;

        const subscription = this.componentPrintStatusStream.subscribe(data => {
            if (data.status === ComponentPrintStatus.ERROR || data.status === ComponentPrintStatus.RENDERED) {
                renderedItemsCount++;
            }

            if (this.eventLogEnabled) {
                if (data.status === ComponentPrintStatus.RENDERED) {
                    const timeOfStart = _.get(dashboardItemsStatuses, `${data.id}.moment`, moment());
                    this.logEvent(`${renderedItemsCount}. Item ${data.id} render time ${moment().diff(timeOfStart, 'seconds')} second(s)`);
                }
                if (data.status === ComponentPrintStatus.ERROR) {
                    this.logEvent(`${renderedItemsCount}. Dashboard item ${data.id} error`);
                }
                // const textStatus = data.status
                //     ? ComponentPrintStatus[data.status]
                //     : 'Data loading';
                // this.logEvent(`Dashboard item  ${data.id} status: ${textStatus}`);
            }

            dashboardItemsStatuses[data.id] = {status: data.status, moment: moment()};

            if (renderedItemsCount == expectingComponentCount) {
                subscription.unsubscribe();
                this.logEvent('All charts should be rendered now');
                this.logEvent(`Render finished in ${moment().diff(renderStartTime, 'second')} seconds`);

                setTimeout(() => {
                    this.logEvent('Print signal sent');
                    this.sendPrintSignal();
                }, 2000);
            }
        })
    }

    private logEvent(msg: string) {
        this.eventLogService.logEvent(msg + ' at ' + new Date().toISOString());
    }

    private sendPrintSignal() {
        if (window['ExpertPdfJSObj']) {
            window['ExpertPdfJSObj'].startConversion();
        } else {
            window.print();
        }
    }
}
