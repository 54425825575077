import { Observable, of as observableOf } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { StorageProvider } from './storage.provider';
import { ExternalSignin, Identity, Signin, Signup } from '../models';
import { ErrorMessage, ErrorMessageType } from '../consts/error.consts';
import { environment } from '../../environments/environment';

import { ResourcesConsts } from '../consts/resources.const';
import { String } from 'typescript-string-operations-ng4';
import { ActiveDirectoryService } from '../services/activeDirectory.service';
import * as _ from 'lodash';
import { ISecuritySettings } from '../../app-take-survey/models';
import { Guid } from '../../infrastructure/helpers/guid.helper';
import { CustomURLEncoder } from '../../infrastructure/helpers/urlEncode.helper';
import { UserRoles } from '../consts/auth.consts';
import { ChangePassword } from '../models/change-password.model';

export const HOME_URL = '/';
export const SURVEY_URL = '/surveys';
export const CONTACT_URL = '/contacts';
export const REPORTS_URL = '/reports';
export const DASHBOARDS_URL = '/dashboards';
export const DEVICE_ID = 'device_id';

export interface AutoLoginSetings {
    allow_auto_login: boolean;
    contact_id: string;
}

@Injectable()
export class IdentityProvider {
    constructor(
        private $http: HttpClient,
        private storageProvider: StorageProvider,
        private activeDirectoryService: ActiveDirectoryService
    ) {}

    static getRoles(identity: Identity): string[] {
        return Array.isArray(identity.roles)
            ? identity.roles
            : identity.roles.split(',').map((i: string) => i.trim());
    }

    static getDefaultUrl(identity: Identity, isTakeSurvey?: boolean) {
        if (identity && identity.roles) {
            const roles = IdentityProvider.getRoles(identity);
            if (isTakeSurvey) {
                if (
                    _.intersection(roles, [
                        UserRoles.REPORT_VIEWER,
                        UserRoles.REPORT_ADMINISTRATOR
                    ]).length === roles.length
                ) {
                    return REPORTS_URL;
                } else {
                    return SURVEY_URL;
                }
            } else {
                if (_.indexOf(roles, UserRoles.SYSTEM_ADMINISTRATOR) !== -1 ||
                    _.indexOf(roles, UserRoles.SURVEY_ADMINISTRATOR) !== -1 ||
                    _.indexOf(roles, UserRoles.SURVEY_EDITOR) !== -1) {
                    return SURVEY_URL;
                } else if (_.indexOf(roles, UserRoles.REPORT_ADMINISTRATOR) !== -1) {
                    return DASHBOARDS_URL;
                } else if (_.indexOf(roles, UserRoles.CONTACT_ADMINISTRATOR) !== -1) {
                    return CONTACT_URL;
                } else {
                    return SURVEY_URL;
                }
            }
        } else {
            return HOME_URL;
        }
    }

    static getTakeSurveyHost(identity: Identity) {
        let url = '';
        if (typeof identity.hosts === 'string') {
            url = identity.hosts.split(',')[0];
        } else if (_.isArray(identity.hosts)) {
            url = identity.hosts[0];
        }

        if (url != '' && !url.startsWith('http'))
            url = `https://${url}`;

        return url;
    }

    static isAllowedToUseAdminApp(roles): boolean {
        return !!_.without(roles, UserRoles.REPORT_VIEWER, UserRoles.RESPONDENT).length;
    }

    static redirectToTakeSurvey(identity:Identity, roles) {
        let path;
        if (_.indexOf(roles, UserRoles.RESPONDENT) == -1) {
            path = REPORTS_URL;
        } else {
            path = SURVEY_URL;
        }
        const url = `${IdentityProvider.getTakeSurveyHost(identity) + path}?jwt=${identity.redirect_token}&admin_redirect=true`;
        window.location.replace(url);
    }

    private get hostname(): string {
        return window.location.hostname;
    }

    private get isAdmin() {
        return environment.isAdminSite;
    }

    compareHosts(identity) {
        const hosts = _.isArray(identity.hosts) ? identity.hosts.map(host => host.toLowerCase()) : identity.hosts.toLowerCase();
        return hosts.indexOf(this.hostname.toLowerCase()) !== -1;
    }

    private validateSubdomain(jwtToken: string): Identity {
        const identity = <Identity>JSON.parse(jwtToken);
        if (
            (this.isAdmin && identity) ||
            (!this.isAdmin && identity && this.compareHosts(identity))
        ) {
            return identity;
        }
        return null;
    }

    checkToken(): Observable<Identity> {
        return this.storageProvider.getIdentity().pipe(
            map((jwtToken: string) => {
                return this.validateSubdomain(jwtToken);
            })
        );
    }

    invitationSignIn(invitationToken: string): Observable<Identity> {
        const params = new HttpParams()
            .set('grant_type', 'survey_invitation')
            .set('token', invitationToken);

        const authUrl: string = ResourcesConsts.SIGNIN;
        let account;

        // TODO: logout with the current token
        return this.$http.post(authUrl, params).pipe(
            mergeMap(accountData => {
                account = accountData;
                return this.storageProvider.saveAccount(account.account_name);
            }),
            mergeMap(() => observableOf(account)),
            mergeMap((identity: Identity) => this.saveIdentity(identity))
        );
    }

    responseResumeSignIn(resumeKey: string, contactId = null): Observable<Identity> {
        const params = new HttpParams()
            .set('grant_type', 'resume_response_key')
            .set('resume_key', resumeKey)
            .set('contact_id', contactId);
        const authUrl: string = ResourcesConsts.SIGNIN;
        let account;
        // TODO: logout with the current token
        return this.$http.post(authUrl, params).pipe(
            mergeMap(accountData => {
                account = accountData;
                return this.storageProvider.saveAccount(account.account_name);
            }),
            mergeMap(() => observableOf(account)),
            mergeMap((identity: Identity) => this.saveIdentity(identity))
        );
    }

    checkRecipientAutologin(invitationToken): Observable<AutoLoginSetings> {
        const params = {
            "invitation_token": invitationToken
        };
        return this.$http.post<AutoLoginSetings>(ResourcesConsts.RECIPIENT_LOGIN, params);
    }

    signIn(data: Signin): Observable<Identity> {
        const accountName = data.account?.trim();
        let params = new HttpParams({ encoder: new CustomURLEncoder() })
            .set('grant_type', 'password')
            .set('account', accountName)
            .set('username', data.id?.trim())
            .set('password', data.password)
            .set('device_id', this.getDeviceId())
            .set('client_id', environment.isAdminSite ? 'AdminWebApp' : 'TakeSurveyWebApp')
            .set('mfa_email_token', data.mfa_email_token);
        if (data.captcha) {
            params = params.set('captcha', data.captcha);            
        }
        const authUrl: string = ResourcesConsts.SIGNIN;
        const rootRequest = this.isAdmin
            ? observableOf({ account_name: accountName })
            : this.storageProvider
                .getAccount()
                .pipe(map(acc => ({ account_name: acc })));
        return rootRequest.pipe(
            mergeMap((account: any) => {
                if (!this.isAdmin) {
                    params = params.set('account', account.account_name);
                }
                return this.storageProvider.saveAccount(account.account_name);
            }),
            mergeMap(() => {
                return this.checkConcurrentUsers(params);
            }),
            mergeMap((result) => {
                if (result) {
                    return this.getToken(authUrl, params);
                } else {
                    return observableOf(null);
                }
            })
        );
    }

    externalSignIn(data: ExternalSignin): Observable<Identity> {
        const accountName = data.account?.trim();
        let params = new HttpParams({ encoder: new CustomURLEncoder() })
            .set('grant_type', 'external_provider')
            .set('account', accountName)
            .set('username', data.id?.trim())
            .set('provider_name', data.provider_name)
            .set('token', data.token)
            .set('device_id', this.getDeviceId())
            .set('client_id', 'AdminWebApp');
        const authUrl: string = ResourcesConsts.SIGNIN;
        const rootRequest = this.isAdmin
            ? observableOf({ account_name: accountName })
            : this.storageProvider
                .getAccount()
                .pipe(map(acc => ({ account_name: acc })));
        return rootRequest.pipe(
            mergeMap((account: any) => {
                if (!this.isAdmin) {
                    params = params.set('account', account.account_name);
                }
                return this.storageProvider.saveAccount(account.account_name);
            }),
            mergeMap(() => {
                return this.checkConcurrentUsers(params);
            }),
            mergeMap((result) => {
                if (result) {
                    return this.getToken(authUrl, params);
                } else {
                    return observableOf(null);
                }
            })
        );
    }

    getToken(authUrl, params) {
        return this.$http.post(authUrl, params).pipe(
            mergeMap(identity => {
                const roles = IdentityProvider.getRoles(identity as Identity);
                if (
                    !IdentityProvider.isAllowedToUseAdminApp(roles) &&
                    environment.isAdminSite
                ) {
                    IdentityProvider.redirectToTakeSurvey(identity as Identity, roles);
                    return observableOf(null);
                } else {
                    return this.saveIdentity(identity as Identity);
                }
            })
        );
    }
    signUp(data: Signup): Observable<Identity> {
        const body = JSON.stringify(data);
        const url: string = ResourcesConsts.SIGNUP;
        return this.$http.post(url, body).pipe(
            mergeMap(() =>
                this.signIn({
                    account: data.account,
                    id: data.user_name,
                    password: data.password,
                    mfa_email_token: ''
                })
            ),
            mergeMap((identity: Identity) => this.saveIdentity(identity))
        );
    }

    signInForPrint(token, account_name) {
        let params = new HttpParams()
            .set('grant_type', 'print_pdf')
            .set('token', token);
        const authUrl: string = ResourcesConsts.SIGNIN;
        const rootRequest = this.isAdmin
            ? observableOf({ account_name: account_name })
            : this.storageProvider
                .getAccount()
                .pipe(map(acc => ({ account_name: acc })));
        return rootRequest.pipe(
            mergeMap((account: any) => {
                if (!this.isAdmin) {
                    params = params.set('account', account.account_name);
                }
                return this.storageProvider.saveAccount(account.account_name);
            }),
            mergeMap(() => this.$http.post(authUrl, params)),
            mergeMap(identity => {
                const roles = IdentityProvider.getRoles(identity as Identity);
                if (
                    !IdentityProvider.isAllowedToUseAdminApp(roles) &&
                    environment.isAdminSite
                ) {
                    IdentityProvider.redirectToTakeSurvey(identity as Identity, roles);
                    return observableOf(null);
                } else {
                    return this.saveIdentity(identity as Identity);
                }
            })
        );
    }

    public logOut(skipApiCall?: boolean): Observable<boolean> {
        const authUrl: string = ResourcesConsts.SIGNOUT_ME;
        return this.storageProvider.getIdentity().pipe(
            mergeMap((jwtToken: string) => {
                const isLoggedIn = !!this.validateSubdomain(jwtToken);
                if (!isLoggedIn)
                    return observableOf(false);

                if (skipApiCall)
                    return observableOf(true);

                const signOutOptions = environment.isAdminSite ? { 'from_all_web_apps': true } : {};
                return this.$http.post(authUrl, signOutOptions).pipe(
                    map(() => true),
                    catchError(() => observableOf(true))
                );
            }),
            mergeMap((isLoggedIn: boolean) => {
                if (!isLoggedIn)
                    return observableOf(false);

                return environment.isAdminSite
                    ? this.storageProvider.clearIdentityAndAccount()
                    : this.storageProvider.clearIdentity();
            })
        );
    }

    public isLoggedIn(): Observable<boolean> {
        return this.storageProvider.validateIdentity();
    }
    private validateIdentityHost(hosts: string|string[]): boolean{
        if(!hosts){
            return false;
        }
        if(typeof hosts === 'string'){
            return hosts.toLowerCase().includes(this.hostname.toLocaleLowerCase());
        }
        return hosts.map(h => h.toLowerCase()).includes(this.hostname.toLocaleLowerCase());
    }
    saveIdentity(identity: Identity): Observable<Identity> {
        if (!identity) {
            return null;
        }
        return this.storageProvider.getIdentity().pipe(
            map((jwtToken: string) => {
                const storageIdentity = <Identity>JSON.parse(jwtToken);
                if (
                    !storageIdentity ||
                    (storageIdentity &&
                        this.validateIdentityHost(storageIdentity.hosts))
                ) {
                    return true;
                }
                return false;
            }),
            mergeMap((f: boolean) => {
                if (f) {
                    this.storageProvider.saveIdentity(JSON.stringify(identity));
                    this.activeDirectoryService.checkActiveDirectoryAccess();
                    return observableOf(identity);
                }
                throw {
                    status: 1,
                    message: ErrorMessage.LOGIN_AFTER_WRONG_DOMAIN_ERROR,
                    notify: true,
                    notificationType: ErrorMessageType.WARNING
                };
            })
        );
    }

    getSecuritySettings(): Observable<ISecuritySettings> {
        const url: string = ResourcesConsts.GET_SECURITY_SETTINGS;
        return this.$http
            .get<ISecuritySettings>(url);
    }

    checkConcurrentUsers(params): Observable<boolean> {
        let userName = params.username ? params.username : params.get('username');
        if (userName.includes('\\')) {
            const splitedName = userName.split('\\');
            userName = splitedName[splitedName.length - 1];
        }
        const url: string = String.Format(
            ResourcesConsts.CHECK_CONCURRENT_USERS,
            userName,
            environment.isAdminSite ? 'AdminWebApp' : 'TakeSurveyWebApp'
        );
        return this.$http
            .get(url)
            .pipe(
                map(data => {
                    const confirmMessage = environment.isAdminSite ?
                        'Another user is currently signed in with this username. ' +
                        'Signing in now will end that user\'s Checkbox session and sign them out. ' +
                        'Click OK to sign in or Cancel to cancel your sign in. \n' +
                        'If you need to purchase additional user licenses, please contact sales@checkbox.com.' :
                        'Another user is currently signed in with this username. ' +
                        'Signing in now will end that user\'s Checkbox session and sign them out. ' +
                        'Click OK to sign in or Cancel to cancel your sign in.';
                    if (data['device_id'] === this.getDeviceId() || data['device_id'] === null) {
                        return true;
                    } else if (window.confirm(confirmMessage)) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
    }

    getDeviceId() {
        if (window.localStorage.getItem(DEVICE_ID)) {
            return window.localStorage.getItem(DEVICE_ID);
        } else {
            const guid = Guid.newGuid();
            window.localStorage.setItem(
                DEVICE_ID,
                guid
            );
            return guid;
        }
    }

    changePassword(contactId: string, data: ChangePassword): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url: string = String.Format(
            ResourcesConsts.CHANGE_PASSWORD,
            contactId
        );
        return this.$http.post(url, JSON.stringify(data), { headers: headers });
    }
}
