import * as general from '../../../infrastructure/store/reducers';

export interface AppState extends general.AppState {
    /* put here app specific state */
}

const rootReducers: any = {
    /* put here app specific reducers */
};

// WORKAROUND: the workaround helps to merge
// general reduceres with application specific reducers
// it's needed for the success of AoT compilation
export let reducers = null;
function initReducers() {
    reducers = { ...general.reducers, ...rootReducers };
}
initReducers();
// ------
