import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../consts/resources.consts';
import { MergeGroup } from '../../models/merge-codes/mergeItems';
import { RequestCacheService } from '../../../infrastructure/services/index';

@Injectable()
export class SurveyMergeCodesProvider {
    constructor(
        private $http: HttpClient,
        private $requestCache: RequestCacheService
    ) {}

    getSurveyPageMergeCodes(surveyId, pageId, isTemplate = false): Observable<MergeGroup[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE_MERGE_CODES : ResourcesConsts.SURVEY_PAGE_MERGE_CODES;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        return this.$requestCache
            .reDuplicate(url, this.$http.get(url));
    }

    getSurveyInvitationMergeCodes(surveyId, invitationId): Observable<MergeGroup[]> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_INVITATION_MERGE_CODES,
            surveyId,
            invitationId
        );
        return this.$http
            .get<MergeGroup[]>(url);
    }

    getDefaultInvitationFooterMergeCodes(): Observable<MergeGroup[]> {
        return this.$http
            .get<MergeGroup[]>(ResourcesConsts.EMAIL_INVITATION_FOOTER_MERGE_CODES);
    }
}
