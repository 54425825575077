export const IconsRegistry ={
    'fi_plus': 'assets/icons/fi_plus.svg',
    'import': 'assets/icons/import.svg',
    'fi_chevron_up': 'assets/icons/fi_chevron_up.svg',
    'fi_chevron_down': 'assets/icons/fi_chevron_down.svg',
    'fi_user': 'assets/icons/fi_user.svg',
    'fi_funnel': 'assets/icons/fi_funnel.svg',
    'fi_search': 'assets/icons/fi_search.svg',
    'fi_trash': 'assets/icons/fi_trash.svg',
    'fi_folder_upload': 'assets/icons/fi_folder_upload.svg',
    'fi_x': 'assets/icons/fi_x.svg',
    'fi_archive': 'assets/icons/fi_archive.svg',
    'fi_check': 'assets/icons/fi_check.svg',
    'fi_edit': 'assets/icons/fi_edit.svg',
    'fi_calendar': 'assets/icons/fi_calendar.svg',
    'fi_lock': 'assets/icons/fi_lock.svg',
    'fi_more_horizontal': 'assets/icons/fi_more_horizontal.svg',
    'fi_read_only': 'assets/icons/fi_read_only.svg',
    'fi_settings': 'assets/icons/fi_settings.svg',
    'fi_star_fill': 'assets/icons/fi_star_fill.svg',
    'fi_star': 'assets/icons/fi_star.svg',
    'fi_pie_chart': 'assets/icons/fi_pie_chart.svg',
    'fi_copy': 'assets/icons/fi_copy.svg',
    'fi_download': 'assets/icons/fi_download.svg',
    'fi_edit_2': 'assets/icons/fi_edit_2.svg',
    'fi_shield': 'assets/icons/fi_shield.svg',
    'fi_empty': 'assets/icons/fi_empty.svg',
    'fi_logout': 'assets/icons/fi_logout.svg',
    'fi_book': 'assets/icons/fi_book.svg',
    'fi_support': 'assets/icons/fi_support.svg',
    'fi_system': 'assets/icons/fi_logout.svg',
    'fi_account_setting': 'assets/icons/fi_account_setting.svg',
    'fi_upgrade': 'assets/icons/fi_upgrade.svg',
    'fi_user_edit': 'assets/icons/fi_user_edit.svg',
    'fi_user_2': 'assets/icons/fi_user_2.svg',
    'fi_users': 'assets/icons/fi_users.svg',
    'fi_alert_triangle_filled': 'assets/icons/fi_alert_triangle_filled.svg',
    'fi_alert_triangle_outline': 'assets/icons/fi_alert_triangle_outline.svg',
    'fi_check_circle_filled': 'assets/icons/fi_check_circle_filled.svg',
    'fi_check_circle_outline': 'assets/icons/fi_check_circle_outline.svg',
    'fi_close_circle_filled': 'assets/icons/fi_close_circle_filled.svg',
    'fi_close_circle_outline': 'assets/icons/fi_close_circle_outline.svg',
    'fi_info_filled': 'assets/icons/fi_info_filled.svg',
    'fi_info_outline': 'assets/icons/fi_info_outline.svg',
    'fi_external-link': 'assets/icons/fi_external-link.svg',
    'fi_more_vertical': 'assets/icons/fi_more_vertical.svg',
    'fi_printer': 'assets/icons/fi_printer.svg',
    'fi_json': 'assets/icons/fi_json.svg',
    'fi_file': 'assets/icons/fi_file.svg',
    'fi_unlock': 'assets/icons/fi_unlock.svg',
    'fi_eye': 'assets/icons/fi_eye.svg',
    'fi_edit_3': 'assets/icons/fi_edit_3.svg',
    'fi_share_2': 'assets/icons/fi_share_2.svg',
    'fi_message_square': 'assets/icons/fi_message_square.svg',
    'fi_circle_indicator': 'assets/icons/fi_circle_indicator.svg',
    'filled_refresh': 'assets/icons/filled_refresh.svg',
    'cb_import': 'assets/icons/cb_import.svg',
    'cb_export': 'assets/icons/cb_export.svg',
    'fi_response': 'assets/icons/fi_response.svg',
    'fi_all': 'assets/icons/fi_all.svg',
}
