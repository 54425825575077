export interface ResponseCreateData {
    survey_id: number;
    language: string;
    password: string;
    recipient_id: string;
    invitation_token: string;
    anonymous_respondent_id: string;
    is_test: boolean;
    started?: Date;
    ended?: Date;
    status?: ResponseStatus;
    ip_Address: string;
    hidden_items: HiddenItemValue[];
}

export interface HiddenItemValue {
    variable_name: string; // or item_id
    value: string;
}

export enum ResponseStatus {
    Partial = 'Partial',
    Completed = 'Completed'
}
