import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UsersProvider } from '../../app-admin/providers/contacts.provider';
import { ContactSources } from '../../app-admin/models';
import {
    map,
    concatMap,
    takeWhile,
    first
} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ApplicationSettingsProvider } from '../../app-admin/settings/application-settings/application-settings.provider';

@Injectable({
    providedIn: 'root',
})
export class ActiveDirectoryService {
    private sources = new BehaviorSubject<ContactSources>([
        {
            source_id: 'checkbox-membership-provider',
            source_type: 'Checkbox'
        }
    ]);
    private activeDirectoryAccess = new BehaviorSubject<boolean>(false);
    private activeDirectoryPreloading = new BehaviorSubject<boolean>(false);
    constructor(
        private usersProvider: UsersProvider,
        private systemSettingsProvider: ApplicationSettingsProvider
        ) {}

    checkActiveDirectoryAccess() {
        const isMultiTenant = environment.isMultiTenant;
        if (!isMultiTenant) {
            this.getSources()
                .pipe(
                    takeWhile(res => res as boolean),
                    concatMap(() => this.checkLicense())
                )
                .subscribe();
        }
    }

    getSources() {
        return this.usersProvider.getContactSources().pipe(
            map(data => {
                this.sources.next(data);
                return typeof data !== 'undefined' && data.length > 0;
            })
        );
    }

    getSetting() {
        this.systemSettingsProvider.getContactPerformanceSetting().pipe(
            first()
        ).subscribe(
            data => this.activeDirectoryPreloading.next(data['disable_contact_list_preloading_for_active_directory'])
        );
    }

    checkLicense() {
        return this.usersProvider.checkLicensing().pipe(
            map(data => {
                const licenses = data;
                let licenseAccess = true;
                licenses.forEach(license => {
                    if (
                        license.status &&
                        license.status === 'LimitExceeded' &&
                        license.limit_type &&
                        license.limit_type === 'NetworkUserLimit'
                    ) {
                        licenseAccess = false;
                    }
                });
                this.activeDirectoryAccess.next(licenseAccess);
            })
        );
    }

    get getContactSources() {
        return this.sources;
    }

    get getActiveDirectoryAccess() {
        return this.activeDirectoryAccess;
    }

    get getActiveDirectoryPreloading() {
        return this.activeDirectoryPreloading;
    }

    setActiveDirectoryPreloading(val) {
        this.activeDirectoryPreloading.next(val);
    }
}
