import * as actions from '../actions';
import { State, initialState } from './identity.state';

export function identityReducer(
    state = initialState,
    action: actions.identity.Actions
): State {
    switch (action.type) {
        case actions.identity.CHECK_TOKEN:
        case actions.identity.SIGNIN:
        case actions.identity.EXTERNAL_SIGNIN:
        case actions.identity.SIGNUP:
        case actions.identity.SIGNOUT:
        case actions.identity.SIGNOUT_INVALID_TOKEN: {
            return Object.assign({}, state, {
                loading: true,
                identity: null
            });
        }
        case actions.identity.CHECK_TOKEN_SUCCESS:
        case actions.identity.SIGNIN_SUCCESS:
        case actions.identity.SIGNUP_SUCCESS: {
            return Object.assign({}, state, {
                loading: false,
                identity: action.payload
            });
        }
        case actions.identity.SIGNOUT_SUCCESS: {
            return Object.assign({}, state, {
                loading: false,
                identity: null
            });
        }
        case actions.identity.IDENTITY_FAILED: {
            return Object.assign({}, state, {
                loading: false
            });
        }
        default:
            return state;
    }
}
