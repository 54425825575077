import { filter, first, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { interval } from 'rxjs';
import { timeout, retry } from 'rxjs/operators';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import { CustomMatSnackBarComponent } from '../../shared/components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import * as FileSaver from 'file-saver';
import { dataURItoBlob } from '../helpers/dataURItoBlob.helper';
import { ToasterService } from '../services';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ExportType, PdfGenerationStatus } from '../../app-admin/downloads/download-item.model';

@Injectable()
export class FileExportProvider {
    constructor(
        private $http: HttpClient,
        private toasterService: ToasterService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService) {}

    getFileFromBackend(url, requestOptions = {}) {
        return this.$http
            .get(url, {
                observe: 'response',
                responseType: 'blob',
                ...requestOptions
            })
            .pipe(
                retry(3),
                map(data => this.saveFile(data))
            );
    }

    saveFile(data) {
        const body = data.body;
        const blob = new Blob([body], {
            type: 'application/octet-stream'
        });
        const contentDispositionHeader = data.headers.get(
            'Content-Disposition'
        );
        const fileName = contentDispositionHeader
            .split('filename=')[1]
            .split(';')[0]
            .trim()
            .replace(/"/g, '');
        FileSaver.saveAs(blob, fileName);
    }

    getExportResult(exportId: string, exportType: ExportType) {
        this.showExportProcessingMessage(exportType);
        return interval(5000).pipe(
                mergeMap(() => {
                return this.checkExportRequestStatus(exportId);
            }),
            filter((response: any) => { // replace with takeWhile, invert the statement, set true as second var in takeWhile call
                // and remove first() to get data from every response
                return response.status === PdfGenerationStatus.ERROR ||
                    response.status === PdfGenerationStatus.COMPLETED;
            }),
            first(),
            map(response => {
                if (response.status === PdfGenerationStatus.ERROR) {
                    this.hideExportProcessingMessage();
                    let errorMessage;
                    if (response.exception_id) {
                        errorMessage = `An error has occurred. Please check the Checkbox exception log for more information. Exception ID is ${response.exception_id}.`;
                    } else {
                        errorMessage = response.eror || `Export error.`;
                    }
                    console.error(errorMessage);
                    this.toasterService.showError({message: errorMessage, fullError: errorMessage});
                }
                if (response.status === PdfGenerationStatus.COMPLETED) {
                    this.hideExportProcessingMessage();
                }
                return response;
            }),
            mergeMap(response => {
                if (response.status === PdfGenerationStatus.COMPLETED) {
                    return this.getFile(response);
                }
            }),
            first()
        );
    }

    private getFile(exportResult) {
        const url: string = String.Format(
            ResourcesConsts.DOWNLOADS_FILE_DATA,
            exportResult.id
        );
        return this.$http.get(url)
            .pipe(
                map(data => {
                    const blob = dataURItoBlob(`data:${exportResult.mime_content_type};base64,` + data);
                    FileSaver.saveAs(blob, exportResult.file_name);
                    return data;
                })
            );
    }

    private checkExportRequestStatus(exportId: string) {
        const url: string = String.Format(ResourcesConsts.DOWNLOADS_FILE, exportId);
        return this.$http.get(url);
    }

    private showExportProcessingMessage(exportType: ExportType) {
        const config = new MatSnackBarConfig();
        config.horizontalPosition = 'center';
        config.verticalPosition = 'top';
        config.duration = 0;
        config.panelClass = ['info'];
        let key: string;
        switch(exportType) {
            case ExportType.REPORT_PDF_EXPORT:
            case ExportType.SURVEY_PDF_EXPORT:
                key = 'SHARED.EXPORT_PDF_MESSAGE';
                break;
            case ExportType.SURVEY_RESPONSE_CSV:
            case ExportType.SURVEY_RESPONSE_MAXDIFF_CSV:
            case ExportType.SURVEY_RESPONSE_SPSS:
            case ExportType.SURVEY_RESPONSE_SPSS_COMPATIBLE_CSV:
                key = 'SHARED.EXPORT_RESPONSES_MESSAGE';
                break;
        }

        this.translateService.get(key).subscribe(translation => {
            this.snackBar
                .openFromComponent(CustomMatSnackBarComponent, {
                ...config,
                data: {
                    text: translation,
                    hideCloseBtn: false,
                    fullErrorMessage: translation,
                    hideCopyBtn: true
                }
            });
        });
    }

    private hideExportProcessingMessage() {
        this.snackBar.dismiss();
    }
}
