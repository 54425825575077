<cb-main-dialog [title]="title" [texts]="[message, 'SHARED.NO-UNDONE-WARNING' | translate]" 
    [mainButton]="'SHARED.DELETE' | translate"
    [cancelButton]="'SHARED.CANCEL' | translate" (onMain)="closeDialog()" [disabled]="!form.valid"
    (onCancel)="dialogRef.close()">
    <div class="cb-confirm-content">
        <form [formGroup]="form" novalidate name="confirmationForm">
            <mat-form-field class="no-label no-top dialog-form" appearance="outline">
                <input matInput placeholder="{{ 'DIALOG.DELETE-CONFIRM' | translate }}"
                    formControlName="delete_confirm" />
                <mat-error>
                    {{ 'DIALOG.TYPE-DELETE' | translate }} {{ objectName }}
                </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="checkboxRule" *ngIf="checkboxRule">
                {{checkboxRuleText}}
            </mat-checkbox>
        </form>
    </div>
</cb-main-dialog>