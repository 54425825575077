<ng-container  *ngIf="filtersData?.date_filter?.date_filter_start" >
    <div class="applied-filter-item">
        <strong>{{'DASHBOARD.EDIT.START-DATE' | translate }}</strong>
        {{ ' ' }} {{ getNonUtcDateFromString(filtersData.date_filter.date_filter_start) | date: 'medium' }}
    </div>
</ng-container>
<ng-container *ngIf="filtersData?.date_filter?.date_filter_end">
    <div class="applied-filter-item">
        <strong>{{ 'DASHBOARD.EDIT.END-DATE' | translate }}</strong>
        {{ ' ' }} {{ getNonUtcDateFromString(filtersData.date_filter.date_filter_end) | date: 'medium' }}
    </div>
</ng-container>
<ng-container *ngFor="let orFilter of filtersData?.filter_group?.children_filter_groups">
    <ng-container *ngFor="let filter of orFilter.filters">
        <div class="applied-filter-item">
            {{ getAdminFiltersText(filter) }}
        </div>
    </ng-container>
</ng-container>
<ng-container *ngFor="let orFilter of filtersData?.form?.filters; let row = index">
    <ng-container *ngFor="let filter of orFilter.and_filters; let col = index">
        <div class="applied-filter-item">
            {{ getLiveFilterText(filter, row, col) }}
        </div>
    </ng-container>
</ng-container>
