export interface BaseSmsMessage {
    type: 'single' | 'batch';
    text: string;
    message_type: MessageType;
    whatsapp_message_properties: WhatsappMessageProperties;
}

export interface SingleSmsMessage extends BaseSmsMessage {
    to_phone_number: string;
}

export interface BatchSmsMessage extends BaseSmsMessage {
    to_phone_numbers: string[];
}

export interface WhatsappMessageProperties {
    contact_id: string;
    survey_id: number;
    template_type: WhatsappTemplateType;
    survey_url?: string;
}

export enum MessageType {
    SMS = 0,
    WhatsApp = 1
}

export enum WhatsappTemplateType {
    ShareSurvey = 'ShareSurvey',
    ShareSurveyNoFirstName = 'ShareSurveyNoFirstName',
}