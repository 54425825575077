import { Pipe, PipeTransform} from '@angular/core';
import { TakeSurveySanitizerService } from '../services/take-survey-sanitizer.service';

@Pipe({ name: 'keepHtml', pure: true })
export class EscapeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: TakeSurveySanitizerService) {}

    transform(content, allowedTags?) {
        return this.sanitizer.transformPipe(content, allowedTags);
    }
}
