// For auto unsubscribe with this decorator
// Create 'private componentDestroyed = new Subject();' in your component
// and use 'takeUntil(this.componentDestroyed)' for stream subscription
// ngOnDestroy() {} // must be present
export function AutoUnsubscribe(): ClassDecorator {
    return function(target: any) {
        const originOnDestroy = target.prototype.ngOnDestroy;
        target.prototype.ngOnDestroy = function() {
            if (this
                && this.componentDestroyed
                && !this.componentDestroyed.closed
                && !this.componentDestroyed.isStoped
                && this.componentDestroyed.observers.length) {
                this.componentDestroyed.next();
                this.componentDestroyed.unsubscribe();
            }
            if (originOnDestroy) {
                originOnDestroy.apply(this);
            }
        };
    };
}
