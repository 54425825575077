import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { SurveyPage } from '../../../app-admin/models';
import { ResourcesConsts } from '../../../app-admin/consts/resources.consts';
import { ReorderData } from '../../../app-admin/models/reorderData';

@Injectable()
export class SurveyPagesProvider {
    constructor(private $http: HttpClient) {}

    getAllPages(surveyId: number, isTemplate = false): Observable<SurveyPage[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGES : ResourcesConsts.PAGES;
        const url: string = String.Format(source, surveyId);
        return this.$http
            .get<SurveyPage[]>(url);
    }

    addPage(surveyId: number, page: SurveyPage, isTemplate = false): Observable<SurveyPage> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGES : ResourcesConsts.PAGES;
        const url: string = String.Format(source, surveyId);
        return this.$http
            .post<SurveyPage>(url, page);
    }

    loadPages(surveyId: number, isTemplate = false): Observable<SurveyPage[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGES : ResourcesConsts.PAGES;
        const url: string = String.Format(
            source,
            surveyId.toString()
        );
        return this.$http
            .get<SurveyPage[]>(url);
    }

    updateSurveyPage(
        surveyId: number,
        pageId: number,
        page: SurveyPage,
        isTemplate = false
    ): Observable<SurveyPage> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE : ResourcesConsts.PAGE;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        const pageWithOutItems = Object.assign({}, page);
        delete pageWithOutItems['items'];
        return this.$http
            .put<SurveyPage>(url, pageWithOutItems);
    }

    removePage(surveyId: number, pageId: number, isTemplate = false): Observable<SurveyPage> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE : ResourcesConsts.PAGE;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        return this.$http
            .delete<SurveyPage>(url);
    }

    reorderPage(
        model: ReorderData,
        surveyId: number,
        pageId: number,
        isTemplate = false
    ): Observable<SurveyPage> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE_POSITION : ResourcesConsts.SURVEY_EDITOR_REORDER_PAGE;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        return this.$http
            .put<SurveyPage>(url, model);
    }
}
