import { GDPRComponent } from './gdpr/gdpr.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/modules/shared.module';
import { NotFoundPageComponent } from './not-found/not-found-page.component';
import { BlankPageComponent } from './blank/blank-page.component';
import { ApplicationNotActiveComponent } from './application-not-active/application-not-active.component';
import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { LazyTranslateLoaderModule } from '../../app-admin/entry-point/lazy-translate-loader.module';

const declarations = [
    // components
    GDPRComponent,
    NotFoundPageComponent,
    BlankPageComponent,
    ApplicationNotActiveComponent
];

const entryComponents = [
    // components
    /* put here app specific services */
];

@NgModule({
    imports: [
        // system
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // custom
        CustomMaterialModule,
        SharedModule,
        // translation
        LazyTranslateLoaderModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents],
    entryComponents: entryComponents
})
export class TakeSurveyPagesModule { }
