import * as _ from 'lodash';
import { ProfileProperty } from '../../../../infrastructure/models/profile-property.model';
import { ISecuritySettings } from '../../../../infrastructure/models/security-settings.model';
import { ISingleSignOn } from '../../../../infrastructure/models/single-sign-on.model';
import { RegistrationSettings } from '../../../../infrastructure/models/registration-settings.model';
import { PerformanceSettings } from '../../../../infrastructure/models/performance-settings.model';

export class UserApplicationSettings {
    profileProperties: ProfileProperty[];
    registrationSettings: RegistrationSettings;
    securitySettings: ISecuritySettings;
    singleSignOn: ISingleSignOn;
    performanceSettings: PerformanceSettings;

    constructor(props) {
        _.forEach(props, (value, key) => {
            this[_.camelCase(key)] = value;
        });
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            json[_.snakeCase(key)] = value;
        });
        return json;
    }
}
