import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throwError } from 'rxjs';

@Injectable()
export class RequestQueueService {
    cache = new Map();
    observerCache = {};

    setQueue(url, requestObservable: Observable<any>) {

        const cached = this.cache.get(url);
        if (!cached) {
            this.observerCache[url] = new Subject();
            this.cache.set(url, {});

            return requestObservable.pipe(
                map(data => {
                    this.observerCache[url].next(data);
                    this.checkQueue(url);
                    return data;
                }),
                catchError(err => {
                    this.checkQueue(url);
                    return throwError(err);
                })
            );
        } else {
            this.cache.set(url, { requestObs: requestObservable });
            return this.observerCache[url];
        }
    }

    checkQueue(url) {
        const request = this.cache.get(url).requestObs;
        if (request) {
            this.cache.set(url, {});
            request.subscribe(
                data => {
                    this.observerCache[url].next(data);
                    this.checkQueue(url);
                }
            );
        } else {
            this.cache.delete(url);
            delete this.observerCache[url];
        }
    }
}
