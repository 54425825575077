import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'cb-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public form: FormGroup;
    @Output() sendMail: EventEmitter<void> = new EventEmitter();
    @Input() emailSent: boolean;
    @Input() isBrokenToken: boolean;
    @Input() account: string;
    isServer = environment.name === 'server';
    constructor(
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            contactid: ['', [Validators.required]]
        });
    }

    onSubmit() {
        const data = this.form.value;
        this.sendMail.emit(data);
    }
}
