import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { IdentityProvider } from '../providers/identity.provider';
import { AppState } from './reducers';
import { identity as IdentityAction } from './actions';
import { ExternalSignin, Identity, JWTSignInEffectData, Signin, Signup } from '../models';
import { getIdentityUser } from './selectors';
import { HandleErrorAction } from './actions/error.actions';
import { ChangePasswordPayload } from '../models/change-password.model';

export const LOGIN_URL = '/login';

@Injectable()
export class IdentityStore {
    private redirectUrl;

    constructor(
        private router: Router,
        private store: Store<AppState>
    ) { }

    get identity(): Observable<Identity> {
        return this.store.select(getIdentityUser);
    }

    doCheckToken(): void {
        this.store.dispatch(new IdentityAction.CheckTokenAction());
    }

    doSignIn(data: Signin): void {
        this.store.dispatch(new IdentityAction.SigninAction(data));
    }

    doExternalSignIn(data: ExternalSignin): void {
        this.store.dispatch(new IdentityAction.ExternalSigninAction(data));
    }

    doSignUp(data: Signup): void {
        this.store.dispatch(new IdentityAction.SignupAction(data));
    }

    doSignOut(): void {
        this.store.dispatch(new IdentityAction.SignoutAction());
        this.setRedirectUri('');
        this.router.navigate([LOGIN_URL]);
    }

    doSignInWithJWT(data: JWTSignInEffectData): void {
        this.store.dispatch(new IdentityAction.SignInWithJWTAction(data));
    }

    doStoreIdentity(identity) {
        this.store.dispatch(new IdentityAction.SigninSuccessAction(identity));
    }

    get user(): Observable<any> {
        return this.identity.pipe(
            map((i: Identity) => (i ? <any>{ id: i.user_name } : null))
        );
    }

    isLoggedIn(): Observable<boolean> {
        return this.identity.pipe(
            map((i: Identity) => !!i && !!i.access_token)
        );
    }

    doHandleError(err) {
        this.store.dispatch(new HandleErrorAction(err));
    }

    setRedirectUri(uri: string) {
        if (uri !== '/login' && uri !== '/') {
            // prevent redirection back to login page
            this.redirectUrl = uri;
        }
    }

    goToRedirectUri(newIdentity: Identity, isTakeSurvey?: boolean) {
        const defaultUrl = IdentityProvider.getDefaultUrl(
            newIdentity,
            isTakeSurvey
        );
        if (defaultUrl.indexOf('http') > -1) {
            window.location.replace(defaultUrl);
            return '';
        }
        if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
        } else {
            this.router.navigate([defaultUrl]);
        }
    }

    goToLoginUri() {
        this.router.navigate([LOGIN_URL]);
    }

    relogin() {
        const newUrl = this.router.url;

        this.setRedirectUri(newUrl);
        this.store.dispatch(new IdentityAction.SignoutAction());
        this.goToLoginUri();
    }

    reloginOnInvalidToken() {
        const newUrl = this.router.url;

        this.setRedirectUri(newUrl);
        this.store.dispatch(new IdentityAction.SignoutInvalidTokenAction());
        this.goToLoginUri();
    }

    changePassword(data: ChangePasswordPayload): void {
        this.store.dispatch(new IdentityAction.ChangePasswordAction(data));
    }
}
