import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MoveSurveyItemData } from '../../models/moveItemData';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../consts/resources.consts';
import { SurveyQuestionType } from '../../../infrastructure/consts/surveys.consts';
import { DataTransformer } from '../transforms/dataTransformer';
import * as _ from 'lodash';
import { InterceptorOptions } from '../../../infrastructure/consts/interceptors.const';

export enum MissingTranslationHandlingTypes {
    NONE = 'None',
    USE_DEFAULT_LANGUAGE = 'UseDefaultLanguage',
    USE_DEFAULT_LANGUAGE_WITH_LANGUAGE_PREFIX = 'UseDefaultLanguageWithLanguagePrefix'
}

@Injectable()
export class SurveyEditorProvider {
    autoCompleteUrl: string =
        ResourcesConsts.SURVEY_EDITOR_GET_AUTOCOMPLETE_LISTS;
    constructor(private $http: HttpClient) {}

    addQuestion(
        model: any,
        surveyId: number | string,
        updateUrl?: string,
        isTemplate = false
    ): Observable<any> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ITEMS : ResourcesConsts.SURVEY_EDITOR_ADD_QUESTION;
        const url: string = String.Format(
            source,
            surveyId
        );

        if (this.checkSingleLineType(model)) {
            if (model.autocomplete_list_name && model.autocomplete_list) {
                const newList = this.getPostedObject(model);
                return this.$http.post(this.autoCompleteUrl, newList).pipe(
                    mergeMap(list => {
                        model['autocomplete_list_id'] = list['id'];
                        return updateUrl
                            ? this.$http
                                .put(updateUrl, model)
                            : this.$http
                                .post(url, model);
                    })
                );
            }
        }

        if (
            this.checkRadioButtonType(model) ||
            this.checkCheckBoxType(model) ||
            this.checkDropDownListType(model)
        ) {
            DataTransformer.transformToSend(model);
        }

        return this.$http
            .post(url, model);
    }

    addQuestionFromTemplate(surveyId, data) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_EDITOR_ADD_QUESTION_FROM_TEMPLATE,
            surveyId
        );
        return this.$http
        .post(url, data);
    }

    updateQuestion(model: any, surveyId: number | string, isTemplate = false): Observable<any> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ITEM : ResourcesConsts.SURVEY_EDITOR_UPDATE_QUESTION;
        const url: string = String.Format(
            source,
            surveyId,
            model.id
        );

        if (this.checkSingleLineType(model)) {
            if (
                model.autocomplete_list_id &&
                !model.autocomplete_list_is_default
            ) {
                const newList = this.getPostedObject(model);
                return this.$http
                    .put(
                        `${this.autoCompleteUrl}/${model.autocomplete_list_id}`,
                        newList
                    )
                    .pipe(
                        mergeMap(() => {
                            return this.$http
                                .put(url, model);
                        })
                    );
            } else {
                if (
                    !model.autocomplete_list_is_default &&
                    model.autocomplete_list_name
                ) {
                    return this.addQuestion(model, surveyId, url);
                }
            }
        }

        if (
            this.checkRadioButtonType(model) ||
            this.checkCheckBoxType(model) ||
            this.checkDropDownListType(model)
        ) {
            DataTransformer.transformToSend(model);
        }

        return this.$http
            .put(url, model);
    }

    getAllQuestions(surveyId, isTemplate = false) {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ITEMS : ResourcesConsts.SURVEY_EDITOR_ADD_QUESTION;
        const url: string = String.Format(
            source,
            surveyId
        );
        return this.$http.get(url);
    }

    getPostedObject(model) {
        return {
            name: model.autocomplete_list_name,
            items: _.split(model.autocomplete_list, '\n')
        };
    }

    checkSingleLineType(model) {
        return model.item_type === SurveyQuestionType.SINGLE_LINE_TEXT;
    }

    checkRadioButtonType(model) {
        return model.item_type === SurveyQuestionType.RADIOBUTTONS;
    }

    checkCheckBoxType(model) {
        return model.item_type === SurveyQuestionType.CHECKBOXES;
    }

    checkDropDownListType(model) {
        return model.item_type === SurveyQuestionType.DROPDOWNLIST;
    }

    reorderQuestion(
        model: MoveSurveyItemData,
        surveyId: number,
        itemId: number,
        isTemplate = false
    ): Observable<any> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_POSITION : ResourcesConsts.SURVEY_EDITOR_REORDER_QUESTION;
        const url: string = String.Format(
            source,
            surveyId,
            itemId
        );
        return this.$http
            .put(url, model);
    }

    getQuestions(
        surveyId: number,
        pageId: number,
        language?: string,
        isTemplate = false
    ): Observable<any[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE_ITEMS : ResourcesConsts.SURVEY_EDITOR_GET_QUESTIONS_PER_PAGE;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        const params = language
            ? new HttpParams().set('language', language)
            : null;
        return this.$http
            .get<any[]>(url, { params });
    }

    // TODO: create provider for take survey app
    getSurveyPageItems(surveyId: number, pageId: number, language?: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_EDITOR_GET_QUESTIONS_PER_PAGE,
            surveyId,
            pageId
        );
        const params = language
            ? new HttpParams().set('language', language)
            : null;

        const headers = new HttpHeaders({[InterceptorOptions.RETRY_ATTEMPTS]: '5'});

        return this.$http
            .get<any[]>(url, { params, headers });
    }

    getQuestion(
        surveyId: number,
        itemId: number,
        language?: string,
        missingTranslationHandling?: MissingTranslationHandlingTypes,
        isTemplate = false
    ): Observable<any> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ITEM : ResourcesConsts.SURVEY_EDITOR_GET_QUESTION;
        const url: string = String.Format(
            source,
            surveyId,
            itemId
        );
        let params = new HttpParams();
        if (language) {
            params = params.set('language', language);
        }
        if (missingTranslationHandling) {
            params = params.set(
                'missing_translation_handling',
                missingTranslationHandling
            );
        }
        return this.$http
            .get(url, { params });
    }

    cloneQuestion(
        surveyId: number,
        itemId: number,
        params?,
        isTemplate = false
    ): Observable<any[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_EDITOR_CLONE_QUESTION : ResourcesConsts.SURVEY_EDITOR_CLONE_QUESTION;
        const url: string = String.Format(
            source,
            surveyId,
            itemId
        );
        return this.$http
            .post<any[]>(url, params);
    }

    deleteQuestion(surveyId: number, itemId: number, isTemplate = false): Observable<any[]> {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ITEM : ResourcesConsts.SURVEY_EDITOR_DELETE_QUESTION;
        const url: string = String.Format(
            source,
            surveyId,
            itemId
        );
        return this.$http
            .delete<any[]>(url);
    }

    getImage(id: string): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_EDITOR_GET_IMAGE,
            id
        );
        return this.$http
            .get(url);
    }

    clonePage(surveyId: number, pageId: number, position?: number, isTemplate = false) {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_PAGE_CLONE : ResourcesConsts.PAGE_CLONE;
        const url: string = String.Format(
            source,
            surveyId,
            pageId
        );
        return this.$http
            .post(url, { position });
    }

    enableItem(surveyId: number, itemId: number, isEnabled: boolean, isTemplate = false) {
        const source = isTemplate ? ResourcesConsts.SURVEY_TEMPLATE_ENABLE_ITEM : ResourcesConsts.SURVEY_ENABLE_ITEM;
        const url: string = String.Format(
            source,
            surveyId,
            itemId
        );
        return this.$http
            .put(url, { 'enabled': isEnabled });
    }
}
