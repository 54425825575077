import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './reducers';
import { LogErrorAction } from './actions/error.actions';
import { getLastFiveErrors, getLastError } from './selectors';
import { ApplicationError } from '../models';

@Injectable()
export class ErrorStore {
    constructor(private store: Store<AppState>) {}

    get lastFiveErrors(): Observable<ApplicationError[]> {
        return this.store.select(getLastFiveErrors);
    }

    get lastError(): Observable<ApplicationError> {
        return this.store.select(getLastError);
    }

    doLogError(error: ApplicationError) {
        this.store.dispatch(new LogErrorAction(error));
    }
}
