import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostedPage } from '../../models/hosted-page';
import { CartInfo } from '../../models/cart-info';

@Injectable()
export class OrderProvider {
    constructor(private $http: HttpClient) {}

    private url: string = 'https://orders.checkbox.com/mvc/api/order/'; 


    public getChargebeeCheckoutUrl(item: string){
        return this.$http
        .get<HostedPage>(this.url + 'ChargebeeCheckoutUrl/' + item);
    }

    public getStatusHostedPage(hostedPage: HostedPage){
        return this.$http.
        get<string>(this.url +  'CheckHostedPageStatus/' + hostedPage.Id);
    }

    public getCart(productName: string){
        let product = ""
        if(productName == "Checkbox-SaaS---Basic-USD-Yearly"){
            product = "Checkbox Hosted - Basic Plan 2.0 - Annual Fee per User"
        } else if(productName == "Checkbox-SaaS---Advanced-USD-Yearly"){
            product = "Checkbox Hosted - Advanced Plan 2.0 - Annual Fee per User";
        }
        let products = [];
        products.push({
                          Qty: 1,
                          ProductName: product
                      });
        let postData = {
          CartProductDetails: products
      };
        return this.$http.post<CartInfo>('https://orders.checkbox.com/mvc/api/cart', postData);
    }

    public postUpgrade(cartGuid: string, domain: string, appContextGuid:string){
        let model = {
        BillingInfo: null,
        ShipmentInfo: null,
        CreditCardData: null,
        Domain: domain,
        ApplicationContextGuid: appContextGuid,
        CartGuid: cartGuid,
        IsTaxExempt: false
                  };
      return this.$http.post<CartInfo>(this.url, model);
    }
}
