import { Injectable } from '@angular/core';
import { ResourcesConsts } from '../consts/resources.consts';
import { ContactExportCsvOptions } from '../../infrastructure/models/contact.model';
import { FileExportProvider } from '../../infrastructure/providers/file-export.provider';
import { addQueryString } from '../../infrastructure/helpers/string.helpers';

@Injectable()
export class ExportContactsProvider {
    constructor(private fileExportProvider: FileExportProvider) {}

    exportContacts(options: ContactExportCsvOptions) {
        const query = options.group_ids ?
            addQueryString({
                initialString: '',
                paramName: 'group_ids',
                paramValue: options.group_ids.join(",")
            })
            : '';
        
        const url: string = ResourcesConsts.CONTACTS_CSV_EXPORT + query;
        return this.fileExportProvider.getFileFromBackend(url);
    }
}
