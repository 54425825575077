export interface ContactEmailVerificationTokenIssueResult {
    code_status: ContactEmailVerificationCodeStatus,
    token: string
}

export enum ContactEmailVerificationCodeStatus {
    Valid = 'Valid',
    Invalid = 'Invalid',
    Expired = 'Expired',
    EmailMismatch = 'EmailMismatch',
    Locked = 'Locked'
}

export enum ContactEmailVerificationCase {
    SupportEmailVerification = 'SupportEmailVerification',
    LoginEmailVerification = 'LoginEmailVerification',
    MfaLogin = 'MfaLogin'
}

export enum ContactEmailVerificationStatus {
    Verified = 'Verified',
    NotVerified = 'NotVerified'
}

export interface ContactEmailVerificationSettings {
    expiration_period_in_days: number
}