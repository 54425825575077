import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import {
    ContactGroup,
    ContactGroupList,
    Contact,
    ContactGroupPageRequestOptions
} from '../models';
import { ResourcesConsts } from '../consts/resources.consts';
import {
    ContactGroupModel,
    CreateContactGroupList,
    createContacts
} from '../../infrastructure/models/contact.model';
import { CustomURLEncoder } from '../../infrastructure/helpers/urlEncode.helper';

@Injectable()
export class GroupsProvider {
    constructor(private $http: HttpClient) {}

    loadGroups(
        options: ContactGroupPageRequestOptions
    ): Observable<ContactGroupList> {

        let params = new HttpParams({encoder: new CustomURLEncoder() });
        const defaultOptions = Object.entries(options);
        defaultOptions.forEach(item => {
            params = params.set(item[0], item[1]);
            switch (item[0]) {
                case 'queryString':
                    params = params.set('search_text', item[1]);
                    break;
                case 'pageSize':
                    params = params.set('page_size', item[1]);
                    break;
                case 'pagesNumber':
                    params = params.set('page_num', item[1]);
                    break;
                case 'permission':
                    params = params.set('permission', item[1]);
                    break;
                case 'sourceId':
                    params = params.set('source_id', item[1]);
                    break;
                case 'includeListData':
                    params = params.set('include_list_data', item[1]);
                    break;
                default:
                    break;
            }
        });
        params = params.set('sort_ascending', 'true');
        const url: string = String.Format(ResourcesConsts.GROUPS);
        return this.$http.get(url, { params: params }).pipe(
            map((data: any) => new CreateContactGroupList(data))
        );
    }

    createGroup(newGroup: ContactGroup): Observable<ContactGroup> {
        const url: string = String.Format(ResourcesConsts.GROUPS);
        return this.$http.post(url, newGroup).pipe(
            map((data: any) => new ContactGroupModel(data))
        );
    }

    loadGroupById(groupId: string): Observable<ContactGroup> {
        const url: string = String.Format(
            ResourcesConsts.GROUP_DETAIL,
            groupId
        );
        return this.$http.get(url).pipe(
            map((data: any) => new ContactGroupModel(data))
        );
    }

    removeGroupById(groupId: string): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.GROUP_DETAIL,
            groupId
        );
        return this.$http
            .delete<void>(url);
    }

    batchRemoveGroups(contactGroupIds: string[], removeContacts) {
        const url: string = ResourcesConsts.GROUPS + '/bulk-delete';
        const params = {
            group_ids: contactGroupIds,
            delete_contacts: removeContacts
        };
        return this.$http
            .post(url, params);
    }

    putGroupById(
        groupId: string,
        group: ContactGroup
    ): Observable<ContactGroup> {
        const url: string = String.Format(
            ResourcesConsts.GROUP_DETAIL,
            groupId
        );
        return this.$http.put(url, group).pipe(
            map((data: any) => new ContactGroupModel(data))
        );
    }

    getMembersById(groupId: string): Observable<Contact[]> {
        const url: string = String.Format(
            ResourcesConsts.GROUP_MEMBER,
            groupId
        );
        return this.$http.get(url).pipe(
            map((data: any) => createContacts(data))
        );
    }

    putMembersById(groupId: string, members: string[]): Observable<Contact[]> {
        const url: string = String.Format(
            ResourcesConsts.GROUP_MEMBER,
            groupId
        );
        return this.$http.put(url, members).pipe(
            map((data: any) => createContacts(data))
        );
    }
}
