import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordPayload } from '../../../infrastructure/models/change-password.model';
import { ISecuritySettings } from '../../../infrastructure/models/security-settings.model';
import { Signin } from '../../../infrastructure/models/signin.model';
import { IdentityStore } from '../../../infrastructure/store/identity.store';

@Component({
    selector: 'cb-change-password-page',
    templateUrl: 'change-password-page.component.html'
})

export class ChangePasswordPageComponent implements OnInit {
    signInData: Signin;
    securitySettings: ISecuritySettings;

    constructor(
        private store: IdentityStore,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.signInData = this.router.getCurrentNavigation()?.extras?.state as Signin;
    }

    ngOnInit(): void {
        this.securitySettings = this.route.snapshot.data.securitySettings;
    }

    onPasswordChanged(data: ChangePasswordPayload) {
        this.store.changePassword(data);
    }
}
