import { Injectable } from '@angular/core';
import {
    SurveyQuestionType
} from '../../infrastructure/consts/surveys.consts';
import * as _ from 'lodash';
import { first, map, mergeMap } from 'rxjs/operators';
import { AuthConfigConst } from '../../infrastructure/consts/auth.consts';
import { Guid } from '../../infrastructure/helpers/guid.helper';
import { Observable, of } from 'rxjs';
import { IdentityStore } from '../../infrastructure/store';
import { LocalStorage, Storage } from '../../infrastructure/providers/storage';
import { ActivatedRoute } from '@angular/router';
import { SurveyPage } from '../../infrastructure/models/survey-page.model';
import { getInvitationRecipientId } from '../../infrastructure/helpers/survey-invitation.helper';
import { Response } from '../models';

@Injectable()
export class TakeSurveyService {
    private storage: Storage;
    constructor(private store: IdentityStore, private route: ActivatedRoute) {
        this.storage = new LocalStorage();
    }

    getRespondentKey(): Observable<string> {
        return this.storage
            .getItem(AuthConfigConst.RESPONDENT_KEY)
            .pipe(
                map(key => {
                    if (key) {
                        return key;
                    }

                    const guid = Guid.newGuid();
                    this.storage.setItem(AuthConfigConst.RESPONDENT_KEY, guid);
                    return guid;
                })
            );
    }

    getAnonymousRespondentId(): Observable<string> {
        return this.store.isLoggedIn()
            .pipe(
                mergeMap(isLoggedIn => {
                    if (isLoggedIn) {
                        return of(null);
                    }

                    const invitationToken = this.route.snapshot.queryParams['i'];
                    const recipientId = invitationToken
                        ? getInvitationRecipientId(invitationToken)
                        : null;
                    return recipientId ? of(recipientId) : this.getRespondentKey();
                }),
                first()
            );
    }

    calculatePageScore(currentPage: SurveyPage, response: Response) {
        currentPage.points = response.pages.find(page => page.page_id === currentPage.id)?.page_score ?? 0;
    }

    orderItemChoices(item) {
        if (!item.data) {
            return item;
        }

        if (item.item_type === SurveyQuestionType.MAXDIFF) {
            item.dataSets = item.data.sets;
            return item;
        }

        if (item.choices && item.data.choices) {
            item.choices.forEach(ch => {
                const choicePosition = item.data.choices.indexOf(ch.id);
                // prevent resetting of choice position for rank order item
                if (ch.original_position == null && choicePosition >= 0) {
                    ch.position = choicePosition + 1;
                }
            });
            item.choices = _.sortBy(item.choices, 'position');
            // normalize position
            item.choices.forEach((ch, i) => { ch.position = i + 1; });
        }
        _.unset(item, 'data');
        return item;
    }
}
