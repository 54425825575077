import { SurveysSettingProvider } from '../../infrastructure/providers/surveys-setting.provider';
import {
    AutocompleteListProvider,
    DashboardsProvider,
    StyleTemplatesProvider,
    SurveyEditorProvider,
    SurveyExpressionsProvider,
    SurveyInvitationProvider,
    SurveyPagesProvider,
    SurveyResponseProvider,
    SurveysProvider,
    UsersProvider,
    UserProvider,
    ExternalLoginProvider
} from '../../app-admin/providers';
import { ApplicationSettingsProvider } from '../../app-admin/settings/application-settings/application-settings.provider';
import { TakeSurveyProvider } from '../../infrastructure/providers/take-survey.provider';
import { StorageProvider } from '../../infrastructure/providers';
import { LoginProvider } from '../../infrastructure/providers/login.provider';
import { ResponseDataResolver } from './response-data.resolver';
import { FileExportProvider } from '../../infrastructure/providers/file-export.provider';
import { DashboardExpressionsProvider } from '../../infrastructure/providers/dashboards-expressions/dashboard-expressions.provider';
import { ResetPasswordProvider } from '../../infrastructure/providers/reset-password.provider';

export const dataProviders = [
    SurveyPagesProvider,
    SurveyEditorProvider,
    AutocompleteListProvider,
    TakeSurveyProvider,
    SurveysSettingProvider,
    ApplicationSettingsProvider,
    SurveysProvider,
    UsersProvider,
    StorageProvider,
    LoginProvider,
    StyleTemplatesProvider,
    SurveyResponseProvider,
    SurveyInvitationProvider,
    ResponseDataResolver,
    DashboardsProvider,
    FileExportProvider,
    SurveyExpressionsProvider,
    DashboardExpressionsProvider,
    ResetPasswordProvider,
    UserProvider,
    ExternalLoginProvider
];

export {
    SurveyPagesProvider,
    SurveyEditorProvider,
    AutocompleteListProvider,
    TakeSurveyProvider,
    ApplicationSettingsProvider,
    SurveysSettingProvider,
    SurveysProvider,
    UsersProvider,
    StorageProvider,
    LoginProvider,
    SurveyExpressionsProvider,
    FileExportProvider,
    ResetPasswordProvider,
    UserProvider
};
