import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { map } from 'rxjs/operators';
import { Identity } from '../models';
import { IdentityStore } from '../store';
import { UserRoles } from '../consts/auth.consts';
import * as _ from 'lodash';

@Injectable()
export class AccountSettingsGuard implements CanActivate {
    constructor(private store: IdentityStore, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.identity.pipe(
            map((identity: Identity) => {
                if (
                    identity.roles &&
                    _.includes(identity.roles, UserRoles.SYSTEM_ADMINISTRATOR)
                ) {
                    return true;
                } else {
                    this.router.navigate(['404']);
                    return false;
                }
            })
        );
    }
}
