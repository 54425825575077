import { State, initialState } from './layout.state';
import * as actions from '../actions';

export function layoutReducer(
    state = initialState,
    action: actions.layout.Actions
): State {
    switch (action.type) {
        case actions.layout.OPEN_CLOSE_SIDENAV:
            return Object.assign({}, state, {
                isLoading: state.isLoading,
                sidenavOpened: !state.sidenavOpened
            });
        case actions.layout.SHOW_PROGRESS:
            return Object.assign({}, state, {
                isLoading: true,
                sidenavOpened: state.sidenavOpened
            });
        case actions.layout.HIDE_PROGRESS:
            return Object.assign({}, state, {
                isLoading: false,
                sidenavOpened: state.sidenavOpened
            });
        case actions.layout.CHANGE_PRINT_MODE:
            return Object.assign({}, state, {
                printMode: action.payload
            });
        default:
            return state;
    }
}
