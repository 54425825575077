import { Component, Inject, NgZone, HostListener, ElementRef, Renderer2, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'cb-custom-mat-snack-bar',
    templateUrl: 'custom-mat-snack-bar.component.html',
    styleUrls: ['custom-mat-snack-bar.component.scss']
})

export class CustomMatSnackBarComponent {

    public contentEventEmitter = new EventEmitter<any>();
    public tagWhiteList = {
        a: ['data-eventid']
    };
    public none = 'none';
    public outline = 'outline';
    public filled = 'fill';
    public success = 'success';
    public error = 'error';
    public warning = 'warning';
    public info = 'info';
    private validStyles = [this.none, this.outline, this.filled];
    private validTypes = [this.success, this.error, this.warning, this.info];
    public currentStyle: string = this.none;
    public snackBarShowData = {}
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.data.hideCopyBtn && !this.eRef.nativeElement.contains(event.target)) {
            this.dismiss();
        }
    }
    
    constructor(
        private eRef: ElementRef,
        private renderer: Renderer2,
        public snackBarRef: MatSnackBarRef<CustomMatSnackBarComponent>,
        private readonly zone: NgZone,
        private _clipboardService: ClipboardService,
        @Inject(MAT_SNACK_BAR_DATA) public data: any) {
            this.currentStyle = this.validStylesOrDefault(data.style);
            renderer.listen(eRef.nativeElement, 'click', (event) => {
                var id = event.target.getAttribute('data-eventid');       
                if (id) {
                    this.contentEventEmitter.emit(id);
                }
                
                return false;
            });
        this.snackBarShowData[this.filled] = {}
        this.snackBarShowData[this.outline] = {}
        this.snackBarShowData[this.filled][this.success] ={
            icon : 'fi_check_circle_outline',
            captionKey: 'SHARED.SUCCESS',
            color: ''
        }
        this.snackBarShowData[this.filled][this.error] ={
            icon : 'fi_close_circle_outline',
            captionKey: 'SHARED.CRITICAL',
            color: ''
        }
        this.snackBarShowData[this.filled][this.warning] ={
            icon : 'fi_alert_triangle_outline',
            captionKey: 'SHARED.WARNING',
            color: ''
        }
        this.snackBarShowData[this.filled][this.info] ={
            icon : 'fi_info_outline',
            captionKey: 'SHARED.INFORMATION',
            color: ''
        }
        this.snackBarShowData[this.outline][this.success] ={
            icon : 'fi_check_circle_filled',
            captionKey: 'SHARED.SUCCESS',
            color: 'success'
        }
        this.snackBarShowData[this.outline][this.error] ={
            icon : 'fi_close_circle_filled',
            captionKey: 'SHARED.CRITICAL',
            color: 'warn'
        }
        this.snackBarShowData[this.outline][this.warning] ={
            icon : 'fi_alert_triangle_filled',
            captionKey: 'SHARED.WARNING',
            color: 'warning'
        }
        this.snackBarShowData[this.outline][this.info] ={
            icon : 'fi_info_filled',
            captionKey: 'SHARED.INFORMATION',
            color: 'accent'
        }
    }

    dismiss() {
        this.zone.run(() => {
                this.snackBarRef.dismiss();
            }
        );
    }
    dismissWithAction() {
        this.zone.run(() => {
                this.snackBarRef.dismissWithAction();
            }
        );
    }
    copyToClipboard() {
        this._clipboardService.copyFromContent(this.data.fullErrorMessage);
    }
    private validStylesOrDefault(type: string): string {
        return this.validStyles.includes(type) ? type : 'none';
    }
}
