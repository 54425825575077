import { ResponseItemSaveData } from './response-item-save-data.model';

export interface ResponsePagePostData {
    page_id: number;
    action: ResponsePageAction;
    items: ResponseItemSaveData[];
}

export enum ResponsePageAction {
    Save = 'Save',
    MoveForward = 'MoveForward',
    MoveBackward = 'MoveBackward',
    UpdateConditions = 'UpdateConditions'
}
