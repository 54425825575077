import { mergeMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, from as fromPromise } from 'rxjs';
import { CrossStorageClient, CrossStorageClientOptions } from 'cross-storage';
import { environment } from '../../environments/environment';

export interface Storage {
    checkConnect?: () => Observable<boolean>;
    getItem(key: string): Observable<string>;
    setItem(key: string, value: string): Observable<boolean>;
    clearItem(key: string): Observable<boolean>;
    clearItems(keys: string[]): Observable<boolean>;
    onChange(fn: (data) => void): void;
}

@Injectable()
export class LocalStorage implements Storage {
    private fn: (data) => void;

    public getItem(key: string): Observable<string> {
        return of(localStorage.getItem(key)).pipe(map(jwtToken => jwtToken));
    }

    public setItem(key: string, value: string): Observable<boolean> {
        localStorage.setItem(key, value);
        return of(true);
    }

    public clearItem(key: string): Observable<boolean> {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
            return of(true);
        }
        return of(false);
    }

    public clearItems(keys: string[]): Observable<boolean> {
        for (const idx in keys) {
            if (keys.hasOwnProperty(idx)) {
                const key = keys[idx];
                if (localStorage.getItem(key)) {
                    localStorage.removeItem(key);
                }
            }
        }
        return of(true);
    }

    public onChange(fn: (data) => void) {
        this.fn = fn;
        if (window.addEventListener && this.fn) {
            window.addEventListener('storage', this.fn, false);
        }
    }
}

class ExCrossStorageClientOptions implements CrossStorageClientOptions {
    timeout?: number;
    promise?: any;
    frameId?: string;
    storageChanged?: any;
}

export class RemoteStorage extends LocalStorage {
    private storage: CrossStorageClient;

    constructor() {
        super();
        const opt = new ExCrossStorageClientOptions();
        const storageHubUrl =
            (window['__env'] && window['__env'].storageHubUrl) ||
            environment.storageHubUrl;
        opt.timeout = 10000;
        this.storage = new CrossStorageClient(storageHubUrl, opt);
    }

    public getItem(key: string): Observable<string> {
        if (localStorage.getItem(key)) {
            return of(localStorage.getItem(key)).pipe(
                map(jwtToken => jwtToken)
            );
        } else {
            return fromPromise(this.storage.onConnect()).pipe(
                mergeMap(() => {
                    return fromPromise(
                        this.storage.get(key).then(item => {
                            localStorage.setItem(key, item);
                            return item;
                        })
                    );
                })
            );
        }
    }

    public checkConnect() {
        return fromPromise(this.storage.onConnect().then(() => true));
    }
}
