import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { ApplicationError } from '../models';
import { ResourcesConsts } from '../consts/resources.const';
import * as _ from 'lodash';

@Injectable()
export class ErrorProvider {
    constructor(private $http: HttpClient) {}

    offlineErrorsList: any[] = [];

    get hasOfflineErrors() {
        return !!this.offlineErrorsList.length;
    }

    logError(error: ApplicationError): Observable<ApplicationError> {
        const url: string = ResourcesConsts.LOG;
        return this.$http
            .post<ApplicationError>(url, error);
    }

    sendOfflineErrors() {
        const errorReqList = _.map(this.offlineErrorsList, err => this.logError(err));
        this.offlineErrorsList = [];
        return forkJoin(errorReqList);
    }
}
