import * as _ from "lodash";
import { ItemOption } from "../../shared/models/survey-items/question-items/itemOption";

export function getFilters(searchText, pageNum, pageSize, statusFilter, sortBy?, sortOrder?, dateFrom?, dateTo?) {
    return {
        searchText: searchText,
        pageNum: pageNum,
        pageSize: pageSize,
        statusFilter: statusFilter,
        sortBy: sortBy,
        sortOrder: sortOrder,
        dateFrom: dateFrom,
        dateTo: dateTo
    };
}

export function getQuestionItemInnerCssClass(questionItem: any): string {
    const captionPositionClass = `cb-question-item-caption-position--${questionItem.question_text_position.toLowerCase()}`;
    const itemPositionClass = `cb-question-item-position--${questionItem.item_position.toLowerCase()}`;
    const itemLayoutClass = questionItem.layout
        ? `cb-item-layout--${questionItem.layout.toLowerCase()}`
        : '';
    return (
        'cb-question-item-wrapper-inner ' +
        captionPositionClass +
        ' ' +
        itemPositionClass +
        ' ' +
        itemLayoutClass
    );
}

export function getEnabledRegularChoices(choices: ItemOption[]): ItemOption[] {
    if (!choices)
        return [];

    return _.filter(
        choices,
        (choice: ItemOption): boolean => {
            return !choice.is_other && !choice.is_none_of_above && (choice.enabled == null || choice.enabled);
        }
    );
}

export function getEnabledChoices(choices: ItemOption[]): ItemOption[] {
    if (!choices)
        return [];

    return _.filter(
        choices,
        (choice: ItemOption): boolean => {
            return choice.enabled == null || choice.enabled;
        }
    );
}


