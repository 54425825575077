/* put here app specific services */
import { TakeSurveyFlowService } from './take-survey-flow.service';
import { TakeSurveyService } from './take-survey.service';
import { TakeSurveyPipingService } from '../../infrastructure/services/take-survey-piping.service';
import { SurveyResponseRestoreService } from './survey-response-restore.service';
import { SurveyResponsePostDataHelper } from './survey-response-post-data-helper.service';
import { TakeSurveyErrorsService } from './take-survey-errors.service';
import { sharedServices } from '../../shared/services/index';

export const services = [
        ...sharedServices,
    TakeSurveyFlowService,
    TakeSurveyService,
    TakeSurveyPipingService,
    SurveyResponseRestoreService,
    SurveyResponsePostDataHelper,
    TakeSurveyErrorsService,
];
