export interface DashboardFilter {
    id: number;
    value: string;
    operator: FilterOperator;
    filter_type: string;
    parentId: number;
}

export interface DashboardFilterSaveData {
    id?: number;
    value: string;
    operator: FilterOperator;
    filter_type: DashboardFilterType;
    choice?: any;
}

export interface DashboardFilterGroupSaveData {
    id?: number;
    children_filter_groups: Array<DashboardFilterGroupSaveData>;
    logical_operator: FilterGroupOperator;
    filters: Array<DashboardFilterSaveData>;
}

export interface SurveyItemDashboardFilter extends DashboardFilter {
    survey: SurveyOverview;
    survey_item: SurveyItemOverview;
}

export interface SurveyItemDashboardFilterSaveData
    extends DashboardFilterSaveData {
    survey_id: number;
    survey_item_id: number;
    survey?: any;
    survey_item?: any;
}

export interface SurveyOverview {
    id: number;
    name: string;
}

export interface SurveyItemOverview {
    id: number;
    question_text: string;
}

export interface SurveyResponseDashboardFilter extends DashboardFilter {
    response_property: string;
}

export interface SurveyResponseDashboardFilterSaveData
    extends DashboardFilterSaveData {
    response_property: string;
}

export interface ContactProfileDashboardFilter extends DashboardFilter {
    contact_profile_property_name: string;
}

export interface ContactProfileDashboardFilterSaveData
    extends DashboardFilterSaveData {
    contact_profile_property_name: string;
}

export interface CsvColumnFilter extends DashboardFilter {
    column_id: number;
}

export interface CsvColumnFilterSaveData extends DashboardFilter {
    column_id: number;
}

export interface DashboardItemLiveFilter {
    operator: string;
    source_item: number;
    source_type: string;
    survey_id?: string;
    target_value: string | number | Date;
}

export enum FilterOperator {
    Equal,
    NotEqual,
    GreaterThan,
    GreaterThanEqual,
    LessThan,
    LessThanEqual,
    Contains,
    DoesNotContain,
    IsNull,
    IsNotNull,
    Answered,
    NotAnswered
}

export enum FilterGroupOperator {
    Or = 'Or',
    And = 'And'
}

export enum DashboardFilterType {
    ContactProfile = 'ContactProfile',
    SurveyItem = 'SurveyItem',
    SurveyResponse = 'SurveyResponse'
}

export enum DashboardExpressionBuilderFilterType {
    ContactProfile = 'profileProperty',
    SurveyItem = 'surveyItem',
    SurveyResponse = 'responseProperty'
}

export interface ReportDateFilter {
    dateFilterStart: string;
    dateFilterEnd: string;
}
