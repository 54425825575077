export interface ApplicationError {
    code: number;
    message: string;
    stack_trace: any;
}

export class ClientUnexpectedError implements ApplicationError {
    constructor(public message: string, public stack_trace: string) {
        this.code = 1;
    }
    public code: number;
}

export class ServerError implements ApplicationError {
    constructor(
        public code: number,
        public message: string,
        public errors: any[]
    ) {}

    public stack_trace = '';
    public notify = true;
    public notificationType = 'info';
}
