import * as _ from 'lodash';
import { ItemsWithOptions } from '../../../shared/models/survey-items/question-items/itemsWithOptions';
import { ItemOption } from '../../../shared/models/survey-items/question-items/itemOption';
import { CheckboxesItem } from '../../../shared/models/survey-items/question-items/checkboxesItem';
import { SurveyQuestionType } from '../../../infrastructure/consts/surveys.consts';

export class DataTransformer {
    static transformToSend(model: ItemsWithOptions) {
        if (!model.choices) {
            model.choices = [];
        }

        DataTransformer.transformItemToSend<ItemsWithOptions>({
            model: model,
            testField: 'allow_other',
            valueField: 'other_value',
            flagField: 'is_other',
            defaultValue: 'Other:'
        });

        if (model.item_type === SurveyQuestionType.CHECKBOXES) {
            DataTransformer.transformItemToSend<CheckboxesItem>({
                model: <CheckboxesItem>model,
                testField: 'allow_none_of_above',
                valueField: 'none_value',
                flagField: 'is_none_of_above',
                defaultValue: 'None Of The Above'
            });
        }
    }

    static transformFromReceived(model: ItemsWithOptions) {
        if (!model.choices) {
            model.choices = [];
        }
        DataTransformer.transformItemFromReceived<ItemsWithOptions>({
            model: model,
            testField: 'allow_other',
            valueField: 'other_value',
            flagField: 'is_other'
        });

        if (model.item_type === SurveyQuestionType.CHECKBOXES) {
            DataTransformer.transformItemFromReceived<CheckboxesItem>({
                model: <CheckboxesItem>model,
                testField: 'allow_none_of_above',
                valueField: 'none_value',
                flagField: 'is_none_of_above'
            });
        }
    }

    static transformItemToSend<T>(options: {
        model: T;
        testField: string;
        valueField: string;
        flagField: string;
        defaultValue: string;
    }) {
        const {
            model,
            testField,
            valueField,
            flagField,
            defaultValue
        } = options;
        const searchIndex = _.findIndex(
            model['choices'],
            item => item[flagField]
        );

        // check options adding
        if (model[testField]) {
            if (searchIndex === -1) {
                // Add new choice
                const newChoiceItem: ItemOption = {
                    is_default: false,
                    is_none_of_above: flagField === 'is_none_of_above',
                    is_other: flagField === 'is_other',
                    position: model['choices'].length,
                    text: model[valueField] || defaultValue
                };
                model['choices'].push(newChoiceItem);
            } else {
                // replace it
                model['choices'][searchIndex].text =
                    model[valueField] || defaultValue;
            }
        }
        // check options removing
        if (!model[testField]) {
            if (searchIndex !== -1) {
                model['choices'].splice(searchIndex, 1);
            }
        }
    }

    static transformItemFromReceived<T>(options: {
        model: T;
        testField: string;
        valueField: string;
        flagField: string;
    }): void {
        const { model, testField, valueField, flagField } = options;

        if (model[testField]) {
            const searchIndex = _.findIndex(model['choices'], {
                [flagField]: true
            });
            if (searchIndex !== -1) {
                model[valueField] = model['choices'][searchIndex].text;
            }
        }
    }
}
