import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SurveyDefaultText } from '../../infrastructure/consts/surveyDefaultText';

@Injectable()
export class SharedService {
    private source$ = new BehaviorSubject<any>({});
    public message$ = this.source$.asObservable();
    public surveyText = new BehaviorSubject(SurveyDefaultText);
    public validationText = new BehaviorSubject({});
    public surveyInfo = new BehaviorSubject({});
    public captchaKeys = new BehaviorSubject({});
    public surveyRespondentSettings = new BehaviorSubject(null);
    constructor() {}

    share(type: string, data: any = {}) {
        const inputData = { ...{ type }, ...{ data } };
        this.source$.next(inputData);
    }

    getData(name) {
        return this.message$.pipe(filter(c => c.type === name));
    }
}
