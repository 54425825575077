export class ResourcesConsts {
    public static RESPONSE = '/{account}/surveys/{0}/responses';
    public static POST_RESPONSE =
        '/{account}/surveys/{0}/responses/{1}/current-page';
    public static RESPONSE_LANGUAGE =
        '/{account}/surveys/{0}/responses/{1}/language';
    public static GET_CAPCTHA_SITE_KEYS =
        '/{account}/application-settings/captcha/site-keys';
    public static UPLOAD_RESPONDENT_FILE = '/{account}/surveys/{0}/responses/{1}/items/{2}/files';
    public static GET_RESPONDENT_FILE = '/{account}/surveys/{0}/responses/{1}/files/{2}/content';
    public static GET_SURVEY_APPEARANCE_SETTINGS =
        '/{account}/surveys/{0}/appearance-settings';
    public static REPORTS = '/{account}/reports';
    public static GET_RESPONSE = '/{account}/surveys/{0}/responses/{1}';
    public static APPLICATION_SETTINGS_VALIDATION_TEXT =
        '/{account}/application-settings/texts/validation-message/{0}';
    public static MY_SURVEYS = '/{account}/my-surveys';
    public static CONTACT_LANGUAGE = '/{account}/contacts/me/application-language';
}
