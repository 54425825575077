import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cb-main-dialog',
  templateUrl: './main-dialog.component.html',
  styleUrls: ['./main-dialog.component.scss']
})
export class MainDialogComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() texts: string[];
  @Input() mainButton: string;
  @Input() cancelButton: string;
  @Input() disabled = false;
  @Output() onMain = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  constructor() { }
}
