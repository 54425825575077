import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elipsis'
})
export class ElipsisPipe implements PipeTransform {

  transform(value: string, lenth: number): string {
    const sufixRange = 8;
    if (!value) {
      return '';
    }
    return value.length > lenth ? `${value.substring(0, lenth-3-sufixRange)}...${value.substring(value.length-sufixRange, value.length)}` : value;
  }

}
