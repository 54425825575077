export class ResourcesConsts {
    public static LOG = '/{account}/client-errors';
    public static SIGNIN = '/{account}/oauth2/token';
    public static ACCOUNT = '/hosts/{host}/account-name';
    public static SIGNUP = '/{account}/users';
    public static SIGNOUT = '/{account}/signout';
    public static RECIPIENT_LOGIN = '/{account}/recipient-login';
    public static PROFILE_SETTINGS =
        '/{account}/application-settings/contact/profile-properties';
    public static REGISTRATION_SETTINGS =
        '/{account}/application-settings/contact/registration';
    public static CURRENT_USER_INFO =
        '/{account}/contacts/me/info';
    public static RESET_PASSWORD = '/{account}/contacts/reset-password';
    public static CSV_CONTACTS = 'Everyone_csvExport.csv';
    public static GET_SECURITY_SETTINGS =
        '/{account}/application-settings/contact/security';
    public static RESET_PASSWORD_SEND_EMAIL =
        '/{account}/contacts/{0}/reset-password/send-email';
    public static RESET_PASSWORD_GET_USER =
        '/{account}/contacts/reset-password/find-contact?token={0}';
    public static RESET_PASSWORD_CREATE_NEW =
        '/{account}/contacts/reset-password/set-new-password';
    public static CHECK_CONCURRENT_USERS =
        '/{account}/contacts/{0}/active-session/{1}';
    public static SIGNOUT_ME =
        '/{account}/contacts/me/logout';
    public static CHECK_TOKEN = '/{account}/token-info';
    public static CHANGE_PASSWORD = '/{account}/contacts/{0}/change-password';

    public static GOOGLE_SIGNIN = 'https://accounts.google.com/o/oauth2/v2/auth';
}
