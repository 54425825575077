import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Identity } from '../../../infrastructure/models';
import { IdentityStore } from '../../../infrastructure/store';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { ResetPasswordProvider } from '../../providers';

@Component({
    selector: 'cb-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss']
})
@AutoUnsubscribe()
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
    public identity: Observable<Identity>;

    public sendEmail = true;
    public resetPassword = false;
    public showNotification = false;
    public message: string;
    public account: string;
    public notificationClass: string;
    public user: object;
    public emailSent: boolean;
    public brokenToken: boolean;
    private token: string;
    public isLoading: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private store: IdentityStore,
        private route: ActivatedRoute,
        private $resetPasswordProvider: ResetPasswordProvider
    ) {
        this.identity = this.store.identity;
    }

    ngOnInit() {
        this.identity.subscribe(i => {
            if (i && i.access_token) {
                this.store.goToRedirectUri(i);
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params.token) {
                this.sendEmail = false;
                this.token = params.token;
                this.checkAccount(params.token);
            }
        });
    }

    sendMail($event) {
        const data = {
            contact_id: $event.contactid,
            is_admin_site: false
        };
        this.$resetPasswordProvider.sendEmailForResetPassword(data)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                result => {
                    this.emailSent = true;
                    this.brokenToken = false;
                },
                error => {
                    this.emailSent = true;
                    this.brokenToken = false;
                }
            );
    }

    checkAccount(token) {
        this.isLoading = true;
        this.$resetPasswordProvider.getUserByToken(token)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                result => {
                    this.user = result;
                    this.resetPassword = true;
                    this.isLoading = false;
                },
                error => {
                    this.resetPassword = false;
                    this.brokenToken = true;
                    this.sendEmail = true;
                    this.isLoading = false;
            }
        );
    }

    resetMail($event) {
        const message = `Password successfully updated. Click "BACK TO LOGIN" link below to login.`;
        const data = {
            new_password: $event.passwordConfirmation,
            token: this.token
        };
        this.$resetPasswordProvider.sendNewPassword(data)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                result => {
                    this.resetPassword = false;
                    this.showNotification = true;
                    this.message = message;
                },
                error => {
                    this.resetPassword = false;
                    this.showNotification = true;
                    this.message = 'Error on update password';
                }
        );
    }

    ngOnDestroy() {}
}
