export class MoveSurveyItemData {
    survey_id: number;
    item_id: number;
    page_id: number;
    position: number;
}

export class MoveReportItemData {
    report_id: number;
    item_id: number;
    page_id: number;
    position: number;
}
