import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../consts/resources.const';
import { HttpClient } from '@angular/common/http';
import { CreateProfilePropertyModel } from '../../app-admin/models';
import { createContactProfiles } from '../models/contact.model';

@Injectable()
export class AppConfigService {
    public emailMode: string;
    public profileConfig: CreateProfilePropertyModel[];

    constructor(private $http: HttpClient) {}

    loadContactProfileProperty(): Observable<CreateProfilePropertyModel[]> {
        const url: string = String.Format(ResourcesConsts.PROFILE_SETTINGS);
        return this.$http.get(url).pipe(
            map((data: any) => {
                const contactProperties = data.map(item => {
                    return {
                        property_id: item.id,
                        property_name: item.name,
                        is_hidden: item.is_hidden,
                        value: ''
                    };
                });
                return createContactProfiles(contactProperties);
            })
        );
    }

    getConfig() {
        this.loadContactProfileProperty().subscribe(
            (data: CreateProfilePropertyModel[]) => {
                this.profileConfig = data;
            }
        );
    }

    public getProfileConfig() {
        return this.profileConfig || [];
    }
}
