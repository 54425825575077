import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthConfigConst } from '../consts/auth.consts';

@Injectable({
    providedIn: 'root',
})
export class AccountNameResolverProvider {
    private resolvedAccountName:string;
    constructor() {}

    getTakeSurveyAccount(): Observable<string> {
        if (!this.resolvedAccountName)
            this.resolvedAccountName = localStorage.getItem(AuthConfigConst.TAKE_SURVEY_KEY);

        return of(this.resolvedAccountName);
    }

    saveTakeSurveyAccount(account: string): Observable<boolean> {
        this.resolvedAccountName = account;
        localStorage.setItem(AuthConfigConst.TAKE_SURVEY_KEY, account);
        return of(true);
    }
}
