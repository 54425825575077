import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

export class MinLengthAsyncValidator {
    static validate(
        minLength: number
    ): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value) {
                return null;
            }

            const valid = control.value.length >= minLength;
            return valid ? of(null) : of({ minLength: true });
        };
    }
}
