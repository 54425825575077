import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

export class RegexAsyncValidator {
    static validate(
        regex: RegExp,
        error: ValidationErrors
    ): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value) {
                return null;
            }

            const valid = regex.test(control.value);
            return valid ? of(null) : of(error);
        };
    }
}
