import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throwError } from 'rxjs';

@Injectable()
export class RequestCacheService {
    cache = new Map();
    observerCache = {};
    maxAge = 50;

    reDuplicate(url, requestObservable: Observable<any>) {
        const expired = Date.now() - this.maxAge;
        if (this.cache.size) {
            this.cache.forEach(expiredEntry => {
                if (
                    expiredEntry.lastRead < expired &&
                    expiredEntry.setResponse
                ) {
                    this.cache.delete(expiredEntry.url);
                    delete this.observerCache[url];
                }
            });
        }
        const cached = this.cache.get(url);
        if (!cached) {
            this.observerCache[url] = new Subject();
            const entry = { url, lastRead: Date.now() };
            this.cache.set(url, entry);
            return requestObservable.pipe(
                map(data => {
                    this.observerCache[url].next(data);
                    this.cache.get(url).setResponse = true;
                    return data;
                }),
                catchError(err => {
                    this.cache.delete(url);
                    return throwError(err);
                })
            );
        }
        return this.observerCache[url];
    }
}
