import { Pipe, PipeTransform } from '@angular/core';
import { transformToCloudfrontUrl } from '../../infrastructure/helpers/cloudfront.helper';

@Pipe({
    name: 'assetspipe'
})
export class AssetsPipe implements PipeTransform {

    constructor() {
    }

    transform(url: string) {
        return transformToCloudfrontUrl(url);
    }
}
