export interface ToggleItem {
    default: string;
    on: string;
    off: string;
}

export const TOGGLE_VALUES = {
    on: { name: 'on', value: true },
    off: { name: 'off', value: false }
};
