import { first, map, mergeMap } from 'rxjs/operators';
import { DashboardItem, DashboardItemSaveData } from '../../../app-admin/models/dashboards/dashboard-item.model';
import { DisplayItem } from '../../../app-admin/models/display-item.model';
import { MatrixItem } from '../../../shared/models/survey-items/question-items/matrix';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import { PagedList } from '../../models';
import {
    ChartAnalytics,
    Dashboard,
    DashboardCreateData,
    DashboardGlobalFilters,
    DashboardListItem,
    DashboardPage,
    DashboardSettings,
    MoveReportItemData,
    SurveyListGetData
} from '../../../app-admin/models';
import { ResourcesConsts } from '../../../app-admin/consts/resources.consts';
import { DashboardPageReorderData, DashboardPageSaveData } from '../../../app-admin/models/dashboards/dashboard-page.model';
import { FileExportProvider } from '../file-export.provider';
import { CustomURLEncoder } from '../../../infrastructure/helpers/urlEncode.helper';
import { SkipInterceptor } from '../../consts/interceptors.const';
import { ToasterService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ExportType } from '../../../../src/app-admin/downloads/download-item.model';

@Injectable()
export class DashboardsProvider {
    constructor(
        private $http: HttpClient,
        private fileExportProvider: FileExportProvider,
        private toasterService: ToasterService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {}

    addDashboard(model: DashboardCreateData): Observable<Dashboard> {
        const url: string = String.Format(ResourcesConsts.CREATE_DASHBOARD);
        return this.$http
            .post<Dashboard>(url, model);
    }

    getReports(params) {
        const url: string = ResourcesConsts.CREATE_DASHBOARD;
        return this.$http
            .get<PagedList<any>>(url, { params: params });
    }

    loadDashboards(
        getData: SurveyListGetData
    ): Observable<PagedList<DashboardListItem>> {
        const url: string = ResourcesConsts.DASHBOARD;
        const params = new HttpParams({encoder: new CustomURLEncoder() })
            .set(
                'folder_id',
                getData.folder_id ? getData.folder_id.toString() : ''
            )
            .set('search_text', getData.search_text)
            .set('page_num', getData.paging_info.page_num.toString())
            .set('page_size', getData.paging_info.page_size.toString())
            .set('report_type', 'Dashboard')
            .set('status', getData.status_filter);
        return this.$http
            .get<PagedList<DashboardListItem>>(url, { params: params });
    }

    loadDashboardPages(dashboardId: number): Observable<DashboardPage[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_PAGES,
            dashboardId
        );
        return this.$http
            .get<DashboardPage[]>(url);
    }

    loadDashboardPageItems(
        dashboardId: number,
        pageId: number
    ): Observable<DashboardItem[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_ITEMS,
            dashboardId,
            pageId
        );
        return this.$http
            .get<DashboardItem[]>(url);
    }

    loadDashboardById(dashboardId: number): Observable<Dashboard> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_BY_ID,
            dashboardId
        );
        return this.$http
            .get<Dashboard>(url);
    }

    loadDashboardsBySurveyId(
        surveyId: string
    ): Observable<PagedList<DashboardListItem>> {
        const url: string = String.Format(ResourcesConsts.DASHBOARD);
        const params = new HttpParams().set('survey_id', surveyId);
        return this.$http
            .get<PagedList<DashboardListItem>>(url, { params: params });
    }

    loadSurveyQuestionsBySurveyId(
        surveyId: number
    ): Observable<DisplayItem[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_ITEM_SURVEY_QUESTIONS,
            surveyId
        );
        const params = new HttpParams()
            .set('language_code', '');
        return this.$http
            .get<DisplayItem[]>(url, { params: params });
    }

    loadDashboardFilterSources(surveyId: number): Observable<DisplayItem[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_ITEM_FILTER_SOURCES,
            surveyId
        );
        const params = new HttpParams().set('language_code', '');
        return this.$http
            .get<DisplayItem[]>(url, { params: params });
    }

    updateDashboardById(
        dashboardId: number,
        model: Dashboard
    ): Observable<Dashboard> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_BY_ID,
            dashboardId
        );
        return this.$http
            .put<Dashboard>(url, model);
    }

    loadDashboardDateFilter(
        dashboardId: number
    ): Observable<DashboardGlobalFilters> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_DATE_FILTER,
            dashboardId
        );
        return this.$http
            .get<DashboardGlobalFilters>(url);
    }

    loadDataForDashboardItemChart(
        dashboardId: number,
        itemId: number,
        model: DashboardGlobalFilters
    ): Observable<ChartAnalytics[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_ITEM_CHART_DATA,
            dashboardId,
            itemId
        );
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .post<ChartAnalytics[]>(url, model, {headers: headers});
    }

    loadDashboardCompatibleResponseProperties(): Observable<string[]> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_COMPATIBLE_RESPONSE_PROPERTIES
        );
        return this.$http
            .get<string[]>(url);
    }

    reorderDashboardPage(
        dashboardId: number,
        pageId: number,
        model: DashboardPageReorderData
    ): Observable<null> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_PAGE_REORDER_DATA,
            dashboardId,
            pageId
        );
        return this.$http
            .put<null>(url, model);
    }

    addDashboardPage(
        dashboardId: number,
        model: DashboardPageSaveData
    ): Observable<DashboardPage> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_PAGES,
            dashboardId
        );
        return this.$http
            .post<DashboardPage>(url, model);
    }

    deleteDashboardPage(dashboardId: number, pageId: number): Observable<null> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_PAGE,
            dashboardId,
            pageId
        );
        return this.$http
            .delete<null>(url);
    }

    updateDashboardPage(
        dashboardId: number,
        pageId: number,
        model: DashboardPageSaveData
    ): Observable<DashboardPage> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_PAGE,
            dashboardId,
            pageId
        );
        return this.$http
            .put<DashboardPage>(url, model);
    }

    loadAllSurveys(): Observable<any[]> {
        const params = new HttpParams()
            .set('granted_permission_list', 'AnalyzeData');
        const url: string = String.Format(ResourcesConsts.SURVEYS);
        return this.$http.get(url, {params}).pipe(
            map((x: any) => x.items)
        );
    }

    createDashboardItem(
        dashboardId: number,
        model: DashboardItemSaveData
    ): Observable<DashboardItem> {
        const url: string = String.Format(
            ResourcesConsts.CREATE_DASHBOARD_ITEM,
            dashboardId
        );
        return this.$http
            .post<DashboardItem>(url, model);
    }

    deleteDashboardItem(dashboardId: number, itemId: number): Observable<null> {
        const url: string = String.Format(
            ResourcesConsts.DELETE_DASHBOARD_ITEM,
            dashboardId,
            itemId
        );
        return this.$http
            .delete<null>(url);
    }

    updateDashboardItem(
        dashboardId: number,
        itemId: number,
        model: DashboardItemSaveData
    ): Observable<DashboardItem> {
        const url: string = String.Format(
            ResourcesConsts.DELETE_DASHBOARD_ITEM,
            dashboardId,
            itemId
        );
        return this.$http
            .put<DashboardItem>(url, model);
    }

    updateDashboardItemVisibility(
        dashboardId: number,
        itemId: number,
        enabled: boolean
    ): Observable<null> {
        const url: string = String.Format(
            ResourcesConsts.UPDATE_DASHBOARD_ITEM_VISIBILITY,
            dashboardId,
            itemId
        );
        return this.$http
            .put<null>(url, {enabled: enabled});
    }

    reorderReports(
        dashboardId: number,
        itemId: number,
        moveItemData: MoveReportItemData
    ): Observable<null> {
        const url: string = String.Format(
            `${ResourcesConsts.DELETE_DASHBOARD_ITEM}/page-position`,
            dashboardId,
            itemId
        );
        return this.$http
            .put<null>(url, moveItemData);
    }

    updateDashboardFilters(
        dashboardId: number,
        model: DashboardGlobalFilters
    ): Observable<DashboardGlobalFilters> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_DATE_FILTER,
            dashboardId
        );
        return this.$http
            .put<DashboardGlobalFilters>(url, model);
    }

    findDashboardByExternalKey(externalId: string): Observable<Dashboard> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_BY_EXTERNAL_ID,
            externalId
        );
        return this.$http
            .get<Dashboard>(url);
    }

    deleteDashboardById(dashboardId: number): Observable<null> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_BY_ID,
            dashboardId
        );
        return this.$http
            .delete<null>(url);
    }

    cloneDashboardById(dashboardId: number, data): Observable<object> {
        const url: string = String.Format(
            ResourcesConsts.CLONE_DASHBOARD_ITEM,
            dashboardId
        );
        return this.$http
            .post(url, data);
    }

    loadDashboardSettings(dashboardId: number): Observable<DashboardSettings> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_SETTINGS,
            dashboardId
        );
        return this.$http
            .get<DashboardSettings>(url);
    }

    updateDashboardSettings(
        dashboardId: number,
        model: DashboardSettings
    ): Observable<DashboardSettings> {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_SETTINGS,
            dashboardId
        );
        return this.$http
            .put<DashboardSettings>(url, model);
    }

    getIsLiveFiltersGranted(dashboardId: number): Observable<boolean> {
        const url: string = String.Format(
            ResourcesConsts.IS_LIVE_FILTERS_GRANTED,
            dashboardId
        );
        return this.$http
            .get<boolean>(url);
    }

    getQuickDashboardBySurveyId(
        surveyId: number
    ): Observable<number> {
        const url: string = String.Format(
            ResourcesConsts.QUICK_DASHBOARD,
            surveyId
        );
        return this.$http
            .get<number>(url);
    }

    prepareQuickDashboardBySurveyId(
        surveyId: number
    ): Observable<number> {
        const url: string = String.Format(
            ResourcesConsts.QUICK_DASHBOARD,
            surveyId
        );
        return this.$http
            .post<number>(url, {});
    }

    refreshQuickReport(surveyId: number): Observable<null> {
        const url: string = String.Format(
            `${ResourcesConsts.QUICK_DASHBOARD}/refresh`,
            surveyId
        );
        return this.$http
            .post<null>(url, null);
    }

    generatePDF(dashboardId: number) {
        const url: string = String.Format(ResourcesConsts.DASHBOARD_GENERATE_PDF, dashboardId);
        return this.$http.post(url, {orientation: 'Portrait'}).pipe(
            mergeMap(id => {
                return this.fileExportProvider.getExportResult(id as string, ExportType.REPORT_PDF_EXPORT);
            }),
            first()
        );
    }

    getAvailableChartsBySources(data) {
        const url: string = String.Format(
            ResourcesConsts.CHART_TYPES
        );
        return this.$http
            .post(url, data);
    }

    getDataSourceRelatedSurveyItem(reportId: number, reportItemId: number, dataSourceId: number) {
        const url: string = String.Format(
            ResourcesConsts.DASHBOARD_RELATED_SURVEY_ITEM,
            reportId,
            reportItemId,
            dataSourceId
        );
        const headers = new HttpHeaders({ [SkipInterceptor.NOT_FOUND]: 'true' });
        return this.$http
            .get<MatrixItem>(url, { headers });
    }
}
