import { of as observableOf, Observable, of } from 'rxjs';

import { mergeMap, catchError, map, concatMap, first } from 'rxjs/operators';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import {
    AutoLoginSetings,
    IdentityProvider
} from '../../infrastructure/providers/identity.provider';
import { StorageProvider } from '../../infrastructure/providers';
import { IdentityStore } from '../../infrastructure/store';
import { SurveyResponseProvider } from '../../infrastructure/providers/responses/survey-response.provider';
import { ApplicationLanguageService } from '../../infrastructure/services/application-language.service';
import { SsoService } from '../../infrastructure/services';

@Injectable()
export class ResponseDataResolver implements Resolve<any> {
    constructor(
        private surveyResponseProvider: SurveyResponseProvider,
        private identityProvider: IdentityProvider,
        private storageProvider: StorageProvider,
        private identityStore: IdentityStore,
        private applicationLanguageService: ApplicationLanguageService,
        private ssoService: SsoService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | any {
        const invitationToken = route.queryParams.i;
        const resumeKey = route.queryParams.rk;
        const contactId = route.queryParams.uid;
        if (invitationToken) {
            return this.resolveResponseData(this.loginByInvitation(invitationToken));
        }
        if (resumeKey) {
            return this.resolveResponseData(this.loginByResumeKey(resumeKey, contactId));
        }
        return of(null);
    }

    handleTokenStatus(): Observable<any> {
        return this.storageProvider.getIdentity() 
            .pipe(
                first(),
                mergeMap(identity => {
                    return identity ? this.ssoService.checkToken() : of(false);
                }),
                mergeMap(isActiveToken => {
                    return !isActiveToken ? this.identityProvider.logOut(true) : of(null);
                })
            );
    }

    resolveResponseData(initObservable: Observable<any>): Observable<any> {
        let loginData;
        return this.handleTokenStatus()
            .pipe(
                concatMap(() => {
                   return initObservable ;
                }),
                concatMap(ld => {
                    loginData = ld;
                    return this.applicationLanguageService.initLanguageSettings();
                }),
                map(() => loginData)
            );
    }

    loginByInvitation(invitationToken: string): Observable<any> {
        if (!invitationToken)
            return of(null);

        const checkAutoLoginRequest = this.identityProvider.checkRecipientAutologin(
            invitationToken
        );
        return checkAutoLoginRequest.pipe(
            mergeMap(
                (autoLoginSettings: AutoLoginSetings) => {
                    if (!autoLoginSettings || !autoLoginSettings.allow_auto_login) {
                        return of(null);
                    } else {
                        return this.identityProvider
                            .invitationSignIn(invitationToken)
                            .pipe(
                                map(id => {
                                    this.identityStore.doStoreIdentity(id);
                                })
                            );
                    }
                }
            ),
            catchError(err => {
                return of({
                    invalidInvitation: true
                });
            })
        );
    }

    loginByResumeKey(resumeKey, contactId): Observable<any> {
        const responseDataRequest = this.surveyResponseProvider.getResponseDataByResumeKey(
            resumeKey, contactId
        );
        return responseDataRequest.pipe(
            mergeMap(
                (autoLoginSettings: AutoLoginSetings) => {
                    if (!autoLoginSettings.contact_id) {
                        // now or autologin or no action.
                        // contact_id will be null, if user was anonymous or
                        // anonymized by some settings.
                        return of(autoLoginSettings);
                    } else {
                        return this.identityProvider.responseResumeSignIn(resumeKey, autoLoginSettings.contact_id)
                            .pipe(
                                map((id) => {
                                    this.identityStore.doStoreIdentity(id);
                                    return autoLoginSettings;
                                })
                            );
                    }
                }
            ),
            catchError(err => {
                return observableOf(null);
            })
        );
    }
}
