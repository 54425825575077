export class SkipInterceptor {
    public static JWT = 'SkipJwtInterceptor';
    public static NOT_FOUND = 'SkipNotFoundInterceptor';
    public static UNAUTHORIZED = 'SkipUnAuthorizedInterceptor';
    public static URL = 'SkipUrlInterceptor';
    public static ERROR_SHOW = 'SkipErrorShowInterceptor';
    public static FORBIDDEN = 'SkipForbiddenInterceptor';
}

export class InterceptorOptions {
    public static RETRY_ATTEMPTS = 'InterceptorRetryAttempts';
}
