import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../app-admin/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SimpleConfirmationDialogComponent } from '../../app-admin/shared/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { String } from 'typescript-string-operations-ng4';

@Injectable()
export class DialogsService {
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService
    ) {}

    public confirm(
        title: string,
        message: string,
        objectName: string
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<ConfirmationDialogComponent>;

        dialogRef = this.dialog.open(ConfirmationDialogComponent);

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.objectName = objectName;

        return dialogRef.afterClosed();
    }

    public confirmWithTranslate(
        title: string,
        message: string,
        objectName: string,
        objectId = '',
        checkboxRule = false,
        checkboxRuleText = ''
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<ConfirmationDialogComponent>;

        dialogRef = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.title = this.getTranslation(title);
        dialogRef.componentInstance.message = this.getTranslation(message);
        dialogRef.componentInstance.objectName = `${this.getTranslation(
            objectName
        )} ${objectId}`;
        dialogRef.componentInstance.checkboxRule = checkboxRule;
        dialogRef.componentInstance.checkboxRuleText = this.getTranslation(checkboxRuleText);
        return dialogRef.afterClosed();
    }

    public simpleConfirm(message: string, replacerText = '') {
        let dialogRef: MatDialogRef<SimpleConfirmationDialogComponent>;
        this.translateService.get(message).subscribe((data: string) => {
            if (replacerText) {
               data = String.Format(data, replacerText);
            }
            setTimeout(() => {
                dialogRef = this.dialog.open(SimpleConfirmationDialogComponent, { height: '250px', width: '350px'});
                dialogRef.componentInstance.message = data;
                return dialogRef.afterClosed();
            }, 500);
        });
    }

    private getTranslation(text): string {
        let translatedString = '';

        if (text) {
            this.translateService.get(text).subscribe((data: string) => {
                translatedString = data;
            });
        }

        return translatedString;
    }
}
