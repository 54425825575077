import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../consts/resources.consts';
import { SingleSmsMessage, BatchSmsMessage } from '../models/messaging/sms-message.model';

@Injectable({
    providedIn: 'root'
})
export class SmsProvider {

    constructor(private $http: HttpClient) {}

    sendSingleSms(message: SingleSmsMessage): Observable<any> {
        const url = ResourcesConsts.SMS;
        return this.$http
            .post<string>(`${url}`, message);
    }

    sendBatchSms(message: BatchSmsMessage): Observable<any> {
        const url = ResourcesConsts.SMS + '/batch';
        return this.$http
            .post<string>(`${url}`, message);
    }
}
