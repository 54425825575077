import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsProvider {
  private isInitialized = false;

  constructor() {
    const apiKey = environment.analyticsApiKey ?? '';
    const apiHost = environment.analyticsHost ?? '';

    if (apiKey && apiHost) {
      posthog.init(apiKey, { api_host: apiHost, debug: !environment.production });
      this.isInitialized = true;

      if (!environment.production) {
        console.log('PostHog initialized with API Key:', apiKey, 'and Host:', apiHost);
      }
    } else {
      console.warn('PostHog is not initialized: missing API key or host.');
    }
  }

  trackEvent(event: string, properties?: any) {
    if (this.isInitialized) {
      posthog.capture(event, properties);

      if (!environment.production){
        console.log('Event tracked:', event, properties);
      }
    } else {
      console.warn('PostHog event not tracked: PostHog is not initialized.');
    }
  }

  trackPageView(path: string) {
    if (this.isInitialized) {
      this.trackEvent('$pageview', { path });

      if (!environment.production) {
        console.log('Pageview tracked:', path);
      }
    } else {
      console.warn('Pageview not tracked: PostHog is not initialized.');
    }
  }
}
