import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'cb-reset-password-notification',
    templateUrl: './reset-password-notification.component.html',
    styleUrls: ['./reset-password-notification.component.scss']
})
export class ResetPasswordNotificationComponent implements OnInit {
    @Input() message: string;
    constructor() {}

    ngOnInit() {}
}
