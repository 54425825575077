import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cb-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
    @Input() show: boolean;
    @Input() blockLayout: boolean;
}
