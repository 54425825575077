<span mat-dialog-content>{{ message }}</span>
<div mat-dialog-actions class="cb-right">
    <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="dialogRef.close(true)"
    >
        {{ 'SHARED.CLOSE' | translate }}
    </button>
</div>
