export function getInvitationRecipientId(invitationToken: string): string {
    // legacy invitation used recipient guid instead of token with expiration
    if (!invitationToken || invitationToken.indexOf('.') < 0)
        return invitationToken;

    // extract recipient id from invitation token
    try {
        const payload = invitationToken.split('.')[1];
        const parsedPayload = JSON.parse(atob(payload));
        return parsedPayload['rid'];
    } catch (e) {
        return null;
    }
}