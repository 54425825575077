import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../consts/resources.consts';

@Injectable()
export class AdminAppFileUploadProvider {
    constructor(private $http: HttpClient) {}
    importSurveyTextsJSON(file: File, surveyId: string) {
        const headers = new HttpHeaders();
        const form = new FormData();
        form.append('uploadFile', file, file.name);

        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        const options = { headers };

        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEXT,
            surveyId,
            'import'
        );
        return this.$http
            .post(url, form, options);
    }

    parseContactImportCsv(file: File, fields: string[]): Observable<any> {
        const headers = new HttpHeaders();
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('columns', fields.join(','));

        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        const options = { headers };

        const url: string = String.Format(ResourcesConsts.CONTACT_PARSE_CSV);
        return this.$http
            .post(url, form, options);
    }

    importReportsCSV(file: File, surveyId: number): Observable<any> {
        const headers = new HttpHeaders();
        const form = new FormData();
        form.append('file', file, file.name);

        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        const options = { headers };

        const url: string = String.Format(
            ResourcesConsts.IMPORT_RESPONSES_UPLOAD,
            surveyId
        );
        return this.$http
            .post(url, form, options);
    }
}
