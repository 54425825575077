import { ResponsePagePostData } from '../../app-take-survey/models/response-page-post-data.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { CaptchaKeys, Response, ResponseCreateData } from '../../app-take-survey/models';
import { SurveyAppearanceSettings, PagedList } from '../models';
import { ResourcesConsts } from '../../app-take-survey/consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';
import { RequestQueueService } from '../services';
import { SkipInterceptor } from '../../infrastructure/consts/interceptors.const';

@Injectable()
export class TakeSurveyProvider {
    resetCaptchaSubject = new Subject<void>();
    executeCaptchaSubject = new Subject<void>();
    invisibleCaptchaResolveSubject = new Subject<number>();

    constructor(private $http: HttpClient,
        private $requestQueue: RequestQueueService) {}

    createResponse(
        surveyId: number,
        model: ResponseCreateData
    ): Observable<Response> {
        const url: string = String.Format(ResourcesConsts.RESPONSE, surveyId);
        return this.$http
            .post<Response>(url, model);
    }

    changeResponseLanguage(
        surveyId: number,
        responseId: string,
        language: string
    ) {
        const url: string = String.Format(
            ResourcesConsts.RESPONSE_LANGUAGE,
            surveyId,
            responseId
        );
        return this.$http
            .put(url, { language });
    }

    postCurrentPage(
        surveyId: number,
        responseId: string,
        model: ResponsePagePostData
    ): Observable<Response> {
        const url: string = String.Format(
            ResourcesConsts.POST_RESPONSE,
            surveyId,
            responseId
        );
        return this.$requestQueue
            .setQueue(url, this.$http.post(url, model));
    }

    getAppearanceSettings(
        surveyId: number
    ): Observable<SurveyAppearanceSettings> {
        const url: string = String.Format(
            ResourcesConsts.GET_SURVEY_APPEARANCE_SETTINGS,
            surveyId
        );
        return this.$http
            .get<SurveyAppearanceSettings>(url);
    }

    getSignatureImage(surveyId: number, responseId: string, fileId: string): Observable<any> {
        const url = String.Format(ResourcesConsts.GET_RESPONDENT_FILE, surveyId, responseId, fileId);
        return this.$http.get(url, { responseType: 'blob' });
    }

    getCaptchaSiteKeys(): Observable<CaptchaKeys> {
        const url = String.Format(ResourcesConsts.GET_CAPCTHA_SITE_KEYS);
        return this.$http
            .get<CaptchaKeys>(url);
    }

    getResponseById(
        surveyId: string | number,
        responseId: string
    ): Observable<Response> {
        const url: string = String.Format(
            ResourcesConsts.GET_RESPONSE,
            surveyId,
            responseId
        );
        return this.$http
            .get<Response>(url);
    }

    getMySurveys(params) {
        const url: string = ResourcesConsts.MY_SURVEYS;
        return this.$http
            .get<PagedList<any>>(url, { params });
    }
}
