import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { ApplicationSettingsProvider } from '../../app-admin/settings/application-settings/application-settings.provider';
import { RootRedirectOption } from '../../app-admin/settings/application-settings/preferences-application-settings/perferences-application-settings.model';
import { EnvironmentProvider } from '../../infrastructure/providers/environment.provider';
import { mergeMap } from 'rxjs/operators';
import { IdentityStore } from '../../infrastructure/store';
import { of, zip } from 'rxjs';

@Injectable()
export class TakeSurveyRedirectGuard implements CanActivate {
    constructor(
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private router: Router,
        private environmentProvider: EnvironmentProvider,
        private identityStore: IdentityStore
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const applicationSettingsObservable = this.applicationSettingsProvider.getSystemPreferences();
        const identityObservable = this.identityStore.identity;

        return zip(applicationSettingsObservable, identityObservable).pipe(
            mergeMap(([settings, identity]) => {
                switch (settings.root_redirect_option) {
                    case RootRedirectOption.TAKE_SURVEY_LOGIN:
                        this.router.navigate(['/login']);
                        break;
                    case RootRedirectOption.ADMIN_LOGIN:
                        const adminLoginUrl = this.getAdminLoginUrl();
                        window.location.replace(adminLoginUrl);
                        break;
                    case RootRedirectOption.CUSTOM_URL:
                        window.location.replace(
                            settings.root_redirect_custom_url
                        );
                        break;
                    case RootRedirectOption.DEFAULT:
                        if (identity && identity.access_token) {
                            this.identityStore.goToRedirectUri(identity, true);
                        } else {
                            this.identityStore.goToLoginUri();
                        }
                        break;
                    case RootRedirectOption.DISPLAY_MESSAGE:
                    case RootRedirectOption.BLANK:
                        break;
                }
                return of(true);
            })
        );
    }

    getAdminLoginUrl() {
        return `${
            this.environmentProvider.storageHubUrl.split('/hub')[0]
        }/login`;
    }

    getTakeSurveyLoginUrl() {
        return window.location.href + 'login';
    }
}
