import { Injectable } from '@angular/core';
import { catchError, concatMap, map, mergeMap, takeWhile } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { StorageProvider } from '../providers/storage.provider';
import { IdentityStore } from '../store';
import { HttpClient } from '@angular/common/http';
import { ResourcesConsts } from '../consts/resources.const';

@Injectable()
export class SsoService {
    constructor(
        private storageProvider: StorageProvider,
        private identityStore: IdentityStore,
        private httpClient: HttpClient
    ) {
    }

    firstAttemptStarted: boolean;
    alreadyhad401: boolean;


    async takeSurveyAutologin(): Promise<boolean> {
        this.firstAttemptStarted = true;
        let adminTokenValid = false;
        const takeSurveyTokenAvailable = await this.storageProvider.validateIdentity().toPromise();
        if (takeSurveyTokenAvailable) {
            const tokenIsValid = await this.checkToken().toPromise();
            if (tokenIsValid) {
                return true;
            }
        }
        const identity = await this.storageProvider.getIdentity().toPromise();
        const adminTokenTaken = await this.getTokenFromAdminApp(identity).toPromise();
        if (adminTokenTaken) {
            adminTokenValid = await this.checkToken().toPromise();
        }
        if (!adminTokenValid) {
            this.storageProvider.clearIdentity();
            this.identityStore.doCheckToken();
        }
        return true;
    }

    getTokenFromAdminApp(oldId?): Observable<boolean> {
        const oldIdentity = JSON.parse(oldId);
        return this.storageProvider.clearIdentity().pipe(
            mergeMap(() => this.storageProvider.changeToCrossStorage()),
            takeWhile(res => {
                return res;
            }),
            concatMap(() => this.storageProvider.getIdentity()),
            map((adminId) => {
                if (!adminId) {
                    this.storageProvider.changeToLocalStorage();
                    return false;
                }

                const adminIdentity = JSON.parse(adminId);
                if (!oldIdentity || oldIdentity.user_name === adminIdentity.user_name) {
                    this.identityStore.doCheckToken();
                }
                this.storageProvider.changeToLocalStorage();
                return true;
            }),
            catchError((err) => {
                this.storageProvider.changeToLocalStorage();
                return of(true);
            })
        );
    }

    checkToken(): Observable<boolean> {
        return this.httpClient.get(ResourcesConsts.CHECK_TOKEN).pipe(
            map((res: any) => {
                return res.active;
            }));
    }
}
