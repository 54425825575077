import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as xss from 'xss';
import * as _ from 'lodash';
import {IWhiteList} from 'xss';

@Injectable()
export class TakeSurveySanitizerService {
    private readonly standardAttributes: string[] = ['style', 'id', 'class', 'title'];
    private readonly whitelist: IWhiteList;
    constructor(private sanitizer: DomSanitizer) {
        this.whitelist = this.initializeWhiteList();
    }

    transformPipe(content, allowedTags?) {
        const xssFilterOptions = this.getXssFilterOptions(allowedTags);
        const processedHTML = xss.filterXSS(content, xssFilterOptions);
        return this.sanitizer.bypassSecurityTrustHtml(processedHTML);
    }

    getTextContent(html: string): String {
        const xssFilterOptions = this.getXssFilterOptions();
        const div = document.createElement('div');
        div.innerHTML = xss.filterXSS(html, xssFilterOptions);
        return div.textContent || div.innerText || '';
    }

    sanitizeHtml(html: string): String {
        const xssFilterOptions = this.getXssFilterOptions();
        return xss.filterXSS(html, xssFilterOptions);
    }

    private extendWhiteListWithArgs (allowedTags) {
        const whitelist = this.whitelist;

        // merging the objects by properties to avoid _.merge issue with arrays
        if (allowedTags) {
            for (const prop in allowedTags) {
                if (whitelist[prop]) {
                    whitelist[prop] = _.concat(whitelist[prop], allowedTags[prop]);
                } else {
                    whitelist[prop] = allowedTags[prop];
                }
            }
        }
        return whitelist;
    }

    private getXssFilterOptions(allowedTags?) {
        return {
            css: false,
            whiteList: this.extendWhiteListWithArgs(allowedTags)
        };
    }

    private initializeWhiteList(): IWhiteList {
        const defaultWhiteList = xss.getDefaultWhiteList();
        return _.assign(defaultWhiteList, {
            a: [...defaultWhiteList.a, 'rel'],
            button: this.standardAttributes,
            div: this.standardAttributes,
            h1: this.standardAttributes,
            h2: this.standardAttributes,
            h3: this.standardAttributes,
            h4: this.standardAttributes,
            h5: this.standardAttributes,
            h6: this.standardAttributes,
            iframe: [
                'width',
                'srcdoc',
                'src',
                'sandbox',
                'referrerpolicy',
                'name',
                'loading',
                'importance',
                'height',
                'scp',
                'allowpaymentrequest',
                'allowfullscreen',
                'allow'
            ],
            img: [...this.standardAttributes, 'src', 'alt', 'width', 'height'],
            ol: this.standardAttributes,
            p: this.standardAttributes,
            picture: this.standardAttributes,
            source: [...this.standardAttributes, 'srcset', 'media', 'src', 'alt'],
            span: this.standardAttributes,
            table: [...defaultWhiteList.table, ...this.standardAttributes],
            td: [...defaultWhiteList.td, ...this.standardAttributes],
            tr: [...defaultWhiteList.tr, ...this.standardAttributes]
        });
    }
}
