import * as _ from 'lodash';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';
import { environment } from '../../environments/environment';

export class StyleTemplateBackgroundImage {
    // split into 2 classes
    id: string;
    contentCategory: string;
    contentId: number;
    contentSize: number;
    contentType: string;
    fileName: string;
    fileUrl: string;

    constructor(props) {
        _.forEach(props, (value, key) => {
            this[_.camelCase(key)] = value;
        });
    }

    getUrl(currentAccountName: string, apiUrl: string) {
        let url;
        if (this.id) {
            const resourceUrl = String.Format(
                ResourcesConsts.SURVEY_EDITOR_GET_IMAGE,
                this.id
            );
            if (environment.name === 'server') {
                url =
                    apiUrl +
                    resourceUrl
                        .replace(/\{account\}/gim, '')
                        .replace('//', '/');
            } else {
                url =
                    apiUrl +
                    resourceUrl.replace(/\{account\}/gim, currentAccountName);
            }
        }
        return this.fileUrl || this.id ? url : null;
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            json[_.snakeCase(key)] = value;
        });
        return json;
    }
}

export class StyleTemplate {
    id: number;
    name: string;
    header?: string;
    footer?: string;
    isPublic?: boolean = true;
    isEditable?: boolean = true;
    customCss?: string;
    mainFont?: string;
    mainFontSize?: string;
    mainFontColor?: string;
    headerColor?: string;
    headerImage?: StyleTemplateBackgroundImage;
    footerColor?: string;
    footerImage?: StyleTemplateBackgroundImage;
    backgroundColor?: string;
    backgroundImage?: StyleTemplateBackgroundImage;
    buttonColor?: string;
    buttonTextColor?: string;
    showProgressBar: boolean = true;
    isHeaderAbove?: boolean;
    createdBy?: string;

    constructor(props?) {
        _.forEach(props, (value, key) => {
            switch (key) {
                case 'header_image':
                case 'footer_image':
                case 'background_image':
                    if (value) {
                        this[
                            _.camelCase(key)
                        ] = new StyleTemplateBackgroundImage(value);
                    }
                    break;
                default:
                    this[_.camelCase(key)] = value;
            }
        });
    }

    toJSON() {
        const json = {};
        _.forEach(this, (value, key) => {
            switch (key) {
                case 'headerImage':
                case 'footerImage':
                case 'backgroundImage':
                    if (value) {
                        json[
                            `${_.snakeCase(key)}_id`
                        ] = (value as any).id;
                    }
                    break;
                default:
                    json[_.snakeCase(key)] = value;
            }
        });
        return json;
    }
}
