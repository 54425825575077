import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsRegistry } from '../../consts/icons.const';

@Component({
  selector: 'cb-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() text: string;
  @Input() color: string;
  @Input() size: string;
  @Input() icon: string;
  @Input() actionIcon: string;
  @Output() action = new EventEmitter<void>();
  get registryIcon() {
    return this.inIconRegistry(this.icon);
  }
  get registryActionIcon() {
    return this.inIconRegistry(this.actionIcon);
  }
  sizeSmall = 'small';
  sizeMedium = 'medium';
  sizeLarge = 'large';
  colorPrimary = 'primary';
  colorSuccess = 'success';
  colorWarning = 'warning';
  colorDanger = 'danger';
  colorAccent = 'accent';
  colorDefault = 'default';
  validSizes = [this.sizeSmall, this.sizeMedium, this.sizeLarge];
  validColors = [this.colorPrimary, this.colorSuccess, this.colorWarning, this.colorDanger, this.colorAccent, this.colorDefault];
  currentClasses: string = '';
  constructor() { }

  ngOnInit() {
    this.size = this.size || this.sizeMedium;
    this.color = this.color || this.colorDefault;
    this.color = this.validColors.includes(this.color) ? this.color : this.colorDefault;
    this.size = this.validSizes.includes(this.size) ? this.size : this.sizeMedium;
    this.currentClasses = `${this.size} ${this.color}`;
  }
  onActionClicked(){
    this.action.emit();
  }
  inIconRegistry(icon: string) {
    return Object.keys(IconsRegistry).includes(icon);
  }

}
