import { Action } from '@ngrx/store';
import { ExternalSignin, Identity, JWTSignInEffectData, Signin, Signup } from '../../models';
import { ChangePasswordPayload } from '../../models/change-password.model';

export const SIGNIN = '[Auth] Signin';
export const SIGNIN_SUCCESS = '[Auth] Signin Success';
export const CHECK_TOKEN = '[Auth] Check Token';
export const CHECK_TOKEN_SUCCESS = '[Auth] Check Token Success';
export const SIGNUP = '[Auth] Signup';
export const SIGNUP_SUCCESS = '[Auth] Signup Success';
export const SIGNOUT = '[Auth] Signout';
export const SIGNOUT_INVALID_TOKEN = '[Auth] Signout Invalid Token';
export const SIGNOUT_SUCCESS = '[Auth] Signout Success';
export const IDENTITY_FAILED = '[Auth] Identity Failed';
export const UNAUTHORIZED = '[Auth] Unauthorized';
export const SINGIN_WITH_JWT = '[Auth] Signin With JWT';
export const EXTERNAL_SIGNIN = '[Auth] Google Signin';
export const CHANGE_PASSWORD = '[Auth] Change password';

export class SigninAction implements Action {
    readonly type = SIGNIN;

    constructor(public payload: Signin) {}
}

export class ExternalSigninAction implements Action {
    readonly type = EXTERNAL_SIGNIN;

    constructor(public payload: ExternalSignin) {}
}

export class SigninSuccessAction implements Action {
    readonly type = SIGNIN_SUCCESS;

    constructor(public payload: Identity = null) {}
}

export class CheckTokenAction implements Action {
    readonly type = CHECK_TOKEN;
}

export class CheckTokenSuccessAction implements Action {
    readonly type = CHECK_TOKEN_SUCCESS;

    constructor(public payload: Identity) {}
}

export class SignupAction implements Action {
    readonly type = SIGNUP;

    constructor(public payload: Signup) {}
}

export class SignupSuccessAction implements Action {
    readonly type = SIGNUP_SUCCESS;

    constructor(public payload: Identity) {}
}

export class UnauthorizedAction implements Action {
    readonly type = UNAUTHORIZED;
}

export class SignoutAction implements Action {
    readonly type = SIGNOUT;

    constructor() {}
}

export class SignoutInvalidTokenAction implements Action {
    readonly type = SIGNOUT_INVALID_TOKEN;

    constructor() {}
}

export class SignoutSuccessAction implements Action {
    readonly type = SIGNOUT_SUCCESS;

    constructor() {}
}

export class IdentityFailedAction implements Action {
    readonly type = IDENTITY_FAILED;

    constructor() {}
}

export class SignInWithJWTAction implements Action {
    readonly type = SINGIN_WITH_JWT;

    constructor(public payload: JWTSignInEffectData) {
    }
}

export class ChangePasswordAction implements Action {
    readonly type = CHANGE_PASSWORD;

    constructor(public payload: ChangePasswordPayload) {
    }
}

export type Actions =
    | SigninAction
    | SigninSuccessAction
    | CheckTokenAction
    | CheckTokenSuccessAction
    | SignupAction
    | SignupSuccessAction
    | UnauthorizedAction
    | SignoutAction
    | SignoutInvalidTokenAction
    | SignoutSuccessAction
    | IdentityFailedAction
    | SignInWithJWTAction
    | ExternalSigninAction
    | ChangePasswordAction;
