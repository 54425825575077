export const getEnvironmentDomain = (hostUrl: string) => {
    const hostParts = hostUrl.split('.');
    const hostPartsLength = hostParts.length;
    if (hostParts.length > 1) {
        return (
            hostParts[hostPartsLength - 2] +
            '.' +
            hostParts[hostPartsLength - 1]
        );
    } else {
        return hostUrl;
    }
}
