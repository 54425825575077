import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cb-application-not-active',
    templateUrl: './application-not-active.component.html',
    styleUrls: ['./application-not-active.component.scss']
})
export class ApplicationNotActiveComponent implements OnInit {
    domain: string;
    ngOnInit(): void {
        this.domain = window.location.hostname;
    }
}
