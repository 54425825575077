import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../../consts/resources.consts';
import { ZendeskJwt } from '../../models/support/zendesk-jwt.model';

@Injectable()
export class ZendeskSsoProvider {
    constructor(private $http: HttpClient) {}

    getJwt(): Observable<ZendeskJwt> {
        const url: string = ResourcesConsts.ZENDESK_SSO_JWT;

        return this.$http.get<ZendeskJwt>(url);
    }
}
