import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SkipInterceptor } from '../../infrastructure/consts/interceptors.const';

import {
    Contact,
    CreateContactModel,
    Contacts,
    ContactProfile,
    ContactGroup,
    ContactSources,
    MembershipItems,
    ContactData
} from '../models';
import { ResourcesConsts } from '../consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';
import {
    ContactModel,
    ContactsModel,
    createContactGroups,
    createContactProfiles,
    createRoles,
    MembershipsModel
} from '../../infrastructure/models/contact.model';
import {
    addQueryString,
    createPageQuery
} from '../../infrastructure/helpers/string.helpers';
import { PageRequestOptions } from '../../infrastructure/models';

@Injectable()
export class UsersProvider {
    constructor(private $http: HttpClient) {}

    loadContacts(): Observable<Contacts> {
        const url: string = ResourcesConsts.CONTACTS;
        return this.$http.get(url).pipe(
            map((data: any) => new ContactsModel(data))
        );
    }

    loadContactsPage(options: PageRequestOptions, includeDetails: boolean, permission: string = ''): Observable<Contacts> {
        let query = createPageQuery(options);
        query = addQueryString({
            initialString: query,
            paramName: 'include_details',
            paramValue: `${includeDetails}`
        });
        query = addQueryString({
            initialString: query,
            paramName: 'sort_ascending',
            paramValue: 'true'
        });
        query = addQueryString({
            initialString: query,
            paramName: 'permission',
            paramValue: `${permission}`
        });
        const url: string = String.Format(ResourcesConsts.CONTACTS) + query;

        return this.$http.get(url).pipe(
            map((data: any) => new ContactsModel(data))
        );
    }

    loadContact(contactId: string, source = '', skipForbiddenError = false): Observable<Contact> {

        let params = new HttpParams();
        let headers = new HttpHeaders().set(SkipInterceptor.NOT_FOUND, '');
        if (skipForbiddenError) {
            headers = headers.set(SkipInterceptor.FORBIDDEN, '');
        }
        if (contactId.includes('\\')) {
            const splitedId = contactId.split('\\');
            contactId = splitedId[splitedId.length - 1];
        }
        if (source) {
            params = params.set('source_id', source);
        }
        const url: string = String.Format(
            ResourcesConsts.CONTACT_INFO,
            contactId
        );
        return this.$http.get(url , {headers, params}).pipe(
            map((data: any) => new ContactModel(data))
        );
    }

    loadSimpleContactsPage(options: PageRequestOptions, rolesFilter: string): Observable<Contacts> {
        let query = createPageQuery(options);
        query = addQueryString({
            initialString: query,
            paramName: 'include_details',
            paramValue: 'false'
        });
        query = addQueryString({
            initialString: query,
            paramName: 'sort_ascending',
            paramValue: 'true'
        });
        if (rolesFilter) {
            query = addQueryString({
                initialString: query,
                paramName: 'roles',
                paramValue: rolesFilter
            });
        }

        const url: string = String.Format(ResourcesConsts.CONTACTS_LIST) + query;

        return this.$http.get(url).pipe(
            map((data: any) => new ContactsModel(data))
        );
    }

    updateContact(
        contactId: string,
        contactData: Contact,
        sourceId?
    ): Observable<Contact> {
        const query = sourceId ? `?source_id=${sourceId}` : '';
        if (contactId.includes('\\')) {
            const splitedId = contactId.split('\\');
            contactId = splitedId[splitedId.length - 1];
        }
        const url: string = String.Format(
            ResourcesConsts.CONTACT_INFO,
            contactId
        )  + query;
        return this.$http.put(url, contactData).pipe(
            map((data: any) => new CreateContactModel(data))
        );
    }

    addContact(contact: CreateContactModel): Observable<Contact> {
        const url: string = ResourcesConsts.CONTACTS;
        return this.$http.post(url, contact).pipe(
            map((data: any) => new CreateContactModel(data))
        );
    }

    removeContact(contactId: string): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_INFO,
            contactId
        );
        return this.$http
            .delete<void>(url);
    }

    batchRemoveContacts(contactIds: string[], removeResponses) {
        const url: string = ResourcesConsts.CONTACTS + '/bulk-delete';
        const params = {
            contact_ids: contactIds,
            delete_responses: removeResponses
        };
        return this.$http
            .post(url, params);
    }

    loadContactProfileById(contactId: string): Observable<ContactProfile[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_PROFILE,
            contactId
        );
        return this.$http.get(url).pipe(
            map((data: any) => createContactProfiles(data))
        );
    }

    changeContactProfileById(
        contactId: string,
        changes: ContactProfile[]
    ): Observable<ContactProfile[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_PROFILE,
            contactId
        );
        return this.$http.put(url, changes).pipe(
            map((data: any) => createContactProfiles(data))
        );
    }

    loadContactRoles(): Observable<string[]> {
        const url: string = String.Format(ResourcesConsts.ROLES);
        return this.$http.get(url).pipe(
            map((data: any) => createRoles(data))
        );
    }

    loadAvailableContactRoles(): Observable<string[]> {
        const url: string = String.Format(ResourcesConsts.AVAILABLE_ROLES);
        return this.$http.get(url).pipe(
            map((data: any) => createRoles(data))
        );
    }

    loadContactRolesById(contactId: string): Observable<string[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_ROLES,
            contactId
        );
        return this.$http.get(url).pipe(
            map((data: any) => createRoles(data))
        );
    }

    assignContactRolesById(
        contactId: string,
        roles: string[]
    ): Observable<string[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_ROLES,
            contactId
        );
        return this.$http.put(url, roles).pipe(
            map((data: any) => createRoles(data))
        );
    }

    removeContactRolesById(
        contactId: string,
        role: string
    ): Observable<string[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_ROLES_REMOVE,
            contactId,
            role
        );
        return this.$http.delete(url).pipe(
            map((data: any) => createRoles(data))
        );
    }

    loadContactGroupsById(contactId: string): Observable<ContactGroup[]> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_GROUPS,
            contactId
        );
        return this.$http.get(url).pipe(
            map((data: any) => createContactGroups(data))
        );
    }

    putContactGroupsById(
        contactId: string,
        groups: string[]
    ): Observable<ContactGroup[]> {
        if (contactId.includes('\\')) {
            const splitedId = contactId.split('\\');
            contactId = splitedId[splitedId.length - 1];
        }
        const url: string = String.Format(
            ResourcesConsts.CONTACT_GROUPS,
            contactId
        );
        return this.$http.put(url, groups).pipe(
            map((data: any) => createContactGroups(data))
        );
    }

    removeContactGroupsById(
        contactId: string,
        groupId: string
    ): Observable<void> {
        const url: string = String.Format(
            ResourcesConsts.CONTACT_GROUP_REMOVE,
            contactId,
            groupId
        );
        return this.$http
            .delete<void>(url);
    }

    checkLicensingLimits() {
        const url: string = String.Format(
            ResourcesConsts.CHECK_LICENSING_LIMITS,
            'SurveyEditorLimit'
        );
        return this.$http.get(url).pipe(
            map(data => data)
        );
    }

    checkLicensing() {
        const url: string = String.Format(ResourcesConsts.CHECK_LICENSING);
        return this.$http.get<any>(url).pipe(
            map(data => data)
        );
    }

    importContacts(
        contacts: Contact[],
        groups: string[],
        roles: string[],
        update_existing: boolean
    ) {
        const url: string = String.Format(ResourcesConsts.CONTACT_IMPORT);
        return this.$http
            .post<ContactData>(url, { contacts, groups, roles, update_existing });
    }

    getContactSources(): Observable<ContactSources> {
        const url: string = String.Format(ResourcesConsts.CONTACT_SOURCES);
        return this.$http
            .get<ContactSources>(url);
    }

    getCurrentContactLanguage() {
        const url: string = String.Format(ResourcesConsts.CONTACT_LANGUAGE);
        return this.$http
            .get<Array<string>>(url)
            .pipe(
                map(val => val || 'en-US')
            );
    }

    loadMembershipPage(
        options: PageRequestOptions
    ): Observable<MembershipItems> {
        let query = createPageQuery(options);
        query = addQueryString({
            initialString: query,
            paramName: 'include_details',
            paramValue: 'true'
        });
        query = addQueryString({
            initialString: query,
            paramName: 'sort_ascending',
            paramValue: 'true'
        });
        const url: string = String.Format(ResourcesConsts.MEMBERSHIP) + query;

        return this.$http.get(url).pipe(
            map((data: any) => new MembershipsModel(data))
        );
    }
}
