import { Component, Input } from '@angular/core';
import { DashboardFilterType, DashboardItemLiveFilter } from '../../models';
import { getShortenedString } from '../../../infrastructure/helpers/string.helpers';
import * as _ from 'lodash';
import * as moment from 'moment';
import { getNonUtcDateFromString } from '../../../infrastructure/helpers/date.helper';

@Component({
    selector: 'cb-live-filter-text',
    templateUrl: './live-filter-text.component.html'
})
export class LiveFilterTextComponent {
    getNonUtcDateFromString = getNonUtcDateFromString;
    @Input() filtersData;
    @Input() itemData;

    parseDate(str: string) {
        const mmnt = moment(str, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
        return mmnt.isValid() ? mmnt.format('YYYY-MM-DD') : str;
    }

    getAdminFiltersText(filter) {
        let type, leftOperand, operator, rightOperand;
        if (filter) {
            operator = filter.operator;
            switch (filter.filter_type) {
                case DashboardFilterType.SurveyItem:
                    type = 'Survey';
                    if (filter.survey_item) {
                        leftOperand = getShortenedString(filter.survey_item.question_text, 50);
                        rightOperand = _.isString(filter.survey_item.choice_text) ? filter.survey_item.choice_text : filter.value;
                    }
                    break;
                case DashboardFilterType.ContactProfile:
                    type = 'Profile';
                    leftOperand = filter.contact_profile_property_name;
                    rightOperand = filter.value;
                    break;
                case DashboardFilterType.SurveyResponse:
                    type = 'Response';
                    leftOperand = filter.response_property;
                    rightOperand = this.parseDate(filter.value);
                    break;

            }
            return `${type}/${leftOperand} ${operator} ${rightOperand}`;
        }
    }

    getLiveFilterText(filter: DashboardItemLiveFilter, row: number, col: number) {
        let outputString = '';
        switch (filter.source_type) {
            case 'surveyItem':
                outputString = outputString
                    .concat('Survey/')
                    .concat(
                        this.getSurveyQuestionName(
                            filter.source_item,
                            row,
                            col
                        )
                    );
                break;
            case 'profileProperty':
                outputString = outputString
                    .concat('Profile/')
                    .concat(
                        this.getProfileOrResponsePropertyName(
                            filter.source_item
                        )
                    );
                break;
            case 'responseProperty':
                outputString = outputString
                    .concat('Response/')
                    .concat(
                        this.getProfileOrResponsePropertyName(
                            filter.source_item,
                            false
                        )
                    );
                break;
            default:
                break;
        }
        const target = this.getTargetForLiveFilter(filter);
        return outputString
            .concat(' ')
            .concat(filter.operator)
            .concat(' ')
            .concat(target);
    }

    getTargetForLiveFilter(filter) {
        if (filter.choice) {
            return filter.choice.text;
        }
        if (moment(filter.target_value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
            return moment(filter.target_value).format('lll');
        } else {
            return filter.target_value;
        }
    }

    getSurveyQuestionName(sourceItemId, row, col) {
        const question = _.isEmpty(this.filtersData.sourceItemsCollection)
            ? { text: '' }
            : this.filtersData.sourceItemsCollection.find(x => x.orIndex === row && x.andIndex === col) ?
                this.filtersData.sourceItemsCollection.find(x => x.orIndex === row && x.andIndex === col).items.find(x => x.id === sourceItemId)
                : { text: '' };
        return getShortenedString(question.text, 50);
    }

    getProfileOrResponsePropertyName(
        sourceItemId: number,
        isProfileCollection = true
    ) {
        const collection = isProfileCollection
            ? this.filtersData.userProfileProps
            : this.filtersData.responseProps;
        return collection.find(x => x.id === sourceItemId).name;
    }
}
