import {
    SurveyItemAnswerType,
    SurveyQuestionType,
    SurveyRankOrderType,
    SurveySliderValueType
} from '../../infrastructure/consts/surveys.consts';
import {
    HiddenItemValue,
    CompositeQuestionAnswer,
    MaxDiffItemAnswer,
    MaxDiffSetAnswer,
    MultipleChoiceAnswer,
    OrderedChoiceInfo,
    RankOrderItemAnswer,
    ResponseItemSaveData,
    ResponsePagePostData,
    SelectedChoiceInfo,
    SingleChoiceAnswer,
    SliderItemAnswer,
    TextAnswer,
    UploadedFileAnswer,
    CustomSourceDropdownlistAnswer,
    ResponsePageAction
} from '../models';
import * as _ from 'lodash';
import { MatrixItem } from '../../shared/models/survey-items/question-items/matrix';
import { ContactFormField, ContactFormItem } from '../../shared/models/survey-items/question-items/contactFormItem';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SurveyPage } from '../../infrastructure/models/survey-page.model';
import { getEnabledChoices } from '../../infrastructure/helpers/surveys.helper';

@Injectable()
export class SurveyResponsePostDataHelper {
    getHiddenItemsValues(queryParams: Params, hiddenPages: SurveyPage[]) : HiddenItemValue[] {
        const variableNames = [];
        const hiddenItemsValues: HiddenItemValue[] = [];

        hiddenPages.forEach(p => {
            p.items.forEach(item => {
                variableNames.push(item.variable_name);
            });
        });

        variableNames.forEach(variableName => {
            if (queryParams.hasOwnProperty(variableName)) {
                hiddenItemsValues.push(<HiddenItemValue>{
                    variable_name: variableName,
                    value: queryParams[variableName]
                });
            }
        });

        return hiddenItemsValues;
    }

    getResponsePagePostData(
        page: SurveyPage, action: ResponsePageAction, signatureFormData): ResponsePagePostData {
        return {
            page_id: page.id,
            action: action,
            items: _.chain(page.items)
                .filter(x => !x.excluded)
                .filter(x => x.enabled)
                .forEach(item => {
                    this.composeAnswerDependingOnItemType(
                        item,
                        signatureFormData
                    );
                })
                .filter(s => s.answer !== null)
                .map(s =>
                    s.answer && s.answer.subitems
                        ? <ResponseItemSaveData>{
                              item_id: s.id,
                              subitems: s.answer.subitems,
                              answer: null
                          }
                        : <ResponseItemSaveData>{
                              item_id: s.id,
                              answer: s.answer
                          }
                )
                .value()
        };
    }

    private composeAnswerDependingOnItemType(item, signatureFormData?) {
        const enabled_choices = item.choices ? getEnabledChoices(item.choices) : null;
        switch (item.item_type) {
            case SurveyQuestionType.SINGLE_LINE_TEXT:
            case SurveyQuestionType.MULTI_LINE_TEXT:
            case SurveyQuestionType.CAPTCHA:
            case SurveyQuestionType.SUM_TOTAL:
                const answerText = _.isString(item.answer_text) ? item.answer_text : item.default_text;
                const trimmedText = _.trim(answerText);
                if (item.item_type === SurveyQuestionType.CAPTCHA
                    &&  ((item.captcha_type === 'Visible' && !item.visibleRecaptchaSiteKey)
                        || (item.captcha_type === 'Invisible' && !item.inVisibleRecaptchaSiteKey)
                    )
                ) {
                    break;
                }
                item.answer = <TextAnswer>{
                    answer_type: SurveyItemAnswerType.TEXT,
                    text: trimmedText || null,
                    is_required: item.is_required,
                    is_soft_required: item.is_soft_required
                };
                break;
            case SurveyQuestionType.CHECKBOXES:
                if (item.otherOption) {
                    const otherIndex = _.findIndex(enabled_choices, { is_other: true });
                    if (otherIndex && otherIndex > -1) {
                        enabled_choices[otherIndex] = item.otherOption;
                    } else {
                        enabled_choices.push(item.otherOption);
                    }
                }
                if (item.noneOption && item.noneOption.is_default) {
                    const noneOption = {
                        choice_id: item.noneOption.id,
                        text: item.noneOption.text
                    };
                    item.answer = <MultipleChoiceAnswer>{
                        answer_type: SurveyItemAnswerType.MULTIPLE_CHOICE,
                        is_soft_required: item.is_soft_required,
                        choices: [noneOption]
                    };
                } else {
                    item.answer = <MultipleChoiceAnswer>{
                        answer_type: SurveyItemAnswerType.MULTIPLE_CHOICE,
                        is_soft_required: item.is_soft_required,
                        choices: (_.filter(
                            enabled_choices,
                            x => x.is_default
                        ) as any[]).map(
                            o =>
                                <SelectedChoiceInfo>{
                                    choice_id: o.id,
                                    text: o.is_other ? o.other_value : o.text
                                }
                        )
                    };
                }
                break;
            case SurveyQuestionType.IMAGE_CHOICE:
                item.answer = <MultipleChoiceAnswer>{
                    answer_type: SurveyItemAnswerType.MULTIPLE_CHOICE,
                    is_soft_required: item.is_soft_required,
                    choices: (_.filter(
                        enabled_choices,
                        x => x.is_default
                    ) as any[]).map(
                        o =>
                            <SelectedChoiceInfo>{
                                choice_id: o.id
                            }
                    )
                };
                break;
            case SurveyQuestionType.RADIOBUTTONS:
            case SurveyQuestionType.DROPDOWNLIST:
            case SurveyQuestionType.NET_PROMOTER_SCORE:
                item.answer = <SingleChoiceAnswer>{
                    answer_type: SurveyItemAnswerType.SINGLE_CHOICE,
                    choice_id: item.value ? item.value : null,
                    text: item.other_value ? item.other_value : null,
                    is_required: item.is_required,
                    is_soft_required: item.is_soft_required
                };
                break;
            case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                item.answer = <CustomSourceDropdownlistAnswer>{
                    answer_type: SurveyItemAnswerType.CUSTOM_SOURCE_DROPDOWNLIST,
                    value: item.other_value ? item.other_value : item.value ? item.value : null,
                    is_required: item.is_required,
                    is_soft_required: item.is_soft_required
                };
                break;
            case SurveyQuestionType.RATINGSCALE:
                item.answer = <SingleChoiceAnswer>{
                    answer_type: SurveyItemAnswerType.SINGLE_CHOICE,
                    choice_id: item.choice_id,
                    text: null,
                    is_required: item.is_required,
                    is_soft_required: item.is_soft_required
                };
                break;
            case SurveyQuestionType.RATING:
                item.answer = <SingleChoiceAnswer>{
                    answer_type: SurveyItemAnswerType.SINGLE_CHOICE,
                    choice_id: item.selectedScore >= 1 && item.selectedScore <= enabled_choices.length
                        ? enabled_choices[item.selectedScore - 1].id
                        : null,
                    text: null,
                    is_required: item.is_required,
                    is_soft_required: item.is_soft_required
                };
                break;
            case SurveyQuestionType.SLIDER:
                item.answer = <SliderItemAnswer>{
                    answer_type: SurveyItemAnswerType.SLIDER
                };
                item.answer.is_required = item.is_required;
                if (item.value_type === SurveySliderValueType.NUMBER_RANGE) {
                    item.answer.value = item.value
                        ? `${item.value}`
                        : `${item.min_value}`;
                } else {
                    item.answer.choice_id = item.value && item.value <= enabled_choices.length
                        ? enabled_choices[item.value - 1].id
                        : null;
                }
                break;
            case SurveyQuestionType.RANKORDER:
                if (
                    item.rank_order_type === SurveyRankOrderType.DRAGN_DROPPABLE
                ) {
                    item.answer = <RankOrderItemAnswer>{
                        answer_type: SurveyItemAnswerType.RANK_ORDER,
                        is_soft_required: item.is_soft_required,
                        choices: item.choices_to_rank.map(
                            c =>
                                <OrderedChoiceInfo>{
                                    choice_id: c.id,
                                    position: c.position
                                }
                        ),
                        is_required: item.is_required
                    };
                } else if (
                    item.rank_order_type ===
                    SurveyRankOrderType.SELECTABLE_DRAGN_DROP
                ) {
                    item.answer = <RankOrderItemAnswer>{
                        answer_type: SurveyItemAnswerType.RANK_ORDER,
                        is_soft_required: item.is_soft_required,
                        choices: item.selected_choices
                            ? item.selected_choices.map(
                                  c =>
                                      <OrderedChoiceInfo>{
                                          choice_id: c.id,
                                          position: c.position
                                      }
                              )
                            : [],
                        is_required: item.is_required
                    };
                } else {
                    item.answer = <RankOrderItemAnswer>{
                        answer_type: SurveyItemAnswerType.RANK_ORDER,
                        is_soft_required: item.is_soft_required,
                        choices: item.choices.map(c => {
                            if (typeof c.answer === 'number') {
                                return <OrderedChoiceInfo>{
                                    choice_id: c.id,
                                    position: c.answer
                                };
                            }
                        }),
                        is_required: item.is_required
                    };
                    item.answer.choices = item.answer.choices.filter(
                        obj => obj
                    );
                }
                break;
            case SurveyQuestionType.MAXDIFF:
                item.answer = <MaxDiffItemAnswer>{
                    answer_type: SurveyItemAnswerType.MAX_DIFF,
                    is_soft_required: item.is_soft_required,
                    sets: item.sets
                        ? item.sets.map(
                              (c, i) =>
                                  <MaxDiffSetAnswer>{
                                      set_number: i,
                                      best_choice_id: _.find(
                                          c.best_choices,
                                          x => x.checked
                                      )
                                          ? _.find(
                                                c.best_choices,
                                                x => x.checked
                                            ).id
                                          : null,
                                      worst_choice_id: _.find(
                                          c.worst_choices,
                                          x => x.checked
                                      )
                                          ? _.find(
                                                c.worst_choices,
                                                x => x.checked
                                            ).id
                                          : null
                                  }
                          )
                        : [],
                    is_required: item.is_required
                };
                break;
            case SurveyQuestionType.FILE_UPLOAD:
                item.answer = <UploadedFileAnswer>{
                    answer_type: SurveyItemAnswerType.UPLOADED_FILE,
                    is_soft_required: item.is_soft_required,
                    file_id: item.file_id ? item.file_id : null,
                    is_required: item.is_required
                };
                break;
            case SurveyQuestionType.SIGNATURE:
                item.answer = <UploadedFileAnswer>{
                    answer_type: SurveyItemAnswerType.SIGNATURE,
                    is_soft_required: item.is_soft_required,
                    file_id:
                        item.answer && item.answer.file && item.answer.file.id
                            ? item.answer.file.id
                            : null,
                    is_required: item.is_required,
                    has_signature:
                        !_.isEmpty(signatureFormData) ||
                        (item.answer && item.answer.file && item.answer.file.id)
                };
                break;
            case SurveyQuestionType.MATRIX:
                item.answer = <CompositeQuestionAnswer>{
                    item_id: item.item_id,
                    subitems: this.fetchDataFromMatrix(item),
                    is_required: item.is_required
                };
                break;
            case SurveyQuestionType.CONTACT_FORM:
                item.answer = <CompositeQuestionAnswer>{
                    item_id: item.item_id,
                    subitems: this.fetchDataFromContactForm(item),
                    is_required: item.is_required
                };
                break;
            default:
                break;
        }
    }

    private fetchDataFromMatrix(item: MatrixItem): ResponseItemSaveData[] {
        const answers = [];
        if (item.matrixValueAnswers) {
            item.matrixValueAnswers
                .forEach(row => {
                    row
                    .filter(x => {
                        const matrixRow = item.rows.find(r => r.position === x.rowPosition);
                        return !x.isSubheading &&
                            (!item.excluded_rows ||
                                (matrixRow && item.excluded_rows.indexOf(matrixRow.id)) === -1)
                    })
                    .forEach(column => {                       
                        answers.push(<ResponseItemSaveData>{
                            item_id: column.cell_id,
                            answer: this.composeMatrixCellAnswer(
                                column,
                                item.columns
                            ),
                            is_required: column.item && column.item.is_required,
                            is_soft_required: column.item && column.item.is_soft_required
                        });
                        if (column.item && column.item.is_soft_required) {
                            column.item.is_soft_required = false;
                        }
                    });
                });
        }
        return answers;
    }

    private composeMatrixCellAnswer(column, matrixColumns): any {
        switch (column.prototype_item_type) {
            case SurveyQuestionType.SINGLE_LINE_TEXT:
                const answerText = _.isString(column.answer.answer_text) ? column.answer.answer_text : column.answer.default_text;
                const trimmedText = _.trim(answerText);
                return {
                    answer_type: SurveyItemAnswerType.TEXT,
                    text: trimmedText || null
                };
            case SurveyQuestionType.SUM_TOTAL:
                return {
                    answer_type: SurveyItemAnswerType.TEXT,
                    text: column.answer.answer_text
                        ? column.answer.answer_text
                        : null,
                    sum_total_props: {
                        operator: column.answer.operator,
                        total_value: column.answer.total_value,
                        min_value: column.answer.min_value,
                        max_value: column.answer.max_value,
                        column: column.column,
                        columnId: column.answer.columnId
                    }
                };
            case SurveyQuestionType.RADIOBUTTONS:
            case SurveyQuestionType.DROPDOWNLIST:
                return {
                    answer_type: SurveyItemAnswerType.SINGLE_CHOICE,
                    choice_id: column.answer.value ? column.answer.value : null,
                    text: column.answer.other_value
                        ? column.answer.other_value
                        : null
                };
            case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                return {
                    answer_type: SurveyItemAnswerType.CUSTOM_SOURCE_DROPDOWNLIST,
                    value: column.answer.other_value ? column.answer.other_value : column.answer.value ? column.answer.value : null,
                    is_required: column.answer.is_required
                };
            case SurveyQuestionType.CHECKBOXES:
                if (
                    column.answer.noneOption &&
                    column.answer.noneOption.is_default
                ) {
                    return {
                        answer_type: SurveyItemAnswerType.MULTIPLE_CHOICE,
                        choices: [
                            {
                                choice_id: column.answer.noneOption.id,
                                text: column.answer.noneOption.text
                            }
                        ]
                    };
                }
                return {
                    answer_type: SurveyItemAnswerType.MULTIPLE_CHOICE,
                    choices:
                        column.answer.choices &&
                        column.answer.choices
                            .filter(x => x.is_default)
                            .map(
                                o =>
                                    <SelectedChoiceInfo>{
                                        choice_id: o.id,
                                        text: o.text
                                    }
                            )
                };
            case SurveyQuestionType.RATINGSCALE:
                return {
                    answer_type: SurveyItemAnswerType.SINGLE_CHOICE,
                    choice_id: _.get(column, 'answer.choice_id')
                };
            case SurveyQuestionType.SLIDER:
                const answer = <SliderItemAnswer>{
                    answer_type: SurveyItemAnswerType.SLIDER
                };
                const prototypeItem = matrixColumns.find(
                    x => x.position === column.column
                ).prototype_item;
                if (
                    prototypeItem.value_type ===
                    SurveySliderValueType.NUMBER_RANGE
                ) {
                    answer.value = column.answer.value
                        ? `${column.answer.value}`
                        : `${prototypeItem.min_value}`;
                } else {
                    const prototypeItemEnabledChoices = getEnabledChoices(prototypeItem.choices);
                    answer.choice_id = column.answer.value
                        ? prototypeItemEnabledChoices[column.answer.value - 1]
                            ? prototypeItemEnabledChoices[column.answer.value - 1].id
                            : null
                        : null;
                }
                return answer;
            default:
                break;
        }
    }

    private fetchDataFromContactForm(item: ContactFormItem) : ResponseItemSaveData[] {
        const answers = [];
        const responseSubitems = item.subitems || [];
        item.fields.forEach(field => {
            const responseSubitem = responseSubitems.find(si => si.item_id == field.question.id);
            if (field.enabled && (responseSubitem == null || !responseSubitem.excluded)) {
                answers.push(<ResponseItemSaveData>{
                    item_id: field.question.id,
                    answer: this.composeContactFormSubquestionAnswer(field),
                    is_required: field.question.is_required,
                    is_soft_required: field.question.is_soft_required
                });
                if (field.question && field.question.is_soft_required) {
                    field.question.is_soft_required = false;
                }
            }
        });
        return answers;
    }

    private composeContactFormSubquestionAnswer(formField: ContactFormField): any {
        switch (formField.question.item_type) {
            case SurveyQuestionType.SINGLE_LINE_TEXT:
                const answerText = formField.question.answer?.answer_text || '';
                const trimmedText = _.trim(answerText);
                return {
                    answer_type: SurveyItemAnswerType.TEXT,
                    text: trimmedText || null
                };
            default:
                break;
        }
    }
}
