export const fonts = [
    {
        name: 'Andale Mono',
        fontFamily: 'andale mono,times'
    },
    {
        name: 'Arial',
        fontFamily: 'arial,helvetica,sans-serif'
    },
    {
        name: 'Arial Black',
        fontFamily: 'arial black,avant garde'
    },
    {
        name: 'Book Antiqua',
        fontFamily: 'book antiqua,palatino'
    },
    {
        name: 'Calibri',
        fontFamily: 'Calibri'
    },
    {
        name: 'Comic Sans MS',
        fontFamily: 'comic sans ms,sans-serif'
    },
    {
        name: 'Courier New',
        fontFamily: 'courier new,courier'
    },
    {
        name: 'Dosis',
        fontFamily: 'Dosis'
    },
    {
        name: 'Georgia',
        fontFamily: 'georgia,palatino'
    },
    {
        name: 'Helvetica',
        fontFamily: 'helvetica'
    },
    {
        name: 'HelveticaNeue',
        fontFamily: 'HelveticaNeue'
    },
    {
        name: 'Impact',
        fontFamily: 'impact,chicago'
    },
    {
        name: 'Lato',
        fontFamily: 'Lato'
    },
    {
        name: 'MS Sans Serif',
        fontFamily: 'MS Sans Serif'
    },
    {
        name: 'Merriweather Sans',
        fontFamily: 'Merriweather Sans'
    },
    {
        name: 'Raleway',
        fontFamily: 'Raleway'
    },
    {
        name: 'Rubik',
        fontFamily: 'Rubik'
    },
    {
        name: 'Symbol',
        fontFamily: 'symbol'
    },
    {
        name: 'Tahoma',
        fontFamily: 'tahoma,arial,helvetica,sans-serif'
    },
    {
        name: 'Terminal',
        fontFamily: 'terminal,monaco'
    },
    {
        name: 'Times New Roman',
        fontFamily: 'times new roman,times'
    },
    {
        name: 'Trebuchet MS',
        fontFamily: 'trebuchet ms,geneva'
    },
    {
        name: 'Verdana',
        fontFamily: 'verdana,geneva'
    },
    {
        name: 'Webdings',
        fontFamily: 'webdings'
    },
    {
        name: 'Wingdings',
        fontFamily: 'wingdings,zapf dingbats'
    }
];
