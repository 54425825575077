import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourcesConsts } from '../../consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';
import { Observable } from 'rxjs';
import { CustomURLEncoder } from '../../../infrastructure/helpers/urlEncode.helper';
import { Survey, SurveyPage } from '../../models';
@Injectable()
export class SurveyTemplatesProvider {

    constructor(private $http: HttpClient) {}


    loadTemplates( getData ) {
        const url: string = ResourcesConsts.SURVEY_TEMPLATES;
        const params = new HttpParams({encoder: new CustomURLEncoder() }).set('search_text', getData.search_text)
                    .set('page_num', getData.page_num.toString())
                    .set('page_size', getData.page_size.toString());
        return this.$http
            .get<any>(url, { params: params });
    }

    loadAllTemplates() {
        const url: string = ResourcesConsts.SURVEY_TEMPLATES;
        return this.$http
            .get(url);
    }

    getSurveyTemplateById(templateId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE,
            templateId
        );
        return this.$http.get<Survey>(url);
    }

    loadPages(templateId: string) {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE_PAGES,
            templateId.toString()
        );
        return this.$http
            .get<SurveyPage[]>(url);
    }

    getQuestions(
        templateId: string,
        pageId: number
    ): Observable<any[]> {
        const url: string = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE_PAGE_ITEMS,
            templateId,
            pageId
        );
        return this.$http
            .get<any[]>(url);
    }

    deleteTemplate(templateId: number): Observable<any> {
        const url = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE,
            templateId
        );
        return this.$http
            .delete(url);
    }

    updateTemplate(templateId: number, template) {
        const url = String.Format(
            ResourcesConsts.SURVEY_TEMPLATE,
            templateId
        );
        return this.$http
            .put(url, template);
    }

}
