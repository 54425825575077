import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SurveyFolder, Survey } from '../../models';
import { ResourcesConsts } from '../../consts/resources.consts';

@Injectable()
export class FoldersProvider {
    constructor(private $http: HttpClient) {}

    loadFolders(): Observable<SurveyFolder[]> {
        const url: string = ResourcesConsts.SURVEY_FOLDERS;
        return this.$http
            .get<SurveyFolder[]>(url);
    }

    addFolder(surveyFolder: SurveyFolder): Observable<SurveyFolder> {
        const url: string = ResourcesConsts.SURVEY_FOLDERS;
        return this.$http
            .post<SurveyFolder>(url, surveyFolder);
    }

    updateFolder(surveyFolder: SurveyFolder): Observable<SurveyFolder> {
        const url = `${ResourcesConsts.SURVEY_FOLDERS}/${surveyFolder.id}`;
        return this.$http
            .put<SurveyFolder>(url, surveyFolder);
    }

    deleteFolder(folderId: number): Observable<any> {
        const url = `${ResourcesConsts.SURVEY_FOLDERS}/${folderId}`;
        return this.$http
            .delete<any>(url);
    }

    updateSurveysFolder(
        surveyId: number,
        folderId: number
    ): Observable<Survey> {
        const url = `${ResourcesConsts.SURVEYS}/${surveyId}/survey-folder`;
        return this.$http
            .put<Survey>(url, folderId);
    }

    bulkMoveSurveysToFolder(surveyIds: number[], folderId: number) {
        const url: string = ResourcesConsts.SURVEYS + '/bulk-move-to-folder';
        const params = {
            survey_ids: surveyIds,
            folder_id: folderId
        };
        return this.$http.post(url, params);
    }
}
