import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SkipInterceptor } from '../consts/interceptors.const';
import { first, map } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

@Injectable()
export class CustomSourceDropdownlistProvider {
    constructor(private $http: HttpClient) {
    }

    getChoices(url, valueField, customMap: (i: any)=> any = null) {
        const headers = new HttpHeaders({
            [SkipInterceptor.JWT]: 'true',
            [SkipInterceptor.UNAUTHORIZED]: 'true',
            [SkipInterceptor.NOT_FOUND]: 'true'
        });
        return this.$http
            .get(url, { headers })
            .pipe(
                first(),
                map((items: any) => {
                    let choices: any;
                    if (valueField) {
                        choices = items.map(item => item[valueField]);
                    } else {
                        choices = items;
                    }
                    if (customMap) {
                        choices = items.map(customMap);
                    }
                    return choices;
                })
            );
    }
}
