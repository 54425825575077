import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl,
    FormControl
} from '@angular/forms';

@Component({
    selector: 'cb-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    public form: FormGroup;
    public title: string;
    public message: string;
    public objectName: string;
    public checkboxRule: boolean;
    public checkboxRuleText: string;

    static MatchPassword(AC: AbstractControl) {
        const inputEntry = AC.get('delete_confirm').value;
        if (inputEntry !== 'DELETE') {
            AC.get('delete_confirm').setErrors({ MatchPhrase: true });
        } else {
            return null;
        }
    }

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.form = this.fb.group(
            {
                delete_confirm: ['', Validators.required]
            },
            {
                validator: ConfirmationDialogComponent.MatchPassword
            }
        );
        if (this.checkboxRule) {
            this.form.addControl(
                'checkboxRule',
                new FormControl(false)
            );
        }
    }

    closeDialog() {
        const data = this.checkboxRule ? {'confirm' : true, 'confirmCheckboxRule' : this.form.value.checkboxRule} : true;
        this.dialogRef.close(data);
    }
}
