import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourcesConsts } from '../../consts/resources.consts';
import { ContactEmailVerificationCase, ContactEmailVerificationCodeStatus, ContactEmailVerificationSettings, ContactEmailVerificationStatus, ContactEmailVerificationTokenIssueResult } from '../../models/support/email-verification.model';
import { String } from 'typescript-string-operations-ng4';

@Injectable()
export class EmailVerificationProvider {
    constructor(private $http: HttpClient) {
    }

    getEmailVerificationStatus(contactId: string): Observable<ContactEmailVerificationStatus> {
        const url: string = String.Format(
            ResourcesConsts.EMAIL_VERIFICATION_STATUS,
            contactId
        );
        return this.$http
            .get<ContactEmailVerificationStatus>(url);
    }

    sendVerificationCode(contactId: string, verificationCase: ContactEmailVerificationCase, forcely: boolean, email: string, password: string): Observable<any> {
        const url: string = String.Format(
            ResourcesConsts.EMAIL_VERIFICATION_SEND_CODE,
            contactId
        );
        const data = {
            email: email,
            verification_case: verificationCase,
            forcely: forcely,
            password: password
        };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http.post(url, JSON.stringify(data), {headers: headers});
    }

    verifyEmailByCode(contactId: string, code:string, password: string): Observable<ContactEmailVerificationCodeStatus> {
        const url: string = String.Format(
            ResourcesConsts.EMAIL_VERIFICATION_VERIFY,
            contactId
        );
        const data = {
            code: code,
            password: password
        };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .post<ContactEmailVerificationCodeStatus>(url, JSON.stringify(data), {headers: headers});
    }

    issueToken(contactId: string, code:string): Observable<ContactEmailVerificationTokenIssueResult> {
        const url: string = String.Format(
            ResourcesConsts.EMAIL_VERIFICATION_ISSUE_TOKEN,
            contactId
        );
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.$http
            .post<ContactEmailVerificationTokenIssueResult>(url, JSON.stringify(code), {headers: headers});
    }

    getSettings(): Observable<ContactEmailVerificationSettings> {
        const url: string = ResourcesConsts.EMAIL_VERIFICATION_SETTINGS;
        return this.$http
            .get<ContactEmailVerificationSettings>(url);
    }
}
