export class SurveysActions {
    public static RELOAD_LIST = 'reloadList';
    public static LOAD_MORE = 'loadMore';
    public static RELOAD_FAVORITES = 'reloadFavorites';
    public static LOAD_SURVEYS = 'loadSurveys';
    public static PRINT = 'loadSurveys';
}

export class SurveysPagesActions {
    public static RELOAD_PAGES = 'reloadPages';
    public static ACTIVATE_SURVEY_ITEM = 'activateSurveyItem';
    public static CANCEL_SURVEY_ITEM = 'cancelSurveyItem';
    public static ADD_SURVEY_ITEM = 'addSurveyItem';
    public static ADD_SURVEY_ITEM_FROM_TEMPLATE = 'addSurveyItemFromTemplate';
    public static UPDATE_SURVEY_ITEM = 'updateSurveyItem';
    public static ACTIVATE_SURVEY_HIDDEN_ITEM = 'activateSurveyHiddenItem';
    public static ACTIVATE_MATRIX_ITEM = 'activateMatrixItem';
    public static CANCEL_MATRIX_ITEM = 'cancelMatrixItem';
    public static UPDATE_MATRIX_ITEM = 'updateMatrixItem';
    public static ADD_MATRIX_ITEM = 'saveMatrixItem';
    public static CHANGE_SURVEY_STATUS = 'changeSurveyStatus';
    public static CHANGE_SURVEY_LOCK = 'changeSurveyLock';
    public static CHANGE_SURVEY_DATA = 'changeSurveyData';
    public static UPDATE_PAGE_DROPZONES = 'surveyUpdatePageDropzones';
    public static SHARE_PAGE_TITLES = 'pageTitles';
    public static CUSTOM_URL_SETTINGS = 'showCustomUrl';
    public static UPDATE_CONTACT_FORM_FIELD = 'updateContactFormField';
    public static UPDATE_SUBQUESTION_TEXT = 'updateSubquestionText';
    public static CANCEL_CONTACT_FORM_FIELD = 'cancelContactFormField';
}

export class SurveysPageTypes {
    public static HiddenItems = 'HiddenItems';
    public static ContentPage = 'ContentPage';
    public static Completion = 'Completion';
}

export class SurveysSharedData {
    public static SURVEYS_PAGE_SIZE = 20;
    public static DASHBOARDS_PAGE_SIZE = 20;
    public static FAVORITE_SURVEYS_PAGE_SIZE = 3;
    public static CONTACT_PAGE_SIZE = 15;
    public static GROUP_PAGE_SIZE = 15;
    public static ACCESS_SUBJECT_PAGE_SIZE = 5;
    public static INVITATION_PAGE_SIZE = 15;
    public static INVITATION_RECIPIENTS_PAGE_SIZE = 5;
    public static RESPONSES_PAGE_SIZE = 15;
    public static DEFAULT_PAGE_SIZE = 15;
    public static UPLOAD_FILE_MAX_SIZE = 4194304;
}

export class SurveyStatus {
    public static ACTIVE = 'Published';
    public static DRAFT = 'Draft';
    public static ARCHIVED = 'Archived';
}

export class DashboardStatus {
    public static ACTIVE = 'Active';
    public static ARCHIVED = 'Archived';
}

export class EditorSurveyQuestionType {
    public static RADIOBUTTONS = 'RadioButtons';
    public static CHECKBOXES = 'Checkboxes';
    public static DROPDOWNLIST = 'DropdownList';
    public static CUSTOM_SOURCE_DROPDOWNLIST = 'CustomSourceDropdown';
    public static SINGLE_LINE_TEXT = 'SingleLineText';
    public static MULTI_LINE_TEXT = 'MultiLineText';
    public static DRILL_DOWN = 'DrillDown';

    public static RATINGSCALE = 'RadioButtonScale';
    public static RATING = 'Rating';
    public static SLIDER = 'Slider';
    public static NET_PROMOTER_SCORE = 'NetPromoterScore';
    public static RANKORDER = 'RankOrder';
    public static MATRIX = 'Matrix';

    public static CONTACT_FORM = 'ContactForm';
    public static IMAGE_CHOICE = 'ImageChoice';
    public static MAXDIFF = 'MaxDiff';
    public static FILE_UPLOAD = 'FileUpload';
    public static SIGNATURE = 'Signature';
    public static CAPTCHA = 'Captcha';
}

export class SurveyQuestionType extends EditorSurveyQuestionType {
    public static HIDDEN_ITEM = 'HiddenItem';
    public static SUM_TOTAL = 'MatrixSumTotal';
    public static TEXT = 'Text';
}

export class SurveyPrivacyType {
    public static PUBLIC = 'Public';
    public static PASSWORD_PROTECTED = 'PasswordProtected';
    public static INVITATION_ONLY = 'InvitationOnly';
    public static ALL_REGISTERED_USERS = 'AllRegisteredUsers';
    public static ACCESS_CONTROL_LIST = 'AccessControlList';
}

export const SURVEY_PRIVACY = [
    { value: 'Public', viewValue: 'Public', varTitle: '' },
    {
        value: 'PasswordProtected',
        viewValue: 'Password Protected',
        varTitle: 'PASSWORD'
    },
    { value: 'InvitationOnly', viewValue: 'Invitation Only', varTitle: '' },
    {
        value: 'AllRegisteredUsers',
        viewValue: 'All Registered Contacts',
        varTitle: ''
    }
    // { value: 'AccessControlList', viewValue: 'Access List', varTitle: '' },
];

export class SurveyDisplayType {
    public static MESSAGE = 'Message';
    public static IMAGE = 'Image';
    public static SCORING_MESSAGE = 'ScoreMessage';
}

export class SurveyReportType {
    public static REPORT = 'DisplayAnalysis';
    public static SUMMARY = 'DisplayResponse';
}

export class SurveyActionType {
    public static REDIRECT = 'Redirect';
    public static EMAIL_ALERT = 'Email';
    public static EMAIL_RESPONSE = 'EmailResponse';
    public static PROFILE_PROPERTY = 'ProfileUpdater';
    public static JAVASCRIPT = 'Javascript';
    public static WEBHOOK = 'Webhook';
}

export class SurveyPositionType {
    public static TOP = 'Top';
    public static LEFT = 'Left';
    public static BOTTOM = 'Bottom';
    public static RIGHT = 'Right';
    public static CENTER = 'Center';
}

export class SurveyLayoutType {
    public static VERTICAL = 'Vertical';
    public static HORIZONTAL = 'Horizontal';
}

export class SurveyCaptchaType {
    public static VISIBLE = 'Visible';
    public static INVISIBLE = 'Invisible';
}

export class SurveyExpressionsSourceType {
    public static DEFAULT = 'Default';
    public static QUESTION = 'SurveyItem';
    public static USER_ATTRIBUTE = 'ProfileProperty';
    public static RESPONSE_PROPERTY = 'ResponseProperty';
}

export class SurveyExpressionsValueType {
    public static DEFAULT = 'None';
    public static TEXT = 'Text';
    public static DATE = 'Date';
    public static CHOICES = 'Choices';
    public static NONE = 'None';
}

export class SurveySliderValueType {
    public static NUMBER_RANGE = 'NumberRange';
    public static TEXT = 'Text';
    public static IMAGE = 'Image';
}

export class SurveyRankOrderType {
    public static NUMERIC = 'Numeric';
    public static SELECTABLE_DRAGN_DROP = 'SelectableDragnDrop';
    public static DRAGN_DROPPABLE = 'DragnDroppable';
    public static TOP_N = 'TopN';
}

export class SurveyAnswerFormatType {
    public static DATE_USA = 'Date_USA';
    public static DATE_ROTW = 'Date_ROTW';
    public static ALPHA_NUMERIC = 'AlphaNumeric';
    public static ALPHA = 'Alpha';
    public static DECIMAL = 'Decimal';
    public static INTEGER = 'Integer';
    public static EMAIL = 'Email';
    public static MONEY = 'Money';
    public static NUMERIC = 'Numeric';
    public static POSTAL = 'Postal';
    public static PHONE = 'Phone';
    public static SSN = 'SSN';
    public static UPPERCASE = 'Uppercase';
    public static LOWERCASE = 'Lowercase';
    public static URL = 'URL';
    public static REGEX = 'Regex';
}

export class HyperLinkOptionType {
    public static LINK_TO_URL = 'link_to_url';
    public static LINK_TO_RESTART = 'link_to_restart';
    public static REDIRECT_TO_URL = 'redirect_to_url';
    public static RESTART_SURVEY = 'restart_survey';
}

export class SurveyItemAnswerType {
    public static TEXT = 'Text';
    public static SINGLE_CHOICE = 'SingleChoice';
    public static MULTIPLE_CHOICE = 'MultipleChoice';
    public static RANK_ORDER = 'RankOrder';
    public static MAX_DIFF = 'MaxDiff';
    public static UPLOADED_FILE = 'File';
    public static SLIDER = 'Slider';
    public static SIGNATURE = 'Signature';
    public static CUSTOM_SOURCE_DROPDOWNLIST = 'CustomSourceSingleChoice';
}

export interface SurveyViewItem {
    svgName: string;
    title: string;
    tooltip: string;
    matrixColumnTooltip: string;
}

export const SurveyItemView: { [key: string]: SurveyViewItem } = {
    [SurveyQuestionType.SINGLE_LINE_TEXT]: {
        svgName: 'single-line-text',
        title: 'SURVEY-VIEW.SINGLE_LINE_TEXT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SINGLE_LINE_TEXT',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.SINGLE_LINE_TEXT'
    },
    [SurveyQuestionType.RADIOBUTTONS]: {
        svgName: 'radio-button-active',
        title: 'SURVEY-VIEW.RADIOBUTTONS',
        tooltip: 'SURVEY-VIEW.TOOLTIP.RADIOBUTTONS',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.RADIOBUTTONS'
    },
    [SurveyQuestionType.CHECKBOXES]: {
        svgName: 'checkbox',
        title: 'SURVEY-VIEW.CHECKBOXES',
        tooltip: 'SURVEY-VIEW.TOOLTIP.CHECKBOXES',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.CHECKBOXES'
    },
    [SurveyQuestionType.DROPDOWNLIST]: {
        svgName: 'drop-down-list',
        title: 'SURVEY-VIEW.DROPDOWNLIST',
        tooltip: 'SURVEY-VIEW.TOOLTIP.DROPDOWNLIST',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.DROPDOWNLIST'
    },
    [SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST]: {
        svgName: 'drop-down-list',
        title: 'SURVEY-VIEW.CUSTOM_SOURCE_DROPDOWNLIST',
        tooltip: 'SURVEY-VIEW.TOOLTIP.CUSTOM_SOURCE_DROPDOWNLIST',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.CUSTOM_SOURCE_DROPDOWNLIST'
    },
    [SurveyQuestionType.MATRIX]: {
        svgName: 'matrix',
        title: 'SURVEY-VIEW.MATRIX',
        tooltip: 'SURVEY-VIEW.TOOLTIP.MATRIX',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.CONTACT_FORM]: {
        svgName: 'contact_form',
        title: 'SURVEY-VIEW.CONTACT_FORM',
        tooltip: 'SURVEY-VIEW.TOOLTIP.CONTACT_FORM',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.MULTI_LINE_TEXT]: {
        svgName: 'multi-line-text',
        title: 'SURVEY-VIEW.MULTI_LINE_TEXT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.MULTI_LINE_TEXT',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.MAXDIFF]: {
        svgName: 'max-diff',
        title: 'SURVEY-VIEW.MAXDIFF',
        tooltip: 'SURVEY-VIEW.TOOLTIP.MAXDIFF',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.RANKORDER]: {
        svgName: 'rank-order',
        title: 'SURVEY-VIEW.RANKORDER',
        tooltip: 'SURVEY-VIEW.TOOLTIP.RANKORDER',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.RATING]: {
        svgName: 'favorite-completed',
        title: 'SURVEY-VIEW.RATING',
        tooltip: 'SURVEY-VIEW.TOOLTIP.RATING',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.RATINGSCALE]: {
        svgName: 'rating-scale',
        title: 'SURVEY-VIEW.RATINGSCALE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.RATINGSCALE',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.RATINGSCALE'
    },
    [SurveyQuestionType.SLIDER]: {
        svgName: 'slider',
        title: 'SURVEY-VIEW.SLIDER',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SLIDER',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.SLIDER'
    },
    [SurveyQuestionType.NET_PROMOTER_SCORE]: {
        svgName: 'score',
        title: 'SURVEY-VIEW.NET_PROMOTER_SCORE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.NET_PROMOTER_SCORE',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.SUM_TOTAL]: {
        svgName: 'calculator',
        title: 'SURVEY-VIEW.SUM_TOTAL',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SUM_TOTAL',
        matrixColumnTooltip: 'MATRIX-COLUMN-TOOLTIP.SUM_TOTAL'
    },
    [SurveyQuestionType.FILE_UPLOAD]: {
        svgName: 'upload',
        title: 'SURVEY-VIEW.FILE_UPLOAD',
        tooltip: 'SURVEY-VIEW.TOOLTIP.FILE_UPLOAD',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.CAPTCHA]: {
        svgName: 'captcha',
        title: 'SURVEY-VIEW.CAPTCHA',
        tooltip: 'SURVEY-VIEW.TOOLTIP.CAPTCHA',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.SIGNATURE]: {
        svgName: 'signature',
        title: 'SURVEY-VIEW.SIGNATURE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SIGNATURE',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.IMAGE_CHOICE]: {
        svgName: 'ImageChoice',
        title: 'SURVEY-VIEW.IMAGE_CHOICE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.IMAGE_CHOICE',
        matrixColumnTooltip: ''
    },
    [SurveyQuestionType.DRILL_DOWN]: {
        svgName: 'drop-down-list',
        title: 'SURVEY-VIEW.DRILLDOWN',
        tooltip: 'SURVEY-VIEW.TOOLTIP.DRILLDOWN',
        matrixColumnTooltip: ''
    },

    [SurveyDisplayType.MESSAGE]: {
        svgName: 'source-code',
        title: 'SURVEY-VIEW.MESSAGE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.MESSAGE',
        matrixColumnTooltip: ''
    },
    [SurveyDisplayType.IMAGE]: {
        svgName: 'image',
        title: 'SURVEY-VIEW.IMAGE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.IMAGE',
        matrixColumnTooltip: ''
    },
    [SurveyDisplayType.SCORING_MESSAGE]: {
        svgName: 'source-code',
        title: 'SURVEY-VIEW.SCORING_MESSAGE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SCORING_MESSAGE',
        matrixColumnTooltip: ''
    },
    [SurveyReportType.REPORT]: {
        svgName: 'add-piechart',
        title: 'SURVEY-VIEW.REPORT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.REPORT',
        matrixColumnTooltip: ''
    },
    [SurveyReportType.SUMMARY]: {
        svgName: 'responses',
        title: 'SURVEY-VIEW.SUMMARY',
        tooltip: 'SURVEY-VIEW.TOOLTIP.SUMMARY',
        matrixColumnTooltip: ''
    },

    [SurveyActionType.REDIRECT]: {
        svgName: 'link',
        title: 'SURVEY-VIEW.REDIRECT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.REDIRECT',
        matrixColumnTooltip: ''
    },
    [SurveyActionType.EMAIL_ALERT]: {
        svgName: 'merge',
        title: 'SURVEY-VIEW.EMAIL_ALERT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.EMAIL_ALERT',
        matrixColumnTooltip: ''
    },
    [SurveyActionType.EMAIL_RESPONSE]: {
        svgName: 'responses',
        title: 'SURVEY-VIEW.EMAIL_RESPONSE',
        tooltip: 'SURVEY-VIEW.TOOLTIP.EMAIL_RESPONSE',
        matrixColumnTooltip: ''
    },
    [SurveyActionType.PROFILE_PROPERTY]: {
        svgName: 'profile',
        title: 'SURVEY-VIEW.PROFILE_PROPERTY',
        tooltip: 'SURVEY-VIEW.TOOLTIP.PROFILE_PROPERTY',
        matrixColumnTooltip: ''
    },
    [SurveyActionType.JAVASCRIPT]: {
        svgName: 'source-code',
        title: 'SURVEY-VIEW.JAVASCRIPT',
        tooltip: 'SURVEY-VIEW.TOOLTIP.JAVASCRIPT',
        matrixColumnTooltip: ''
    },
    [SurveyActionType.WEBHOOK]: {
        svgName: 'webhook_item',
        title: 'SURVEY-VIEW.WEBHOOK',
        tooltip: 'SURVEY-VIEW.TOOLTIP.WEBHOOK',
        matrixColumnTooltip: ''
    }
};

export class SurveyExpressionsOperatorType {
    public static EQUAL = 'Equal';
    public static NOT_EQUAL = 'NotEqual';
    public static GREATER_THAN = 'GreaterThan';
    public static GREATER_THAN_EQUAL = 'GreaterThanEqual';
    public static LESS_THAN = 'LessThan';
    public static LESS_THAN_EQUAL = 'LessThanEqual';
    public static CONTAINS = 'Contains';
    public static DOES_NOT_CONTAIN = 'DoesNotContain';
    public static NOT_ANSWERED = 'NotAnswered';
    public static ANSWERED = 'Answered';
    public static IS_NULL = 'IsNull';
    public static IS_NOT_NULL = 'IsNotNull';
}

export class SurveyExpressionsOperandType {
    public static STRING = 'String';
    public static CHOICE = 'Choice';
    public static SURVEY_ITEM = 'SurveyItem';
    public static PROFILE_PROPERTY = 'ProfileProperty';
    public static RESPONSE_PROPERTY = 'ResponseProperty';
}

export class PageExpressionsType {
    public static PAGE_BRANCHING_RULES = 'PageBranchingRules';
    public static PAGE_CONDITION = 'PageIncludeCondition';
}

export class PageExpressionsGroupsType {
    public static EXPRESSIONS_GROUP = 'expressionsGroups';
    public static SUBQUESTION_EXPRESSIONS_GROUP = 'subquestionExpressionsGroups';
}

export class HtmlTags {
    public static ROOT_ELEMENT = '<root />';
}
