import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';

import { GlobalErrorHandler } from './errors/global-handler.error';
import {
    AccountNameResolverProvider,
    dataProviders,
    StorageProvider
} from './providers';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { UrlSanitizerInterceptor } from './interceptors/url-sanitizer.interceptor';
import { guards } from './guards';
import { services } from './services';
import { stores } from './store';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { EnvironmentProvider } from './providers/environment.provider';
import { EnvironmentServiceProvider } from './services/environment.service';

export const providers = [
    ...guards,
    ...stores,
    ...dataProviders,
    ...services,
    {
         provide: ErrorHandler,
         useClass: GlobalErrorHandler
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
        deps: [StorageProvider]
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: UrlSanitizerInterceptor,
        multi: true,
        deps: [
            StorageProvider,
            AccountNameResolverProvider,
            EnvironmentProvider
        ]
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    },
    EnvironmentServiceProvider,
    EnvironmentProvider
];
