import { Injectable } from '@angular/core';
import { SurveyPage } from '../../infrastructure/models/survey-page.model';
import * as _ from 'lodash';
import { SurveysPageTypes, SurveyReportType, SurveyQuestionType } from '../../infrastructure/consts/surveys.consts';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { SurveyEditorProvider } from '../../app-admin/providers/survey-editor/survey-editor.provider';
import { TakeSurveyService } from './take-survey.service';
import {
    Response,
    ResponseCreateData,
    ResponsePageAction,
    ResponsePageSubmitResult,
    UploadedFileAnswer
} from '../models';
import { TakeSurveyProvider } from '../providers';
import { SurveyResponsePostDataHelper } from './survey-response-post-data-helper.service';
import { RespondentFileUploadProvider } from '../providers/respondent-file-upload.provider';
import { Params } from '@angular/router';
import { TakeSurveyValidator } from '../../infrastructure/validators/take-survey.validator';
import { AppearanceSSSModel } from '../../infrastructure/models/survey-settings.model';
import { Survey } from '../../infrastructure/models';
import { TakeSurveyPipingService } from '../../infrastructure/services/take-survey-piping.service';
import { SurveyResponseRestoreService } from './survey-response-restore.service';
import {TakeSurveySanitizerService} from '../../shared/services/take-survey-sanitizer.service';

@Injectable()
export class TakeSurveyFlowService {
    constructor(
        private surveyEditorProvider: SurveyEditorProvider,
        private takeSurveyService: TakeSurveyService,
        private takeSurveyProvider: TakeSurveyProvider,
        private surveyResponsePostDataHelper: SurveyResponsePostDataHelper,
        private fileUploadProvider: RespondentFileUploadProvider,
        private takeSurveyPipingService: TakeSurveyPipingService,
        private surveyResponseRestoreService: SurveyResponseRestoreService,
        private surveySanitizerService: TakeSurveySanitizerService
    ) {}

    createResponse(
        surveyId: number,
        surveyPages: SurveyPage[],
        language: string,
        surveyPassword: string,
        queryParams: Params): Observable<Response> {

        const responseOptions = <ResponseCreateData>{
            language: language,
            invitation_token: queryParams.i,
            is_test: queryParams.test === 'true',
            password: surveyPassword
        };

        const hiddenPages = _.filter(
            surveyPages, p => p.page_type === SurveysPageTypes.HiddenItems);

        const loadHiddenPageItemsObservables = _.map(hiddenPages, page => {
            return this.loadPageItems(surveyId, page, responseOptions.language);
        });

        return forkJoin(loadHiddenPageItemsObservables).pipe(
            mergeMap(() => this.takeSurveyService.getAnonymousRespondentId()),
            mergeMap(anonRespondentId => {
                responseOptions.hidden_items = this.surveyResponsePostDataHelper.getHiddenItemsValues(
                    queryParams, hiddenPages);

                responseOptions.anonymous_respondent_id = anonRespondentId;

                return this.takeSurveyProvider.createResponse(
                    surveyId, responseOptions);
            }),
            first()
        );
    }

    setResponseLanguage(
        response: Response,
        language: string,
        surveyPages: SurveyPage[],
        signatureFormData: any[]): Observable<Response> {

        const surveyId = response.survey_id;
        const currentPage = this.getCurrentSurveyPage(response, surveyPages);
        return this.savePageAnswers(currentPage, response, signatureFormData)
            .pipe(
                mergeMap((updatedResponse: Response) => {
                    // send api request to change response language
                    return this.takeSurveyProvider.changeResponseLanguage(surveyId, response.id, language)
                        .pipe(map(() => {
                            updatedResponse.language = language;
                            return updatedResponse;
                        }));
                })
            );
    }

    openCurrentPage(
        survey: Survey,
        response: Response,
        surveyPages: SurveyPage[],
        appearanceSettings: AppearanceSSSModel): Observable<SurveyPage> {
        const currentPage = this.getCurrentSurveyPage(response, surveyPages);
        return this.loadPageItems(response.survey_id, currentPage, response.language)
            .pipe(
                map(() => {
                    this.integrateResponseInCurrentPage(survey, response, currentPage, appearanceSettings);
                    return currentPage;
                })
            );
    }

    clearLoadedPageItems(surveyPages: SurveyPage[]) {
        surveyPages.forEach(page => {
            if (page.items && page.page_type === SurveysPageTypes.ContentPage) {
                delete page.items;
            }
        });
    }

    postCurrentPageAnswers(
        response: Response,
        surveyPages: SurveyPage[],
        action: ResponsePageAction,
        signatureFormData: any[]): Observable<ResponsePageSubmitResult> {

        const surveyId = response.survey_id;
        const currentPage = this.getCurrentSurveyPage(response, surveyPages);
        const answersData = this.surveyResponsePostDataHelper.getResponsePagePostData(
            currentPage, action, signatureFormData);

        if (action === ResponsePageAction.MoveForward) {
            const validationErrors = TakeSurveyValidator.validate(answersData, currentPage, this.surveySanitizerService);
            if (validationErrors && validationErrors.length) {
                return of(<ResponsePageSubmitResult>{
                    is_success: false,
                    validation_errors: validationErrors
                });
            }
        }

        return this.uploadSignatures(response, answersData, signatureFormData)
            .pipe(
                mergeMap(() =>
                    this.takeSurveyProvider.postCurrentPage(
                        surveyId, response.id, answersData)
                        .pipe(
                            map((response) => {
                                this.takeSurveyService.calculatePageScore(currentPage, response);
                                return <ResponsePageSubmitResult>{
                                    is_success: true,
                                    response: response
                                };
                            })
                        )
                )
            );
    }

    private getCurrentSurveyPage(response: Response, surveyPages: SurveyPage[]): SurveyPage {
        const currentPage = response.current_page_id != null
            ? _.find(surveyPages, { id: response.current_page_id })
            : null;

        return currentPage != null
            ? currentPage
            : _.find(surveyPages, { page_type: SurveysPageTypes.ContentPage });
    }

    private loadPageItems(surveyId: number, page: SurveyPage, language: string): Observable<SurveyPage> {
        if (page.items) {
            return of(page);
        } else {
            return this.surveyEditorProvider
                .getSurveyPageItems(surveyId, page.id, language)
                .pipe(
                    map(items => {
                        page.items = items;
                        return page;
                    })
                );
        }
    }

    private savePageAnswers(
        page: SurveyPage,
        response: Response,
        signatureFormData): Observable<Response> {

        const surveyId = response.survey_id;
        const answersData = this.surveyResponsePostDataHelper.getResponsePagePostData(
            page, ResponsePageAction.Save, signatureFormData);

        return this.uploadSignatures(response, answersData, signatureFormData)
            .pipe(
                mergeMap(() =>
                    this.takeSurveyProvider.postCurrentPage(
                        surveyId, response.id, answersData)
                )
            );
    }

    // TODO: move handling of signatures upload to signature item
    private uploadSignatures(response: Response, answersData, signatureFormData: any[]) {
        if (!signatureFormData || !signatureFormData.length) {
            return of(null);
        }

        // send pictures with signature to back-end,
        // receive file id and put it into answersData
        return forkJoin(
            signatureFormData.map(item =>
                this.fileUploadProvider.uploadRespondentFile(
                    response.survey_id, response.id, item.item_id, item.formData))
        ).pipe(
            map(answers => {
                answers.forEach((ans, index) => {
                    const itemAnswer = answersData.items.find(
                        x => x.item_id === signatureFormData[index].item_id
                    ).answer;
                    (<UploadedFileAnswer>itemAnswer).file_id = ans.id;
                    (<UploadedFileAnswer>itemAnswer).file_size = ans.content_size;
                    (<UploadedFileAnswer>itemAnswer).file_name = ans.file_name;
                });
                return null;
            })
        );
    }

    private integrateResponseInCurrentPage(
        survey: Survey,
        response: Response,
        currentSurveyPage: SurveyPage,
        appearanceSettings: AppearanceSSSModel) {

        if (appearanceSettings.isItemOrderRandomized) {
            this.reorderSurveyPageItems(response, currentSurveyPage);
        }

        this.setSurveyDataForPageItems(survey, currentSurveyPage, response);
        this.mergeResponseDataToCurrentPage(currentSurveyPage, response, appearanceSettings);
        // currently that one changes internal properties of objects, using JS prototype-oriented behavior.
        // Change to make it more 'functional'
        this.takeSurveyService.calculatePageScore(currentSurveyPage, response);
    }

    private reorderSurveyPageItems(response: Response, surveyPage?: SurveyPage) {
        if (!surveyPage) {
            return;
        }

        const responsePage = _.find(response.pages, { page_id: surveyPage.id });
        if (!responsePage) {
            return;
        }

        surveyPage.items.forEach(item => {
            const responseItem = responsePage.items.find(x => x.item_id === item.id);
            if (responseItem != null) {
                item.position = responseItem.position;
            }
        });

        surveyPage.items = _.sortBy(surveyPage.items, 'position');
    }

    private setSurveyDataForPageItems(survey: Survey, surveyPage: SurveyPage, response: Response) {
        const surveyData = {
            response: response,
            survey: survey
        };
        surveyPage.items.forEach(item => {
            if (item.item_type === SurveyReportType.SUMMARY) {
                item.surveyData = surveyData;
            } else if (item.item_type === SurveyQuestionType.FILE_UPLOAD ||
                item.item_type === SurveyQuestionType.SIGNATURE) {
                item.surveyId = survey.id;
                item.responseId = response.id;
            }
        });
    }

    private mergeResponseDataToCurrentPage(
        currentPage: SurveyPage, response: Response, appearanceSettings: AppearanceSSSModel) {

        const responsePage = response.pages.find(p => p.page_id === response.current_page_id);

        // handle piping
        currentPage.pipe_values = responsePage.pipe_values;
        this.takeSurveyPipingService.replacePipingCodes(currentPage);

        // handle item numeration
        if (appearanceSettings && appearanceSettings.showItemsNumber) {
            currentPage.settings = {
                first_question_number: responsePage.first_question_number,
                enable_dynamic_item_numbers: appearanceSettings.enableDynamicItemNumber
            };
        }

        // handle items stuff
        currentPage.items.forEach(item => {
            // clear server error messages
            if (item.server_error_messages) {
                item.server_error_messages = [];
            }
            const res_item = responsePage.items.find(
                x => x.item_id === item.id
            );
            // delete excluded rows info if they not present in response
            if (item.excluded_rows && (!res_item || !res_item.excluded_rows)) {
                delete item.excluded_rows;
            }
            // merge item data in weird way
            const merged = { ...item, ...res_item };
            Object.assign(item, merged);
            item = this.takeSurveyService.orderItemChoices(item);
        });

        this.surveyResponseRestoreService.restorePageItems(currentPage, responsePage);
    }
}
