import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Identity } from '../models';
import { AuthConfigConst } from '../consts/auth.consts';
import { LocalStorage, RemoteStorage, Storage } from './storage';
import { Router } from '@angular/router';

@Injectable()
export class StorageProvider {
    private storage: Storage;
    private fn: (data) => void;
    constructor(private route: ActivatedRoute, private router: Router) {
        this.storage = new LocalStorage();
        this.storage.onChange(this.storageChanged.bind(this));
    }

    private storageChanged(data: any) {
        if (this.fn) {
            this.fn(data);
        }
    }

    public getIdentity(): Observable<string> {
        return this.storage.getItem(AuthConfigConst.IDENTITY_KEY);
    }

    public getAccount(): Observable<string> {
        return this.storage.getItem(AuthConfigConst.ACCOUNT_KEY);
    }

    public getExternalId(): Observable<string> {
        return this.storage.getItem(AuthConfigConst.EXTERNAL_ID);
    }

    public validateIdentity(): Observable<boolean> {
        return this.storage.getItem(AuthConfigConst.IDENTITY_KEY).pipe(
            map((val: string) => {
                try {
                    if (!val) {
                        return false;
                    }
                    const identity = <Identity>JSON.parse(val);
                    if (identity && identity.access_token) {
                        return true;
                    }
                } catch (exc) {
                    return false;
                }
            })
        );
    }

    public saveIdentity(identity: string): Observable<boolean> {
        return this.storage.setItem(AuthConfigConst.IDENTITY_KEY, identity);
    }

    public saveAccount(account: string): Observable<boolean> {
        return this.storage.setItem(AuthConfigConst.ACCOUNT_KEY, account);
    }

    public clearExternalIdy(): Observable<boolean> {
        return this.storage.clearItem(AuthConfigConst.EXTERNAL_ID);
    }

    public clearIdentity(): Observable<boolean> {
        return this.storage.clearItem(AuthConfigConst.IDENTITY_KEY);
    }

    public clearIdentityAndAccount(): Observable<boolean> {
        const keys = [
            AuthConfigConst.IDENTITY_KEY,
            AuthConfigConst.ACCOUNT_KEY
        ];
        return this.storage.clearItems(keys);
    }

    public changeToLocalStorage() {
        if (this.storage.constructor !== LocalStorage) {
            this.storage = new LocalStorage();
        }
    }
    public changeToCrossStorage() {
        this.storage = new RemoteStorage();
        return this.storage.checkConnect().pipe(
            catchError(() => {
                this.changeToLocalStorage();
                return observableOf(null);
            })
        );
    }
}
