import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppLimitModel } from '../models/limits.model';
import { ResourcesConsts } from '../../app-admin/consts/resources.consts';
import { String } from 'typescript-string-operations-ng4';

@Injectable()
export class LimitsProvider {
    constructor(private $http: HttpClient) {}

    getLimitByName(limitName: string): Observable<AppLimitModel> {
        const url: string = String.Format(
            ResourcesConsts.CHECK_LICENSING_LIMITS,
            limitName
        );
        return this.$http.get(url).pipe(
            map((data: any) => new AppLimitModel(data))
        );
    }
}
