import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StyleTemplate } from '../../../infrastructure/models/style-template.model';

@Component({
    selector: 'cb-styled-header',
    templateUrl: 'styled-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StyledHeaderComponent implements OnInit {
    @Input() styleTemplate: StyleTemplate;

    constructor() { }

    ngOnInit() {
    }
}
