export class ErrorMessage {
    public static SERVER_ERROR = 'Server error. Connection was lost';
    public static CLIENT_ERROR_MESSAGE =
        'An error has occurred. Please check the Checkbox exception log for more information.';
    public static LOGIN_AFTER_WRONG_DOMAIN_ERROR =
        'You are already logged in under another account. Please try your login again.';
    public static INVALID_SURVEY_PASSWORD_ERROR =
        'Auth is failed. Code: PasswordInvalid';
}

export class ErrorMessageType {
    public static INFO = 'info';
    public static WARNING = 'warning';
    public static ERROR = 'error';
}
